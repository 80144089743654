import axios from 'axios';
import React, { useContext, useState } from 'react'
import PupilContext from '../../../../Components/context/PupilContext';
import { pepCinCpReportApi } from '../../../../constants/apiUrls';
import PepCinCpHistoryModule from './PepCinCpHistoryModel';

const CINCPHoc = (OriginalComponent) => {
  
  
  const WrappedComponent = () => {
    
    const { activePupilId } = useContext(PupilContext);
    const userId = localStorage.getItem("userId");
    const schoolId = localStorage.getItem("schoolId");

    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
    const [historyFieldName, setHistoryFieldName] = useState("");
    
    const saveField = ({
      setIsLoading,
      attribute,
      value,
    }) => {
      setIsLoading(true);
      const url = pepCinCpReportApi.postCinCpReport;
      const formData = new FormData();
      formData.append("schoolId", schoolId);
      formData.append("userId", userId);
      formData.append("attribute", attribute);
      formData.append("value", value);
      formData.append("pepCinCp.pupilId", activePupilId);

      axios.post(url, formData)
        .then(res => {
          console.log(res.data, "Saving response from pep CIN CP Primary");
          setIsLoading(false);
        })
    }

    const textFieldHandler = ({
      setFieldValue,
      fieldName,
      value,
      setIsLoading,
      saveField
    }) => {
      setFieldValue(fieldName, value);
      saveField({
        value,
        setIsLoading,
        attribute: fieldName,
      });
    }

    const OpenHistoryPopup = (fieldName) => {
      setHistoryFieldName(fieldName);
      setIsHistoryModalOpen(true);
    }

    const closeHistoryModal = () => {
      setIsHistoryModalOpen(false);
    }

    const historyModel = () => (
      <PepCinCpHistoryModule
        isHistoryModalOpen={isHistoryModalOpen}
        closeHistoryModal={closeHistoryModal}
        historyModalAtrributeName={historyFieldName}
      />
    )
    

    return (
      <OriginalComponent
        saveField={saveField}
        textFieldHandler={textFieldHandler}
        historyModel={historyModel()}
        openHistoryPopup={OpenHistoryPopup}
      />
    )
  }

  return WrappedComponent;
}

export default CINCPHoc
