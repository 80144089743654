import axios from 'axios';
import moment from 'moment';
import React from 'react'
import { useContext } from 'react';
import Swal from 'sweetalert2';
import { NCCReportApi } from '../../../constants/apiUrls';
import PupilContext from '../../context/PupilContext';
import { getYearGroup, handleEmptyValuesForSaving, showWarningToast } from '../../utils/utilityFunctions';


const StrengthAndNeedHoc = (OriginalComponent) => {

  
  const WrappedComponent = () => {
    const {pupilInfo}  = useContext(PupilContext);
    

    const saveForm = ({ setIsLoading, values, saveCallback=()=>{} }) => {
      setIsLoading(true);
      let postUrl;
      let objectName;
      if (getYearGroup(pupilInfo.classYear) === "post16") {
        objectName = "strengthsAndNeedsPost16";
        postUrl = NCCReportApi.saveNccPost16StrengthAndNeedData;
      } else {
        objectName = "strengthsAndNeeds";
        postUrl = NCCReportApi.saveStrengthAndNeedReport;
      }

      const formData = new FormData();

      Object.entries(values[objectName]).forEach(([key, value]) => {
        const handledValue = handleEmptyValuesForSaving(value);
        formData.append(`${objectName}.${key}`, handledValue);
        
      })

      axios.post(postUrl, formData)
        .then((res) => {
          setIsLoading(false);
          saveCallback();
        })
        .catch(err => {
          setIsLoading(false);
        })

    }

    const onChangeHandler = ({
      fieldName,
      fieldValue,
      setIsLoading,
      saveForm,
      setFieldValue,
      values
    }) => {
      if (pupilInfo.dateofmeeting) {
        setFieldValue(fieldName, fieldValue);
        const updatedValues = {...values};
        const [objectName, attributeName] = fieldName.split(".");
        updatedValues[objectName][attributeName] = fieldValue;
        saveForm({ setIsLoading, "values": updatedValues });
      } else {  
        showWarningToast("Date of Meeting is not selected!")
      }
    }

    const numberOnChangeHandler = ({
      setFieldValue,
      fieldValue,
      saveForm,
      setIsLoading,
      maxDigit,
      fieldName, }) => {
      const onlyNumberCheckRegex = /^[-+]?[0-9]+$/;
      if (maxDigit) {
        if ((onlyNumberCheckRegex.test(fieldValue) || fieldValue === "") && (fieldValue <= maxDigit)) {
          setFieldValue(fieldName, fieldValue);
          saveForm({
            setIsLoading,

          })
        }
      } else {
        if (onlyNumberCheckRegex.test(fieldValue) || fieldValue === "") {
          setFieldValue(fieldName, fieldValue);
          saveForm({
            setIsLoading,
          })
        }
      }
    }

    const dateChangeHandler = ({ date, form, field, setIsLoading }) => {
      const dateToSave = date ? moment(date).format("DD/MM/YYYY") : null;
      const values = form.values;
      form.setFieldValue(field.name, dateToSave);
      saveForm({
        setIsLoading,
        values
      })

    }

    const formValidationWrapper = ({
      fieldName,
      setCompleteButtonLoader,
      saveForm = () => { },
      saveCallback = () => { },
      successCallback = () => { },
      checkForErrors = (errors) => { },
      objectName,
    }) => {
      const formValidation = (values, formik) => {
        if (pupilInfo.dateofmeeting) {
          const updatedValues = { ...values };
          const error = checkForErrors(values);

          if (error && error.trim().length > 0) {
            Swal.fire({
              iconColor: "red",
              icon: 'warning',
              title: error,
              showConfirmButton: false,
              timer: 1500
            })
          } else {
            successCallback(updatedValues);

            const saveCallbackWrapper = () => {
              formik.setFieldValue(fieldName, 1);
              saveCallback();
            }

            saveForm({
              values: updatedValues,
              setIsLoading: setCompleteButtonLoader,
              saveCallback: saveCallbackWrapper,
              objectName,
            })
          }
        } else {
          showWarningToast("Date of meeting is not selected")
        }
      }
      return formValidation;
    }

    const exportPdf = (reportName) => {
      const a = document.createElement("a");
      const url = NCCReportApi.exportPdfReports({
        reportName,
        pupilId: pupilInfo.pupilId,
        schoolId: pupilInfo.schoolId,
        classYear: pupilInfo.classYear,
        termofpep: pupilInfo.termOfPep,
        pupilsName: pupilInfo.fullname,
        dateofmeeting: pupilInfo.dateofmeeting,
      });
      axios.get(url, { responseType: "blob" })
        .then((res) => {
          let objectUrl = window.URL.createObjectURL(res.data);
          a.href = objectUrl;
          a.download = reportName;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        })
        .catch(err => {
          const error = err;
          console.log(err);
          console.log(err);
        })
    }

    const getReportName = () => {
      const yearGroup = getYearGroup(pupilInfo.classYear);

      switch (yearGroup) {

        case "eyfs":
          return "nccStrengthsAndNeeds";

        case "primary":
          return "nccStrengthsAndNeeds";

        case "secondary":
          return "nccStrengthsAndNeeds";

        case "post16":
          return "nccPost16StrengthsAndNeeds";

      }
    }

    return (
      <OriginalComponent
        saveForm={saveForm}
        onChangeHandler={onChangeHandler}
        numberOnChangeHandler={numberOnChangeHandler}
        dateChangeHandler={dateChangeHandler}
        formValidationWrapper={formValidationWrapper}
        exportPdf={exportPdf}
      />
    )
  }

  return WrappedComponent;
}

export default StrengthAndNeedHoc
