import { Field } from 'formik'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useDebouncedCallback } from 'use-debounce/lib'

const DataTextField = ({
  fieldName,
  savePepPrimary,
  onChangeHandler,
  rest={}
}) => {

  const [isLoading, setIsLoading] = useState(false);

  // wrapp normal save method in useDebouncedCallBack to delay the save of input
  const debouncedSavePrimary = useDebouncedCallback(savePepPrimary, 1000);

  return (
    <Field name={fieldName}>
      {
        ({form, field}) => {
          return (
            <div className="relative">
              <input
                type="text"
                className="text-center w-full"
                value={field.value}
                onChange={(e) => onChangeHandler({ 
                  form, 
                  value: e.target.value, 
                  fieldName,
                  saveField: debouncedSavePrimary,
                  setIsLoading,
                })}
                {...rest}
              />
              {
                isLoading &&
                <div className="absolute top-0 left-0 w-full h-full bg-white grid items-center">
                  <Skeleton />
                </div>
              }
            </div>
          )
        }
      }
    </Field>
  )
}

export default DataTextField
