import axios from 'axios'
import { Form, Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { useReducer } from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import IsCompletedContext from '../../../../Components/context/IsCompletedContext'
import PupilContext from '../../../../Components/context/PupilContext'
import CompleteButton from '../../../../Components/NCC/CompleteButton'
import DateField from '../../../../Components/NCC/DateField'
import InputField from '../../../../Components/NCC/InputField'
import NccMeetingHoc from '../../../../Components/NCC/Meeting/NccMeetingHoc'
import NccMeetingSkeleton from '../../../../Components/NCC/Meeting/NccMeetingSkeleton'
import WhoAttendTableRow from '../../../../Components/NCC/Meeting/WhoAttendTableRow'
import SelectField from '../../../../Components/NCC/SelectField'
import TextAreaField from '../../../../Components/NCC/TextAreaField'
import { checkForForTrimmedEmptyValue, showErrorToast, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions'
import { NCCReportApi } from '../../../../constants/apiUrls'
import { setLoading, setNccMeetingPost16Report, setNccMeetingReport } from '../../../../constants/reducerActions'
import nccMeetingReducer from '../../../../reducers/NCC/nccMeetingReducer'
import DataDateField from '../../../Pep/PepData/components/DataDateField'
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader'

const initialState = {
  post16Meeting: null,
  pupilUnique: null,
  subSchool: null,
  pupilVoice: null,
  presentPer: null,
  exclusionCount: null,
  isLoading: false,
}


const NCCMeetingPost16 = ({
  saveForm,
  onChangeHandler,
  numberOnChangeHandler,
  dateChangeHandler,
  attendanceButtonHandler,
  exclusionsButtonHandler,
  exportPdf,
  formValidationWrapper,
}) => {
  const pdfReportName = "nccPost16Meeting";
  const fullPepPdfReportName = "NccFullPep";
  const objectName = "post16Meeting";
  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");

  const { dateOfMeeting, pupilInfo } = useContext(PupilContext);
  const {setIsCompleted} = useContext(IsCompletedContext);

  const [state, dispatch] = useReducer(nccMeetingReducer, initialState);

  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);

  useEffect(() => {
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      dispatch({ type: setLoading, payload: { isLoading: true } });
      const url = NCCReportApi.getNccMeetingPost16({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      axios.get(url)
        .then(res => {
          dispatch({ type: setNccMeetingPost16Report, payload: res.data })
          console.log(res.data, "response from ncc meeting");
        })
        .catch(err => {
          showErrorToast(err);
          dispatch({ stype: setLoading, payload: { isLoading: false } });
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.", { position: "bottom-right" })
    }
    
  }, [dateOfMeeting])

  const initialFormValues  = {
    post16Meeting: state.post16Meeting,
    pupilUnique: state.pupilUnique,
    subSchool: state.subSchool,
    pupilVoice: state.pupilVoice,
    presentPer: state.presentPer,
    exclusionCount: state.exclusionCount,
  }

  const isCompletedCallback = () => {
    setIsCompleted("meetingIsCompleted", true);
    showReportCompleteSuccess("Meeting Post 16 Completed");
  }

  const checkForErrors = (values) => {
    let error = "";

    if (checkForForTrimmedEmptyValue(values.post16Meeting.ypAttended)) {
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.post16Meeting.dtAttended)) {
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.post16Meeting.swAttended)) {
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.post16Meeting.fcAttended)) {
      error = "Please fill the required";
    }
    else if (checkForForTrimmedEmptyValue(values.post16Meeting.vsAttended)) {
      error = "Please fill the required";
    }

    return error;
  }

  const formValidaton = formValidationWrapper({
    saveForm,
    setIsCompleted,
    fieldName: `${objectName}.isCompleted`,
    objectName,
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: isCompletedCallback,
  });

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      onSubmit={formValidaton}
    >
      
      <Form>
        <PepSecondaryHeader
          heading={`NCC Meeting ${""}`}
          buttons={[
            <CompleteButton
              fieldName={`${objectName}.isCompleted`}
              completeButtonLoader={completeButtonLoader}
            />,
            <div className="export-btn">
              <span className="blue-button px-5 py-2 text-sm">Export</span>
              <ul>
                <li>
                  <button type="button" onClick={() => exportPdf(fullPepPdfReportName)}>Full Pep</button>
                </li>
                <li>
                  <button type="button" onClick={() => exportPdf(pdfReportName)}>PDF</button>
                </li>
              </ul>
            </div>
          ]}
        />
        {
          state.isLoading ?
            <NccMeetingSkeleton /> : 
            <div className='ncc-meeting'>
              <h1 className="pupil-title mb-2 mt-4">Who Attended?</h1>

              <div className="flex rounded-3xl bg-white w-full">
                <div className="table-box w-full overflow-x-auto">
                  <table className="table-auto w-full text-center">
                    <thead>
                      <tr>
                        <th className="blue w-1/4 ...">Name</th>
                        <th className="green w-1/4 ...">Title</th>
                        <th className="pink w-1/4 ...">Email</th>
                        <th className="orange w-1/4 ...">Attended? <span className="required">*</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <WhoAttendTableRow
                        name={pupilInfo.fullname}
                        title="Young Person"
                        email={state.pupilUnique?.youngPersonEmail}
                        attended="post16Meeting.ypAttended"
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                      <WhoAttendTableRow
                        name={state?.subSchool?.designatedTeacherName}
                        title="Designated teacher"
                        email={state?.subSchool?.dtEmail}
                        attended={"post16Meeting.dtAttended"}
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                      <WhoAttendTableRow
                        name={state?.pupilUnique?.socialWorker?.socialWorkerName}
                        title="Social Worker"
                        email={state?.pupilUnique?.socialWorker?.socialWorkerEmail}
                        attended={"post16Meeting.swAttended"}
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                      <WhoAttendTableRow
                        name={state?.pupilUnique?.pupilcarer?.pupilsCarerName}
                        title="Pupil Carer"
                        email={state?.pupilUnique?.pupilcarer?.pupilsCarerEmail}
                        attended={"post16Meeting.fcAttended"}
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                      <WhoAttendTableRow
                        name={state?.pupilUnique?.pepLacLink?.lacLinkName}
                        title="Achievement Officer"
                        email={state?.pupilUnique?.pepLacLink?.lacLinkEmail}
                        attended={"post16Meeting.vsAttended"}
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">Please specify any other services involved e.g. Futures, YJS, CAMHS:  </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.otherServicesInvolved"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                  <div className="pb-2">
                    <p className="inline-block font-medium">Pupil voice (Pupil voice pull through from the 'pupil voice' page)?</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.fromThePupilVoice"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Our setting gives our young person a sense of belonging, connection and safety by...</b> (Please list below how you do this e.g. availability of relationships with trusted adults, focus on repair of relationships, environment - having a safe space, sensory awareness etc.)</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.connectionAndSafety"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Our EET setting and team around our young person, supports the ongoing development of our young person's identity by? </b>(e.g. role models, gender, culture, race, heritage, faith)</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.ongoingDevelopmentOfOurYp"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <h2 className="text-xl font-semibold">Attendance</h2>
                    <div className="flex flex-col gap-3 md:gap-4 md:flex-row md:items-center">
                      <div className="flex gap-2 items-center">
                        <label for="">Attendance %: <span className="required">*</span> </label>
                        <div>
                          <InputField
                            fieldName="presentPer"
                            saveForm={saveForm}
                            onChangeHandler={numberOnChangeHandler}

                            rest={{
                              className: "p-2 radio rounded-full border-gray-300 border text-sm w-16",
                              readOnly: true,
                            }}
                          />
                          {/* <input type="text" className="p-2 radio rounded-full border-gray-300 border text-sm w-16" /> */}
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <button
                          type="button"
                          className="blue-button py-2 px-4 text-sm"
                          onClick={attendanceButtonHandler}
                        >Enter Attendance</button>
                      </div>
                    </div>
                  </div>

                  <div className="pb-2">
                    <p className="inline-block font-medium">If below 95%, what actions are being taken or planned?  </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.takenOrPlanned"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full",
                          placeholder: "Comment",
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder="Comment"></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="py-4 widebox-container">
                  <div className="pb-2">
                    <h2 className="inline-block"><b>Exclusions</b></h2>
                    <div className="flex flex-col gap-3 md:gap-4 md:flex-row md:items-center">
                      <div className="flex gap-2 items-center">
                        <label for="">No fo sessions: <span className="required">*</span> </label>
                        <div>
                          <InputField
                            fieldName="exclusionCount"
                            saveForm={saveForm}
                            onChangeHandler={numberOnChangeHandler}

                            rest={{
                              className: "p-2 radio rounded-full border-gray-300 border text-sm w-16",
                              readOnly: true,
                            }}
                          />
                          {/* <input type="text" className="p-2 radio rounded-full border-gray-300 border text-sm w-16" /> */}
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <button
                          type="button"
                          className="blue-button py-2 px-4 text-sm"
                          onClick={attendanceButtonHandler}
                        >Enter Exclusions</button>
                      </div>
                    </div>
                  </div>
                  <div className="pb-2">
                    <p className="inline-block font-medium">If our young person has had an exclusion in the last term, what actions are in place to prevent further exclusions?</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.preventFurtherExclusions"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full",
                          placeholder: "Comment",
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea-small textarea w-full" placeholder="Comment"></textarea> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor="">Do the Strengths and Needs accurately describe our child/young person? </label>
                  <SelectField
                    fieldName={"post16Meeting.strengthsAndNeedsAccurately"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full p-2 text-sm w-24",
                    }}
                  />

                </div>
                <div className="flex flex-col gap-3 py-1">
                  <div className="pb-2">
                    <p className="inline-block font-medium">If no, amend the section as appropriate to reflect our child/young person. </p>

                  </div>
                </div>

                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor="">Has the attendance, attainment and progress of our young person been shared and discussed?  </label>
                  <SelectField
                    fieldName={"post16Meeting.progressSharedAndDiscussed"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full p-2 text-sm w-24",
                    }}
                  />

                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">If not, please do so now.</p>
                    {/* <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.pleaseDoSoNow"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                    </div> */}
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">What's going well?</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.whatGoingWell"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Things to celebrate</b> Please list any achievements linked to education/extra-curricular activities e.g. attendance, grades, activity, progress, engagement, wider community.</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.thingsToCelebrate"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                    </div>
                  </div>
                </div>
                
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Extra curricular activities?</b> We know that a sense of belonging and connections are important, what hobbies/interests or additional activities is our young person engaged in, being invited to or interested in being a part of?</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.extraCurricularActivities"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">Are there any other interests our young person would like to pursue? Include actions to support this.</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.ypWouldLikeToPursue"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 w-full p-4 gap-5">
                <h1 className="pupil-title mb-2 mt-4">Preparation for Education, Employment and Training</h1>
                <div className="table-box overflow-x-auto">
                  <table className="table-auto w-full text-center">
                    <thead>
                      <tr>
                        <th className="blue">Please tick as appropriate:</th>
                        <th className="d-blue">Started</th>
                        <th className="purple">Completed</th>
                        <th className="orange">Comments or actions to support, including who is responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="blue">CV</td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"post16Meeting.cvCommentsOrActions"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rows={1}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                          />
                          {/* <input type="text" className='pep-input w-full' /> */}
                        </td>
                      </tr>
                      <tr>
                        <td className="blue">Covering letter</td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"post16Meeting.coveringLetterCommentsOrActions"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rows={1}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                          />
                          {/* <input type="text" className='pep-input w-full' /> */}
                        </td>
                      </tr>
                      <tr>
                        <td className="blue">Portfolio of achievements e.g. folder of qualification certificates</td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"post16Meeting.portfolioOfAchievementsCommentsOrActions"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rows={1}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                          />
                          {/* <input type="text" className='pep-input w-full' /> */}
                        </td>
                      </tr>
                      <tr>
                        <td className="blue">Uni, college or job application</td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"post16Meeting.jobApplicationCommentsOrActions"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rows={1}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                          />
                          {/* <input type="text" className='pep-input w-full' /> */}
                        </td>
                      </tr>
                      <tr>
                        <td className="blue">Work placement or volunteering</td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="radio">
                            <label className="inline-flex items-center">
                              <input type="radio" className="form-radio" name="" value="Yes" />
                            </label>
                          </div>
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"post16Meeting.workPlacementCommentsOrActions"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rows={1}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                          />
                          {/* <input type="text" className='pep-input w-full' /> */}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 w-full p-4 gap-5">
                <h1 className="pupil-title mb-2 mt-4">Transition planning :</h1>
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor="">Is our young person, due to transition to the next phase of education, employment or training? </label>
                  <SelectField
                    fieldName={"post16Meeting.nextPhaseOfEducation"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full p-2 text-sm w-24",
                    }}
                  />
                  
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>If yes, please detail transition plans below to ensure thorough discussions are held to inform next steps</b> e.g. has a school/college place been applied for, name next setting, dates starting new phase, detail support needed for positive transition including discussions with key staff at next school</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.heldToInformNextSteps"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor=""><b>Does our young person know what support is available to them in next stage of EET?</b> e.g. going to university, further education or training, housing </label>
                  <SelectField
                    fieldName={"post16Meeting.nextStageOfEET"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full p-2 text-sm w-24",
                    }}
                  />
                  
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">If no, detail who will share this information and when by</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.whoWillShareThisInformation"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">Adults' best hopes for our young person </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.bestHopes"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">If our young person did not attend the PEP meeting, who will feedback to them?</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.feedbackToThem"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 w-full p-4 gap-5">

                <h1 className="pupil-title mb-2 mt-4">Next review</h1>
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor=""><b>Date of next review</b> (consider times and locality to include our young person)</label>
                  <DateField
                    saveForm={saveForm}
                    dateChangeHandler={dateChangeHandler}
                    fieldName="post16Meeting.dateOfNextReview"
                    rest={{
                      className: "w-full border border-gray-300 rounded-3xl px-2 py-1",
                    }}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Who to invite</b> (consider staff from next school or year group if due to transition) </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"post16Meeting.whoToInvite"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                    </div>
                  </div>
                </div>


              </div>

            </div >
        }
      </Form>
    </Formik>
  )
}


export default NccMeetingHoc(NCCMeetingPost16)
