import React, { createContext, useReducer } from 'react';
import popupContextReducer from '../../reducers/popupContextReducer';

const PopupContext = createContext();

const initialState = {
  searchCollopse: false,
  linksOpen: false,
  isSettingOpen: false,
}


export const PopupContextProvider = (props) => {
  const [state, dispatch] = useReducer(popupContextReducer, initialState);
  return (
    <PopupContext.Provider value={{popupState: state, popupDisptacher: dispatch}}>
      {props.children}
    </PopupContext.Provider>
  )

}
  

export default PopupContext;
