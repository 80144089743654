import React from 'react'
import { setLoading, setNccEyfs, setUploadedSchoolReports } from '../../constants/reducerActions'

const nccDataEyfsReducer = (state, action) => {
  const data = action.payload;

  switch(action.type) {
    
    case setNccEyfs:
      return {
        dataEyfs: data.dataEyfs,
        presentPer: data.presentPer,
        exclusionCount: data.exclusionCount,
        schoolUploadedFiles: data.schoolUploadedFiles,
        isLoading: false,
      }

    case setUploadedSchoolReports:
      return {
        ...state,
        schoolUploadedFiles: data.schoolReports,
      }
      // return {
      //   buildingRelationshipsDeveBands: action.payload.dataEyfs?.buildingRelationshipsDeveBands,
      //   buildingRelationshipsEmerExpected: action.payload.dataEyfs?.buildingRelationshipsEmerExpected,
      //   buildingRelationshipsProgress: action.payload.dataEyfs?.buildingRelationshipsProgress,
        
      //   EHCP: action.payload.dataEyfs?.commonData.EHCP,
      //   SENSupport: action.payload.dataEyfs?.commonData.SENSupport,
      //   actionsAreInPlace: action.payload.dataEyfs?.commonData.actionsAreInPlace,
      //   actionsBeingTaken: action.payload.dataEyfs?.commonData.actionsBeingTaken,
      //   attendance: action.payload.presentPer,
      //   noOfSessions: action.payload.exclusionCount,
      //   primaryIdentifiedNeed: action.payload.dataEyfs?.commonData.primaryIdentifiedNeed,
      //   youngPersonHaveSEND: action.payload.dataEyfs?.commonData.youngPersonHaveSEND,
        
      //   comprehensionDeveBands: action.payload.dataEyfs?.comprehensionDeveBands,
      //   comprehensionEmerExpected: action.payload.dataEyfs?.comprehensionEmerExpected,
      //   comprehensionProgress: action.payload.dataEyfs?.comprehensionProgress,
      //   created_date: action.payload.dataEyfs?.created_date,
      //   creatingMaterialsDeveBands: action.payload.dataEyfs?.creatingMaterialsDeveBands,
      //   creatingMaterialsEmerExpected: action.payload.dataEyfs?.creatingMaterialsEmerExpected,
      //   creatingMaterialsProgress: action.payload.dataEyfs?.creatingMaterialsProgress,
      //   currentPathway: action.payload.dataEyfs?.currentPathway,
      //   currentWorking: action.payload.dataEyfs?.currentWorking,
      //   dateOfPep: action.payload.dataEyfs?.dateOfPep,
      //   expectationsTarget: action.payload.dataEyfs?.expectationsTarget,
      //   expectedLevelOfDev: action.payload.dataEyfs?.expectedLevelOfDev,
      //   fineMotorDeveBands: action.payload.dataEyfs?.fineMotorDeveBands,
      //   fineMotorEmerExpected: action.payload.dataEyfs?.fineMotorEmerExpected,
      //   fineMotorProgress: action.payload.dataEyfs?.fineMotorProgress,
      //   grossMotorDeveBands: action.payload.dataEyfs?.grossMotorDeveBands,
      //   grossMotorEmerExpected: action.payload.dataEyfs?.grossMotorEmerExpected,
      //   grossMotorProgress: action.payload.dataEyfs?.grossMotorProgress,
      //   howItIsMeasured: action.payload.dataEyfs?.howItIsMeasured,
      //   id: action.payload.dataEyfs?.id,
      //   imaginativeExpressiveDeveBands: action.payload.dataEyfs?.imaginativeExpressiveDeveBands,
      //   imaginativeExpressiveEmerExpected: action.payload.dataEyfs?.imaginativeExpressiveEmerExpected,
      //   imaginativeExpressiveProgress: action.payload.dataEyfs?.imaginativeExpressiveProgress,
      //   isCompleted: action.payload.dataEyfs?.isCompleted,
      //   isDeleted: action.payload.dataEyfs?.isDeleted,
      //   listnAttnUnderstandingDeveBands: action.payload.dataEyfs?.listnAttnUnderstandingDeveBands,
      //   listnAttnUnderstandingEmerExpected: action.payload.dataEyfs?.listnAttnUnderstandingEmerExpected,
      //   listnAttnUnderstandingProgress: action.payload.dataEyfs?.listnAttnUnderstandingProgress,
      //   makingGoodProgress: action.payload.dataEyfs?.makingGoodProgress,
      //   managingSelfDeveBands: action.payload.dataEyfs?.managingSelfDeveBands,
      //   managingSelfEmerExpected: action.payload.dataEyfs?.managingSelfEmerExpected,
      //   managingSelfProgress: action.payload.dataEyfs?.managingSelfProgress,
      //   nameOfYoungPerson: action.payload.dataEyfs?.nameOfYoungPerson,
      //   naturalWorldDeveBands: action.payload.dataEyfs?.naturalWorldDeveBands,
      //   naturalWorldEmerExpected: action.payload.dataEyfs?.naturalWorldEmerExpected,
      //   naturalWorldProgress: action.payload.dataEyfs?.naturalWorldProgress,
      //   numberDeveBands: action.payload.dataEyfs?.numberDeveBands,
      //   numberEmerExpected: action.payload.dataEyfs?.numberEmerExpected,
      //   numberProgress: action.payload.dataEyfs?.numberProgress,
      //   numericalPatternsDeveBands: action.payload.dataEyfs?.numericalPatternsDeveBands,
      //   numericalPatternsEmerExpected: action.payload.dataEyfs?.numericalPatternsEmerExpected,
      //   numericalPatternsProgress: action.payload.dataEyfs?.numericalPatternsProgress,
      //   pastPresentDeveBands: action.payload.dataEyfs?.pastPresentDeveBands,
      //   pastPresentEmerExpected: action.payload.dataEyfs?.pastPresentEmerExpected,
      //   pastPresentProgress: action.payload.dataEyfs?.pastPresentProgress,
      //   progressOverTheLastTerm: action.payload.dataEyfs?.progressOverTheLastTerm,
      //   pupilCulutreCommuDeveBands: action.payload.dataEyfs?.pupilCulutreCommuDeveBands,
      //   pupilCulutreCommuEmerExpected: action.payload.dataEyfs?.pupilCulutreCommuEmerExpected,
      //   pupilCulutreCommuProgress: action.payload.dataEyfs?.pupilCulutreCommuProgress,
      //   receptionScore: action.payload.dataEyfs?.receptionScore,
      //   schoolReportUploaded: action.payload.dataEyfs?.schoolReportUploaded,
      //   selfRegulationDeveBands: action.payload.dataEyfs?.selfRegulationDeveBands,
      //   selfRegulationEmerExpected: action.payload.dataEyfs?.selfRegulationEmerExpected,
      //   selfRegulationProgress: action.payload.dataEyfs?.selfRegulationProgress,
      //   speakingDeveBands: action.payload.dataEyfs?.speakingDeveBands,
      //   speakingEmerExpected: action.payload.dataEyfs?.speakingEmerExpected,
      //   speakingProgress: action.payload.dataEyfs?.speakingProgress,
      //   targetedCurriculum: action.payload.dataEyfs?.targetedCurriculum,
      //   updated_date: action.payload.dataEyfs?.updated_date,
      //   wordReadingDeveBands: action.payload.dataEyfs?.wordReadingDeveBands,
      //   wordReadingEmerExpected: action.payload.dataEyfs?.wordReadingEmerExpected,
      //   wordReadingProgress: action.payload.dataEyfs?.wordReadingProgress,
      //   writingDeveBands: action.payload.dataEyfs?.writingDeveBands,
      //   writingEmerExpected: action.payload.dataEyfs?.writingEmerExpected,
      //   writingProgress: action.payload.dataEyfs?.writingProgress,
      //   schoolUploadedFiles: action.payload.schoolUploadedFiles,
      //   isLoading: false,
      // }

    case setLoading:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
  }
}

export default nccDataEyfsReducer
