import React from 'react';
import { setAuthenticatedUser, setStateReady } from '../constants/reducerActions';

const mainContextReducer = (state, action) => {
  switch(action.type) {
    case setAuthenticatedUser:
      return {
        ...state,
        isAuthinticated: true,
        roleId: action.payload.roleId,
        schoolId: action.payload?.schoolId,
      }

    case setStateReady:
      return {
        ...state,
        isStateReady: true,
      }
  }
}

export default mainContextReducer;
