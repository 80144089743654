import React, { useContext } from 'react';
import PupilContext from '../../../Components/context/PupilContext';
import PepCinCPEyfs from './components/PepCinCpEyfs';
import PepCinCPPost16 from './components/PepCinCpPost16';
import PepCinCPPrimary from './components/PepCinCpPrimary';
import PepCinCPSecondary from './components/PepCinCpSecondary';


const PepCinCP = () => {
    // Context Pupil info
  const {pupilInfo} = useContext(PupilContext);
  const schoolId = localStorage.getItem("schoolId");
  const componentToRenderOnClassYear = {
    component: null,
  }

  const classYear = pupilInfo.classYear;
   if (
    classYear === "N" || 
    classYear === "N1" || 
    classYear === "N2" || 
    classYear === "N3" || 
    classYear === "N4"
  ) {
    componentToRenderOnClassYear.component =  <PepCinCPEyfs />;
  } else if (
    classYear === "Y1" ||
    classYear === "Y2" ||
    classYear === "Y3" ||
    classYear === "Y4" ||
    classYear === "Y5" ||
    classYear === "Y6" 
  ) {
    componentToRenderOnClassYear.component = <PepCinCPPrimary />;
  } else if (
    classYear === "Y7" ||
    classYear === "Y8" ||
    classYear === "Y9" ||
    classYear === "Y10" ||
    classYear === "Y11"
  ) {
    componentToRenderOnClassYear.component = <PepCinCPSecondary />;
  } else if (
    classYear === "Y12" ||
    classYear === "Y13" ||
    classYear === "Y14" ||
    classYear === "Y15" ||
    classYear === "Y16"
  ) {
    componentToRenderOnClassYear.component = <PepCinCPPost16 />;
  } else {
    componentToRenderOnClassYear.component = <PepCinCPPrimary />;
  }

    return (
        <>
          {componentToRenderOnClassYear.component !== null && componentToRenderOnClassYear.component }
        </>
      );
    
}

export default PepCinCP;