import axios from 'axios';
import moment from 'moment';
import React from 'react'
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { NCCReportApi } from '../../../constants/apiUrls';
import PupilContext from '../../context/PupilContext';
import { routeUrl } from '../../utils/RouteUrl';
import { getYearGroup, handleEmptyValuesForSaving, showWarningToast } from '../../utils/utilityFunctions';

const NccMeetingHoc = (OriginalComponent) => {
  
  const WrappedComponent = () => {

    const {pupilInfo} = useContext(PupilContext);
    const history = useHistory();

    const saveForm = ({ setIsLoading, values, saveCallback = ()=>{} }) => {
      setIsLoading(true);
      let postUrl;
      let objectName;
      if (getYearGroup(pupilInfo.classYear) === "post16") {
        objectName = "post16Meeting";
        postUrl = NCCReportApi.saveNccMeetingPost16;
      } else {
        objectName = "nccPepMeeting";
        postUrl = NCCReportApi.saveNccMeeting;
      }
      const formData = new FormData();

      objectName && Object.entries(values[objectName]).forEach(([key, value]) => {
        const handledValue = handleEmptyValuesForSaving(value);
        formData.append(`${objectName}.${key}`, handledValue);
        // formData.append(`${objectName}.${key}`, value ? value : "");
      })
      
      values["pupilVoice"] && Object.entries(values["pupilVoice"]).forEach(([key, value]) => {
        const handledValue = handleEmptyValuesForSaving(value);
        formData.append(`pupilVoice.${key}`, handledValue);
        // formData.append(`pupilVoice.${key}`, value ? value : "");
      })

      axios.post(postUrl, formData)
        .then((res) => {
          console.log(res.data, "saving response from ncc meeting");
          setIsLoading(false);
          saveCallback();
        })
        .catch(err => {
          showWarningToast("Error while saving Meeting Data");
          setIsLoading(false);
        })

    }

    const onChangeHandler = ({
      fieldName,
      fieldValue,
      setIsLoading,
      saveForm,
      setFieldValue,
      values
    }) => {
      setFieldValue(fieldName, fieldValue);
      const updatedValues = {...values};
      const splittedFieldNames = fieldName.split(".");
      if (splittedFieldNames.length === 1) {
        updatedValues[splittedFieldNames[0]] = fieldValue;

      } else if (splittedFieldNames.length === 2) {
        updatedValues[splittedFieldNames[0]][splittedFieldNames[1]] = fieldValue;

      } else if (splittedFieldNames.length === 3) {
        updatedValues[splittedFieldNames[0]][splittedFieldNames[1]][splittedFieldNames[2]] = fieldValue;
      }
      saveForm({ setIsLoading, "values": updatedValues });
    }

    const numberOnChangeHandler = ({
      setFieldValue,
      fieldValue,
      saveForm,
      setIsLoading,
      maxDigit,
      fieldName, }) => {
      const onlyNumberCheckRegex = /^[-+]?[0-9]+$/;
      if (maxDigit) {
        if ((onlyNumberCheckRegex.test(fieldValue) || fieldValue === "") && (fieldValue <= maxDigit)) {
          setFieldValue(fieldName, fieldValue);
          saveForm({
            setIsLoading,

          })
        }
      } else {
        if (onlyNumberCheckRegex.test(fieldValue) || fieldValue === "") {
          setFieldValue(fieldName, fieldValue);
          saveForm({
            setIsLoading,
          })
        }
      }
    }

    const dateChangeHandler = ({ date, form, field, setIsLoading, saveForm, fieldName }) => {
      const dateToSave = date ? moment(date).format("DD/MM/YYYY") : null;
      form.setFieldValue(fieldName, dateToSave);
      const values = form.values;
      const splittedFieldName = fieldName.split(".");

      switch (splittedFieldName.length) {
        case 1:
          values[splittedFieldName[0]] = dateToSave;
          break;
        case 2:
          values[splittedFieldName[0]][splittedFieldName[1]] = dateToSave;
          break;
        case 3:
          values[splittedFieldName[0]][splittedFieldName[1]][splittedFieldName[2]] = dateToSave;
          break;
      }

      saveForm({
        setIsLoading,
        values
      })

    }

    const attendanceButtonHandler = () => {
      history.push(routeUrl.basic_info_attendance);
    }

    const exclusionsButtonHandler = () => {
      history.push(routeUrl.basic_info_exclusions);
    }

    const updateIsCompleted = (values, objectName) => {
      values[objectName]["isCompleted"] = 1;
    }


    const formValidationWrapper = ({
      fieldName,
      setCompleteButtonLoader,
      saveForm = () => { },
      saveCallback = () => { },
      checkForErrors = (errors) => { },
      objectName,
    }) => {
      const formValidation = (values, formik) => {
        if (pupilInfo.dateofmeeting) {
          const updatedValues = { ...values };
          const error = checkForErrors(values);

          if (error && error.trim().length > 0) {
            Swal.fire({
              iconColor: "red",
              icon: 'warning',
              title: error,
              showConfirmButton: false,
              timer: 1500
            })
          } else {
            updateIsCompleted(updatedValues, objectName);
            const saveCallbackWrapper = () => {
              formik.setFieldValue(fieldName, 1);
              saveCallback();
            }
            saveForm({
              values: updatedValues,
              setIsLoading: setCompleteButtonLoader,
              saveCallback: saveCallbackWrapper,
              objectName,
            })
          }
        } else {
          showWarningToast("Date of meeting is not selected")
        }
      }
      return formValidation;
    }

    const getReportName = () => {
      const yearGroup = getYearGroup(pupilInfo.classYear);

      switch (yearGroup) {
        case "eyfs":
          return "nccMeeting";

        case "primary":
          return "nccMeeting";

        case "secondary":
          return "nccMeeting";

        case "post16":
          return "nccPost16Meeting";
      }
    }

    const exportPdf = (reportName) => {
      const a = document.createElement("a");
      const url = NCCReportApi.exportPdfReports({
        reportName: reportName,
        pupilId: pupilInfo.pupilId,
        schoolId: pupilInfo.schoolId,
        classYear: pupilInfo.classYear,
        termofpep: pupilInfo.termOfPep,
        pupilsName: pupilInfo.fullname,
        dateofmeeting: pupilInfo.dateofmeeting,
      });
      axios.get(url, { responseType: "blob" })
        .then((res) => {
          let objectUrl = window.URL.createObjectURL(res.data);
          a.href = objectUrl;
          a.download = reportName;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        })
        .catch(err => {
          const error = err;
          console.log(err);
          console.log(err);
        })
    }

    return (
      <OriginalComponent
        saveForm={saveForm}
        onChangeHandler={onChangeHandler}
        numberOnChangeHandler={numberOnChangeHandler}
        dateChangeHandler={dateChangeHandler}
        attendanceButtonHandler={attendanceButtonHandler}
        exclusionsButtonHandler={exclusionsButtonHandler}
        formValidationWrapper={formValidationWrapper}
        reportName={getReportName()}
        exportPdf={exportPdf}
      />
    )
  }

  return WrappedComponent;
}

export default NccMeetingHoc

