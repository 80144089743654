import {
  closeHistoryModal,
  loading,
  notLoading,
  openHistoryModal,
  success,
} from "../constants/reducerActions";

const pupilReducer = (state, { type, payload }) => {
  console.log(payload,'pppppppppppppppppppppppp')
  switch (type) {
    case success:
      console.log(payload, "pupil data");
      return {
        ...state,
        isLoading: false,
        forename: payload?.pupilInfo?.forename,
        surname: payload?.pupilInfo?.surname,
        upn: payload?.pupilInfo?.upn,
        uln: payload?.pupilInfo?.uln,
        lvsNotes: payload?.pupilInfo?.lvsNotes,
        dob: payload?.pupilInfo?.dob,
        PupilId:payload?.pupilInfo?.id,
        stringDob: payload?.pupilInfo?.stringdob,
        yeargroup: payload?.pupilInfo?.classyear,
        phase: payload?.pupilInfo?.phase,
        gender: payload?.pupilInfo?.gender,
        senCop: payload?.pupilInfo?.sen,
        cwd: payload?.pupilInfo?.cwd,
        eal: payload?.pupilInfo?.eal,
        firstLanguage: payload?.pupilInfo?.firstLanguage,
        ethnicity: payload?.pupilInfo?.ethnicity,
        uasc: payload?.pupilInfo?.uasc,
        dateIntoCare: payload?.pupilInfo?.stringDateIntoCare,
        dateCareEnded: payload?.pupilInfo?.stringDateIntoCare,
        ragRating: payload?.pupilInfo?.ragRating,
        youngPersonTel: payload?.pupilInfo?.youngPersonTel,
        youngPersonEmail: payload?.pupilInfo?.youngPersonEmail,

        schoolName: payload?.pupilInfo?.subSchool?.schoolname,
        schoolTel: payload?.pupilInfo?.subSchool?.tel,
        schoolEmail: payload?.pupilInfo?.subSchool?.schoolemail,
        schoolAddress1: payload?.pupilInfo?.subSchool?.address,
        schoolAddress2: payload?.pupilInfo?.subSchool?.address2,
        schoolAddress3: payload?.pupilInfo?.subSchool?.city,
        schoolPostcode: payload?.pupilInfo?.subSchool?.zip,
        laInOrOut: payload?.pupilInfo?.boroughInOut,
        schoolWebsite: payload?.pupilInfo?.subSchool?.web,
        ofstedGrade: payload?.pupilInfo?.subSchool?.ofstedGrade,
        schoolLa: payload?.pupilInfo?.subSchool?.schoolLa,
        urn: payload?.pupilInfo?.subSchool?.urn,
        dfe: payload?.pupilInfo?.subSchool?.code,
        schoolmoves: payload?.pupilInfo?.schoolMoves,
        dualRegisterSchool: payload?.pupilInfo?.dualRegisterSchool,
        leavers: payload?.pupilInfo?.leaversflag,
        schoolId : payload?.pupilInfo?.schoolId,

        vsPupilNo: payload?.pupilInfo?.vsPupilNo,
        legalStatus: payload?.pupilInfo?.legalStatus,
        numberOfPlacements: payload?.pupilInfo?.numberOfPlacements,
        typeOfPlacement: payload?.pupilInfo?.typeOfPlacement,
        placementLocalAuthority: "",
        placementDate: payload?.pupilInfo?.stringplacementdate,
        contactArrangements: payload?.pupilInfo?.contactArrangements,
        placementNotification: payload?.pupilInfo?.placementNotification,
        dateBecameCareLeaver: payload?.pupilInfo?.dateBecameCareLeaver,
        numberOfSchool: payload?.pupilInfo?.schoolMoves,
        countryOfOrigin: payload?.pupilInfo?.pepCountry,
        religion: payload?.pupilInfo?.religion,
        laptop: payload?.pupilInfo?.laptop,
        dateLeftCare: payload?.pupilInfo?.stringDateleftCare,
        tution: payload?.pupilInfo?.pepTuition,
        nationalInsuranceNo: payload?.pupilInfo?.nationalInsuranceNo,
        course: payload?.pupilInfo?.course,
        intendedDestination: payload?.pupilInfo?.intendedDestination,
        youngParent: payload?.pupilInfo?.youngParent,
        yosService: payload?.pupilInfo?.yosService,
        cicType: payload?.pupilInfo?.cicType,

        socialWorker:
          payload?.pupilInfo?.socialWorker &&
          payload?.pupilInfo?.socialWorker.socialWorkerName,
        socialWorkerTeam:
          payload?.pupilInfo?.socialWorker &&
          payload?.pupilInfo?.socialWorker.socialWorkerUnitTeam,
        designatedTeacher: payload?.pupilInfo?.subSchool?.designatedTeacherName,
        pupilsCarerName: payload?.pupilInfo?.pupilcarer
          ? payload?.pupilInfo?.pupilcarer.pupilsCarerName
          : "",
        virtualSchoolOfficer: payload?.pupilInfo?.pepLacLink
          ? payload?.pupilInfo?.pepLacLink.lacLinkName
          : "",
        iro: payload?.pupilInfo?.iro ? payload?.pupilInfo?.iro.iroName : "",
      };
    case loading:
      return {
        ...state,
        isLoading: true,
      };
    case notLoading:
      return {
        ...state,
        isLoading: false,
      };
    case openHistoryModal:
      return {
        ...state,
        isHistoryModalOpen: true,
        historyModalName: payload?.historyName,
      };
    case closeHistoryModal:
      return {
        ...state,
        isHistoryModalOpen: false,
      };
  }
};

export default pupilReducer;
