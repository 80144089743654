import axios from 'axios';
import React, { useContext } from 'react'
import PupilContext from '../../../Components/context/PupilContext'
import { getYearGroup } from '../../../Components/utils/utilityFunctions';
import { NCCReportApi } from '../../../constants/apiUrls';
import PepSecondaryHeader from '../../Pep/PepSecondaryHeader';
import NCCSecondaryHeader from '../NCCSecondaryHeader';
import NCCStrengthAndNeedPost16 from './components/NCCStrenghAndNeedPost16';
import NCCStrengthComponenet from "./components/NCCStrengthAndNeed";

const NCCStrength = () => {

  const { pupilInfo } = useContext(PupilContext);

  const componentToRender = {
    Component: null,
    reportName: ""
  }

  const yearGroup = getYearGroup(pupilInfo.classYear);

  switch (yearGroup) {

    case "eyfs":
      componentToRender.Component = <NCCStrengthComponenet />;
      componentToRender.reportName = "nccStrengthsAndNeeds"; 
      
      break;
      
    case "primary":
      componentToRender.Component = <NCCStrengthComponenet />;
      componentToRender.reportName = "nccStrengthsAndNeeds"; 
      break;
      
    case "secondary":
      componentToRender.Component = <NCCStrengthComponenet />;
      componentToRender.reportName = "nccStrengthsAndNeeds"; 
      break;
      
    case "post16":
      componentToRender.Component = <NCCStrengthAndNeedPost16 />;
      componentToRender.reportName = "nccPost16StrengthsAndNeeds"; 
      break;

  }

    const exportPdf = () => {
      const a = document.createElement("a");
      const url = NCCReportApi.exportPdfReports({
        reportName: componentToRender.reportName,
        pupilId: pupilInfo.pupilId,
        schoolId: pupilInfo.schoolId,
        classYear: pupilInfo.classYear,
        termofpep: pupilInfo.termOfPep,
        pupilsName: pupilInfo.fullname,
        dateofmeeting: pupilInfo.dateofmeeting,
      });
      axios.get(url, { responseType: "blob" })
        .then((res) => {
          let objectUrl = window.URL.createObjectURL(res.data);
          a.href = objectUrl;
          a.download = componentToRender.reportName;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        })
        .catch(err => {
          const error = err;
          console.log(err);
          console.log(err);
        })
    }

  return (
    <>
      {/* <PepSecondaryHeader
        heading={`NCC Strength & Needs`}
        buttons={[
          <button className="blue-button complete-button  text-sm">Complete</button>,
          <div className="export-btn">
            <span className="blue-button px-5 py-2  text-sm">Export</span>
            <ul>
              <li>
                <button onClick={exportPdf}>Full Pep</button>
              </li>
              <li>
                <button onClick={exportPdf}>PDF</button>
              </li>
            </ul>
          </div>
        ]}
      /> */}
      {componentToRender.Component !== null && componentToRender.Component}
    </>
  )
}

export default NCCStrength
