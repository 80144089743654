import { FastField, Field } from 'formik';
import React, { memo } from 'react';
import { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import AutoHeightInput from '../AutoHeighInputField';

const InputFieldAutoHeight = ({ fieldName, onChangeHandler, saveForm, restForLoader, restForInput}) => {
  const debouncedSaveForm = useDebouncedCallback(saveForm, 1000);
  

  return (
    <Field name={fieldName}>
    {
      ({form, field}) => {
        const fieldValue = field.value ? field.value : "";
        
        const fieldHandler = ({ value, setIsLoading }) => {
          onChangeHandler({
            fieldName,
            setFieldValue: form.setFieldValue,
            fieldValue: value,
            values: form.values,
            saveForm: debouncedSaveForm,
            setIsLoading,
          })
        }

        return (
          <AutoHeightInput 
            fieldValue={fieldValue}
            fieldHandler={fieldHandler}
            restForLoader={restForLoader}
            fieldName={fieldName}
            restForInput={restForInput}
          />
        )
      }
    }
    </Field>
  );
};

export default InputFieldAutoHeight;
// export default memo(InputFieldAutoHeight);
