import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { routeUrl } from '../../../../../Components/utils/RouteUrl';
import useActivateTabBasicInfo from '../../../../../hooks/useActivateTabBasicInfo';
import PupilContext from '../../../../../Components/context/PupilContext';
import { settings } from '../../../../../Components/utils/Settings';
import priorAttainmentReducer from '../../../../../reducers/PepBasicInfoDoc/priorAttainmentReducer';
import { setPriorAttainment } from '../../../../../constants/reducerActions';
import InputField from '../../../../../Components/NCC/InputField';
import { Form, Formik } from 'formik';
import RadioField from '../../../../../Components/NCC/RadioField';
import NumberTypeField from '../../../../../Components/NCC/NumberTypeField';
import SelectField from '../../../../../Components/NCC/SelectField';
import { NCCReportApi } from '../../../../../constants/apiUrls';

const initialState = {
  goodLevOfDevAchiev: "",
  phonicsScreen: "",
  phonicsResit: "",
  keyStage1PriorAttainment: "",
  phonicKeyStage1Score: "",
  readingKeyStage1Score: "",
  writingKeyStage1Score: "",
  mathKeyStage1Score: "",
  keyStage2PriorAttainment: "",
  ks2ScoreSPAG: "",
  ks2ScoreReading: "",
  ks2ScoreWriting: "",
  ks2ScoreMaths: "",
  keyStage4PriorAttainment: "",
  engResult: "",
  mathResult: "",
  keyStage4Subject1: "",
  keyStage4Subject1Result: "",
  keyStage4Subject2: "",
  keyStage4Subject2Result: "",
  keyStage4Subject3: "",
  keyStage4Subject3Result: "",
  keyStage4Subject4: "",
  keyStage4Subject4Result: "",
  keyStage4Subject5: "",
  keyStage4Subject5Result: "",
  keyStage4Subject6: "",
  keyStage4Subject6Result: "",
  keyStage4Subject7: "",
  keyStage4Subject7Result: "",
  keyStage4Subject8: "",
  keyStage4Subject8Result: "",
  keyStage5Subject1: "",
  keyStage5Subject1Result: "",
  keyStage5Subject2: "",
  keyStage5Subject2Result: "",
  keyStage5Subject3: "",
  keyStage5Subject3Result: "",
  keyStage5Subject4: "",
  keyStage5Subject4Result: "",
  keyStage5Subject5: "",
  keyStage5Subject5Result: "",
  keyStage5Subject6: "",
  keyStage5Subject6Result: "",
  eepYear7: "",
  eepYear8: "",
  eepYear9: "",
  eepYear10: "",
  eepYear11: "",
  eepYear12: "",
  eepYear13: "",
  eepHoursTotal: "",
}

const PepPriorAttainmentTab = () => {

  const [state, dispatch] = useReducer(priorAttainmentReducer, initialState);
  
  useActivateTabBasicInfo(routeUrl.basic_info_prior_attainment);

  const { activePupilId } = useContext(PupilContext);

  const allOption = [
    "1-", "1", "1+",
    "2-", "2", "2+",
    "3-", "3", "3+",
    "4-", "4", "4+",
    "5-", "5", "5+",
    "6-", "6", "6+",
    "7-", "7", "7+",
    "8-", "8", "8+",
    "9-", "9", "U",
    "No GCSE", "Not Entered",
    "L2D*", "L2D", "L2M", "L2P",
    "L1D", "L1M", "L1P", "Pass",
    "9/9", "9/8", "8/8", "8/7",
    "7/7", "7/6", "6/6", "6/5",
    "5/5", "5/4", "4/4", "4/3",
    "3/3", "3/2", "2/2", "2/1",
    "1/1", "D*", "D", "M", "P",
    "Above Average", "Average", "Below Average",
    "Func Skill L1 Pass", "Func Skills L1 Fail",
    "Func Skills L2 Pass", "Func Skills L2 Fail",
    "Func Skills Entry L3 Pass", "Func Skills Entry L3 Fail",
    "ASDAN Pass", "P Scale 1", "P Scale 2", "P Scale 3", "P Scale 4",
    "Entry Level 1", "Entry Level 2", "Entry Level 3", "NC Year 1",
    "NC Year 2", "NC Year 3", "NC Year 4", "NC Year 5", "NC Year 6",
    "P1i", "P1ii", "P2i", "P2ii", "P3i", "P3ii", "P4", "P5", "P6", "P7", "P8",
    "P9", "P10", "P11", "P12", "P13", "P14", "P15", "P16", "P17", "P18", "Other"
  ];

  const ks5allOption = [
    "", "A*", "A", "B", "C", "D",
    "E", "U", "No GCSE", "Not Entered", "L2D*", "L2D",
    "L2M", "L2P", "L1D", "L1M", "L1P", "Pass",
    "9/9", "9/8", "8/8", "8/7", "7/7", "7/6",
    "6/6", "6/5", "5/5", "5/4", "4/4", "4/3",
    "3/3", "3/2", "2/2", "2/1", "1/1", "D*",
    "D", "M", "P", "Above Average", "Average", "Below Average",
    "Func Skill L1 Pass", "Func Skills L1 Fail", "Func Skills L2 Pass",
    "Func Skills L2 Fail", "Func Skills Entry L3 Pass", "Func Skills Entry L3 Fail",
    "ASDAN Pass", "P Scale 1", "P Scale 2", "P Scale 3", "P Scale 4", "Entry Level 1",
    "Entry Level 2", "Entry Level 3", "9", "8", "7", "6", "5", "4", "3", "2", "1"
  ]


  const roleId = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const schoolId = localStorage.getItem("schoolId");

  const {
    goodLevOfDevAchiev,
    phonicsScreen,
    phonicsResit,
    keyStage1PriorAttainment,
    phonicKeyStage1Score,
    readingKeyStage1Score,
    writingKeyStage1Score,
    mathKeyStage1Score,
    keyStage2PriorAttainment,
    ks2ScoreSPAG,
    ks2ScoreReading,
    ks2ScoreWriting,
    ks2ScoreMaths,
    keyStage4PriorAttainment,
    engResult,
    mathResult,
    keyStage4Subject1,
    keyStage4Subject1Result,
    keyStage4Subject2,
    keyStage4Subject2Result,
    keyStage4Subject3,
    keyStage4Subject3Result,
    keyStage4Subject4,
    keyStage4Subject4Result,
    keyStage4Subject5,
    keyStage4Subject5Result,
    keyStage4Subject6,
    keyStage4Subject6Result,
    keyStage4Subject7,
    keyStage4Subject7Result,
    keyStage4Subject8,
    keyStage4Subject8Result,
    keyStage5Subject1,
    keyStage5Subject1Result,
    keyStage5Subject2,
    keyStage5Subject2Result,
    keyStage5Subject3,
    keyStage5Subject3Result,
    keyStage5Subject4,
    keyStage5Subject4Result,
    keyStage5Subject5,
    keyStage5Subject5Result,
    keyStage5Subject6,
    keyStage5Subject6Result,
    eepYear7,
    eepYear8,
    eepYear9,
    eepYear10,
    eepYear11,
    eepYear12,
    eepYear13,
    eepHoursTotal,
  } = state;

  // const [phonicsScreen, setPhonicsScreen] = useState("");
  // const [phonicsResit, setPhonicsResit] = useState("");
  // const [keyStage1PriorAttainment, setKeyStage1PriorAttainment] = useState("");
  // const [phonicKeyStage1Score, setPhonicKeyStage1Score] = useState("");
  // const [readingKeyStage1Score, setReadingKeyStage1Score] = useState("");
  // const [writingKeyStage1Score, setWritingKeyStage1Score] = useState("");
  // const [mathKeyStage1Score, setMathKeyStage1Score] = useState("");
  // const [keyStage2PriorAttainment, setKeyStage2PriorAttainment] = useState("");
  // const [ks2ScoreSPAG, setKs2ScoreSPAG] = useState("");
  // const [ks2ScoreReading, setKs2ScoreReading] = useState("");
  // const [ks2ScoreWriting, setKs2ScoreWriting] = useState("");
  // const [ks2ScoreMaths, setKs2ScoreMaths] = useState("");
  // const [keyStage4PriorAttainment, setKeyStage4PriorAttainment] = useState("");
  // const [engResult, setEngResult] = useState("");
  // const [mathResult, setMathResult] = useState("");

  // //ks4
  // const [keyStage4Subject1, setKeyStage4Subject1] = useState("");
  // const [keyStage4Subject1Result, setKeyStage4Subject1Rsult] = useState("");
  // const [keyStage4Subject2, setKeyStage4Subject2] = useState("");
  // const [keyStage4Subject2Result, setKeyStage4Subject2Rsult] = useState("");
  // const [keyStage4Subject3, setKeyStage4Subject3] = useState("");
  // const [keyStage4Subject3Result, setKeyStage4Subject3Rsult] = useState("");
  // const [keyStage4Subject4, setKeyStage4Subject4] = useState("");
  // const [keyStage4Subject4Result, setKeyStage4Subject4Rsult] = useState("");
  // const [keyStage4Subject5, setKeyStage4Subject5] = useState("");
  // const [keyStage4Subject5Result, setKeyStage4Subject5Rsult] = useState("");
  // const [keyStage4Subject6, setKeyStage4Subject6] = useState("");
  // const [keyStage4Subject6Result, setKeyStage4Subject6Rsult] = useState("");
  // const [keyStage4Subject7, setKeyStage4Subject7] = useState("");
  // const [keyStage4Subject7Result, setKeyStage4Subject7Rsult] = useState("");
  // const [keyStage4Subject8, setKeyStage4Subject8] = useState("");
  // const [keyStage4Subject8Result, setKeyStage4Subject8Rsult] = useState("");

  // // ks5
  // const [keyStage5Subject1, setKeyStage5Subject1] = useState("");
  // const [keyStage5Subject1Result, setKeyStage5Subject1Result] = useState("");
  // const [keyStage5Subject2, setKeyStage5Subject2] = useState("");
  // const [keyStage5Subject2Result, setKeyStage5Subject2Result] = useState("");
  // const [keyStage5Subject3, setKeyStage5Subject3] = useState("");
  // const [keyStage5Subject3Result, setKeyStage5Subject3Result] = useState("");
  // const [keyStage5Subject4, setKeyStage5Subject4] = useState("");
  // const [keyStage5Subject4Result, setKeyStage5Subject4Result] = useState("");
  // const [keyStage5Subject5, setKeyStage5Subject5] = useState("");
  // const [keyStage5Subject5Result, setKeyStage5Subject5Result] = useState("");
  // const [keyStage5Subject6, setKeyStage5Subject6] = useState("");
  // const [keyStage5Subject6Result, setKeyStage5Subject6Result] = useState("");

  // // EEP hours
  // const [eepYear7, setEepYear7] = useState("");
  // const [eepYear8, setEepYear8] = useState("");
  // const [eepYear9, setEepYear9] = useState("");
  // const [eepYear10, setEepYear10] = useState("");
  // const [eepYear11, setEepYear11] = useState("");
  // const [eepYear12, setEepYear12] = useState("");
  // const [eepYear13, setEepYear13] = useState("");
  // const [eepHoursTotal, setEepHoursTotal] = useState("");


  useEffect(() => {
    activePupilId && axios(`${settings.API_HOST}/rest/fetchPupilBasicInfo?pupilId=${activePupilId}&schoolId=${schoolId}&roleId=${roleId}&userId=${userId}`)
      .then(res => {
        dispatch({type: setPriorAttainment, payload: res.data});
        // console.log(res.data.pupilInfo.ks4Subjects, "ks4 subject details");
        // setPhonicsScreen((res.data.pupilInfo && res.data.pupilInfo.phonicsScreen) ? res.data.pupilInfo.phonicsScreen : "");
        // setPhonicsResit((res.data.pupilInfo && res.data.pupilInfo.phonicsResit) ? res.data.pupilInfo.phonicsResit : "");
        // setKeyStage1PriorAttainment((res.data.pupilInfo && res.data.pupilInfo.keyStage1PriorAttainment) ? res.data.pupilInfo.keyStage1PriorAttainment : "");
        // setPhonicKeyStage1Score((res.data.pupilInfo && res.data.pupilInfo.phonicKeyStage1Score) ? res.data.pupilInfo.phonicKeyStage1Score : "");
        // setReadingKeyStage1Score((res.data.pupilInfo && res.data.pupilInfo.readingKeyStage1Score) ? res.data.pupilInfo.readingKeyStage1Score : "");
        // setWritingKeyStage1Score((res.data.pupilInfo && res.data.pupilInfo.writingKeyStage1Score) ? res.data.pupilInfo.writingKeyStage1Score : "");
        // setMathKeyStage1Score((res.data.pupilInfo && res.data.pupilInfo.mathKeyStage1Score) ? res.data.pupilInfo.mathKeyStage1Score : "");
        // setKeyStage2PriorAttainment((res.data.pupilInfo && res.data.pupilInfo.keyStage2PriorAttainment) ? res.data.pupilInfo.keyStage2PriorAttainment : "");
        // setKs2ScoreSPAG((res.data.pupilInfo && res.data.pupilInfo.ks2ScoreSPAG) ? res.data.pupilInfo.ks2ScoreSPAG : "");
        // setKs2ScoreReading((res.data.pupilInfo && res.data.pupilInfo.ks2ScoreReading) ? res.data.pupilInfo.ks2ScoreReading : "");
        // setKs2ScoreWriting((res.data.pupilInfo && res.data.pupilInfo.ks2ScoreWriting) ? res.data.pupilInfo.ks2ScoreWriting : "");
        // setKs2ScoreMaths((res.data.pupilInfo && res.data.pupilInfo.ks2ScoreMaths) ? res.data.pupilInfo.ks2ScoreMaths : "");
        // setKeyStage4PriorAttainment((res.data.pupilInfo && res.data.pupilInfo.keyStage4PriorAttainment) ? res.data.pupilInfo.keyStage4PriorAttainment : "");
        // setEngResult((res.data.pupilInfo && res.data.pupilInfo.engResult) ? res.data.pupilInfo.engResult : "");
        // setMathResult((res.data.pupilInfo && res.data.pupilInfo.mathResult) ? res.data.pupilInfo.mathResult : "");

        // setKeyStage5Subject1((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub1) ? res.data.pupilInfo.keyStage5Sub1 : "");
        // setKeyStage5Subject1Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub1Res) ? res.data.pupilInfo.keyStage5Sub1Res : "");
        // setKeyStage5Subject2((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub2) ? res.data.pupilInfo.keyStage5Sub2 : "");
        // setKeyStage5Subject2Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub2Res) ? res.data.pupilInfo.keyStage5Sub2Res : "");
        // setKeyStage5Subject3((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub3) ? res.data.pupilInfo.keyStage5Sub3 : "");
        // setKeyStage5Subject3Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub3Res) ? res.data.pupilInfo.keyStage5Sub3Res : "");
        // setKeyStage5Subject4((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub4) ? res.data.pupilInfo.keyStage5Sub4 : "");
        // setKeyStage5Subject4Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub4Res) ? res.data.pupilInfo.keyStage5Sub4Res : "");
        // setKeyStage5Subject5((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub5) ? res.data.pupilInfo.keyStage5Sub5 : "");
        // setKeyStage5Subject5Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub5Res) ? res.data.pupilInfo.keyStage5Sub5Res : "");
        // setKeyStage5Subject6((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub6) ? res.data.pupilInfo.keyStage5Sub6 : "");
        // setKeyStage5Subject6Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub6Res) ? res.data.pupilInfo.keyStage5Sub6Res : "");

        // setEepYear7((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear7) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear7 : "");
        // setEepYear8((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear8) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear8 : "");
        // setEepYear9((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear9) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear9 : "");
        // setEepYear10((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear10) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear10 : "");
        // setEepYear11((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear11) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear11 : "");
        // setEepYear12((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear12) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear12 : "");
        // setEepYear13((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear13) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear13 : "");
        // setEepHoursTotal((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursTotal) ? res.data.pupilInfo.basicInformationEepHours.eepHoursTotal : "");

        // res.data.pupilInfo.ks4Subjects.forEach(item => {
        //   if (item.subIndex === 1) {
        //     setKeyStage4Subject1(item.subject);
        //     setKeyStage4Subject1Rsult(item.grade);
        //   } else if (item.subIndex === 2) {
        //     setKeyStage4Subject2(item.subject);
        //     setKeyStage4Subject2Rsult(item.grade);
        //   } else if (item.subIndex === 3) {
        //     setKeyStage4Subject3(item.subject);
        //     setKeyStage4Subject3Rsult(item.grade);
        //   } else if (item.subIndex === 4) {
        //     setKeyStage4Subject4(item.subject);
        //     setKeyStage4Subject4Rsult(item.grade);
        //   } else if (item.subIndex === 5) {
        //     setKeyStage4Subject5(item.subject);
        //     setKeyStage4Subject5Rsult(item.grade);
        //   } else if (item.subIndex === 6) {
        //     setKeyStage4Subject6(item.subject);
        //     setKeyStage4Subject6Rsult(item.grade);
        //   } else if (item.subIndex === 7) {
        //     setKeyStage4Subject7(item.subject);
        //     setKeyStage4Subject7Rsult(item.grade);
        //   } else if (item.subIndex === 8) {
        //     setKeyStage4Subject8(item.subject);
        //     setKeyStage4Subject8Rsult(item.grade);
        //   }
        // })

      });
  }, [activePupilId]);

  const onChangeHandler = ({
    saveForm,
    fieldName,
    setIsLoading,
    fieldValue,
    setFieldValue,
    values,
  }) => {
    setFieldValue(fieldName, fieldValue);
    saveForm({setIsLoading, fieldName, fieldValue})
  } 
  
  const saveForm = ({
    setIsLoading,
    fieldName,
    fieldValue
  }) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("attribute", fieldName);
    formData.append("pupilId", activePupilId);
    formData.append("schoolId", schoolId);
    formData.append("userId", userId);
    formData.append("value", fieldValue);
    
    const url = NCCReportApi.ssavePriorAttainment;

    axios.post(url, formData)
      .then((res) => {
        console.log(res, "res from saving");
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false)
      })
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1000)
  }

  const initialFormValues = {
    goodLevOfDevAchiev,
    phonicsScreen,
    phonicsResit,
    keyStage1PriorAttainment,
    phonicKeyStage1Score,
    readingKeyStage1Score,
    writingKeyStage1Score,
    mathKeyStage1Score,
    keyStage2PriorAttainment,
    ks2ScoreSPAG,
    ks2ScoreReading,
    ks2ScoreWriting,
    ks2ScoreMaths,
    keyStage4PriorAttainment,
    engResult,
    mathResult,
    keyStage4Subject1,
    keyStage4Subject1Result,
    keyStage4Subject2,
    keyStage4Subject2Result,
    keyStage4Subject3,
    keyStage4Subject3Result,
    keyStage4Subject4,
    keyStage4Subject4Result,
    keyStage4Subject5,
    keyStage4Subject5Result,
    keyStage4Subject6,
    keyStage4Subject6Result,
    keyStage4Subject7,
    keyStage4Subject7Result,
    keyStage4Subject8,
    keyStage4Subject8Result,
    keyStage5Subject1,
    keyStage5Subject1Result,
    keyStage5Subject2,
    keyStage5Subject2Result,
    keyStage5Subject3,
    keyStage5Subject3Result,
    keyStage5Subject4,
    keyStage5Subject4Result,
    keyStage5Subject5,
    keyStage5Subject5Result,
    keyStage5Subject6,
    keyStage5Subject6Result,
    eepYear7,
    eepYear8,
    eepYear9,
    eepYear10,
    eepYear11,
    eepYear12,
    eepYear13,
    eepHoursTotal,
  }

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
    >
      <Form>
        <div className="secondary-section w-full">
          <div className="basic-header flex w-full py-2 justify-between">
            <div className="basic-left">
              <h1>Prior Attainment
                <span><i className="fa-regular fa-circle-video"></i></span>
                <span><i className="fa-regular fa-circle-info"></i></span>
              </h1>
            </div>
            <div className="basic-right">
              <span><i className="fa-regular fa-user"></i></span>
              <input type="text" className="pep-input" value="ASSET Secondary" />
              <span className="ml-2 bg-white"><i className="fa-regular fa-video"></i></span>
            </div>
          </div>
          <div className="rounded-3xl bg-white p-4 w-full">
            {/* <div className="flex w-full items-center">
              <label className="pr-4">Assessment date</label>
              <input type="text" className="rounded-3x/l pep-input" />
            </div> */}
            <div className="flex w-full py-4">
              <h3 className="pink rounded-3xl w-full px-4 py-2">Early Years Foundation Stage</h3>
            </div>
            <div className="py-2 flex w-full items-center">
              <span>Was Good Level of Development achieved?</span>
              <div className="pl-2 radio">
                <RadioField 
                  fieldName={"goodLevOfDevAchiev"}
                  onChangeHandler={onChangeHandler}
                  saveForm={saveForm}
                  radioOptionsList={[
                    {innerValue: "Yes", outerValue: "Yes"},
                    {innerValue: "No", outerValue: "No"},
                    {innerValue: "Unkown", outerValue: "Unkown"},
                  ]}
                />
                {/* <label className="inline-flex items-center">
                  <input type="radio" className="form-radio" />
                  <span className="mr-4 ml-2">Yes</span>
                </label>
                <label className="inline-flex items-center">
                  <input type="radio" className="form-radio" />
                  <span className="mr-4 ml-2">No</span>
                </label>
                <label className="inline-flex items-center">
                  <input type="radio" className="form-radio" />
                  <span className="mr-4 ml-2">Unknown</span>
                </label> */}
              </div>
              <div className="pl-0 radio ml-6">
                <span>Year 1 Phonics screen</span>
                <label className="inline-flex items-center ml-4">
                  
                  <InputField 
                    fieldName={"phonicsScreen"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    rest={{
                      className: "pep-input w-24"
                    }}
                  />
                  {/* <input type="yext"
                    className="pep-input w-24"
                    value={phonicsScreen} /> */}
                  <span className="ml-2">/40</span>
                </label>
                <span className="ml-8">Year 2 Phonics Resit</span>
                <label className="inline-flex items-center ml-4">
                  <InputField
                    fieldName={"phonicsResit"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    rest={{
                      className: "pep-input w-24"
                    }}
                  />
                  {/* <input
                    type="yext"
                    className="pep-input w-24"
                    value={phonicsResit} /> */}
                  <span className="ml-2">/40</span>
                </label>
              </div>
            </div>

            <div className="table-box w-full pt-2">
              <div className="py-2 flex w-full items-center">
                <h3><b>Key stage 1 Result</b></h3>
                <div className="pl-4 radio">
                  <RadioField
                    fieldName={"keyStage1PriorAttainment"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    radioOptionsList={[
                      // { innerValue: "Yes", outerValue: "Yes" },
                      { innerValue: "No", outerValue: "No Key Stage 1 attainment" },
                      { innerValue: "Unknown", outerValue: "Unknown" },
                    ]}
                  />
                  {/* <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio"
                      name="accountType"
                      checked={
                        keyStage1PriorAttainment !== null &&
                          keyStage1PriorAttainment === "No"
                          ? true
                          : false
                      }
                    />
                    <span className="ml-2">No Key Stage 1 attainment</span>
                  </label>

                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      className="form-radio"
                      name="accountType"
                      checked={keyStage1PriorAttainment !== null &&
                        keyStage1PriorAttainment === "Unknown"
                        ? true
                        : false
                      } />
                    <span className="ml-2">Unknown</span>
                  </label> */}
                </div>
              </div>
              <div className="table-outer rounded-3xl bg-white">
                <table className="table-fixed text-center w-full">
                  <tbody>
                    <tr>
                      <td className="d-blue text-center">Phonic</td>
                      <td className="blue">
                        <InputField
                          fieldName={"phonicKeyStage1Score"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-32"
                          }}
                          restForLoader={{
                            className: "absolute top-0 left-0 w-full h-full blue grid items-center"
                          }}
                        />
                        {/* <input
                          type="text"
                          className="pep-input w-32"
                          value={phonicKeyStage1Score} /> */}
                      </td>
                      <td className="green">Reading <span className="required">*</span></td>
                      <td className="l-green">
                        <InputField
                          fieldName={"readingKeyStage1Score"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-32"
                          }}
                          restForLoader={{
                            className: "absolute top-0 left-0 w-full h-full l-green grid items-center"
                          }}
                        />
                        {/* <input
                          type="text"
                          className="pep-input w-32"
                          value={readingKeyStage1Score} /> */}
                      </td>
                      <td className="d-orange">Writing <span className="required">*</span></td>
                      <td className="orange">
                        <InputField
                          fieldName={"writingKeyStage1Score"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-32"
                          }}
                          restForLoader={{
                            className: "absolute top-0 left-0 w-full h-full orange grid items-center"
                          }}
                        />
                        {/* <input
                          type="text"
                          className="pep-input w-32"
                          value={writingKeyStage1Score} /> */}
                      </td>
                      <td className="purple">Maths <span className="required">*</span></td>
                      <td className="l-purple">
                        <InputField
                          fieldName={"mathKeyStage1Score"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-32"
                          }}
                          restForLoader={{
                            className: "absolute top-0 left-0 w-full h-full l-purple grid items-center"
                          }}
                        />
                        {/* <input
                          type="text"
                          className="pep-input w-32"
                          value={mathKeyStage1Score} /> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="table-box w-full pt-2">
              <div className="py-2 flex w-full items-center">
                <h3><b>Key stage 2 Results</b></h3>
                <div className="pl-4 radio">
                  <RadioField
                    fieldName={"keyStage2PriorAttainment"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    radioOptionsList={[
                      // { innerValue: "Yes", outerValue: "Yes" },
                      { innerValue: "No", outerValue: "No Key Stage 2 attainment" },
                      { innerValue: "Unknown", outerValue: "Unknown" },
                    ]}
                  />
                  {/* <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio"
                      name="accountType"
                      checked={
                        keyStage2PriorAttainment !== null &&
                          keyStage2PriorAttainment === "No"
                          ? true
                          : false
                      } />
                    <span className="ml-2">No Key Stage 2 attainment</span>
                  </label>
                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      className="form-radio"
                      name="accountType"
                      checked={
                        keyStage2PriorAttainment !== null &&
                          keyStage2PriorAttainment === "Unknown"
                          ? true
                          : false
                      } />
                    <span className="ml-2">Unknown</span>
                  </label> */}
                </div>
              </div>
              <div className="table-outer rounded-3xl bg-white">
                <table className="table-fixed text-center w-full">
                  <tbody>
                    <tr>
                      <td className="d-blue text-center">SPaG</td>
                      <td className="blue">
                        <InputField
                          fieldName={"ks2ScoreSPAG"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-32"
                          }}
                          restForLoader={{
                            className: "absolute top-0 left-0 w-full h-full blue grid items-center"
                          }}
                        />
                        {/* <input
                          type="text"
                          className="pep-input w-32"
                          value={phonicKeyStage1Score} /> */}
                      </td>
                      <td className="green">Reading <span className="required">*</span></td>
                      <td className="l-green">
                        <InputField
                          fieldName={"ks2ScoreReading"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-32"
                          }}
                          restForLoader={{
                            className: "absolute top-0 left-0 w-full h-full l-green grid items-center"
                          }}
                        />
                        {/* <input
                          type="text"
                          className="pep-input w-32"
                          value={readingKeyStage1Score} /> */}
                      </td>
                      <td className="d-orange">Writing <span className="required">*</span></td>
                      <td className="orange">
                        <InputField
                          fieldName={"ks2ScoreWriting"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-32"
                          }}
                          restForLoader={{
                            className: "absolute top-0 left-0 w-full h-full orange grid items-center"
                          }}
                        />
                        {/* <input
                          type="text"
                          className="pep-input w-32"
                          value={writingKeyStage1Score} /> */}
                      </td>
                      <td className="purple">Maths <span className="required">*</span></td>
                      <td className="l-purple">
                        <InputField
                          fieldName={"ks2ScoreMaths"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-32"
                          }}
                          restForLoader={{
                            className: "absolute top-0 left-0 w-full h-full l-purple grid items-center"
                          }}
                        />
                        {/* <input
                          type="text"
                          className="pep-input w-32"
                          value={mathKeyStage1Score} /> */}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="d-blue text-center">Phonic</td>
                      <td className="blue">
                        <input
                          type="text"
                          className="pep-input w-32"
                          value={ks2ScoreSPAG} />
                      </td>
                      <td className="green">Reading <span className="required">*</span></td>
                      <td className="l-green">
                        <input
                          type="text"
                          className="pep-input w-32"
                          value={ks2ScoreReading} />
                      </td>
                      <td className="d-orange">Writing <span className="required">*</span></td>
                      <td className="orange">
                        <input
                          type="text"
                          className="pep-input w-32"
                          value={ks2ScoreWriting} />
                      </td>
                      <td className="purple">Maths <span className="required">*</span></td>
                      <td className="l-purple">
                        <input
                          type="text"
                          className="pep-input w-32"
                          value={ks2ScoreMaths} />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Key starge 4 */}
            <div className="table-box w-full pt-2">
              <div className="py-2 flex w-full items-center">
                <h3><b>Key stage 4 Results</b></h3>
                <div className="pl-4 radio">
                  <RadioField
                    fieldName={"keyStage4PriorAttainment"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    radioOptionsList={[
                      // { innerValue: "Yes", outerValue: "Yes" },
                      { innerValue: "No", outerValue: "No Key Stage 4 attainment"},
                      { innerValue: "Unknown", outerValue: "Unknown" },
                    ]}
                  />
                  {/* <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio"
                      name="accountType"
                      checked={
                        keyStage4PriorAttainment !== null &&
                          keyStage4PriorAttainment === "No"
                          ? true
                          : false
                      } />
                    <span className="ml-2">No Key Stage 4 attainment</span>
                  </label>
                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      className="form-radio"
                      name="accountType"
                      checked={
                        keyStage4PriorAttainment !== null &&
                          keyStage4PriorAttainment === "Unknown"
                          ? true
                          : false
                      } />
                    <span className="ml-2">Unknown</span>
                  </label> */}
                </div>
              </div>
              <div className="table-outer rounded-3xl bg-white">
                <table className="table-fixed text-center w-full">
                  <thead>
                    <tr>
                      <th className="blue w-1/5">Subject Name</th>
                      <th className="d-blue">Level</th>
                      <th className="purple w-1/5">Result</th>
                      <th className="blue w-w-1/5">Subject Name</th>
                      <th className="d-blue">Level</th>
                      <th className="purple w-1/5">Result</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><b>English</b></td>
                      <td>
                        <input
                          type="text"
                          className="w-full"
                          value={keyStage4Subject1}
                        />
                      </td>
                      <td>
                        <select className="pep-select w-full" vlaue={engResult}>
                          <option></option>
                          {allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <b>Maths</b> 
                      </td>
                      <td> 
                        <input
                          type="text"
                          className="w-full"
                          value={keyStage4Subject2}
                        />
                      </td>
                      <td>
                        <select className="pep-select w-full" value={keyStage4Subject2Result}>
                          <option></option>
                          {allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    
                    <tr>
                      <td><b></b></td>
                      <td>
                        <input
                          type="text"
                          className="w-full"
                          value={keyStage4Subject1}
                        />
                      </td>
                      <td>
                        <select className="pep-select w-full" vlaue={engResult}>
                          <option></option>
                          {allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <b></b> 
                      </td>
                      <td> 
                        <input
                          type="text"
                          className="w-full"
                          value={keyStage4Subject2}
                        />
                      </td>
                      <td>
                        <select className="pep-select w-full" value={keyStage4Subject2Result}>
                          <option></option>
                          {allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td><b></b></td>
                      <td>
                        <input
                          type="text"
                          className="w-full"
                          value={keyStage4Subject1}
                        />
                      </td>
                      <td>
                        <select className="pep-select w-full" vlaue={engResult}>
                          <option></option>
                          {allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <b></b> 
                      </td>
                      <td> 
                        <input
                          type="text"
                          className="w-full"
                          value={keyStage4Subject2}
                        />
                      </td>
                      <td>
                        <select className="pep-select w-full" value={keyStage4Subject2Result}>
                          <option></option>
                          {allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td><b></b></td>
                      <td>
                        <input
                          type="text"
                          className="w-full"
                          value={keyStage4Subject1}
                        />
                      </td>
                      <td>
                        <select className="pep-select w-full" vlaue={engResult}>
                          <option></option>
                          {allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <b></b> 
                      </td>
                      <td> 
                        <input
                          type="text"
                          className="w-full"
                          value={keyStage4Subject2}
                        />
                      </td>
                      <td>
                        <select className="pep-select w-full" value={keyStage4Subject2Result}>
                          <option></option>
                          {allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>

            {/* Key stage 5 */}
            <div className="table-box w-full pt-2">
              <div className="py-2 flex w-full items-center">
                <h3><b>Key stage 5 Results</b></h3>
              </div>
              <div className="table-outer rounded-3xl bg-white">
                <table className="table-fixed text-center w-full">
                  <thead>
                    <tr>
                      <th className="blue">Subject Name</th>
                      <th className="blue">Level</th>
                      <th className="d-blue">Result</th>
                      <th className="purple">Subject Name</th>
                      <th className="orange">Result</th>
                      <th className="d-orange">Subject Name</th>
                      <th className="d-pink">Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <InputField
                          fieldName={"keyStage5Subject1"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-full"
                          }}
                        />
                        {/* <input
                        type="yext"
                        className="pep-input"
                        value={keyStage5Subject1}
                        /> */}
                      </td>
                      <td>
                        <InputField
                          fieldName={"keyStage5Subject1"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-full"
                          }}
                        />
                        {/* <input
                        type="yext"
                        className="pep-input"
                        value={keyStage5Subject1}
                        /> */}
                      </td>
                      <td>
                        <SelectField
                          fieldName={"keyStage5Subject1Result"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          optionsList={ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                          rest={{
                            className: "pep-select w-full"
                          }}
                        />
                        {/* <select className="pep-select w-full" value={keyStage5Subject1Result}>
                          <option></option>
                          {ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select> */}
                      </td>
                      <td>
                        <InputField
                          fieldName={"keyStage5Subject2"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-full"
                          }}
                        />
                        {/* <input
                          type="yext"
                          className="pep-input"
                          value={keyStage5Subject2}
                        /> */}
                      </td>
                      <td>
                        <SelectField
                          fieldName={"keyStage5Subject2Result"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          optionsList={ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                          rest={{
                            className: "pep-select w-full"
                          }}
                        />
                        {/* <select className="pep-select w-full" value={keyStage5Subject2Result}>
                          <option></option>
                          {ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select> */}
                      </td>
                      <td>
                        <InputField
                          fieldName={"keyStage5Subject3"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-full"
                          }}
                        />
                        {/* <input
                          type="yext"
                          className="pep-input"
                          value={keyStage5Subject3}
                        /> */}
                      </td>
                      <td>
                        <SelectField
                          fieldName={"keyStage5Subject3Result"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          optionsList={ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                          rest={{
                            className: "pep-select w-full"
                          }}
                        />
                        {/* <select className="pep-select w-full" value={keyStage5Subject3Result}>
                          <option></option>
                          {ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select> */}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <InputField
                          fieldName={"keyStage5Subject4"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-full"
                          }}
                        />
                        {/* <input
                        type="yext"
                        className="pep-input"
                        value={keyStage5Subject1}
                        /> */}
                      </td>
                      <td>
                        <SelectField
                          fieldName={"keyStage5Subject4Result"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          optionsList={ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                          rest={{
                            className: "pep-select w-full"
                          }}
                        />
                        {/* <select className="pep-select w-full" value={keyStage5Subject1Result}>
                          <option></option>
                          {ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select> */}
                      </td>
                      <td>
                        <InputField
                          fieldName={"keyStage5Subject5"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-full"
                          }}
                        />
                        {/* <input
                          type="yext"
                          className="pep-input"
                          value={keyStage5Subject2}
                        /> */}
                      </td>
                      <td>
                        <SelectField
                          fieldName={"keyStage5Subject5Result"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          optionsList={ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                          rest={{
                            className: "pep-select w-full"
                          }}
                        />
                        {/* <select className="pep-select w-full" value={keyStage5Subject2Result}>
                          <option></option>
                          {ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select> */}
                      </td>
                      <td>
                        <InputField
                          fieldName={"keyStage5Subject6"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-input w-full"
                          }}
                        />
                        {/* <input
                          type="yext"
                          className="pep-input"
                          value={keyStage5Subject3}
                        /> */}
                      </td>
                      <td>
                        <SelectField
                          fieldName={"keyStage5Subject6Result"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          optionsList={ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                          rest={{
                            className: "pep-select w-full"
                          }}
                        />
                        {/* <select className="pep-select w-full" value={keyStage5Subject3Result}>
                          <option></option>
                          {ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select> */}
                      </td>
                    </tr>
                    {/* <tr>
                      <td><input
                        type="yext"
                        className="pep-input"
                        value={keyStage5Subject4}
                      /></td>
                      <td>
                        <select className="pep-select w-full" value={keyStage5Subject4Result}>
                          <option></option>
                          {ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                      <td><input
                        type="yext"
                        className="pep-input"
                        value={keyStage5Subject5}
                      /></td>
                      <td>
                        <select className="pep-select w-full" value={keyStage5Subject5Result}>
                          <option></option>
                          {ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                      <td><input
                        type="yext"
                        className="pep-input"
                        value={keyStage5Subject6}
                      /></td>
                      <td>
                        <select className="pep-select w-full" value={keyStage5Subject6Result}>
                          <option></option>
                          {ks5allOption.map((op, i) => (
                            <option key={i}> {op} </option>
                          ))}
                        </select>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>

            {/* EP Hours */}
            <div className="table-box w-full pt-2">
              <div className="py-2 flex w-full items-center">
                <h3><b>EEP Hours</b></h3>
              </div>
              <div className="table-outer rounded-3xl bg-white">
                <table className="table-fixed text-center w-full">
                  <thead>
                    <tr>
                      <th className="blue"></th>
                      <th className="d-blue">Year 7</th>
                      <th className="purple">Year 8</th>
                      <th className="orange">Year 9</th>
                      <th className="d-orange">Year 10</th>
                      <th className="d-pink">Year 11</th>
                      <th className="pink">Year 12</th>
                      <th className="green">Year 13</th>
                      <th className="l-green">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Hours Achieved
                      </td>
                      <td>
                        <NumberTypeField 
                          fieldName={"eepYear7"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-number w-full"
                          }}
                        />
                        {/* <input type="number" className="pep-number" value={eepYear7} /> */}
                      </td>
                      <td>
                        <NumberTypeField 
                          fieldName={"eepYear8"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-number w-full"
                          }}
                        />
                        {/* <input type="number" className="pep-number" value={eepYear8} /> */}
                      </td>
                      <td>
                        <NumberTypeField 
                          fieldName={"eepYear9"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-number w-full"
                          }}
                        />
                        {/* <input type="number" className="pep-number" value={eepYear9} /> */}
                      </td>
                      <td>
                        <NumberTypeField 
                          fieldName={"eepYear10"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-number w-full"
                          }}
                        />
                        {/* <input type="number" className="pep-number" value={eepYear10} /> */}
                      </td>
                      <td>
                        <NumberTypeField 
                          fieldName={"eepYear11"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-number w-full"
                          }}
                        />
                        {/* <input type="number" className="pep-number" value={eepYear11} /> */}
                      </td>
                      <td>
                        <NumberTypeField 
                          fieldName={"eepYear12"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-number w-full"
                          }}
                        />
                        {/* <input type="number" className="pep-number" value={eepYear12} /> */}
                      </td>
                      <td>
                        <NumberTypeField 
                          fieldName={"eepYear13"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "pep-number w-full"
                          }}
                        />
                        {/* <input type="number" className="pep-number" value={eepYear13} /> */}
                      </td>
                      <td>{eepHoursTotal}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Other */}
            <div className="table-box w-full pt-4">
              <div className="blue rounded-3xl w-full px-4 py-2">
                <h3>Other</h3>
              </div>
              <div className="w-full pt-2 flex items-center">
                <table>
                  <th>
                    <td>
                      <label className="pr-4">Pathway <i className="fas fa-folder-open"></i></label>
                    </td>
                    <td>
                          <SelectField 
                            // fieldName={""}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={[
                              <option></option>,
                              <option value="Vocational">Vocational</option>,
                              <option value="Higher Education">Higher Education</option>,
                              <option value="Re-integration">Re-integration</option>,
                              <option value="SEND">SEND</option>,
                              <option value="ESOL">ESOL</option>,
                            ]}
                            rest={{
                              className:"pep-select w-full"
                            }}
                          />
                          {/* <select className="pep-select w-full">
                            <option></option>
                            <option>Vocational</option>
                            <option>Higher Education</option>
                            <option>Re-integration</option>
                            <option>SEND</option>
                            <option>ESOL</option>
                          </select> */}
                    </td>
                    <td>
                      <label className="pr-4">Aspiration <i className="fas fa-folder-open"></i></label>
                    </td>
                    <td>
                          <SelectField 
                            // fieldName={""}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={[
                              <option></option>,
                              <option value="Vocational">Vocational</option>,
                              <option value="Higher Education">Higher Education</option>,
                            ]}
                            rest={{
                              className:"pep-select w-full"
                            }}
                          />
                          {/* <select className="pep-select w-full">
                            <option></option>
                            <option>Vocational</option>
                            <option>Higher Education</option>
                            <option>Re-integration</option>
                            <option>SEND</option>
                            <option>ESOL</option>
                          </select> */}
                    </td>
                  </th>
                </table>

              </div>
              
            </div>
          </div>
        </div >
      </Form>
    </Formik>

  );
}

export default PepPriorAttainmentTab;