import React from 'react'
import { setCurrentPage, setLoading, setPepApproved, setPepApprovedWithAction, setPepInadequate, setPepIsDue, setPepIsDueStatusList, setPepNotApproved, setPepOverDue, setPepRejected, setResultsPerPage, setSearch } from '../../constants/reducerActions'

const headerFilterReducer = (state, action) => {
  const payload = action.payload;

  switch(action.type) {

    case setPepIsDue:
      return {
        statusList: payload.statusList,
        resultsPerPage: 10,
        currentPage: 1,
        search: "",
        isLoading: false,
      }

    case setPepOverDue:
      return {
        statusList: payload.statusList,
        resultsPerPage: 10,
        currentPage: 1,
        search: "",
        isLoading: false,
      }

    case setPepRejected:
      return {
        statusList: payload.statusList,
        resultsPerPage: 10,
        currentPage: 1,
        search: "",
        isLoading: false,
      }

    case setPepInadequate:
      return {
        statusList: payload.statusList,
        resultsPerPage: 10,
        currentPage: 1,
        search: "",
        isLoading: false,
      }

    case setPepApproved:
      return {
        statusList: payload.statusList,
        resultsPerPage: 10,
        currentPage: 1,
        search: "",
        isLoading: false,
      }

    case setPepApprovedWithAction:
      return {
        statusList: payload.statusList,
        resultsPerPage: 10,
        currentPage: 1,
        search: "",
        isLoading: false,
      }

    case setPepNotApproved:
      return {
        statusList: payload.statusList,
        resultsPerPage: 10,
        currentPage: 1,
        search: "",
        isLoading: false,
      }


    case setSearch:
      return {
        ...state,
        search: payload.search,
      }

    case setResultsPerPage: 
      return {
        ...state,
        currentPage: 1,
        resultsPerPage: payload.resultsPerPage,
      }

    case setLoading:
      return {
        ...state,
        isLoading: payload.isLoading,
      }

    case setCurrentPage:
      return {
        ...state,
        currentPage: payload.currentPage, 
      }
  }
}

export default headerFilterReducer