  import axios from 'axios';
import React, { useCallback, useContext, useEffect, useReducer } from 'react';

// Importing skeleton for loading time
import Skeleton from 'react-loading-skeleton';

import { ToastContainer, toast } from 'react-toastify';
import PupilContext from '../../../../../Components/context/PupilContext';
import useActivateTabBasicInfo from '../../../../../hooks/useActivateTabBasicInfo';
import useDocumentTitle from '../../../../../hooks/useDocumentTitle';
import ContactRow from '../../../../../Components/ContactRow';
import contactReducer from '../../../../../reducers/contactReducer';
import { routeUrl } from '../../../../../Components/utils/RouteUrl';
import { closeHistoryModal as closeHistoryModalConstant, openHistoryModal as openHistoryModalConstant, success  } from '../../../../../constants/contactReducerAction';
import HistoryModalContacts from '../../../../../Components/HistoryModalContacts';
import { settings } from '../../../../../Components/utils/Settings';


const initialState = {
  isLoading: true,
  contacts: {},
  isHistoryModalOpen: false,
  historyModalName: "",
  historyHeading: "",
}

const PepContactsTab = (props) => {
  // Getting artive pupil id from context
  const { activePupilId } = useContext(PupilContext);
  const schoolId = localStorage.getItem("schoolId");
  // Setting state and reducer for contacts
  const [state, dispatch] = useReducer(contactReducer, initialState);

  // Change Document Title
  useDocumentTitle("Contacts");

  // Activating the navbar tab with current component
  useActivateTabBasicInfo(routeUrl.basic_info_contacts);
  // useActivateTabLink(props.location.pathname, true);

  useEffect(() => {
    // Getting Pupil Contact From API
    const url = `${settings.API_HOST}/rest/fetchPupilContacts?pupilId=${activePupilId}`;
    activePupilId && axios(url)
      .then((res) => {
        console.log(res.data, "Contacts data");
        dispatch({ type: success, payload: res.data.pupilcontacts })
      }).catch(e => {
        toast.warn("Something went wrong please reload and try again", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: false,
        });
      })
  }, [activePupilId]);

  // Open history model
  const openHistoryModal = useCallback((history, historyHeading) => {
    dispatch({
      type: openHistoryModalConstant,
      payload: { history, historyHeading }
    })
  }, [])

  // Close Modal History for Surname and forename of Pupil
  const closeHistoryModal = () => {
    dispatch({
      type: closeHistoryModalConstant
    });
  }

  return (
    <div className="flex-1 bg-container-custom rounded-custom-content">

      <ToastContainer />

      {/* <ComponentHeader /> */}

      {/* First Table of Page */}
      <div className="grid lg:grid-cols-1 grid-cols-none mt-5">
        <div className="bg-white tableview overflow-x-auto">
          {state.isLoading
            ? <Skeleton height={50} count={6} />
            : <table className="table-auto contacts-table">
              <thead>
                <tr>
                  <th>Connections</th>
                  <th>Name</th>
                  <th>Telephone</th>
                  <th>Mobile</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
              <ContactRow
                  fieldHeading="School"
                  fieldIcon={true}
                  history="socialWorker"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.schoolName}
                  telephone={state.contacts.schoolTel}
                  mobile={state.contacts.schoolMobile}
                  email={state.contacts.schoolEmail}
                />

                <ContactRow
                  fieldHeading="Social Worker"
                  fieldIcon={true}
                  history="socialWorker"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.socialWorkerName}
                  telephone={state.contacts.socialWorkerTel}
                  mobile={state.contacts.socialWorkerMobile}
                  email={state.contacts.socialWorkerEmail}
                />

                <ContactRow
                  fieldHeading="Social Woker 2"
                  fieldIcon={true}
                  history="socialWorker2"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.socialWorker2Name}
                  telephone={state.contacts.socialWorker2Tel}
                  mobile={state.contacts.socialWorker2Mobile}
                  email={state.contacts.socialWorker2Email}
                />

                <ContactRow
                  fieldHeading="Pupil's carer"
                  fieldIcon={true}
                  history="pupilCarer"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.pupilsCarerName}
                  telephone={state.contacts.pupilsCarerTel}
                  mobile={state.contacts.pupilsCarerMobile}
                  email={state.contacts.pupilsCarerEmail}
                />

                <ContactRow
                  fieldHeading="Support Worker"
                  fieldIcon={true}
                  history="supportworker"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.supportworkerName}
                  telephone={state.contacts.supportworkerTel}
                  mobile={state.contacts.supportworkerMobile}
                  email={state.contacts.supportworkerEmail}
                />

                <ContactRow
                  fieldHeading=" Social Worker Support Officer"
                  fieldIcon={true}
                  history="socialWorkerSupportOfficerr"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.virtualSchoolOfficerName}
                  telephone={state.contacts.virtualSchoolOfficerTel}
                  mobile={state.contacts.virtualSchoolOfficerMobile}
                  email={state.contacts.virtualSchoolOfficerEmail}
                />

                <ContactRow
                  fieldHeading="Independent Reviewing Officer"
                  fieldIcon={true}
                  history="iro"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.independentReviewingOfficerName}
                  telephone={state.contacts.independentReviewingOfficerTel}
                  mobile={state.contacts.independentReviewingOfficerMobile}
                  email={state.contacts.independentReviewingOfficerEmail}
                />

                <ContactRow
                  fieldHeading="Social Worker Manager"
                  fieldIcon={true}
                  history="socialWorkerManager"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.socialWorkerManagerName}
                  telephone={state.contacts.socialWorkerManagerTel}
                  mobile={state.contacts.socialWorkerManagerMobile}
                  email={state.contacts.socialWorkerManagerEmail}
                />

                <ContactRow
                  fieldHeading="Achievement Officer"
                  fieldIcon={true}
                  history="virtualSchoolOfficer"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.virtualSchoolOfficerName}
                  telephone={state.contacts.virtualSchoolOfficerTel}
                  mobile={state.contacts.virtualSchoolOfficerMobile}
                  email={state.contacts.virtualSchoolOfficerEmail}
                />

                

                {/* {(() => {
                  if (schoolId && schoolId == 1934) {
                    return (
                      <ContactRow
                        fieldHeading="Virtual School Officer 2"
                        fieldIcon={true}
                        history="virtualschoolofficer2"
                        openHistoryModal={openHistoryModal}
                        name={state.contacts.virtualSchoolOfficer2Name}
                        telephone={state.contacts.virtualSchoolOfficer2Tel}
                        mobile={state.contacts.virtualSchoolOfficer2Mobile}
                        email={state.contacts.virtualSchoolOfficer2Email}
                      />
                    )
                  }
                })()} */}

                <ContactRow
                  fieldHeading="Phase Leader"
                  fieldIcon={true}
                  history="phaseLeader"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.phaseLeaderName}
                  telephone={state.contacts.phaseLeaderTel}
                  mobile={state.contacts.phaseLeaderMobile}
                  email={state.contacts.phaseLeaderEmail}
                />
                
              </tbody>
            </table>
          }
        </div>
      </div>

      {/* Second Table of Page */}
      <div className="grid lg:grid-cols-1 grid-cols-none mt-5">
        <div className="bg-white tableview overflow-x-auto">
          {state.isLoading
            ? <Skeleton height={50} count={6} />
            : <table className="table-auto contacts-table">
              <thead>
                <tr>
                  <th>School Connections</th>
                  <th>Name</th>
                  <th>Telephone</th>
                  <th>Mobile</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {/* <ContactRow
                  fieldHeading="School"
                  fieldIcon={true}
                  history="school"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.schoolName}
                  telephone={state.contacts.schoolTel}
                  mobile={state.contacts.schoolMobile}
                  email={state.contacts.schoolEmail}
                /> */}

                <ContactRow
                  fieldHeading="Designated Lead"
                  fieldIcon={false}
                  name={state.contacts.designatedTeacherName}
                  telephone={state.contacts.designatedTeacherTel}
                  mobile={state.contacts.designatedTeacherMobile}
                  email={state.contacts.designatedTeacherEmail}
                />

                <ContactRow
                  fieldHeading="Assistant DT 1"
                  fieldIcon={false}
                  name={state.contacts.assistantDt1Name}
                  telephone={state.contacts.assistantDt1Tel}
                  mobile={state.contacts.assistantDt1Mobile}
                  email={state.contacts.assistantDt1Email}
                />

                <ContactRow
                  fieldHeading="Assistant DT 2"
                  fieldIcon={false}
                  name={state.contacts.assistantDt2Name}
                  telephone={state.contacts.assistantDt2Tel}
                  mobile={state.contacts.assistantDt2Mobile}
                  email={state.contacts.assistantDt2Email}
                />

                <ContactRow
                  fieldHeading="Assistant DT 3"
                  fieldIcon={false}
                  name={state.contacts.assistantDt3Name}
                  telephone={state.contacts.assistantDt3Tel}
                  mobile={state.contacts.assistantDt3Mobile}
                  email={state.contacts.assistantDt3Email}
                />

                <ContactRow
                  fieldHeading="Assistant DT 4"
                  fieldIcon={false}
                  name={state.contacts.assistantDt4Name}
                  telephone={state.contacts.assistantDt3Tel}
                  mobile={state.contacts.assistantDt3Mobile}
                  email={state.contacts.assistantDt3Email}
                />

                <ContactRow
                  fieldHeading="Assistant DT 4"
                  fieldIcon={false}
                  name={state.contacts.assistantDt4Name}
                  telephone={state.contacts.assistantDt3Tel}
                  mobile={state.contacts.assistantDt3Mobile}
                  email={state.contacts.assistantDt3Email}
                />

                <ContactRow
                  fieldHeading="SENCO"
                  fieldIcon={true}
                  history="senco"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.sencoName}
                  telephone={state.contacts.sencoTel}
                  mobile={state.contacts.sencoMobile}
                  email={state.contacts.sencoEmail}
                />

                <ContactRow
                  fieldHeading="Safeguarding Lead"
                  fieldIcon={true}
                  history="safeguardinglead"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.safeGuardingLeadName}
                  telephone={state.contacts.safeGuardingLeadTel}
                  mobile={state.contacts.safeGuardingLeadMobile}
                  email={state.contacts.safeGuardingLeadEmail}
                />

                <ContactRow
                  fieldHeading=" Assistant Safeguarding 1"
                  fieldIcon={false}
                  name={state.contacts.assis}
                  telephone={state.contacts.safeGuardingLeadTel}
                  mobile={state.contacts.safeGuardingLeadMobile}
                  email={state.contacts.safeGuardingLeadEmail}
                />

                <ContactRow
                  fieldHeading=" Assistant Safeguarding 2"
                  fieldIcon={false}
                  name={state.contacts.assis}
                  telephone={state.contacts.safeGuardingLeadTel}
                  mobile={state.contacts.safeGuardingLeadMobile}
                  email={state.contacts.safeGuardingLeadEmail}
                />

                <ContactRow
                  fieldHeading=" Assistant Safeguarding 3"
                  fieldIcon={false}
                  name={state.contacts.assis}
                  telephone={state.contacts.safeGuardingLeadTel}
                  mobile={state.contacts.safeGuardingLeadMobile}
                  email={state.contacts.safeGuardingLeadEmail}
                />


                <ContactRow
                  fieldHeading="Careers Lead"
                  fieldIcon={true}
                  history="careerslead"
                  openHistoryModal={openHistoryModal}
                  name={state.contacts.careerLeadsName}
                  telephone={state.contacts.careerLeadsTel}
                  mobile={state.contacts.careerLeadsMobile}
                  email={state.contacts.careerLeadsEmail}
                />

                <ContactRow
                  fieldHeading="CAMHS"
                  fieldIcon={false}
                  name={state.contacts.camhsName}
                  telephone={state.contacts.camhsTel}
                  mobile={state.contacts.camhsMobile}
                  email={state.contacts.camhsEmail}
                />

                <ContactRow
                  fieldHeading="EP"
                  fieldIcon={false}
                  name={state.contacts.epName}
                  telephone={state.contacts.epTel}
                  mobile={state.contacts.epMobile}
                  email={state.contacts.epEmail}
                />

                <ContactRow
                  fieldHeading="Attendance Officer"
                  fieldIcon={false}
                  name={state.contacts.attendanceName}
                  telephone={state.contacts.attendanceTel}
                  mobile={state.contacts.attendanceMobile}
                  email={state.contacts.attendanceEmail}
                />

                <ContactRow
                  fieldHeading="Data Extraction"
                  fieldIcon={false}
                  name={state.contacts.dataName}
                  telephone={state.contacts.dataTel}
                  mobile={state.contacts.dataMobile}
                  email={state.contacts.dataEmail}
                />

                {/* {
                  state.contacts.pepOtherContactList.map(contact => {
                    return (
                      <ContactRow
                        fieldHeading={contact.role}
                        fieldIcon={false}
                        name={contact.name}
                        telephone={contact.tel}
                        mobile={contact.mobile}
                        email={contact.email}
                      />
                    )
                  })
                } */}
              </tbody>
            </table>
          }
        </div>
      </div>

      {state.isHistoryModalOpen &&
        <HistoryModalContacts
          isHistoryModalOpen={state.isHistoryModalOpen}
          closeHistoryModal={closeHistoryModal}
          historyModalName={state.historyModalName}
          historyHeading={state.historyHeading}
        />
      }
    </div>
  );


}

export default PepContactsTab;