import React from 'react';
import { routeUrl } from '../../../../../Components/utils/RouteUrl';
import useActivateTabBasicInfo from '../../../../../hooks/useActivateTabBasicInfo';

const PepAddNewSchoolTab = () => {

  useActivateTabBasicInfo(routeUrl.basic_info_add_new_school);

  return (
    <div className="secondary-section w-full">
      <div className="basic-header flex w-full py-2 justify-between">
        <div className="basic-left">
          <h1>Add New School
            <span><i className="fa-regular fa-circle-video"></i></span>
            <span><i className="fa-regular fa-circle-info"></i></span>
          </h1>
        </div>
      </div>
      <div className="rounded-3xl bg-white p-4 w-full">
        <div className="flex flex-wrap w-full">
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">School LA</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">URN</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">DFE</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">School Name</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">Contact Name</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">Contact Tel</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">Email </label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">School Website</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">Address 1</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">Address 2</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">City</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">Postcode</label>
            <input type="text" className="pep-input" />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32">Ofsted Grade</label>
            <select className="pep-select">
              <option></option>
              <option>Good</option>
              <option>Inadequate</option>
              <option>Insufficient evidence</option>
              <option>Outstanding</option>
              <option>Requires improvement</option>
              <option>Serious Weaknesses</option>
              <option>Special Measures</option>
            </select>
          </div>
        </div>
      </div>
      <div className="rounded-3xl bg-white p-4 w-full">
        <div className="table-box w-full pt-2">
          <div className="table-outer rounded-3xl bg-white">
            <table className="table-fixed intervention-table text-center w-full">
              <thead>
                <tr>
                  <th className="blue w-24">Start Date</th>
                  <th className="d-blue">Intervention Type</th>
                  <th className="purple w-40">Agency</th>
                  <th className="orange">Key Worker Name</th>
                  <th className="d-orange w-32">Hours (1 to 25)</th>
                  <th className="d-pink w-24">End Date</th>
                  <th className="pink w-12"></th>
                </tr>
              </thead>
              <tbody>
                <tr>

                  <td></td>
                  <td>
                    <select className="pep-select"><option></option><option>Academic Tutor</option><option>Application Support</option><option>CAMHS (Child and Adolescent Mental Health Services)</option><option>EP</option><option>ESOL Outreach Tutor</option><option>Exclusions / Inclusions</option><option>IAG Interview</option><option>Mentor</option><option>Monday Wheels</option><option>Progressions Coach</option><option>Transition</option><option>VS CAMHS (Virtual School Child and Adolescent Mental Health Services)</option><option>VS EP</option><option>Youth Offending Service</option><option>Other</option></select>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><i className="fa-regular fa-circle-trash"></i></td>
                </tr>
                <tr>

                  <td></td>
                  <td>
                    <select className="pep-select"><option></option><option>Academic Tutor</option><option>Application Support</option><option>CAMHS (Child and Adolescent Mental Health Services)</option><option>EP</option><option>ESOL Outreach Tutor</option><option>Exclusions / Inclusions</option><option>IAG Interview</option><option>Mentor</option><option>Monday Wheels</option><option>Progressions Coach</option><option>Transition</option><option>VS CAMHS (Virtual School Child and Adolescent Mental Health Services)</option><option>VS EP</option><option>Youth Offending Service</option><option>Other</option></select>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><i className="fa-regular fa-circle-trash"></i></td>
                </tr>
                <tr>

                  <td></td>
                  <td>
                    <select className="pep-select"><option></option><option>Academic Tutor</option><option>Application Support</option><option>CAMHS (Child and Adolescent Mental Health Services)</option><option>EP</option><option>ESOL Outreach Tutor</option><option>Exclusions / Inclusions</option><option>IAG Interview</option><option>Mentor</option><option>Monday Wheels</option><option>Progressions Coach</option><option>Transition</option><option>VS CAMHS (Virtual School Child and Adolescent Mental Health Services)</option><option>VS EP</option><option>Youth Offending Service</option><option>Other</option></select>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><i className="fa-regular fa-circle-trash"></i></td>
                </tr>
                <tr>

                  <td></td>
                  <td>
                    <select className="pep-select"><option></option><option>Academic Tutor</option><option>Application Support</option><option>CAMHS (Child and Adolescent Mental Health Services)</option><option>EP</option><option>ESOL Outreach Tutor</option><option>Exclusions / Inclusions</option><option>IAG Interview</option><option>Mentor</option><option>Monday Wheels</option><option>Progressions Coach</option><option>Transition</option><option>VS CAMHS (Virtual School Child and Adolescent Mental Health Services)</option><option>VS EP</option><option>Youth Offending Service</option><option>Other</option></select>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><i className="fa-regular fa-circle-trash"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  );
}

export default PepAddNewSchoolTab;