import { setLoading, setNccSecondaryData, setUploadedSchoolReports } from '../../constants/reducerActions'

const nccDataSecondaryReducer = (state, action) => {
  const data = action.payload;

  switch(action.type) {
    case setNccSecondaryData:
      return {
        presentPer: data.presentPer,
        exclusionCount: data.exclusionCount,
        dataSecondary: data.dataSecondary,
        pupilUniqueInfo: data.pupilUniqueInfo,
        schoolUploadedFiles: data.schoolUploadedFiles,
        previousdataSecondary: data.previousdataSecondary,
        secondaryDataOptionsList: data.secondaryDataOptionsList,
        isLoading: false,
      }
      
    case setUploadedSchoolReports:
      return {
        ...state,
        schoolUploadedFiles: data.schoolReports,
      }
      // return {

      //   EHCP: data.dataSecondary?.commonData?.EHCP,
      //   SENSupport: data.dataSecondary?.commonData?.SENSupport,
      //   actionsAreInPlace: data.dataSecondary?.commonData?.actionsAreInPlace,
      //   actionsBeingTaken: data.dataSecondary?.commonData?.actionsBeingTaken,
      //   attendance: data.presentPer,
      //   id: data.dataSecondary?.commonData?.id,
      //   noOfSessions: data.exclusionCount,
      //   primaryIdentifiedNeed: data.dataSecondary?.commonData?.primaryIdentifiedNeed,
      //   youngPersonHaveSEND: data.dataSecondary?.commonData?.youngPersonHaveSEND,

      //   goodLevOfDevAchiev: data.pupilUniqueInfo?.goodLevOfDevAchiev,
      //   keyStage1PriorAttainment: data.pupilUniqueInfo?.keyStage1PriorAttainment,
      //   keyStage2PriorAttainment: data.pupilUniqueInfo?.keyStage2PriorAttainment,
      //   phonicKeyStage1Score: data.pupilUniqueInfo?.phonicKeyStage1Score,
      //   readingKeyStage1Score: data.pupilUniqueInfo?.readingKeyStage1Score,
      //   writingKeyStage1Score: data.pupilUniqueInfo?.writingKeyStage1Score,
      //   mathKeyStage1Score: data.pupilUniqueInfo?.mathKeyStage1Score,
      //   ks2ScoreSPAG: data.pupilUniqueInfo?.ks2ScoreSPAG,
      //   ks2ScoreReading: data.pupilUniqueInfo?.ks2ScoreReading,
      //   ks2ScoreWriting: data.pupilUniqueInfo?.ks2ScoreWriting,
      //   ks2ScoreMaths: data.pupilUniqueInfo?.ks2ScoreMaths,
      //   engPreviousGrade: data.previousdataSecondary?.engCurrentGrade,
      //   mathsPreviousGrade: data.previousdataSecondary?.mathsCurrentGrade,
        

      //   createdDate: data.dataSecondary?.createdDate,
      //   currentPathway: data.dataSecondary?.currentPathway,
      //   currentlyWorking: data.dataSecondary?.currentlyWorking,
      //   dateOfPep: data.dataSecondary?.dateOfPep,
      //   endEndOfYearTarget: data.dataSecondary?.endEndOfYearTarget,
      //   engCurrentGrade: data.dataSecondary?.engCurrentGrade,
      //   engProgress: data.dataSecondary?.engProgress,
      //   engTargetThisYear: data.dataSecondary?.engTargetThisYear,
      //   engY11Estimate: data.dataSecondary?.engY11Estimate,
      //   engY11Target: data.dataSecondary?.engY11Target,
      //   expectationsAndTarget: data.dataSecondary?.expectationsAndTarget,
      //   howItIsMeasured: data.dataSecondary?.howItIsMeasured,
      //   id: data.dataSecondary?.id,
      //   isCompleted: data.dataSecondary?.isCompleted,
      //   isDeleted: data.dataSecondary?.isDeleted,
      //   makingGoodProgress: data.dataSecondary?.makingGoodProgress,
      //   mathsCurrentGrade: data.dataSecondary?.mathsCurrentGrade,
      //   mathsEndOfYearTarget: data.dataSecondary?.mathsEndOfYearTarget,
      //   mathsProgress: data.dataSecondary?.mathsProgress,
      //   mathsTargetThisYear: data.dataSecondary?.mathsTargetThisYear,
      //   mathsY11Estimate: data.dataSecondary?.mathsY11Estimate,
      //   mathsY11Target: data.dataSecondary?.mathsY11Target,
      //   nameOfYoungPerson: data.dataSecondary?.nameOfYoungPerson,
      //   pupilId: data.dataSecondary?.pupilId,
      //   schoolId: data.dataSecondary?.schoolId,
      //   schoolReportUploaded: data.dataSecondary?.schoolReportUploaded,
      //   sdqDate: data.dataSecondary?.sdqDate,
      //   sdqScore: data.dataSecondary?.sdqScore,
      //   subjectList: data.dataSecondary?.subjectList,
      //   updatedDate: data.dataSecondary?.updatedDate,
      //   workingTowardsQualifications: data.dataSecondary?.workingTowardsQualifications,

      //   secondaryDataOptionsList: data.secondaryDataOptionsList,

      //   schoolUploadedFiles: data.schoolUploadedFiles,
      // }

    case setLoading:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
  }
}

export default nccDataSecondaryReducer
