import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useState, useEffect, useContext, useReducer } from 'react';
import { useToastContainer } from 'react-toastify';
import PupilContext from '../../../../Components/context/PupilContext';
import { settings } from '../../../../Components/utils/Settings';
import { setPepCincpEyfs } from '../../../../constants/reducerActions';
import cincpReducer from '../../../../reducers/cincpReducer';
import PepSecondaryHeader from '../../PepSecondaryHeader';
import CINCPHoc from './CINCPHoc';
import CINCPInputField from './CINCPInputField';
import CINCPTextArea from './CINCPTextArea';
import PepCinCpHistoryModule from './PepCinCpHistoryModel';

const initialState = {
    gldAchieved: "",
    phonicsScore: "",
    comment: "",
    attendanceEndOfAutumn: "",
    attendanceEndOfSpring: "",
    attendanceEndOfSummer: "",
    communicationEndOfAutumn: "",
    communicationEndOfSpring: "",
    communicationEndOfSummer: "",
    physicalDevelopmentEndOfAutumn: "",
    physicalDevelopmentEndOfSpring: "",
    physicalDevelopmentEndOfSummer: "",
    pseDevelopmentEndOfAutumn: "",
    pseDevelopmentEndOfSpring: "",
    pseDevelopmentEndOfSummer: "",
    commentsDevelopmentEndOfAutumn: "",
    commentsDevelopmentEndOfSpring: "",
    commentsDevelopmentEndOfSummer: "",
    educationNeedThisChild: "",
    barrierToMakingGoodProgress: "",
    spendingPpgChild: "",
    helpOvercomeTheBarriers: "",
    childIsNotInSchool: "",
    successLookLike: "",
    isLoading: false,
}

const PepCinCPEyfs = ({ textFieldHandler, saveField, historyModel, openHistoryPopup }) => {

    const { activePupilId } = useContext(PupilContext);
    const roleId = localStorage.getItem("userRole");
    const userId = localStorage.getItem("userId");
    const schoolId = localStorage.getItem("schoolId");
    // const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
    // const [historyModalList, setHistoryModalList] = useState([]);

    const [reportData, dispatch] = useReducer(cincpReducer, initialState);

    useEffect(() => {
        activePupilId && axios(`${settings.API_HOST}/rest/cin-cp?pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
            .then(res => {
                dispatch({ type: setPepCincpEyfs, payload: { data: res.data.pepEyfsCinCp } })
            });
    }, [activePupilId]);


    const initialFormState = {
        gldAchieved: reportData.gldAchieved,
        phonicsScore: reportData.phonicsScore,
        comment: reportData.comment,
        attendanceEndOfAutumn: reportData.attendanceEndOfAutumn,
        attendanceEndOfSpring: reportData.attendanceEndOfSpring,
        attendanceEndOfSummer: reportData.attendanceEndOfSummer,
        communicationEndOfAutumn: reportData.communicationEndOfAutumn,
        communicationEndOfSpring: reportData.communicationEndOfSpring,
        communicationEndOfSummer: reportData.communicationEndOfSummer,
        physicalDevelopmentEndOfAutumn: reportData.physicalDevelopmentEndOfAutumn,
        physicalDevelopmentEndOfSpring: reportData.physicalDevelopmentEndOfSpring,
        physicalDevelopmentEndOfSummer: reportData.physicalDevelopmentEndOfSummer,
        pseDevelopmentEndOfAutumn: reportData.pseDevelopmentEndOfAutumn,
        pseDevelopmentEndOfSpring: reportData.pseDevelopmentEndOfSpring,
        pseDevelopmentEndOfSummer: reportData.pseDevelopmentEndOfSummer,
        commentsDevelopmentEndOfAutumn: reportData.commentsDevelopmentEndOfAutumn,
        commentsDevelopmentEndOfSpring: reportData.commentsDevelopmentEndOfSpring,
        commentsDevelopmentEndOfSummer: reportData.commentsDevelopmentEndOfSummer,
        educationNeedThisChild: reportData.educationNeedThisChild,
        barrierToMakingGoodProgress: reportData.barrierToMakingGoodProgress,
        spendingPpgChild: reportData.spendingPpgChild,
        helpOvercomeTheBarriers: reportData.helpOvercomeTheBarriers,
        childIsNotInSchool: reportData.childIsNotInSchool,
        successLookLike: reportData.successLookLike,
    }

    return (
        <>
            { }
            <useToastContainer />
            <PepSecondaryHeader
                subHeading=""
                heading="CIN / CP / PLAC Early Years - Education Info"
                buttons={[
                    <button className="blue-button px-5 py-2  text-sm">Complete</button>,
                ]}
            />
            <Formik
                initialValues={initialFormState}
                enableReinitialize
            >
                <Form>
                    <div className="secondary-section w-full">
                        <div className="rounded-3xl bg-white p-4 w-full">
                            <div className="flex w-full py-4">
                                <h3 className="blue rounded-3xl w-full px-4 py-2">Prior Attainment</h3>
                            </div>
                            <div className="table-box w-full">
                                <div className="table-outer rounded-3xl bg-white">
                                    <table className="table-fixed text-left w-full">
                                        <thead>
                                            <tr>
                                                <th className="blue w-32">EYFS</th>
                                                <th className="d-blue w-32">GLD achieved?</th>
                                                <th>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="gldAchieved"
                                                        rest={{
                                                            className: "pep-input w-full"
                                                        }}
                                                    />
                                                    
                                                </th>
                                                <th className="orange w-32">Phonics Score</th>
                                                <th>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="phonicsScore"
                                                        rest={{
                                                            className: "pep-input w-full"
                                                        }}
                                                    />
                                                    
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>Comments</td>
                                                <td colspan="4">
                                                    <CINCPTextArea
                                                        placeholder={""}
                                                        fieldName={"barrierToMakingGoodProgress"}
                                                        fieldHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        rest={""}
                                                    />
                                                    
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-3xl bg-white mt-4 p-4 w-full">
                            <div className="flex w-full py-4">
                                <h3 className="pink rounded-3xl w-full px-4 py-2">Latest Attendance and Attainment (EYFS)</h3>
                            </div>
                            <div className="table-box w-full">
                                <div className="table-outer rounded-3xl bg-white">
                                    <table className="table-fixed text-center w-full">
                                        <thead>
                                            <tr>
                                                <th className="blue w-32"></th>
                                                <th className="d-blue w-4/12">End of Autumn 2021</th>
                                                <th className="purple w-4/12">End of Spring 2022</th>
                                                <th className="orange w-4/12">End of Summer 2022</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Attendance</td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="attendanceEndOfAutumn"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.attendanceEndOfAutumn ? reportData.attendanceEndOfAutumn : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="attendanceEndOfSpring"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.attendanceEndOfSpring ? reportData.attendanceEndOfSpring : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="attendanceEndOfSummer"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.attendanceEndOfSummer ? reportData.attendanceEndOfSummer : ""}
                                                    /> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Communication and language</td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="communicationEndOfAutumn"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.communicationEndOfAutumn ? reportData.communicationEndOfAutumn : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="communicationEndOfSpring"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.communicationEndOfSpring ? reportData.communicationEndOfSpring : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="communicationEndOfSummer"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.communicationEndOfSummer ? reportData.communicationEndOfSummer : ""}
                                                    /> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Physical Development</td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="physicalDevelopmentEndOfAutumn"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.physicalDevelopmentEndOfAutumn ? reportData.physicalDevelopmentEndOfAutumn : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="physicalDevelopmentEndOfSpring"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        OpenHistoryPopup className="pep-input w-full"
                                                        value={reportData.physicalDevelopmentEndOfSpring ? reportData.physicalDevelopmentEndOfSpring : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="physicalDevelopmentEndOfSummer"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.physicalDevelopmentEndOfSummer ? reportData.physicalDevelopmentEndOfSummer : ""}
                                                    /> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>PSE development</td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="pseDevelopmentEndOfAutumn"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.pseDevelopmentEndOfAutumn ? reportData.pseDevelopmentEndOfAutumn : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="pseDevelopmentEndOfSpring"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.pseDevelopmentEndOfSpring ? reportData.pseDevelopmentEndOfSpring : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="pseDevelopmentEndOfSummer"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.pseDevelopmentEndOfSummer ? reportData.pseDevelopmentEndOfSummer : ""}
                                                    /> */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Comments</td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="commentsDevelopmentEndOfAutumn"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.commentsDevelopmentEndOfAutumn ? reportData.commentsDevelopmentEndOfAutumn : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="commentsDevelopmentEndOfSpring"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.commentsDevelopmentEndOfSpring ? reportData.commentsDevelopmentEndOfSpring : ""}
                                                    /> */}
                                                </td>
                                                <td>
                                                    <CINCPInputField
                                                        onChangeHandler={textFieldHandler}
                                                        saveField={saveField}
                                                        fieldName="commentsDevelopmentEndOfSummer"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="pep-input w-full"
                                                        value={reportData.commentsDevelopmentEndOfSummer ? reportData.commentsDevelopmentEndOfSummer : ""}
                                                    /> */}
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-3xl bg-white mt-4 p-4 w-full">
                            <div className="py-2 widebox-container">
                                <div className="pb-2">
                                    <p className="inline-block">What are the main educational needs of this child? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("education_need_this_child")}></i></p>
                                    <div className="textarea-block mt-1 w-full">
                                        <CINCPTextArea
                                            placeholder={"Comment"}
                                            fieldName="educationNeedThisChild"
                                            fieldHandler={textFieldHandler}
                                            saveField={saveField}
                                            rest={""}
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="py-2 widebox-container">
                                <div className="pb-2">
                                    <p className="inline-block">What are the barriers to making good progress? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("barriers_to_making_good_progress")} ></i></p>
                                    <div className="textarea-block mt-1 w-full">
                                        <CINCPTextArea
                                            placeholder={"Comment"}
                                            fieldName="barrierToMakingGoodProgress"
                                            fieldHandler={textFieldHandler}
                                            saveField={saveField}
                                            rest={""}
                                        />
                                        
                                    </div>
                                </div>
                                <div className="py-2 widebox-container">
                                    <div className="pb-2">
                                        <p className="inline-block">How is the school spending PPG for the child? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("spending_for_this_child")} ></i></p>
                                        <div className="textarea-block mt-1 w-full">
                                            <CINCPTextArea
                                                placeholder={"Comment"}
                                                fieldName="spendingPpgChild"
                                                fieldHandler={textFieldHandler}
                                                saveField={saveField}
                                                rest={""}
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="py-2 widebox-container">
                                    <div className="pb-2">
                                        <p className="inline-block">What Additional support might help overcome the barriers? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("help_overcome_the_barriers")} ></i></p>
                                        <div className="textarea-block mt-1 w-full">
                                            <CINCPTextArea
                                                placeholder={"Comment"}
                                                fieldName="helpOvercomeTheBarriers"
                                                fieldHandler={textFieldHandler}
                                                saveField={saveField}
                                                rest={""}
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="py-2 widebox-container">
                                    <div className="pb-2">
                                        <p className="inline-block">If this child is NOT in school? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("child_is_not_in_school")} ></i></p>
                                        <div className="textarea-block mt-1 w-full">
                                            <CINCPTextArea
                                                placeholder={"Comment"}
                                                fieldName="childIsNotInSchool"
                                                fieldHandler={textFieldHandler}
                                                saveField={saveField}
                                                rest={""}
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="py-2 widebox-container">
                                    <div className="pb-2">
                                        <p className="inline-block">What would success look like? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("success_look_like")} ></i></p>
                                        <div className="textarea-block mt-1 w-full">
                                            <CINCPTextArea
                                                placeholder={"Comment"}
                                                fieldName="successLookLike"
                                                fieldHandler={textFieldHandler}
                                                saveField={saveField}
                                                rest={""}
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <PepCinCpHistoryModule
                                isHistoryModalOpen={isHistoryModalOpen}
                                closeHistoryModal={closeHistoryModal}
                                historyModalList={historyModalList}
                            /> */}
                        </div>

                        {historyModel}
                    </div>
                </Form>
            </Formik>
        </>
    )
}

export default CINCPHoc(PepCinCPEyfs);