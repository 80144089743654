import { Field, FieldArray } from 'formik';
import React from 'react'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import InputField from '../InputField';
import SelectField from '../SelectField';

const SecondaryKs3SubjectRow = ({
  index,
  ks3CustomSubjectOnChangeHandler,
  saveForm,
  secondaryOptions,
  deleteKs3Subject,
  progressOptionList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  
  return (
    <>
      {
        isLoading ?
          <tr>
            <td colSpan={7}><Skeleton height={20}/></td>
          </tr> :
          <tr key={index}>
            <td className="blue">
              <InputField
                fieldName={`pupilUniqueInfo.subjectList[${index}].subName`}
                onChangeHandler={ks3CustomSubjectOnChangeHandler}
                saveForm={saveForm}
                restForLoader={{
                  className: "absolute top-0 left-0 w-full h-full blue grid items-center"
                }}
              />
            </td>
            <td>
              
            </td>
            <td>
              <SelectField
                fieldName={`pupilUniqueInfo.subjectList[${index}].curGrade`}
                onChangeHandler={ks3CustomSubjectOnChangeHandler}
                saveForm={saveForm}
                optionsList={secondaryOptions}
                rest={{
                  className: "pep-select"
                }}
              />
            </td>
            <td>
              <SelectField
                fieldName={`pupilUniqueInfo.subjectList[${index}].endYearTarget`}
                onChangeHandler={ks3CustomSubjectOnChangeHandler}
                saveForm={saveForm}
                optionsList={secondaryOptions}
                rest={{
                  className: "pep-select"
                }}
              />
            </td>
            <td>
              <SelectField
                fieldName={`pupilUniqueInfo.subjectList[${index}].target`}
                onChangeHandler={ks3CustomSubjectOnChangeHandler}
                saveForm={saveForm}
                optionsList={secondaryOptions}
                rest={{
                  className: "pep-select"
                }}
              />
            </td>
            <td>
              <SelectField
                fieldName={`pupilUniqueInfo.subjectList[${index}].expected`}
                onChangeHandler={ks3CustomSubjectOnChangeHandler}
                saveForm={saveForm}
                optionsList={secondaryOptions}
                rest={{
                  className: "pep-select"
                }}
              />
            </td>
            <td>
              <div className="flex items-center gap-3">
                <SelectField
                  fieldName={`pupilUniqueInfo.subjectList[${index}].progress`}
                  onChangeHandler={ks3CustomSubjectOnChangeHandler}
                  saveForm={saveForm}
                  optionsList={progressOptionList}
                  rest={{
                    className: "pep-select"
                  }}
                />
                <FieldArray name="pupilUniqueInfo.subjectList">
                  {
                    ({form, remove}) => {
                      return (
                        <i
                          className="fa-solid fa-trash-can text-red-500 cursor-pointer"
                          onClick={() => deleteKs3Subject({
                            form,
                            index,
                            setIsLoading,
                            remove,                          
                          })}
                        >
                        </i>
                      )
                    }
                  }
                </FieldArray>
              </div>
            </td>
          </tr>
      }
    </>
  )
}

export default SecondaryKs3SubjectRow