import React, { useEffect, useState } from "react";
import axios from "axios";
import { settings } from "../../Components/utils/Settings";
import { toast } from "react-toastify";

function SchoolCaller() {
  const UserId = localStorage.getItem("userId");

  console.log(UserId);

  useEffect(() => {
    const pupilInfo = localStorage.getItem("getschool");
    async function makecall() {
      https: await axios
        .get(`${settings.API_HOST}/rest/fetchUserDetails?userId=${UserId}`)
        .then((response) => {
          const sohouser = response?.data?.user?.sohoUsername;
          const sohopassword = response?.data?.user?.sohoPassword;

          console.log(pupilInfo);
          const baseurl = "https://soho66.co.uk/92573";
          axios
            .get(
              `${baseurl}/url_dial.aspx?f=${sohouser}&h=${sohopassword}&t=${pupilInfo}`
            )
            .then((res) => {
              res.header("Access-Control-Allow-Origin", "*");
              res.header("Access-Control-Allow-Headers", "X-Requested-With");
              console.log(res);
            });
        });
    }
    makecall();
    // return () => source.cancel();
  }, []);

  return <div></div>;
}

export default SchoolCaller;
