import { Field } from 'formik';
import { fromPairs } from 'lodash';
import React from 'react'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton';

const RadioField =  ({
  rest, 
  fieldName, 
  onChangeHandler, 
  saveForm, 
  radioOptionsList,
  disabled = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex gap-3 relative items-center" {...rest}>
      <Field name={fieldName}>
        {
          ({field, form}) => {
            return (
              <>
                {
                  radioOptionsList && radioOptionsList.map(option => {
                    return (
                      <label className="flex items-center gap-1 cursor-pointer">
                        <input
                          type="radio"
                          value={option.innerValue}
                          checked={field.value === option.innerValue}
                          onChange={() => onChangeHandler({
                            saveForm,
                            fieldName,
                            setIsLoading,
                            fieldValue: option.innerValue,
                            setFieldValue: form.setFieldValue,
                            values: form.values,
                          })}
                          disabled={disabled}
                        />
                        {option.outerValue}
                      </label>
                    )
                  })
                }
              </>
            )
          }
        }
      </Field>
      {
        isLoading &&
          <div className='absolute top-0 left-0 h-full w-full grid items-center bg-white'>
            <Skeleton />
          </div>
      }
    </div>
  )
}

export default RadioField
