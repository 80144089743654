import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import PupilContext from '../Components/context/PupilContext';
import { routeUrl } from '../Components/utils/RouteUrl';

// This Hooks activate tab link with given path 
// Also checks if no student is selected than its return back to attendance page by backToDashboard paramter setting true
const useActivateTabBasicInfo = (pathname, backToDashboard) => {
  const history = useHistory();
  // const {activePupilId} = useContext(PupilContext);

  useEffect(() => {

    // Checks if backToDashboard is true then its return to dashboard there is no active pupil id selected
    // if(backToDashboard) {
    //   !activePupilId && history.push(routeUrl.attendance);
    // }
    const navMenu = document.querySelector(".basic-tab");
    // const navBar = document.querySelector(".nav-bar");
    // const body = document.querySelector("body");

    // This add and removes navbar when its in mobile
    // if (navBar && !navBar.classList.contains("hidden")) {
    //   navBar.classList.add("hidden");
    //   body && body.classList.remove("overflow-hidden");
    // }

    // Activating the given link in nav tabs
    navMenu && navMenu.childNodes.forEach(li => {
      // console.log(li);
      const a = li.childNodes[0];
      // console.log(a);
      if(a.pathname === pathname) {
        li.classList.add("tab-active");
        // li.classList.add("mr-4");
      } else {
        li.classList.remove("tab-active");
        // li.classList.remove("mr-4");
      }
    })

  }, []);
}

export default useActivateTabBasicInfo;
