import React from 'react';
import {
    Route,
    Redirect
  } from "react-router-dom";
  
//Pre defined Routes URL -> To change url pattern easily in future
import { routeUrl } from '../Components/utils/RouteUrl';


const PrivateRouter = ({component: Component, isAuthinticated, ...rest}) => {
    return (
        <Route
        {...rest}
        render={
          (props) =>  isAuthinticated === true
          ? <Component {...props} />
          : <Redirect to={{pathname: routeUrl.login_url, state: {from: props.location}}} />}
      />
    );
}

export default PrivateRouter;