
import React from 'react';
import { useState } from 'react';

const AttendanceContext = React.createContext();

export  const AttendanceContextProvider = (props) => {

  const Term = localStorage.getItem('term')
  console.log(Term)
  const [currentSesson, setcurrentSesson] = useState(Term);
  
  // setcurrentSesson(TermGet)
  const [getSelectedStudent, setSelectedStudent] = useState("");

  return(
    <AttendanceContext.Provider 
      value={{ 
        currentSesson, 
        setcurrentSesson, 
        getSelectedStudent, 
        setSelectedStudent 
      }}
    >
      {props.children}
    </AttendanceContext.Provider>
  )
}

export default AttendanceContext;
