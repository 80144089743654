import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
//Importing Icons from react icon
import { FiFolder } from "react-icons/fi";
import Icon, { FolderOutlined } from "@ant-design/icons"

// Importing form fields

import TextFormField from "../../Components/TextFormField";

// Importing skeleton for loading time
import Skeleton from "react-loading-skeleton";
import Modal from "react-modal";
// Importing Activate Link Function for activating the components
import HistoryModal from "../../Components/HistoryModal";

import { ToastContainer, toast } from "react-toastify";
import PupilContext from "../../Components/context/PupilContext";
import ComponentHeader from "../../Components/ComponentHeader";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useActivateTabLink from "../../hooks/useActivateTabLink";
import {
  closeHistoryModal as closeHistoryModalConstant,
  loading,
  notLoading,
  openHistoryModal as openHistoryModalConstant,
  success,
} from "../../constants/reducerActions";
import pupilReducer from "../../reducers/pupilReducer";
import { useHistory } from "react-router";
import useActivateTabBasicInfo from "../../hooks/useActivateTabBasicInfo";
import { routeUrl } from "../../Components/utils/RouteUrl";
import { logRoles } from "@testing-library/dom";
import { settings } from "../../Components/utils/Settings";
import { Button, DatePicker, Input, Select } from "antd";
import moment from "moment";

const initialState = {
  isLoading: true,
  forename: "",
  surname: "",
  upn: "",
  dob: "",
  stringDob: "",
  yeargroup: "",
  phase: "",
  gender: "",
  senCop: "",
  eal: "",
  firstLanguage: "",
  ethnicity: "",
  uasc: "",
  dateIntoCare: "",
  youngPersonEmail: "",

  schoolName: "",
  schoolTel: "",
  schoolEmail: "",
  schoolAddress1: "",
  schoolAddress2: "",
  schoolAddress3: "",
  schoolPostcode: "",
  laInOrOut: "",
  schoolWebsite: "",
  ofstedGrade: "",
  schoolLa: "",
  urn: "",
  dfe: "",
  dualRegisterSchool: "",

  vsPupilNo: "",
  legalStatus: "",
  numberOfPlacements: "",
  typeOfPlacement: "",
  placementLocalAuthority: "",
  placementDate: "",
  contactArrangements: "",
  placementNotification: "",
  dateBecameCareLeaver: "",
  numberOfSchool: "",
  countryOfOrigin: "",
  religion: "",
  dateLeftCare: "",
  tution: "",

  socialWorker: "",
  socialWorkerTeam: "",
  designatedTeacher: "",
  pupilsCarerName: "",
  virtualSchoolOfficer: "",
  iro: "",

  isHistoryModalOpen: false,
  historyModalName: "",
};

const Pupil = (props) => {
  const rollId = localStorage.getItem("userRole");
  console.log("pupil Render");
  const history = useHistory();
  // Getting Context variables
  const { activePupilId,reloadValue, setreloadValue } = useContext(PupilContext);

  const [state, dispatch] = useReducer(pupilReducer, initialState);
  const [Popschool,setPopschool] = useState(false);
  const [SchoolInfo,setSchoolInfo] = useState(false)
  const [ArrayData,setArrayData] = useState([])
  const [searchArray,setsearchArray] = useState([])
  const [DatePop,setDatePop] = useState(false)
  const [Dates,setDates] = useState('')
  const [SubSchoolId,setSubSchoolId] = useState('')
  const [HistoryPop,setHistoryPop] = useState(false)
  const [HistoryData,setHistoryData] = useState([])
  const {
    isLoading,

    forename,
    surname,
    upn,
    dob,
    stringDob,
    yeargroup,
    phase,
    gender,
    senCop,
    eal,
    firstLanguage,
    ethnicity,
    uasc,
    dateIntoCare,
    youngPersonEmail,
    PupilId,

    schoolName,
    schoolTel,
    schoolEmail,
    schoolAddress1,
    schoolAddress2,
    schoolAddress3,
    schoolPostcode,
    laInOrOut,
    schoolWebsite,
    ofstedGrade,
    schoolLa,
    schoolId,
    urn,
    dfe,
    dualRegisterSchool,

    vsPupilNo,
    legalStatus,
    numberOfPlacements,
    typeOfPlacement,
    placementLocalAuthority,
    placementDate,
    contactArrangements,
    placementNotification,
    dateBecameCareLeaver,
    numberOfSchool,
    countryOfOrigin,
    religion,
    dateLeftCare,
    tution,

    socialWorker,
    socialWorkerTeam,
    designatedTeacher,
    pupilsCarerName,
    virtualSchoolOfficer,
    iro,

    isHistoryModalOpen,
    historyModalName,
  } = state;
  //change window/page title
  useDocumentTitle("Pupil");

  useActivateTabLink(routeUrl.pupil, true);

  const UserRole =localStorage?.getItem('userRole')
  const UserId = localStorage?.getItem('userId')

  useEffect(() => {
    // getting Pupil Basic Info from Pupil Basic Info API
    if (activePupilId) {
      axios(
        `${settings.API_HOST}/rest/fetchPupilBasicInfo?pupilId=${activePupilId}`
      )
        .then((res) => {
          dispatch({ type: success, payload: res.data });
        })
        .catch((err) => {
          toast.warn(
            "Something went wrong please reload and try again and again",
            {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: false,
            }
          );
        });
    }
    // activePupilId &&
  }, [activePupilId,reloadValue]);

  // Open History Modal
  const openHistoryModal = (e, history) => {
    dispatch({
      type: openHistoryModalConstant,
      payload: { historyName: history },
    });
  };

  // Close Modal History for Surname and forename of Pupil
  const closeHistoryModal = () => dispatch({ type: closeHistoryModalConstant });

  const calculateAgeFromDob = (dob) => {
    if (dob !== null) {
      const dobList = dob.split("-");
      const year = dobList[0];
      const month = dobList[1];
      const day = dobList[2];
      // const year = Number(dob.substr(0, 4));
      // const month = Number(dob.substr(4, 2)) - 1;
      // const day = Number(dob.substr(6, 2));
      const today = new Date();
      let age = today.getFullYear() - year;
      if (
        today.getMonth() < month ||
        (today.getMonth() === month && today.getDate() < day)
      ) {
        age--;
      }
      return age;
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }; 

  const closeModal = ()=>{
    setPopschool(false);
    setSchoolInfo(false)
    setDatePop(false)
    setHistoryPop(false)
  }

  const HandleDate = (date)=>{
    //console.log('handle date',date)
    const upDate = moment(date).format('DD/MM/YYYY');
  //console.log(upDate)
  setDates(upDate)
  }

  

  const HandleSave = () =>{

    const newUrl = `${settings.API_HOST}/rest/updatedPupilsSubSchool`

    //const newdates = JSON.parse(Dates)

    var bodyCommentData = new FormData();

    bodyCommentData.append("subSchoolData.pupilId", PupilId);
    bodyCommentData.append("subSchoolData.subSchoolId", SubSchoolId);
    bodyCommentData.append("subSchoolData.date", Dates);
    bodyCommentData.append("subSchoolData.userId", UserId);


    axios({
      method: "post",
      url: newUrl,
      data: bodyCommentData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
if (res?.data?.successmessage !== 'null'){
  //const successmessage =  res?.data?.successmessage;
  toast.success('School changed successfully!')
  setDatePop(false)
  if (reloadValue === true) {
    setreloadValue(false)
  }
  else{
    setreloadValue(true)
  }
}
    })
  }

  const HandleHistory = (e,type) =>{
    setHistoryPop(true)
console.log(e,type,schoolId)

axios.get(`${settings.API_HOST}/rest/fetchPupilHistory?pupilId=${PupilId}&schoolId=${schoolId}&history=${type}`)
.then((res)=>{
  console.log(res)
  const ArrayData = res?.data?.pupilContactsHistory?.schoolHistory
  console.log(ArrayData)
  // const newArray =ArrayData.map(items=>{
  //   return items
  // })
  setHistoryData(ArrayData)
  console.log(HistoryData)
})
  }

  const HandleSchool = async (e) => {
    setPopschool(true)
   await axios
    .get(
      `${settings.API_HOST}/rest/fetchAllSchoolsData?userId=${UserId}&schoolId=${schoolId}&roleId=${UserRole}`
    )
    .then((res) => {
      console.log(res,"School Info")
      const  SchoolDataarray= res?.data?.schoolsList
     
      const newdata = SchoolDataarray.map(item=>{
        console.log(item)
        return item
    })
      setArrayData(SchoolDataarray)
    })

console.log(Popschool)
  }

  const HandleSchoolDetails = (e) =>{
console.log(e)
    // axios
    // .get(
    //   `${settings.API_HOST}/rest/fetchAllSchoolsData?userId=${UserId}&schoolId=${schoolId}&roleId=${UserRole}`
    // )
    // .then((res) => {
    //   console.log(res,"School Info")
    //   // const  SchoolDataarray= res?.data?.schoolsList
     
    //   // const newdata = SchoolDataarray.map(item=>{
    //   //   return item
    //   // })
    //   // setArrayData(newdata)
    // })

    setPopschool(false)
    setSchoolInfo(true)

    console.log(e)
  }

  console.log(searchArray)

const  HandleSearch = (e)=>{
//console.log(e)
axios.get(`${settings.API_HOST}/rest/fetchFilteredSchoolsList?schoolId=${schoolId}&userId=${UserId}&roleId=${UserRole}&keyword=${e}`)
.then((response) => {
  console.log(response)
  const responsedata = response?.data?.schoolsList
  setsearchArray(responsedata)
})

  
      //setsearchArray(b)
}

const HandleChange = (e) =>{
  setSubSchoolId(e)
  setDatePop(true)
  setPopschool(false)
}

  return (
    <>
<Modal
isOpen={HistoryPop}
onRequestClose={closeModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        id="schoolhistory"
>
<button className="btn-close" onClick={closeModal}> x  </button>
<h3>School Update History</h3>
<hr />
<table className="table-auto w-100 child-table tablesection">
          <thead>
            <tr>
              <th className="cursor-pointer header-table1">
                <div className="flex flex-row items-center justify-between">
                  <span className="">ID</span>
                  <span className="mr-4"></span>
                </div>
              </th>
              <th className="cursor-pointer header-table2">
                <div className="flex flex-row items-center justify-between">
                  <span className=""> Name </span>
                  <span className="mr-4"></span>
                </div>
              </th>

              <th className="cursor-pointer header-table3">
                <div className="flex flex-row items-center justify-between">
                  <span className=""> Date </span>
                  <span className="mr-4"></span>
                </div>
              </th>

              

              
            </tr>
          </thead>
          <tbody className="mt-4">
            
            
            
            
            {HistoryData?.map(items=>(
                <tr onClick={(e)=>HandleChange()}>
                    <td>{items?.id}</td>
                    <td>{items?.name}</td>
                    <td>{items?.startDate}</td>
                    {/* <td>{items?.city}</td>
                    <td>{items?.code}</td>
                    <td>{items?.phase}</td>
                    <td>{items?.status}</td>  */}
                </tr>
             ))}
            
            
          </tbody>
        </table>
</Modal>

    <Modal
    isOpen={DatePop}
    onRequestClose={closeModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        id="datepopup"
    >
<button className="btn-close" onClick={closeModal}> x  </button>
<label>Please enter the Start Day of the School </label>
<DatePicker format='DD/MM/YYYY' onChange={(e)=>HandleDate(e)} />&nbsp; &nbsp;&nbsp;
<Button type="primary" shape="round" size="large" onClick={(e)=>HandleSave()}>Submit</Button>
    </Modal>

    <Modal
    isOpen={Popschool}
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      id ='schl-slt'
    >

<button className="btn-close" onClick={closeModal}> x  </button>
      <h3 style={{margin:'0px',padding:'0px'}}>Change School</h3>
        <hr />
        <div className="search-label sm:mt-2 md:mt-0 tablesearch ks5search">
              <span className="mr-3 ">Search : </span>
              <input className="w-16 px-6 py-2 rounded-full outile-none focus:outline-none table_count_action w-48 "
              onChange={(e)=>HandleSearch(e?.target?.value)}
              style={{width:'65%'}}
              />
            </div>

      <thead className="d-flex mb-7 headtable">
         
        </thead>
        <table className="table-auto w-100 child-table tablesection">
          <thead>
            <tr>
              <th className="cursor-pointer header-table1">
                <div className="flex flex-row items-center justify-between">
                  <span className="">ID</span>
                  <span className="mr-4"></span>
                </div>
              </th>
              <th className="cursor-pointer header-table2">
                <div className="flex flex-row items-center justify-between">
                  <span className=""> School Name </span>
                  <span className="mr-4"></span>
                </div>
              </th>

              <th className="cursor-pointer header-table3">
                <div className="flex flex-row items-center justify-between">
                  <span className=""> URN </span>
                  <span className="mr-4"></span>
                </div>
              </th>

              <th className="cursor-pointer header-table4">
                <div className="flex flex-row items-center justify-between">
                  <span className=""> City </span>
                  <span className="mr-4"></span>
                </div>
              </th>

              <th className="cursor-pointer header-table4">
                <div className="flex flex-row items-center justify-between">
                  <span className=""> Postcode </span>
                  <span className="mr-4"></span>
                </div>
              </th>

              <th className="cursor-pointer header-table4">
                <div className="flex flex-row items-center justify-between">
                  <span className=""> Phase </span>
                  <span className="mr-4"></span>
                </div>
              </th>

              <th className="cursor-pointer header-table4">
                <div className="flex flex-row items-center justify-between">
                  <span className=""> Status </span>
                  <span className="mr-4"></span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="mt-4">
           
            <>
            {searchArray?.length !== 0 ? 
            
             searchArray?.map(items=>(
              <tr onClick={(e)=>HandleChange(items?.id)}>
                
                  <td>{items?.id}</td>
                  <td>{items?.schoolname}</td>
                  <td>{items?.urn}</td>
                  <td>{items?.city}</td>
                  <td>{items?.zip}</td>
                  <td>{items?.phase}</td>
                  <td>{items?.status}</td> 
              </tr>
           ))
            
            :
            ArrayData?.map(items=>(
                <tr onClick={(e)=>HandleChange()}>
                  
                    <td>{items?.id}</td>
                    <td>{items?.schoolname}</td>
                    <td>{items?.urn}</td>
                    <td>{items?.city}</td>
                    <td>{items?.code}</td>
                    <td>{items?.phase}</td>
                    <td>{items?.status}</td> 
                </tr>
             ))}


            
            <div className="select-label" style={{display:'flex',width:'100%'}}>
                <span style={{display:'flex',position:'absolute',right:'4%'}}>
                  Show
                  <b>&nbsp;{searchArray?.length}</b> of
                  <b>&nbsp;{searchArray?.length}</b> entries
                </span>
              </div>
             
           
            
            </>
          </tbody>
        </table>

    </Modal>

    
    <div className="flex-1 bg-container-custom rounded-custom-content padding-custom-content">
      <ToastContainer />

      <ComponentHeader />
     
      

      <form>
        <div className="grid lg:grid-cols-3 gap-4 mt-5 grid-cols-1 pupilPage">
          {/* Pupil Information */}
          {isLoading ? (
            <Skeleton count={12} height={50} />
          ) : (
            <div className="bg-white tableview pupilTable">
              <div className="form-heading rounded-custom-content">
                <h1>Pupil Information</h1>
              </div>

              <TextFormField
                fieldName="forename"
                fieldLabel="Forename"
                fieldValue={forename}
                fieldIcon={
                  <FiFolder
                    onClick={(e) => openHistoryModal(e, "forename")}
                    className="form-field-icon"
                  />
                }
                required={true}
              />

              <TextFormField
                fieldName="surname"
                fieldLabel="Surname"
                fieldValue={surname}
                fieldIcon={
                  <FiFolder
                    onClick={(e) => openHistoryModal(e, "surname")}
                    className="form-field-icon"
                  />
                }
                required={true}
              />

              <TextFormField
                fieldName="upn"
                fieldLabel="UPN"
                fieldValue={upn}
                required={true}
              />

              <TextFormField
                fieldName="dob"
                fieldLabel="DOB"
                fieldValue={stringDob}
              />

              <TextFormField
                fieldName="age"
                fieldLabel="Age"
                fieldValue={calculateAgeFromDob(dob)}
              />

              <TextFormField
                fieldName="yeargroup"
                fieldLabel="Year Group"
                required={true}
                fieldValue={yeargroup.split(" ")[0]}
                // optionList={[
                //   { optionValue: "N 2020", optionLabel: "N" },
                //   { optionValue: "N1 2020", optionLabel: "N1" },
                //   { optionValue: "N2 2020", optionLabel: "N2" },
                //   { optionValue: "N3 2020", optionLabel: "N3" },
                //   { optionValue: "N4 2020", optionLabel: "N4" },
                //   { optionValue: "R 2020", optionLabel: "R" },
                //   { optionValue: "Y1 2020", optionLabel: "Y1" },
                //   { optionValue: "Y2 2020", optionLabel: "Y2" },
                //   { optionValue: "Y3 2020", optionLabel: "Y3" },
                //   { optionValue: "Y4 2020", optionLabel: "Y4" },
                //   { optionValue: "Y5 2020", optionLabel: "Y5" },
                //   { optionValue: "Y6 2020", optionLabel: "Y6" },
                //   { optionValue: "Y7 2020", optionLabel: "Y7" },
                //   { optionValue: "Y8 2020", optionLabel: "Y8" },
                //   { optionValue: "Y9 2020", optionLabel: "Y9" },
                //   { optionValue: "Y10 2020", optionLabel: "Y10" },
                //   { optionValue: "Y11 2020", optionLabel: "Y11" },
                //   { optionValue: "Y12 2020", optionLabel: "Y12" },
                //   { optionValue: "Y13 2020", optionLabel: "Y13" },
                //   { optionValue: "Y14 2020", optionLabel: "Y14" },
                // ]}
              />

              <TextFormField
                fieldName="phase"
                fieldLabel="Phase"
                fieldValue={phase}
                // optionList={[
                //   { optionValue: "EYFS", optionLabel: "EYFS" },
                //   { optionValue: "Primary", optionLabel: "Primary" },
                //   { optionValue: "Post-16", optionLabel: "Post-16" },
                //   { optionValue: "Secondary", optionLabel: "Secondary" },
                //   { optionValue: "Year 14+", optionLabel: "Year 14+" },
                // ]}
              />

              <TextFormField
                fieldName="gender"
                fieldLabel="Gender"
                fieldValue={gender === "F" ? "Female" : "Male"}
                required={true}
                // optionList={[
                //   { optionValue: "M", optionLabel: "Male" },
                //   { optionValue: "F", optionLabel: "Female" },
                //   { optionValue: "NA", optionLabel: "Not Specified" },
                //   { optionValue: "Indeterminate", optionLabel: "Indeterminate" },
                // ]}
              />

              <TextFormField
                fieldName="senCop"
                fieldLabel="SEND"
                fieldValue={senCop}
                required={true}
                // optionList={[
                //   { optionValue: "Unknown", optionLabel: "Unknown" },
                //   { optionValue: "None", optionLabel: "None" },
                //   { optionValue: "SEN Support", optionLabel: "SEN Support" },
                //   { optionValue: "EHCP", optionLabel: "EHCP" },
                // ]}
              />

              <TextFormField
                fieldName="eal"
                fieldLabel="EAL"
                fieldValue={eal}
                // optionList={[
                //   { optionValue: "Yes", optionLabel: "Yes" },
                //   { optionValue: "No", optionLabel: "No" },
                // ]}
              />

              <TextFormField
                fieldName="firstLanguage"
                fieldLabel="First Language"
                fieldValue={firstLanguage}
              />

              <TextFormField
                fieldName="ethnicity"
                fieldLabel="Ethnicity"
                fieldValue={ethnicity}
              />

              <TextFormField
                fieldName="uasc"
                fieldLabel="UASC"
                fieldValue={uasc}
                checkedFieldValue="Yes"
                unCheckedFieldValue="No"
                fieldRadioSpan="No"
                required={true}
              />

              <TextFormField
                fieldName="dateIntoCare"
                fieldLabel="Date Into Care"
                fieldValue={dateIntoCare}
                required={true}
              />

              <TextFormField
                fieldName="personEmail"
                fieldLabel="Young Person Email"
                fieldValue={youngPersonEmail}
              />
            </div>
          )}
          {/* School Column */}
          {isLoading ? (
            <Skeleton count={12} height={50} />
          ) : (
            <div className="bg-white tableview pupilTable">
              <div className="form-heading rounded-custom-content">
                <h1>School</h1>
              </div>
              {console.log(UserRole)}
              
              {UserRole === '12' ? 
              <div className="schoolrow">
              <label>School Name <FolderOutlined  style={{ fontSize: "20px", color:'#237bff',cursor:'pointer'}}
              onClick={(e)=>HandleHistory(e, "School")} />
              </label>
              
              <Input  className="schoolinput" value={schoolName}
              onClick={(e)=>HandleSchool()}
              readOnly />
                
                  
                
              
              </div>
               : 
              <TextFormField
                fieldName="schoolName"
                fieldLabel="School Name"
                fieldValue={schoolName}
                required={true}
              />
          }

              <TextFormField
                fieldName="schoolTel"
                fieldLabel="School Tel"
                fieldValue={schoolTel}
              />

              <TextFormField
                fieldName="schoolEmail"
                fieldLabel="School Email"
                fieldValue={schoolEmail}
              />

              <TextFormField
                fieldName="schoolAddress1"
                fieldLabel="School Address1"
                fieldValue={schoolAddress1}
              />

              <TextFormField
                fieldName="schoolAddress2"
                fieldLabel="School Address2"
                fieldValue={schoolAddress2}
              />

              <TextFormField
                fieldName="schoolAddress3"
                fieldLabel="School Address3"
                fieldValue={schoolAddress3}
              />

              <TextFormField
                fieldName="schoolPostalcode"
                fieldLabel="School Postcode"
                fieldValue={schoolPostcode}
              />

              <TextFormField
                fieldName="inLaOrOut"
                fieldLabel="In LA or Out"
                // fieldLabel="inLaOrOut"
                fieldRadioSpan="Out"
                fieldValue={laInOrOut}
                checkedFieldValue="In"
                unCheckedFieldValue="Out"
              />

              <TextFormField
                fieldName="schoolWebsite"
                fieldLabel="School Website"
                fieldValue={schoolWebsite}
              />

              <TextFormField
                fieldName="ofstedGrade"
                fieldLabel="Ofsted Grade"
                fieldValue={ofstedGrade}
              />

              <TextFormField
                fieldName="schoolLa"
                fieldLabel="School LA"
                fieldValue={schoolLa}
              />

              <TextFormField
                fieldName="urn"
                fieldLabel="URN"
                fieldValue={urn}
              />

              <TextFormField
                fieldName="dfe"
                fieldLabel="DFE"
                fieldValue={dfe}
              />

              <TextFormField
                fieldName="dualRegisteredSchool"
                fieldLabel="Dual Registered School"
                fieldValue={dualRegisterSchool}
              />

              {/* <RadioFormField
                  fieldName="leavers"
                  fieldLabel="Leavers"
                  fieldRadioSpan="No"
                  fieldValue={this.state.leavers.toString()}
                  onChangeHandler={this.onChangeRadioHandler}
                  checkedFieldValue="1"
                  unCheckedFieldValue="0"
                /> */}
            </div>
          )}

          {/* Additional Pupil Information Column */}
          {isLoading ? (
            <Skeleton count={12} height={50} />
          ) : (
            <div className="bg-white tableview pupilTable">
              <div className="form-heading rounded-custom-content">
                <h1>Additional Pupil Information</h1>
              </div>

              <TextFormField
                fieldName="vsPupilNo"
                fieldLabel="VS No"
                required={true}
                fieldValue={vsPupilNo}
              />

              <TextFormField
                fieldName="legalStatus"
                fieldLabel="Legal Satus"
                fieldValue={legalStatus}
              />

              <TextFormField
                fieldName="numberOfPlacement"
                fieldLabel="Number of Placement"
                fieldValue={numberOfPlacements}
              />

              <TextFormField
                fieldName="typeOfPlacement"
                fieldLabel="Types of Placement"
                fieldValue={typeOfPlacement}
                // optionList={[
                //   {
                //     title: "A3: Placed for adoption with consent with current foster carer (under section 19 of the Adoption Act 2002)",
                //     optionValue: "A3: Placed for adoption with consent with current foster carer (under section 19 of the Adoption Act 2002)",
                //     optionLabel: "A3: Placed for adoption with consent with...",
                //   },
                //   {
                //     title: "A4: Placed for adoption with consent not with current foster carer (under section 19 of the Adoption Act 2002)",
                //     optionValue: "A4: Placed for adoption with consent not with current foster carer (under section 19 of the Adoption Act 2002)",
                //     optionLabel: "A4: Placed for adoption with consent with...",
                //   },
                //   {
                //     title: "A5: Placed for adoption with placement order (under section 21 of the 2002 Act) with current foster carer",
                //     optionValue: "A5: Placed for adoption with placement order (under section 21 of the 2002 Act) with current foster carer",
                //     optionLabel: "A5: Placed for adoption with consent with...",
                //   },
                //   {
                //     title: "A6: Placed for adoption with placement order (under section 21 of the 2002 Act) not with current foster carer",
                //     optionValue: "A6: Placed for adoption with placement order (under section 21 of the 2002 Act) not with current foster carer",
                //     optionLabel: "A6: Placed for adoption with consent with...",
                //   },
                //   {
                //     title: "H5: Residential accommodation not subject to Children's homes regulations (exceptional and unregulated placements only)",
                //     optionValue: "H5: Residential accommodation not subject to Children's homes regulations (exceptional and unregulated placements only)",
                //     optionLabel: "H5:Residential accommodation not subject...",
                //   },
                //   {
                //     title: "K1: Secure unit",
                //     optionValue: "K1: Secure unit",
                //     optionLabel: "K1: Secure unit",
                //   },
                //   {
                //     title: "K2AGENCY: Children's Home (Agency Placement)",
                //     optionValue: "K2AGENCY: Children's Home (Agency Placement)",
                //     optionLabel: "K2AGENCY: Children's Home (Agency Placement)",
                //   },
                //   {
                //     title: "K2DCC: Children's Home (DCC Placement)",
                //     optionValue: "K2DCC: Children's Home (DCC Placement)",
                //     optionLabel: "K2DCC: Children's Home (DCC Placement)",
                //   },
                //   {
                //     title: "P1: Placed with own parents or other person with parental responsibility",
                //     optionValue: "P1: Placed with own parents or other person with parental responsibility",
                //     optionLabel: "P1: Placed with own parents or other...",
                //   },
                //   {
                //     title: "P2a: DCC Supported Lodgings (Age 16+)",
                //     optionValue: "P2a: DCC Supported Lodgings (Age 16+)",
                //     optionLabel: "P2a: DCC Supported Lodgings(Age 16+)",
                //   },
                //   {
                //     title: "P2b: Bed and Breakfast (Age 16+)",
                //     optionValue: "P2b: Bed and Breakfast (Age 16+)",
                //     optionLabel: "P2b: Bed and Breakfast(Age 16+)",
                //   },
                //   {
                //     title: "P2c: Semi-Independent Living (Block Contract) (Age 16+)",
                //     optionValue: "P2c: Semi-Independent Living (Block Contract) (Age 16+)",
                //     optionLabel: "P2c: Semi-Independent Living (Block Contract) (Age 16+)",
                //   },
                //   {
                //     title: "P2d: Semi-Independent Living (Spot Purchased) (Age 16+)",
                //     optionValue: "P2d: Semi-Independent Living (Spot Purchased) (Age 16+)",
                //     optionLabel: "P2d: Semi-Independent Living (Spot Purchased) (Age 16+)",
                //   },
                //   {
                //     title: "P2e: Other Independent Living Arrangement (Age 16+)",
                //     optionValue: "P2e: Other Independent Living Arrangement (Age 16+)",
                //     optionLabel: "P2e: Other Independent Living Arrangement (Age 16+)",
                //   },
                //   {
                //     title: "P3: Residential employment",
                //     optionValue: "P3: Residential employment",
                //     optionLabel: "P3: Residential employment",
                //   },
                //   {
                //     title: "R1: Residential care home (where personal care is provided)",
                //     optionValue: "R1: Residential care home (where personal care is provided)",
                //     optionLabel: "R1: Residential care home...",
                //   },
                //   {
                //     title: "R2: NHS/Health Trust or other establishment providing medical or nursing care",
                //     optionValue: "R2: NHS/Health Trust or other establishment providing medical or nursing care",
                //     optionLabel: "R2: NHS/ Health Trust or other...",
                //   },
                //   {
                //     title: "R3: Family centre or mother and baby unit",
                //     optionValue: "R3: Family centre or mother and baby unit",
                //     optionLabel: "R3: Family centre or mother and baby unit",
                //   },
                //   {
                //     title: "R5: Young Offender Institution or prison",
                //     optionValue: "R5: Young Offender Institution or prison",
                //     optionLabel: "R5: Young Offender Institution or prison",
                //   },
                //   {
                //     title: "S1: Residential schools not registered as a Children's Home",
                //     optionValue: "S1: Residential schools not registered as a Children's Home",
                //     optionLabel: "S1: Residential schools not...",
                //   },
                //   {
                //     title: "T0: Temporary placement (temp break from main placement)",
                //     optionValue: "T0: Temporary placement (temp break from main placement)",
                //     optionLabel: "T0: Temporary placement...",
                //   },
                //   {
                //     title: "U1AGENCY: Foster placement with relative or friend - Long Term (Agency)",
                //     optionValue: "U1AGENCY: Foster placement with relative or friend - Long Term (Agency)",
                //     optionLabel: "U1AGENCY: Foster placement with...",
                //   },
                //   {
                //     title: "U1DCC: Foster placement with relative or friend - Long Term (DCC)",
                //     optionValue: "U1DCC: Foster placement with relative or friend - Long Term (DCC)",
                //     optionLabel: "U1DCC: Foster placement with...",
                //   },
                //   {
                //     title: "U2AGENCY: Foster placement with relative or friend who is an approved adopter - FFA or Concurrent Planning (Agency)",
                //     optionValue: "U2AGENCY: Foster placement with relative or friend who is an approved adopter - FFA or Concurrent Planning (Agency)",
                //     optionLabel: "U2AGENCY: Foster placement with...",
                //   },
                //   {
                //     title: "U2DCC: Foster placement with relative or friend who is an approved adopter - FFA or Concurrent Planning (DCC)",
                //     optionValue: "U2DCC: Foster placement with relative or friend who is an approved adopter - FFA or Concurrent Planning (DCC)",
                //     optionLabel: "U2DCC: Foster placement with...",
                //   },
                //   {
                //     title: "U3AGENCY: Foster placement with relative or friend - NOT Long Term NOT FFA or Concurrent Planning (Agency)",
                //     optionValue: "U3AGENCY: Foster placement with relative or friend - NOT Long Term NOT FFA or Concurrent Planning (Agency)",
                //     optionLabel: "U3AGENCY: Foster placement with...",
                //   },
                //   {
                //     title: "U3DCC: Foster placement with relative or friend - NOT Long Term NOT FFA or Concurrent Planning (DCC)",
                //     optionValue: "U3DCC: Foster placement with relative or friend - NOT Long Term NOT FFA or Concurrent Planning (DCC)",
                //     optionLabel: "U3DCC: Foster placement with...",
                //   },
                //   {
                //     title: "U4AGENCY: Foster placement with other Foster Carer - Long Term (Agency)",
                //     optionValue: "U4AGENCY: Foster placement with other Foster Carer - Long Term (Agency)",
                //     optionLabel: "U4AGENCY: Foster placement with...",
                //   },
                //   {
                //     title: "U4DCC: Foster placement with other Foster Carer - Long Term (DCC)",
                //     optionValue: "U4DCC: Foster placement with other Foster Carer - Long Term (DCC)",
                //     optionLabel: "U4DCC: Foster placement...",
                //   },
                //   {
                //     title: "U5AGENCY: Foster placement with other Foster Carer who is an approved adopter - FFA or Concurrent Planning (Agency)",
                //     optionValue: "U5AGENCY: Foster placement with other Foster Carer who is an approved adopter - FFA or Concurrent Planning (Agency)",
                //     optionLabel: "U5AGENCY: Foster placement with other..",
                //   },
                //   {
                //     title: "U5DCC: Foster placement with other Foster Carer who is an approved adopter - FFA or Concurrent Planning (DCC)",
                //     optionValue: "U5DCC: Foster placement with other Foster Carer who is an approved adopter - FFA or Concurrent Planning (DCC)",
                //     optionLabel: "U5DCC: Foster placement with...",
                //   },
                //   {
                //     title: "U6AGENCY: Foster placement with other Foster Carer - NOT Long Term NOT FFA or Concurrent Planning (Agency)",
                //     optionValue: "U6AGENCY: Foster placement with other Foster Carer - NOT Long Term NOT FFA or Concurrent Planning (Agency)",
                //     optionLabel: "U6AGENCY: Foster placement with...",
                //   },
                //   {
                //     title: "U6DCC: Foster placement with other Foster Carer - NOT Long Term NOT FFA or Concurrent Planning (DCC)",
                //     optionValue: "U6DCC: Foster placement with other Foster Carer - NOT Long Term NOT FFA or Concurrent Planning (DCC)",
                //     optionLabel: "U6DCC: Foster placement with other...",
                //   },
                //   {
                //     title: "Z1a: Placement directed by court with carer who is not an approved Foster Carer (Children Act 1989 - Section 38(6))",
                //     optionValue: "Z1a: Placement directed by court with carer who is not an approved Foster Carer (Children Act 1989 - Section 38(6))",
                //     optionLabel: "Z1a: Placement directed by court...",
                //   },
                //   {
                //     title: "Z1b: Any other placement",
                //     optionValue: "Z1b: Any other placement",
                //     optionLabel: "Z1b: Any other placement",
                //   },
                //   {
                //     title: "52 week placement",
                //     optionValue: "52 week placement",
                //     optionLabel: "52 week placement",
                //   },
                //   {
                //     title: "Court ordered secure remand in LA accommodation",
                //     optionValue: "Court ordered secure remand in LA accommodation",
                //     optionLabel: "Court ordered secure remand in LA...",
                //   },
                //   {
                //     title: "Foster Care",
                //     optionValue: "Foster Care",
                //     optionLabel: "Foster Care",
                //   },
                //   {
                //     title: "Kinship",
                //     optionValue: "Kinship",
                //     optionLabel: "Kinship",
                //   },
                //   {
                //     title: "Placement with other foster carer - not long term or FFA",
                //     optionValue: "Placement with other foster carer - not long term or FFA",
                //     optionLabel: "Placement with other foster carer...",
                //   },
                //   {
                //     title: "Q1 - Foster placement with relative or friend",
                //     optionValue: "Q1 - Foster placement with relative or friend",
                //     optionLabel: "Q1 - Foster placement with relative...",
                //   },
                //   {
                //     title: "Q2 - Placement with other foster carer",
                //     optionValue: "Q2 - Placement with other foster carer",
                //     optionLabel: "Q2 - Placement with other foster carer",
                //   },
                //   {
                //     title: "REGULATION 24 - TEMPORARY APPROVAL OF FAMILY & FRIENDS AS FOSTER CARERS",
                //     optionValue: "REGULATION 24 - TEMPORARY APPROVAL OF FAMILY & FRIENDS AS FOSTER CARERS",
                //     optionLabel: "REGULATION 24 - TEMPORARY APPROVAL OF FAMILY ...",
                //   },
                //   {
                //     title: "Residential",
                //     optionValue: "Residential",
                //     optionLabel: "Residential",
                //   },
                //   {
                //     title: "RESIDENTIAL CARE HOME REGISTERED UNDER 1984 ACT",
                //     optionValue: "RESIDENTIAL CARE HOME REGISTERED UNDER 1984 ACT",
                //     optionLabel: "RESIDENTIAL CARE HOME REGISTERED...",
                //   },
                //   {
                //     title: "RESIDENTIAL SCHOOL",
                //     optionValue: "RESIDENTIAL SCHOOL",
                //     optionLabel: "RESIDENTIAL SCHOOL",
                //   },
                //   {
                //     title: "Secure - YOUNG OFFENDER INST./PRISON",
                //     optionValue: "Secure - YOUNG OFFENDER INST./PRISON",
                //     optionLabel: "Secure - YOUNG OFFENDER INST./PRISON",
                //   },
                //   {
                //     title: "Semi Independent",
                //     optionValue: "Semi Independent",
                //     optionLabel: "Semi Independent",
                //   },
                //   {
                //     title: "VOL HOSTEL/SEMI INDEPENDENCE WITH FORMAL SUPPORT",
                //     optionValue: "VOL HOSTEL/SEMI INDEPENDENCE WITH FORMAL SUPPORT",
                //     optionLabel: "VOL HOSTEL/SEMI INDEPENDENCE WITH FORMAL SUPPORT",
                //   },

                // ]}
              />

              <TextFormField
                fieldName="placementLocalAuthority"
                fieldLabel="Placement Local Authority"
                fieldValue={placementLocalAuthority}
              />

              <TextFormField
                fieldName="placementDate"
                fieldLabel="Placement Date"
                fieldValue={placementDate}
              />

              <TextFormField
                fieldName="contactArrangements"
                fieldLabel="Contact Arrangements"
                fieldValue={contactArrangements}
              />

              <TextFormField
                fieldName="placementNotification"
                fieldLabel="Placement Notification"
                fieldValue={placementNotification}
              />

              <TextFormField
                fieldName="dateBecameCareLeaver"
                fieldLabel="Date became Care Leaver"
                fieldValue={dateBecameCareLeaver}
              />

              <TextFormField
                fieldName="numberOfSchools"
                fieldLabel="Number of Schools"
                fieldValue={numberOfSchool}
              />

              <TextFormField
                fieldName="countryOfOrigin"
                fieldLabel="Country of Origin"
                fieldValue={countryOfOrigin}
              />

              <TextFormField
                fieldName="religion"
                fieldLabel="Religion"
                fieldValue={religion}
              />

              <TextFormField
                fieldName="dateLeftCare"
                fieldLabel="Date Left Care"
                fieldValue={dateLeftCare}
              />

              <TextFormField
                fieldName="tution"
                fieldLabel="Tuition"
                fieldValue={tution}
              />
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 mt-4">
          {/* Connection Information Column */}
          <div className="tableview bg-white">
            <div className="form-heading rounded-custom-content">
              {isLoading ? (
                <Skeleton count={1} height={10} />
              ) : (
                <h1>Connections</h1>
              )}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4 connectionSpace">
              {isLoading ? (
                <Skeleton count={2} height={50} />
              ) : (
                <div className="subConnection">
                  <TextFormField
                    fieldName="socialWorkerName"
                    fieldLabel="Social Worker Name"
                    required={true}
                    fieldValue={socialWorker}
                  />
                  <TextFormField
                    fieldName="socialWorkerTeam"
                    fieldLabel="Social Worker Team"
                    required={true}
                    fieldValue={socialWorkerTeam}
                  />
                </div>
              )}
              {isLoading ? (
                <Skeleton count={2} height={50} />
              ) : (
                <div className="subConnection">
                  <TextFormField
                    fieldName="designatedTeacher"
                    fieldLabel="Designated Teacher"
                    fieldValue={designatedTeacher}
                  />
                  <TextFormField
                    fieldName="pupilCarerName"
                    fieldLabel="Pupil Carer Name"
                    fieldValue={pupilsCarerName}
                    required={true}
                  />
                </div>
              )}
              {isLoading ? (
                <Skeleton count={2} height={50} />
              ) : (
                <div className="subConnection">
                  <TextFormField
                    fieldName="virtualSchoolOfficer"
                    fieldLabel="Virtual School Officer"
                    fieldValue={virtualSchoolOfficer}
                    required={true}
                  />
                  <TextFormField
                    fieldName="iro"
                    fieldLabel="IRO"
                    fieldValue={iro}
                    required={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      {isHistoryModalOpen && (
        <HistoryModal
          isHistoryModalOpen={isHistoryModalOpen}
          closeHistoryModal={closeHistoryModal}
          historyModalName={historyModalName}
        />
      )}
    </div>
    </>
  );
};

export default Pupil;
