import { closeHistoryModal, failure, openHistoryModal, success } from "../constants/contactReducerAction"
import { loading, notLoading } from "../constants/reducerActions"

const contactReducer = (state, action) => {
  switch (action.type) {
    case success:
      return {
        ...state,
        contacts: action.payload,
        isLoading: false,
      }
    case failure:
      return {
        ...state,
        contacts: {},
        isLoading: false,
      }
    case openHistoryModal:
      return {
        ...state,
        isHistoryModalOpen: true,
        historyModalName: action.payload.history,
        historyHeading: action.payload.historyHeading,
      }
    case closeHistoryModal:
      return {
        ...state,
        isHistoryModalOpen: false,
      }
    case loading:
      return {
        ...state,
        isLoading: true,
      }
    case notLoading:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state;
  }
}

export default contactReducer;