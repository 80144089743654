import React from 'react'
import Skeleton from 'react-loading-skeleton'

const PupilVoicePost16Skeleton = () => {
  return (
    <>
      <div className='w-1/2 pb-3'>
        <Skeleton  height={20} />
      </div>
      <div className="rounded-3xl bg-white p-4 overflow-hidden flex flex-col gap-3">
        <div className="flex flex-col gap-1">
          <div className="w-full">
            <Skeleton  height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton  height={20} />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="w-full">
            <Skeleton  height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton  height={20} />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="w-full">
            <Skeleton  height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton  height={20} />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="w-full">
            <Skeleton  height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton  height={20} />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="w-full">
            <Skeleton  height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton  height={20} />
          </div>
        </div>
      </div>
    </>
    
    
  )
}

export default PupilVoicePost16Skeleton
