import { Field } from 'formik';
import React from 'react'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useDebouncedCallback } from 'use-debounce/lib';


const CINCPTextArea = ({
  placeholder,
  fieldName,
  fieldHandler,
  saveField,
  rest,
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const debouncedSaved = useDebouncedCallback(saveField, 1000);

  return (
    <div className='relative'>
      <Field name={fieldName}>
        {
          ({field, form}) => {
            return (
              <textarea 
                className="rounded-2xl px-2 py-4 textarea w-full" 
                placeholder={placeholder} 
                value={field.value}
                onChange={(e) => fieldHandler({
                  fieldName,
                  setIsLoading,
                  value: e.target.value,
                  saveField: debouncedSaved,
                  setFieldValue: form.setFieldValue,
                })}
                {...rest}

              >
              </textarea>
            )
          }
        }
      </Field>
      {
        isLoading &&
          <div className='absolute w-full h-full top-0 left-0 grid items-center bg-white px-2'>
            <Skeleton />
          </div>
      }
    </div>
  )
}

export default CINCPTextArea
