import React from 'react';

const PepStatus = () => {
  return (
    <div className="flex-1 bg-container-custom rounded-custom-content">
      <div className="grid lg:grid-cols-1 grid-cols-none mt-5">
        <div className="bg-white tableview overflow-x-auto">
          <table className="text-left table-auto contacts-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Surname</th>
                <th>School Name</th>
                <th>Year Group</th>
                <th>Date of PEP</th>
                <th>Term of PEP</th>
                <th>PEP Status</th>
                <th>PEP Sign Off</th>
                <th>PEP Grade</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};

export default PepStatus;
