import React from 'react'

const AltNeedTableRToN4 = ({
  pupilInfo
}) => {
  return (
    <div className="rounded-3xl bg-white mt-4 p-4 w-full">
      <div className="py-2 flex w-full items-center">
        <label>This student is receiving 25 hours per week in an Ofsted registered establishment?</label>
        <div className="pl-4 radio">
          <label className="inline-flex items-center">
            <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center ml-6">
            <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>

      <div className="py-2 flex w-full items-center">
        <label>Is the student receiving more than 15 hours per week education?</label>
        <div className="pl-4 radio">
          <label className="inline-flex items-center">
            <input type="radio" className="form-radio" name="receiving15HoursWeek" />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center ml-6">
            <input type="radio" className="form-radio" name="receiving15HoursWeek" />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>

      <div className="w-full pb-2">
        <span className="inline-block">If yes, how many hours in total?<br />what is the funding stream for this?</span>
        <div className="textarea-block mt-1">
          <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Type here..." ></textarea>
        </div>
      </div>

      <div className="py-2 flex w-full items-center">
        <label>Number of Government Funded hours</label>
        <input type="number" className="pep-input" />
      </div>

      <div className="py-2 flex w-full items-center">
        <label>Number of Additional Funded hours</label>
        <input type="number" className="pep-input" />
      </div>

      <div className="py-2 flex w-full items-center">
        <label>Are the Government funded hours stretched?</label>
        <div className="pl-4 radio">
          <label className="inline-flex items-center">
            <input type="radio" className="form-radio" name="govtFundedHoursStretched" />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center ml-6">
            <input type="radio" className="form-radio" name="govtFundedHoursStretched" />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>

      <div className="py-2 mt-4 flex w-full items-center">
        <div className="w-5/12 flex">
          <label>Is the pupil NEET?</label>
          <div className="pl-4 radio">
            <label className="inline-flex items-center">
              <input type="radio" className="form-radio" name="pupilNeet" />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input type="radio" className="form-radio" name="accountType" />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>
        <div className="w-7/12 flex items-center">
          <label className="w-24">Reason :</label>
          <input type="text" className="pep-input textarea-input" />
        </div>
      </div>

    </div>
  )
}

export default AltNeedTableRToN4