import { Field } from 'formik'
import React, { memo, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useDebouncedCallback } from 'use-debounce/lib';

const NumberTypeField = ({
  fieldName,
  saveForm,
  maxDigit,
  onChangeHandler,
  rest,
  restForLoader,
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const debouncedSavePepPrimary = useDebouncedCallback(saveForm, 1000);

  return (
    <Field name={fieldName}>
      {
        ({ form, field }) => {
          return (
            <div className="relative">
              <input
                type="number"
                className="rounded-3xl pep-input w-24"
                value={field.value ? field.value : ""}
                onChange={(e) => onChangeHandler({
                  maxDigit,
                  setIsLoading,
                  fieldName,
                  values: form.values,
                  fieldValue: e.target.value,
                  setFieldValue: form.setFieldValue,
                  saveForm: debouncedSavePepPrimary,
                })}
                {...rest}
              />
              {
                isLoading &&
                <div className="absolute top-0 left-0 w-full h-full bg-white pt-1" {...restForLoader}>
                  <Skeleton />
                </div>
              }
            </div>
          )
        }
      }
    </Field>
  )
}

export default NumberTypeField;
