import React from 'react';
import { Link } from 'react-router-dom';
import { routeUrl } from '../../../../Components/utils/RouteUrl';

const BasicInfoHeader = () => {
  const schoolId = localStorage.getItem("schoolId");
  console.log(schoolId, "testing school id")
  return (
    <div className="rounded-3xl bg-white pl-12 pb-2 w-full">
      <ul className="lg:flex items-center basic-tab">
        <li>
          <Link to={routeUrl.basic_info_pupil}>
            <span>Pupil</span>
          </Link>
        </li>
        <li>
          <Link to={routeUrl.basic_info_contacts}>
            <span>Contacts</span>
          </Link>
        </li>

        {(() => {
          if (schoolId && schoolId != 1934) {
            return (
              <li>
                <Link to={routeUrl.basic_info_attendance}>
                  <span>Attendance</span>
                </Link>
              </li>
            )
          }
        })()}

        {(() => {
          if (schoolId && schoolId != 1934) {
            return (
              <li>
                <Link to={routeUrl.basic_info_exclusions}>
                  <span>Suspensions</span>
                </Link>
              </li>
            )
          }
        })()}

        <li>
          <Link to={routeUrl.basic_info_pep_alt_ed_neet}>
            <span>Alt Ed-NEET</span>
          </Link>
        </li>
        <li>
          <Link to={routeUrl.basic_info_doc}>
            <span>Docs</span>
          </Link>
        </li>
        {/* <li className="tab-active mr-4"> */}
        <li>
          <Link to={routeUrl.basic_info_prior_attainment}>
            <span>Prior Attainment</span>
          </Link>
        </li>
        <li>
          <Link to={routeUrl.basic_info_pep_intervention}>
            <span>Interventions</span>
          </Link>
        </li>
        {/* <li>
          <Link to={routeUrl.basic_info_case_notes}>
            <span>Case Notes</span>
          </Link>
        </li> */}
        <li>
          <Link to={routeUrl.basic_info_uasc}>
            <span>UASC</span>
          </Link>
        </li>
        {/* <li>
          <Link to={routeUrl.basic_info_add_new_school}>
            <span>Add New School</span>
          </Link>
        </li> */}
      </ul>
    </div>
  );
}


export default BasicInfoHeader;
