import React, { useEffect } from 'react';
import ReactDom from 'react-dom';

// This Modal class is to created to appear model on the screen
const Modal = (props) => {
  useEffect(() => {
    document.addEventListener("keydown", closeOnEscape);
    return () => document.removeEventListener("keydown", closeOnEscape);
  }, []);
  
  const closeOnEscape = (e) => {
    e.key === "Escape" && props.closeModal();
  }

  // This method close model 
  const closeModal = () => {
    props.closeModal();
  }

  if(!props.isOpen) return null;
  
  return ReactDom.createPortal (
    <div className="modal">
      <div className="modal-overlay" onClick={closeModal}></div>
      <div className="modal-content flex justify-center items-center relative">
        {props.children}
      </div>
    </div>,
    document.getElementById("modal-portal")
  );
} 

export default Modal;
