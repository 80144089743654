import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { useReducer } from 'react';
import Swal from 'sweetalert2';
import PupilContext from '../../../../Components/context/PupilContext';
import IsCompletedContext from '../../../../Components/context/IsCompletedContext';
import PupilVoiceHoc from '../../../../Components/NCC/PupilVoice/PupilVoiceHoc';
import PupilVoiceSkeleton from '../../../../Components/NCC/PupilVoice/PupilVoiceSkeleton';
import TextAreaField from '../../../../Components/NCC/TextAreaField';
import { capatalizeFirstLetter, checkForForTrimmedEmptyValue, getYearGroup, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions';
import { NCCReportApi } from '../../../../constants/apiUrls';
import { setLoading, setNccPupilVoice } from '../../../../constants/reducerActions';
import PupilVoiceReducer from '../../../../reducers/NCC/PupilVoiceReducer';
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader';
import CompleteButton from '../../../../Components/NCC/CompleteButton';

const initialState = {
  pupilVoice: null,
  isLoading: false,
}

const NccPupilVoice = ({
  saveForm,
  onChangeHandler,
  numberOnChangeHandler,
  dateChangeHandler,
  exportPdf,
  formValidationWrapper,
}) => {
  const reportName = "nccPupilvoice";
  const fullPepPdfReportName = "NccFullPep";
  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");

  const { dateOfMeeting, pupilInfo } = useContext(PupilContext);

  const [state, dispatch] = useReducer(PupilVoiceReducer, initialState);
  const { setIsCompleted } = useContext(IsCompletedContext);
  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);

  useEffect(() => {
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      dispatch({ type: setLoading, payload: { isLoading: true } });
      const url = NCCReportApi.getPupilVoice({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      axios.get(url)
        .then(res => {
          dispatch({ type: setNccPupilVoice, payload: res.data })
          console.log(res.data, "from pupil voice");
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.", { position: "bottom-right" })
    }
    
  }, [dateOfMeeting])

  const initialFormState = {
    pupilVoice: state.pupilVoice,
  }


  const successCallback = (values) => {
    values.pupilVoice.isCompleted = 1;
  }

  const checkForErrors = (values) => {
    let error = "";

    if (checkForForTrimmedEmptyValue(values.pupilVoice.childDreamsAndFutureGoals)) {
      error = "'Child/Young person's hopes, dreams and future goals' is required";
    }

    return error;
  }

  const formValidaton = formValidationWrapper({
    saveForm,
    setIsCompleted,
    fieldName: "pupilVoice.isCompleted",
    objectName: "pupilVoice",
    successCallback,
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: () => showReportCompleteSuccess("Pupil Voice Completed"),
  });

  return (
    <Formik
      initialValues={initialFormState}
      enableReinitialize
      onSubmit={formValidaton}
    >
      <Form>
        <PepSecondaryHeader
          heading={`NCC Pupil Voice ${capatalizeFirstLetter(getYearGroup(pupilInfo.classYear))}`}
          buttons={[
            <CompleteButton
              fieldName={"pupilVoice.isCompleted"}
              completeButtonLoader={completeButtonLoader}
            />,
            <div className="export-btn">
              <span className="blue-button px-5 py-2  text-sm">Export</span>
              <ul>
                <li>
                  <button type="button" onClick={() => exportPdf(fullPepPdfReportName)}>Full Pep</button>
                </li>
                <li>
                  <button type="button" onClick={() => exportPdf(reportName)}>PDF</button>
                </li>
              </ul>
            </div>
          ]}
        />
        {
          state.isLoading ?
            <PupilVoiceSkeleton /> :
            <div className='ncc-pupil-voice'>
              <h1 className="pupil-title mb-4">Hopes, dreams and future goals</h1>
                <div className="rounded-3xl bg-white p-4 overflow-hidden">
      
                  <div className="flex flex-col gap-3">
                    <div className="pb-2">
                    <p className="inline-block font-medium">Child/Young person's hopes, dreams and future goals: <span className='required'>*</span>  </p>
                      <div className="textarea-block mt-1 w-full">
                        <TextAreaField 
                          fieldName={"pupilVoice.childDreamsAndFutureGoals"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                          }}
                        />
                      </div>
                    </div>
                    <div className="pb-2">
                      <p className="inline-block font-medium">If our young person is not in the meeting, who has had this conversation with them?  </p>
                      <div className="textarea-block mt-1 w-full">
                        
                        <TextAreaField 
                          fieldName={"pupilVoice.youngPersonIsNotInTheMeeting"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                          }}
                        />
                      </div>
                    </div>
                    <div className="pb-2">
                      <p className="inline-block font-medium"><b>What else does our child/young person want us to know about education - record how/who will address anything raised here?</b> E.g. what do you enjoy about education? What are you good at? What makes you feel safe and comfortable in school? Is there anything you don't like about school ? what needs to be done to make it better? What help do you need at school to do things you don't already do? Is there anything else you would like to tell us? </p>
                      <div className="textarea-block mt-1 w-full">
                        
                        <TextAreaField 
                          fieldName={"pupilVoice.knowAboutEducation"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div> 
            </div>
        }
      </Form>
    </Formik>
      
  )
}

export default PupilVoiceHoc(NccPupilVoice);
