import React from 'react'
import { setPriorAttainment } from '../../constants/reducerActions'

const setReducerValue = (value, emptyValue="") =>  {
  if (value && value !== null && value !== "null") {
    return value;
  } else {
    return emptyValue;
  }
}

const priorAttainmentReducer = (state, action) => {
  const data = action.payload;
  switch(action.type) {
    case setPriorAttainment:
      return {
        ...state,
        goodLevOfDevAchiev: setReducerValue(data.pupilInfo.goodLevOfDevAchiev),
        phonicsScreen: setReducerValue(data.pupilInfo.phonicsScreen),
        phonicsResit: setReducerValue(data.pupilInfo.phonicsResit),
        keyStage1PriorAttainment: setReducerValue(data.pupilInfo.keyStage1PriorAttainment),
        phonicKeyStage1Score: setReducerValue(data.pupilInfo.phonicKeyStage1Score),
        readingKeyStage1Score: setReducerValue(data.pupilInfo.readingKeyStage1Score),
        writingKeyStage1Score: setReducerValue(data.pupilInfo.writingKeyStage1Score),
        mathKeyStage1Score: setReducerValue(data.pupilInfo.mathKeyStage1Score),
        keyStage2PriorAttainment: setReducerValue(data.pupilInfo.keyStage2PriorAttainment),
        ks2ScoreSPAG: setReducerValue(data.pupilInfo.ks2ScoreSPAG),
        ks2ScoreReading: setReducerValue(data.pupilInfo.ks2ScoreReading),
        ks2ScoreWriting: setReducerValue(data.pupilInfo.ks2ScoreWriting),
        ks2ScoreMaths: setReducerValue(data.pupilInfo.ks2ScoreMaths),
        keyStage4PriorAttainment: setReducerValue(data.pupilInfo.keyStage4PriorAttainment),
        engResult: setReducerValue(data.pupilInfo.engResult),
        mathResult: setReducerValue(data.pupilInfo.mathResult),
        // keyStage4Subject1: setReducerValue(data.pupilInfo.),
        // keyStage4Subject1Result: setReducerValue(data.pupilInfo.),
        // keyStage4Subject2: setReducerValue(data.pupilInfo.),
        // keyStage4Subject2Result: setReducerValue(data.pupilInfo.),
        // keyStage4Subject3: setReducerValue(data.pupilInfo.),
        // keyStage4Subject3Result: setReducerValue(data.pupilInfo.),
        // keyStage4Subject4: setReducerValue(data.pupilInfo.),
        // keyStage4Subject4Result: setReducerValue(data.pupilInfo.),
        // keyStage4Subject5: setReducerValue(data.pupilInfo.),
        // keyStage4Subject5Result: setReducerValue(data.pupilInfo.),
        // keyStage4Subject6: setReducerValue(data.pupilInfo.),
        // keyStage4Subject6Result: setReducerValue(data.pupilInfo.),
        // keyStage4Subject7: setReducerValue(data.pupilInfo.),
        // keyStage4Subject7Result: setReducerValue(data.pupilInfo.),
        // keyStage4Subject8: setReducerValue(data.pupilInfo.),
        // keyStage4Subject8Result: setReducerValue(data.pupilInfo.),
        keyStage5Subject1: setReducerValue(data.pupilInfo.keyStage5Sub1),
        keyStage5Subject1Result: setReducerValue(data.pupilInfo.keyStage5Sub1Res),
        keyStage5Subject2: setReducerValue(data.pupilInfo.keyStage5Sub2),
        keyStage5Subject2Result: setReducerValue(data.pupilInfo.keyStage5Sub2Res),
        keyStage5Subject3: setReducerValue(data.pupilInfo.keyStage5Sub3),
        keyStage5Subject3Result: setReducerValue(data.pupilInfo.keyStage5Sub3Res),
        keyStage5Subject4: setReducerValue(data.pupilInfo.keyStage5Sub4),
        keyStage5Subject4Result: setReducerValue(data.pupilInfo.keyStage5Sub4Res),
        keyStage5Subject5: setReducerValue(data.pupilInfo.keyStage5Sub5),
        keyStage5Subject5Result: setReducerValue(data.pupilInfo.keyStage5Sub5Res),
        keyStage5Subject6: setReducerValue(data.pupilInfo.keyStage5Sub6),
        keyStage5Subject6Result: setReducerValue(data.pupilInfo.keyStage5Sub6Res),
        eepYear7: setReducerValue(data.pupilInfo.basicInformationEepHours.eepHoursYear7),
        eepYear8: setReducerValue(data.pupilInfo.basicInformationEepHours.eepHoursYear8),
        eepYear9: setReducerValue(data.pupilInfo.basicInformationEepHours.eepHoursYear9),
        eepYear10: setReducerValue(data.pupilInfo.basicInformationEepHours.eepHoursYear10),
        eepYear11: setReducerValue(data.pupilInfo.basicInformationEepHours.eepHoursYear11),
        eepYear12: setReducerValue(data.pupilInfo.basicInformationEepHours.eepHoursYear12),
        eepYear13: setReducerValue(data.pupilInfo.basicInformationEepHours.eepHoursYear13),
        eepHoursTotal: setReducerValue(data.pupilInfo.basicInformationEepHours.eepHoursTotal),
      }
  }
}

// setPhonicsScreen((res.data.pupilInfo && res.data.pupilInfo.phonicsScreen) ? res.data.pupilInfo.phonicsScreen : "");
// setPhonicsResit((res.data.pupilInfo && res.data.pupilInfo.phonicsResit) ? res.data.pupilInfo.phonicsResit : "");
// setKeyStage1PriorAttainment((res.data.pupilInfo && res.data.pupilInfo.keyStage1PriorAttainment) ? res.data.pupilInfo.keyStage1PriorAttainment : "");
// setPhonicKeyStage1Score((res.data.pupilInfo && res.data.pupilInfo.phonicKeyStage1Score) ? res.data.pupilInfo.phonicKeyStage1Score : "");
// setReadingKeyStage1Score((res.data.pupilInfo && res.data.pupilInfo.readingKeyStage1Score) ? res.data.pupilInfo.readingKeyStage1Score : "");
// setWritingKeyStage1Score((res.data.pupilInfo && res.data.pupilInfo.writingKeyStage1Score) ? res.data.pupilInfo.writingKeyStage1Score : "");
// setMathKeyStage1Score((res.data.pupilInfo && res.data.pupilInfo.mathKeyStage1Score) ? res.data.pupilInfo.mathKeyStage1Score : "");
// setKeyStage2PriorAttainment((res.data.pupilInfo && res.data.pupilInfo.keyStage2PriorAttainment) ? res.data.pupilInfo.keyStage2PriorAttainment : "");

// setKs2ScoreSPAG((res.data.pupilInfo && res.data.pupilInfo.ks2ScoreSPAG) ? res.data.pupilInfo.ks2ScoreSPAG : "");

// setKs2ScoreReading((res.data.pupilInfo && res.data.pupilInfo.ks2ScoreReading) ? res.data.pupilInfo.ks2ScoreReading : "");
// setKs2ScoreWriting((res.data.pupilInfo && res.data.pupilInfo.ks2ScoreWriting) ? res.data.pupilInfo.ks2ScoreWriting : "");
// setKs2ScoreMaths((res.data.pupilInfo && res.data.pupilInfo.ks2ScoreMaths) ? res.data.pupilInfo.ks2ScoreMaths : "");
// setKeyStage4PriorAttainment((res.data.pupilInfo && res.data.pupilInfo.keyStage4PriorAttainment) ? res.data.pupilInfo.keyStage4PriorAttainment : "");
// setEngResult((res.data.pupilInfo && res.data.pupilInfo.engResult) ? res.data.pupilInfo.engResult : "");
// setMathResult((res.data.pupilInfo && res.data.pupilInfo.mathResult) ? res.data.pupilInfo.mathResult : "");

// setKeyStage5Subject1((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub1) ? res.data.pupilInfo.keyStage5Sub1 : "");
// setKeyStage5Subject1Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub1Res) ? res.data.pupilInfo.keyStage5Sub1Res : "");
// setKeyStage5Subject2((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub2) ? res.data.pupilInfo.keyStage5Sub2 : "");
// setKeyStage5Subject2Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub2Res) ? res.data.pupilInfo.keyStage5Sub2Res : "");
// setKeyStage5Subject3((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub3) ? res.data.pupilInfo.keyStage5Sub3 : "");
// setKeyStage5Subject3Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub3Res) ? res.data.pupilInfo.keyStage5Sub3Res : "");
// setKeyStage5Subject4((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub4) ? res.data.pupilInfo.keyStage5Sub4 : "");
// setKeyStage5Subject4Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub4Res) ? res.data.pupilInfo.keyStage5Sub4Res : "");
// setKeyStage5Subject5((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub5) ? res.data.pupilInfo.keyStage5Sub5 : "");
// setKeyStage5Subject5Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub5Res) ? res.data.pupilInfo.keyStage5Sub5Res : "");
// setKeyStage5Subject6((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub6) ? res.data.pupilInfo.keyStage5Sub6 : "");
// setKeyStage5Subject6Result((res.data.pupilInfo && res.data.pupilInfo.keyStage5Sub6Res) ? res.data.pupilInfo.keyStage5Sub6Res : "");

// setEepYear7((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear7) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear7 : "");
// setEepYear8((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear8) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear8 : "");
// setEepYear9((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear9) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear9 : "");
// setEepYear10((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear10) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear10 : "");
// setEepYear11((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear11) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear11 : "");
// setEepYear12((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear12) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear12 : "");
// setEepYear13((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursYear13) ? res.data.pupilInfo.basicInformationEepHours.eepHoursYear13 : "");
// setEepHoursTotal((res.data.pupilInfo && res.data.pupilInfo.basicInformationEepHours && res.data.pupilInfo.basicInformationEepHours.eepHoursTotal) ? res.data.pupilInfo.basicInformationEepHours.eepHoursTotal : "");

export default priorAttainmentReducer