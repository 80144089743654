import React from 'react'
import Skeleton from 'react-loading-skeleton'

const NCCDataEyfsSkeleton = () => {
  return (
    <>
      <div className="rounded-3xl p-6 bg-white lg:w-3/5 md:pr-8 overflow-hidden">
        <div className='py-2'>
          <Skeleton height={20}/>
        </div>
        <div className='py-2'>
          <Skeleton height={20}/>
        </div>
      </div>

      <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
        <div className='mb-3'>
          <div className='py-2 w-28'>
            <Skeleton height={30} />
          </div>
          <div className='py-2 w-80'>
            <Skeleton height={30} />
          </div>
          <div className='py-2'>
            <Skeleton height={30} />
          </div>
        </div>
        <div className='mb-3'>
          <div className='py-2 w-28'>
            <Skeleton height={30} />
          </div>
          <div className='py-2 w-80'>
            <Skeleton height={30} />
          </div>
          <div className='py-2'>
            <Skeleton height={30} />
          </div>
        </div>
        <div className='mb-3'>
          <div className='py-2 w-28'>
            <Skeleton height={30} />
          </div>
          <div className='py-2 w-80'>
            <Skeleton height={30} />
          </div>
          <div className='py-2'>
            <Skeleton height={30} />
          </div>
        </div>
      </div>
      <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
        <div className='py-2'>
          <Skeleton height={30} />
        </div>
        <div className='py-2'>
          <Skeleton height={30} />
        </div>
      </div>
    </>
  )
}

export default NCCDataEyfsSkeleton
