import React from 'react';

const AllPep = () => {
  return (
    <div>
      <h1>All Pep</h1>
    </div>
  ) 
};

export default AllPep;
