import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useReducer, useState } from 'react'
import Swal from 'sweetalert2';
import IsCompletedContext from '../../../../Components/context/IsCompletedContext';
import PupilContext from '../../../../Components/context/PupilContext';
import CompleteButton from '../../../../Components/NCC/CompleteButton';
import SelectField from '../../../../Components/NCC/SelectField';
import StrengthAndNeedHoc from '../../../../Components/NCC/StrengthAndNeed/StrengthAndNeedHoc';
import StrengthAndNeedSkeleton from '../../../../Components/NCC/StrengthAndNeed/StrengthAndNeedSkeleton';
import TextAreaField from '../../../../Components/NCC/TextAreaField'
import { checkForForTrimmedEmptyValue, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions';
import { NCCReportApi } from '../../../../constants/apiUrls';
import { setLoading, setNccStrengthAndNeedPost16 } from '../../../../constants/reducerActions';
import strengthAndNeedReducer from '../../../../reducers/NCC/strengthAndNeedReducer';
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader';



const initialState = {
  strengthsAndNeedsPost16: null,
  isLoading: false,
}

const frameworkScoreOptionList = [
  <option value=""></option>,
  <option value="Demonstrates a good understanding and has experience of solving problems independently">Demonstrates a good understanding and has experience of solving problems independently</option>,
  <option value="Demonstrates a good understanding and has experience of problem solving independently, sometimes needing adult guidance">Demonstrates a good understanding and has experience of problem solving independently, sometimes needing adult guidance</option>,
  <option value="Demonstrates a good understanding and can solve problems with guidance from adults">Demonstrates a good understanding and can solve problems with guidance from adults</option>,
  <option value="Demonstrates some understanding and needs guidance from an adult to solve problems">Demonstrates some understanding and needs guidance from an adult to solve problems</option>,
  <option value="Needs support and guidance from an adult to problem solve">Needs support and guidance from an adult to problem solve</option>,
] 

const NCCStrengthAndNeedPost16 = ({
  saveForm,
  onChangeHandler,
  numberOnChangeHandler,
  dateChangeHandler,
  exportPdf,
  formValidationWrapper,
}) => {
  const objectName = "strengthsAndNeedsPost16";
  const pdfReportName = "nccPost16StrengthsAndNeeds";
  const fullPepPdfReportName = "NccFullPep";
  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");

  const [state, dispatch] = useReducer(strengthAndNeedReducer, initialState);

  const { dateOfMeeting, pupilInfo } = useContext(PupilContext);
  const {setIsCompleted} = useContext(IsCompletedContext);
  
  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);

  useEffect(() => {
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      dispatch({ type: setLoading, payload: { isLoading: true } });
      const url = NCCReportApi.getStrengthAndNeedPost16Report({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      axios.get(url)
        .then(res => {
          dispatch({ type: setNccStrengthAndNeedPost16, payload: res.data })
          console.log(res.data, "from strength need post 16");
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.", { position: "bottom-right" })
    }
    
  }, [dateOfMeeting])

  const initialFormState = {
    strengthsAndNeedsPost16: state.strengthsAndNeedsPost16,
  }

  const isCompletedCallback = () => {
    setIsCompleted("strengthAndNeedIsCompleted", true);
    showReportCompleteSuccess("Strength & Needs Post 16 Completed");
  }

  const successCallback = (values) => {
    values[objectName]["isCompleted"] = 1;
  }

  const checkForErrors = (values) => {
    let error = "";

    if (checkForForTrimmedEmptyValue(values.strengthsAndNeedsPost16.relationshipsStrengths)) {
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeedsPost16.communicationStrengths)) {
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeedsPost16.preparingForAdulthoodStrengths)) {
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeedsPost16.mentalHealthStrengths)) {
      error = "Please fill the required";
    }
    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeedsPost16.executiveFunctioningStrengths)) {
      error = "Please fill the required";
    }
    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeedsPost16.problemSolvingStrengths)) {
      error = "Please fill the required";
    }
    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeedsPost16.teamWorkingStrengths)) {
      error = "Please fill the required";
    }
    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeedsPost16.timeManagementStrengths)) {
      error = "Please fill the required";
    }
    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeedsPost16.travelStrengths)) {
      error = "Please fill the required";
    }

    return error;
  }

  const formValidaton = formValidationWrapper({
    saveForm,
    setIsCompleted,
    fieldName: `${objectName}.isCompleted`,
    objectName,
    successCallback,
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: isCompletedCallback,
  });

  return (
    <Formik
      initialValues={initialFormState}
      enableReinitialize
      onSubmit={formValidaton}
    >
      <Form>
        <PepSecondaryHeader
          heading={`NCC Strength & Needs Post-16`}
          buttons={[
            <CompleteButton
              fieldName={`${objectName}.isCompleted`}
              completeButtonLoader={completeButtonLoader}
            />,
            <div className="export-btn">
              <span className="blue-button px-5 py-2  text-sm">Export</span>
              <ul>
                <li>
                  <button type="button" onClick={() => exportPdf(fullPepPdfReportName)}>Full Pep</button>
                </li>
                <li>
                  <button type="button" onClick={() => exportPdf(pdfReportName)}>PDF</button>
                </li>
              </ul>
            </div>
          ]}
        />
        {
          state.isLoading ?
            <StrengthAndNeedSkeleton /> :
            <div className="strength-data">

              <div className="rounded-3xl bg-white p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium pb-2">Relationships: What are the strengths in our child/young person relationships? Does our child/young person need any support with peer/adult relationships?</p>
                    <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                      <label htmlFor="" className='inline-block'>Strengths: <span className="required">*</span></label>
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.relationshipsStrengths"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl p-2 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="table-box pt-4 overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className="blue">Need</th>
                        <th className="d-blue">Action required</th>
                        <th className="purple">Person(s) Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.relationshipsStrengthsNeed"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.relationshipsStrengthsActionRequired"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.relationshipsStrengthsPersonResponsible"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium pb-2">Communication Skills: How does our child/young person communicate to get their needs met?</p>
                    <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                      <label htmlFor="">Strengths: <span className="required">*</span></label>
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.communicationStrengths"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl p-2 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="table-box pt-4 overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className="blue">Need</th>
                        <th className="d-blue">Action required</th>
                        <th className="purple">Person(s) Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.communicationStrengthsNeed"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.communicationStrengthsActionRequired"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.communicationPersonResponsible"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor="">Does our child/young person have English as an additional language? </label>
                  <SelectField
                    fieldName={"strengthsAndNeedsPost16.additionalLanguage"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option></option>,
                      <option>Yes</option>,
                      <option>No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full p-2 text-sm w-24"
                    }}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">If yes, is additional support required? Please state.  </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.isAdditionalSupportRequired"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium pb-2">Mental Health and Emotional Wellbeing: including emotional regulation when our child/young person experiences a stressful situation</p>
                    <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                      <label htmlFor="">Strengths: <span className="required">*</span></label>
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.mentalHealthStrengths"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl p-2 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="table-box pt-4 overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className="blue">Need</th>
                        <th className="d-blue">Action required</th>
                        <th className="purple">Person(s) Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.mentaldHalthStrengthsNeed"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.mentaldHalthStrengthsActionRequired"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.mentalHdalthPersonResponsible"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium pb-2"><b>Executive Functioning Skills: Being able to plan, organise, monitor and carry out tasks throughout the day</b> (e.g. emotional regulation, memory, initiate task, shift from one task to another)</p>
                    <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                      <label htmlFor="">Strengths: <span className="required">*</span></label>
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.executiveFunctioningStrengths"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl p-2 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="table-box pt-4 overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className="blue">Need</th>
                        <th className="d-blue">Action required</th>
                        <th className="purple">Person(s) Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.executiveFunctioningStrengthsNeed"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.executiveFunctioningStrengthsActionRequired"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.executiveFunctioningPersonResponsible"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium pb-2">Preparing for Adulthood: friends, part of a community, skills to employment, skills to greater independence and skills to good health</p>
                    <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                      <label htmlFor="">Strengths: <span className="required">*</span></label>
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.preparingForAdulthoodStrengths"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl p-2 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="table-box pt-4 overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className="blue">Need</th>
                        <th className="d-blue">Action required</th>
                        <th className="purple">Person(s) Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.preparingForAdulthoodStrengthsNeed"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.preparingForAdulthoodStrengthsActionRequired"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.preparingForAdulthoodPersonResponsible"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <h1 className="pb-2 text-xl">Other Skills</h1>
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor=""><b>Problem Solving:</b> Tell, explain, describe questions? tell me a time you had a problem and what you did to resolve it? This can relate to education, home, work etc. e.g. if you're due to be in college or work but feeling too unwell to attend, what would you do? You were getting a lift home from college/work from a friend but they can no longer take you, what do you do? </label>
                </div>
                <div className="flex flex-col mt-4 gap-3">
                  <div className="pb-2">
                    <h3 className="inline-block font-medium">Framework score</h3>
                    <div className="textarea-block mt-1 w-full">
                      <SelectField
                        fieldName={"strengthsAndNeedsPost16.problemSolvingFramework"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        optionsList={frameworkScoreOptionList}

                      />
                      {/* <select className="border-gray-300 border rounded-full p-2 text-sm">
                        <option>Demonstrates a good understanding and has experience of solving problems independently</option>
                        <option>Demonstrates a good understanding and has experience of problem solving independently, sometimes needing adult guidance</option>
                        <option>Demonstrates a good understanding and can solve problems with guidance from adults</option>
                        <option>Demonstrates some understanding and needs guidance from an adult to solve problems</option>
                        <option>Needs support and guidance from an adult to problem solve</option>
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                      <label htmlFor="">Strengths: <span className="required">*</span></label>
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.problemSolvingStrengths"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="table-box pt-4 overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className="blue">Need</th>
                        <th className="d-blue">Action required</th>
                        <th className="purple">Person(s) Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                              fieldName={"strengthsAndNeedsPost16.problemSolvingStrengthsNeed"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                              fieldName={"strengthsAndNeedsPost16.problemSolvingStrengthsActionRequired"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                              fieldName={"strengthsAndNeedsPost16.problemSolvingStrengthsPersonResponsible"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor=""><b>Team Working:</b> Tell, explain, describe questions? Describe when you have worked well as a team? E.g. work as part of a small or large group such as team project, having a role within a team to serve customers, arranging meal for people you live with</label>
                </div>
                <div className="flex flex-col mt-4 gap-3">
                  <div className="pb-2">
                    <h3 className="inline-block font-medium">Framework score</h3>
                    <div className="textarea-block mt-1 w-full">
                      <SelectField
                        fieldName={"strengthsAndNeedsPost16.teamWorkingFramework"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        optionsList={frameworkScoreOptionList}

                      />
                      {/* <select className="border-gray-300 border rounded-full p-2 text-sm">
                        <option>Demonstrates a good understanding and has experience of solving problems independently</option>
                        <option>Demonstrates a good understanding and has experience of problem solving independently, sometimes needing adult guidance</option>
                        <option>Demonstrates a good understanding and can solve problems with guidance from adults</option>
                        <option>Demonstrates some understanding and needs guidance from an adult to solve problems</option>
                        <option>Needs support and guidance from an adult to problem solve</option>
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                      <label htmlFor="">Strengths: <span className="required">*</span></label>
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.teamWorkingStrengths"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="table-box pt-4 overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className="blue">Need</th>
                        <th className="d-blue">Action required</th>
                        <th className="purple">Person(s) Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.teamWorkingStrengthsNeed"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.teamWorkingStrengthsActionRequired"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.teamWorkingStrengthsPersonResponsible"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor=""><b>Time Management: </b> e.g. are you on time for school, college, work, appointments? Do you have strategies in place to help you to be punctual? If you had to be in Nottingham for 10am, how would you ensure you're there on time?</label>
                </div>
                <div className="flex flex-col mt-4 gap-3">
                  <div className="pb-2">
                    <h3 className="inline-block font-medium">Framework score</h3>
                    <div className="textarea-block mt-1 w-full">
                      <SelectField
                        fieldName={"strengthsAndNeedsPost16.timeManagementFramework"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        optionsList={frameworkScoreOptionList}

                      />
                      {/* <select className="border-gray-300 border rounded-full p-2 text-sm">
                        <option>Demonstrates a good understanding and has experience of solving problems independently</option>
                        <option>Demonstrates a good understanding and has experience of problem solving independently, sometimes needing adult guidance</option>
                        <option>Demonstrates a good understanding and can solve problems with guidance from adults</option>
                        <option>Demonstrates some understanding and needs guidance from an adult to solve problems</option>
                        <option>Needs support and guidance from an adult to problem solve</option>
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                      <label htmlFor="">Strengths: <span className="required">*</span></label>
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.timeManagementStrengths"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="table-box pt-4 overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className="blue">Need</th>
                        <th className="d-blue">Action required</th>
                        <th className="purple">Person(s) Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.timeManagementStrengthsNeed"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.timeManagementStrengthsActionRequired"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.timeManagementStrengthsPersonResponsible"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor=""><b>Travel: </b> How confident is our young person in travelling independently from home to a familiar place?</label>
                </div>
                <div className="flex flex-col mt-4 gap-3">
                  <div className="pb-2">
                    <h3 className="inline-block font-medium">Framework score</h3>
                    <div className="textarea-block mt-1 w-full">
                      <SelectField
                        fieldName={"strengthsAndNeedsPost16.travelFramework"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        optionsList={frameworkScoreOptionList}

                      />
                      {/* <select className="border-gray-300 border rounded-full p-2 text-sm">
                        
                        <option>Demonstrates a good understanding and has experience of solving problems independently</option>
                        <option>Demonstrates a good understanding and has experience of problem solving independently, sometimes needing adult guidance</option>
                        <option>Demonstrates a good understanding and can solve problems with guidance from adults</option>
                        <option>Demonstrates some understanding and needs guidance from an adult to solve problems</option>
                        <option>Needs support and guidance from an adult to problem solve</option>
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                      <label htmlFor="">Strengths: <span className="required">*</span></label>
                      <TextAreaField
                        fieldName={"strengthsAndNeedsPost16.travelStrengths"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "rounded-2xl p-2 textarea textarea-small w-full"
                        }}
                        rows={2}
                      />
                      {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="table-box pt-4 overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className="blue">Need</th>
                        <th className="d-blue">Action required</th>
                        <th className="purple">Person(s) Responsible</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.travelStrengthsNeed"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.travelStrengthsActionRequired"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                        <td>
                          <TextAreaField
                            fieldName={"strengthsAndNeedsPost16.travelStrengthsPersonResponsible"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            rest={{
                              className: "rounded-2xl p-2 textarea textarea-small w-full"
                            }}
                            rows={2}
                          />
                          {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
        }
      </Form>
    </Formik>
  )
}

export default StrengthAndNeedHoc(NCCStrengthAndNeedPost16);
