import { FastField, Field } from 'formik';
import React from 'react'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDebouncedCallback } from 'use-debounce/lib';

const InputField = ({
  rest,
  onChangeHandler,
  saveForm,
  fieldName,
  maxDigit,
  restForLoader
}) => {

  const [isLoading, setIsLoading] = useState(false);
  
  

  const debounceSaveForm = useDebouncedCallback(saveForm, 1000);

  return (
    <div className='relative'>
      <Field name={fieldName}>
        {
          ({form, field}) => {
            return (
              <input 
                type="text" 
                className='p-2 rounded-full border-gray-300 border text-sm'
                value={field.value ? field.value : ""}
                onChange={(e) => onChangeHandler({
                  fieldName,
                  setIsLoading,
                  fieldValue: e.target.value,
                  saveForm: debounceSaveForm,
                  setFieldValue: form.setFieldValue,
                  values: form.values,
                  // maxDigit
                })}
                {...rest}
              />
            )
          }
        }
      </Field>
      {
        isLoading && 
          <div className='absolute top-0 left-0 w-full h-full gray-table-color grid items-center' {...restForLoader}>
            <Skeleton />
          </div>
      }
    </div>
  )
}

export default InputField
