import React, { useContext } from 'react'
import PupilContext from '../../../Components/context/PupilContext'
import { getYearGroup } from '../../../Components/utils/utilityFunctions';
import NCCSecondaryHeader from '../NCCSecondaryHeader'
import NCCMeetingPost16 from './components/NCCMeetingPost16';
import NCCMeetingComponent from "./components/NCCMeeting";
import PepSecondaryHeader from '../../Pep/PepSecondaryHeader';
import { NCCReportApi } from '../../../constants/apiUrls';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

const NCCMeeting = () => {

  const { pupilInfo } = useContext(PupilContext);

  const componentToRender = {
    Component: null,
    componentTitle: "",
    reportName: "",
  }

  const yearGroup = getYearGroup(pupilInfo.classYear);

  switch (yearGroup) {

    case "eyfs":
      componentToRender.Component = <NCCMeetingComponent />;
      componentToRender.componentTitle = "";
      componentToRender.reportName = "nccMeeting";
      break;

    case "primary":
      componentToRender.Component = <NCCMeetingComponent />;
      componentToRender.componentTitle = "";
      componentToRender.reportName = "nccMeeting";
      break;

    case "secondary":
      componentToRender.Component = <NCCMeetingComponent />;
      componentToRender.componentTitle = "";
      componentToRender.reportName = "nccMeeting";
      break;

    case "post16":
      componentToRender.Component = <NCCMeetingPost16 />;
      componentToRender.componentTitle = "POST-16";
      componentToRender.reportName = "nccPost16Meeting";
      break;

  }

  const exportPdf = () => {
    const a = document.createElement("a");
    const url = NCCReportApi.exportPdfReports({
      reportName: componentToRender.reportName,
      pupilId: pupilInfo.pupilId,
      schoolId: pupilInfo.schoolId,
      classYear: pupilInfo.classYear,
      termofpep: pupilInfo.termOfPep,
      pupilsName: pupilInfo.fullname,
      dateofmeeting: pupilInfo.dateofmeeting,
    });
    axios.get(url, { responseType: "blob" })
      .then((res) => {
        let objectUrl = window.URL.createObjectURL(res.data);
        a.href = objectUrl;
        a.download = componentToRender.reportName;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      })
      .catch(err => {
        const error = err;
        console.log(err);
        console.log(err);
      })
  }

  return (
    <>
      <ToastContainer />
      {componentToRender.Component !== null && componentToRender.Component}
    </>
  )
}

export default NCCMeeting
