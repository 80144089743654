import { urlencoded } from 'body-parser';
import React, { useContext } from 'react';
import { Switch } from 'react-router';
import MainContext from '../../../Components/context/MainContext';
import PrivateRouter from '../../../Components/PrivateRoute';
import { routeUrl } from '../../../Components/utils/RouteUrl';
import PepAttendanceTab from './Pages/PepAttendanceTab/Index';
import PepBasicDocsTab from './Pages/PepBasicDocsTab/Index';
import PepBasicPupilTab from './Pages/PepBasicPupilTab/Index';
import PepPriorAttainmentTab from './Pages/PepPriorAttainmentTab/Index';
import Pupil from '../../Pupil/Index';
import PepContactsTab from './Pages/PepContactsTab/Index';
import PepAltEdNeetTab from './Pages/PepAltEdNeetTab/Index';
import PepInterventionTab from './Pages/PepInterventionTab/Index';
import PepCaseNotesTab from './Pages/PepCaseNotesTab/Index';
import PepUascTab from './Pages/PepUascTab/Index';
import PepAddNewSchoolTab from './Pages/PepAddNewSchoolTab/Index';
import Exclusions from '../../Exclusions/Index';

const BasicInfoRoute = () => {
  const { state: mainContext } = useContext(MainContext);
  
  return (
    <Switch>

      

      <PrivateRouter 
        exact
        path={ routeUrl.basic_info_doc }
        component={ PepBasicDocsTab }
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter 
        exact
        path={ routeUrl.basic_info_attendance }
        component={ PepAttendanceTab }
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter 
        exact
        path={ routeUrl.basic_info_prior_attainment }
        component={ PepPriorAttainmentTab }
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter
        exact
        path={routeUrl.basic_info_contacts}
        component={PepContactsTab}
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter
        exact
        path={routeUrl.basic_info_pep_alt_ed_neet}
        component={PepAltEdNeetTab}
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter
        exact
        path={routeUrl.basic_info_pep_intervention}
        component={PepInterventionTab}
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter
        exact
        path={routeUrl.basic_info_exclusions}
        component={Exclusions}
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter
        exact
        path={routeUrl.basic_info_case_notes}
        component={PepCaseNotesTab}
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter
        exact
        path={routeUrl.basic_info_uasc}
        component={PepUascTab}
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter
        exact
        path={routeUrl.basic_info_add_new_school}
        component={PepAddNewSchoolTab}
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />

      <PrivateRouter
        path="/"
        component={PepBasicPupilTab}
        isAuthinticated={mainContext.isAuthinticated}
        roleId={mainContext.roleId}
      />
      
      


    </Switch>
  );
}

export default BasicInfoRoute;
