import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FooterLink extends Component {
  render() {
    return (
      <Link to={`${this.props.link}`}>
        <li className="flex flex-row items-center">
          <span>{this.props.linkName}</span>
        </li>
      </Link>
    );
  }
}

export default FooterLink;
