import React from 'react'
import Skeleton from 'react-loading-skeleton'

const StrengthAndNeedSkeleton = () => {
  return (
    <>
      <div className = "rounded-3xl bg-white p-4 overflow-hidden" >
        <div className="flex flex-col gap-4 w-full">
          <div className="w-full">
            <Skeleton height={20} />
          </div>
          <div className="flex gap-8 items-center w-full">
            <div className="w-48">
              <Skeleton height={20} />
            </div>
            <div className="w-full">
              <Skeleton height={20} />
            </div>
          </div>
          <div className="flex gap-5 w-full">
            <div className='w-full p-3'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-3'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-3'>
              <Skeleton height={20} />
            </div>
          </div>
          <div className="flex gap-5 w-full">
            <div className='w-full p-6'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-6'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-6'>
              <Skeleton height={20} />
            </div>
          </div>
        </div>

      </div>
      <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
        <div className="flex flex-col gap-4 w-full">
          <div className="w-full">
            <Skeleton height={20} />
          </div>
          <div className="flex gap-8 items-center w-full">
            <div className="w-48">
              <Skeleton height={20} />
            </div>
            <div className="w-full">
              <Skeleton height={20} />
            </div>
          </div>
          <div className="flex gap-5 w-full">
            <div className='w-full p-3'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-3'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-3'>
              <Skeleton height={20} />
            </div>
          </div>
          <div className="flex gap-5 w-full">
            <div className='w-full p-6'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-6'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-6'>
              <Skeleton height={20} />
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
        <div className="flex flex-col gap-4 w-full">
          <div className="w-full">
            <Skeleton height={20} />
          </div>
          <div className="flex gap-8 items-center w-full">
            <div className="w-48">
              <Skeleton height={20} />
            </div>
            <div className="w-full">
              <Skeleton height={20} />
            </div>
          </div>
          <div className="flex gap-5 w-full">
            <div className='w-full p-3'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-3'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-3'>
              <Skeleton height={20} />
            </div>
          </div>
          <div className="flex gap-5 w-full">
            <div className='w-full p-6'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-6'>
              <Skeleton height={20} />
            </div>
            <div className='w-full p-6'>
              <Skeleton height={20} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StrengthAndNeedSkeleton
