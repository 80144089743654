import { Field } from 'formik'
import React from 'react'
import { routeUrl } from '../utils/RouteUrl';

const CompleteButton = ({
  completeButtonLoader,
  fieldName,
}) => {
  const name = "";
  return (
    <Field name={fieldName}>
      {
        ({ field, form }) => {
          const a = field;
          return (
            <button
              type="submit"
              className='blue-button text-sm px-4 py-2'
              disabled={completeButtonLoader ? true : false}
            // onClick={() => onClickHandler(form.values)}
            >
            {
              completeButtonLoader ?
                <div className="spinner"></div> :
                "Complete"
                  
            }
            </button>
          )
        }
      }

    </Field>
  )
}

export default CompleteButton