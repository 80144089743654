
import React from 'react';
  
const Loader = () => {
  return (
    <div className="h-screen w-ascreen flex justify-center items-center fixed h-full w-full top-0 left-0 right-0 bottom-0 z-10">
      <div className="loader"></div>
    </div>
  );
}
  
  export default Loader;
  