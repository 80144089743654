export const exclusionData = [

    {
        "id" : 1,
        "name" : "Physical assault against a pupil",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Fighting"
           },
           {
               "id" : 2,
               "name" : "Violent behaviour"
           },
           {
               "id" : 3,
               "name" : "Wounding"
           },
           {
               "id" : 4,
               "name" : "Obstruction and jostling"
           }
        ]
    },
    {
        "id" : 2,
        "name" : "Physical assault against an adult",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Violent behaviour"
           },
           {
               "id" : 2,
               "name" : "Wounding"
           },
           {
               "id" : 3,
               "name" : "Obstruction and jostling"
           }
        ]        
    },
    {
        "id" : 3,
        "name" : "Verbal abuse / threatening behaviour against a pupil",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Threatened violence"
           },
           {
               "id" : 2,
               "name" : "Aggressive behaviour"
           },
           {
               "id" : 3,
               "name" : "Swearing"
           },
           {
               "id" : 4,
               "name" : "Verbal intimidation"
           }
        ]        
    },
    {
        "id" : 4,
        "name" : "Verbal abuse / threatening behaviour against an adult",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Threatened violence"
           },
           {
               "id" : 2,
               "name" : "Aggressive behaviour"
           },
           {
               "id" : 3,
               "name" : "Swearing"
           },
           {
               "id" : 4,
               "name" : "Verbal intimidation"
           }
        ]        
    },
    {
        "id" : 5,
        "name" : "Use or threat of use of an offensive weapon or prohibited item",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Carrying or bringing onto the school site an offensive weapon / prohibited item such as knives sharp instruments and BB guns"
           },
           {
               "id" : 2,
               "name" : "Carrying any article that has been or is likely to be used to commit an offence cause personal injury or damage to property"
           },
           {
               "id" : 3,
               "name" : "Use of an offensive weapon"
           }

        ]        
    },
    {
        "id" : 6,
        "name" : "Bullying",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Verbal"
           },
           {
               "id" : 2,
               "name" : "Physical"
           },
           {
               "id" : 3,
               "name" : "Cyber bullying"
           }
        ]        
    },
    {
        "id" : 7,
        "name" : "Racist abuse",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Racist taunting and harassment"
           },
           {
               "id" : 2,
               "name" : "Derogatory racist statements"
           },
           {
               "id" : 3,
               "name" : "Swearing that can be attributed to racist characteristics"
           },
           {
               "id" : 4,
               "name" : "Racist bullying"
           },
           {
               "id" : 4,
               "name" : "Racist graffiti"
           }
        ]        
    },
    {
        "id" : 8,
        "name" : "Abuse against sexual orientation and gender identity",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Homophobic biphobic and transphobic bullying"
           },
           {
               "id" : 2,
               "name" : "LGBT+ graffiti"
           },
           {
               "id" : 3,
               "name" : "LGBT+ taunting and harassment"
           },
           {
               "id" : 4,
               "name" : "Swearing that can be attributed to LGBT+ characteristics"
           }
        ]        
    },
    {
        "id" : 9,
        "name" : "Abuse relating to disability",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Derogatory statements or swearing about a disability"
           },
            {
               "id" : 2,
               "name" : "Bullying related to disability"
           },
            {
               "id" : 3,
               "name" : "Disability related graffiti"
           },
            {
               "id" : 4,
               "name" : "Disability related taunting and harassment"
           }              
        ]        
    },
    {
        "id" : 10,
        "name" : "Sexual misconduct",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Sexual abuse"
           },
           {
               "id" : 2,
               "name" : "Sexual harassment"
           },
           {
               "id" : 3,
               "name" : "Sexual assault"
           },
           {
               "id" : 4,
               "name" : "Lewd behaviour"
           },
           {
               "id" : 5,
               "name" : "Sexual bullying"
           },
           {
               "id" : 6,
               "name" : "Sexual graffiti"
           }
        ]        
    },
    {
        "id" : 11,
        "name" : "Drug and alcohol related",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Possession of illegal drugs"
           },
           {
               "id" : 2,
               "name" : "Inappropriate use of prescribed drugs"
           },
           {
               "id" : 3,
               "name" : "Drug dealing"
           },

           {
               "id" : 4,
               "name" : "Smoking"
           },
           {
               "id" : 5,
               "name" : "Alcohol abuse"
           },
           {
               "id" : 6,
               "name" : "Substance abuse"
           }
        ]        
    },
    {
        "id" : 12,
        "name" : "Damage",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Damage includes damage to school or personal property belonging to any member of the school community"
           },
           {
               "id" : 2,
               "name" : "Vandalism"
           },
           {
               "id" : 3,
               "name" : "Arson"
           },
           {
               "id" : 4,
               "name" : "Graffiti"
           }

        ]        
    },
    {
        "id" : 13,
        "name" : "Theft",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Stealing school property"
           },
           {
               "id" : 2,
               "name" : "Stealing personal property (pupil or adult)"
           },
           {
               "id" : 3,
               "name" : "Stealing from local shops on a school outing"
           },
           {
               "id" : 4,
               "name" : "Selling and dealing in stolen property"
           }
        ]        
    },
    {
        "id" : 14,
        "name" : "Persistent disruptive behaviour",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Challenging behaviour"
           },
           {
               "id" : 2,
               "name" : "Disobedience"
           },
           {
               "id" : 3,
               "name" : "Persistent violation of school rules"
           },
           {
               "id" : 4,
               "name" : "Raising of fire alarms falsely"
           }
        ]        
    },
    {
        "id" : 15,
        "name" : "Inappropriate use of social media or online technology",
        "sub_reason" : [
           {
               "id" : 1,
               "name" : "Sharing of inappropriate images (of adult or pupil)"
           },
            {
               "id" : 2,
               "name" : "Cyber bullying or threatening behaviour online"
           },
                      {
               "id" : 3,
               "name" : "Organising or facilitating criminal behaviour using social media"
           }
        ]        
    }
    ,
    {
        "id" : 16,
        "name" : "Others",
        "sub_reason" : []        
    }
    
    

    
]