import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { ToastContainer, toast } from "react-toastify";
import {  useParams } from "react-router-dom"; 
//Import plus icon from react-native icon
import { FiEdit3 } from "react-icons/fi";
import { FiTrash } from "react-icons/fi";

import ActivateLink from "../../Components/ActivateLink";

//React multi select for main reason
import Multiselect from "multiselect-react-dropdown";

//React date picker
import DatePicker from "react-datepicker";

//import sexclusion reason from moc file
import { exclusionData } from "../../mockdata/ExlusionReason";
import axios from "axios";

import FileSaver from "file-saver";
import moment from "moment";
// Sweet Alert Componen
import Swal from "sweetalert2";
import PupilContext from "../../Components/context/PupilContext";
import { routeUrl } from "../../Components/utils/RouteUrl";
import ComponentHeader from "../../Components/ComponentHeader";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useActivateTabLink from "../../hooks/useActivateTabLink";
import { settings } from "../../Components/utils/Settings";
import useActivateTabBasicInfo from "../../hooks/useActivateTabBasicInfo";

const Exclusions = (props) => {

//  const params = useParams(); 
//  const newdatedata= atob(params?.id)
//  const dateupdate = JSON.stringify(newdatedata)
//  console.log(atob(params.id))
  // Getting Context Variables
  const {
    activePupilId,
    setActivePupilIdHandler,
    newdate,
    pupilInfo: pupilInfoContext,
    
  } = useContext(PupilContext);
  const history = useHistory();

  console.log(newdate)

  // const myDate = moment(newdate, 'YYYY-MM-DD');
  // console.log(myDate)

  // const uptodate = myDate.format('MM/DD/YYYY')

  //console.log(uptodate)
  //document.getElementById('mydate').value = newdate;
  const schoolId = localStorage.getItem("schoolId");

  const [options, setOptions] = useState([]);
  const [selectedReason, setselectedReason] = useState([]);
  const [startdate, setstartdate] = useState(new Date());
  const [enddate, setenddate] = useState(new Date());
  const [type, settype] = useState("");
  const [comment, setcomment] = useState("");
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [selectedStudentExclusionLoading, setselectedStudentExclusionLoading] =
    useState(false);
  const [selectedStudentExclusionData, setselectedStudentExclusionData] =
    useState(null);
  const [formLoading, setformLoading] = useState(false);
  const [exportLoading, setexportLoading] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [deleteExclusionId, setdeleteExclusionId] = useState(null);
  const [selectedStudent, setselectedStudent] = useState(activePupilId);
  const [Dates, setDates] = useState('');
  const [Saved, setSaved] = useState(false);
  //change window/page title
  useDocumentTitle("Exclusion");

  // Activating the navbar tab with current component
  useActivateTabLink(routeUrl.exclusions, true);

  // Activating exlusion in basic info
  useActivateTabBasicInfo(routeUrl.basic_info_exclusions);

  // const inputDate = moment(newdate, 'DD/MM/YYYY');
  // const currentTime = moment();
  // const timeZoneOffset = '(India Standard Time)';

  // console.log(timeZoneOffset)
  
  // // Combine the input date with the current time
  // const combinedDateTime = inputDate.set({
  //   hour: currentTime.hours(),
  //   minute: currentTime.minutes(),
  //   second: currentTime.seconds()
  // });
  
  // // Format the combined date and time with the current time zone offset
  // const formattedDate = combinedDateTime.format(`ddd MMM DD YYYY HH:mm:ss [GMT]ZZ $`);

  // console.log(formattedDate,'00000',startdate)
// console.log(newdate,'0000000000')
// const dateString = newdate;
// const format = 'DD/MM/YYYY';

// const parsedDate = moment(dateString, format, true); // The true flag enables strict parsing.






  useEffect(() => {
    setOptions(exclusionData);
    setselectedStudentExclusionLoading(true);
   
    // setstartdate(newdate)

    // if (parsedDate.isValid()) {
    //   const formattedDate = parsedDate.format('ddd DD MMM YYYY HH:mm:ss [GMT]ZZ (z)');
    //   setstartdate(moment(formattedDate).format('DD/MM/YYYY'))
    //   console.log(formattedDate);
    // } else {
    //   console.log('Invalid date format');
    // }

    axios
      .get(
        `${settings.API_HOST}/rest/fetchExclusionByPupil?pupilId=${activePupilId}`
      )
      .then((res) => {
        console.log(res.data, "from exclusion");
        setselectedStudentExclusionData(
          res.data.exclusionReasons === null ? [] : res.data.exclusionReasons
        );
        setselectedStudentExclusionLoading(false);
      })
      .catch((e) => {
        toast.warn("Something went wrong please reload and try again", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: false,
        });
        setselectedStudentExclusionData([]);
        setselectedStudentExclusionLoading(false);
      });
  }, [activePupilId, Saved]);

  const onSelect = (selectedList, selectedItem) => {
    setselectedReason(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setselectedReason(selectedList);
  };

  //change date time
  const handleDateChangeStart = (date) => {
    setstartdate(date);
  };

  //change date time
  const handleDateChangeEnd = (date) => {
    setenddate(date);
  };

  const formSubmitSave = async (e) => {
    e.preventDefault();
    const allsub_reason_box = document.getElementsByClassName("sub_reason_box");

    let error = [];
    let mainReasonAsComma = "";
    let subReasonAsComma = "";

    if (type === "") {
      toast.warn("Exclusion type field is required", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: false,
      });

      error.push("Error");
    }

    if (selectedReason.length === 0) {
      toast.warn("At least an exclusion reason is required", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: false,
      });

      error.push("Error");
      return;
    } else {
      selectedReason.map((reson, i) => {
        if (i === 0) {
          mainReasonAsComma = reson.name;
        } else {
          mainReasonAsComma = mainReasonAsComma + ", " + reson.name;
        }
      });
    }

    if (allsub_reason_box.length !== 0) {
      for (let i = 0; i < allsub_reason_box.length; i++) {
        if (allsub_reason_box[i].value === "") {
          toast.warn(
            `Extended ${allsub_reason_box[i].getAttribute(
              "name"
            )} reason field is required`,
            {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: false,
            }
          );
          error.push("Error");
        } else {
          if (i === 0) {
            subReasonAsComma = allsub_reason_box[i].value;
          } else {
            subReasonAsComma =
              subReasonAsComma + ", " + allsub_reason_box[i].value;
          }
        }
      }
    }

    if (startdate === "" || startTime === "") {
      toast.warn("Start date & time field is required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      error.push("Error");
    }

    if (enddate === "" || endTime === "") {
      toast.warn("End date & time field is required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      error.push("Error");
    }

    if (comment === "") {
      toast.warn("Comment field is required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      error.push("Error");
    }

    if (error.length === 0) {
      setformLoading(true);

      const userId = await localStorage.getItem("userId");

      if (userId) {
        const newFormData = new FormData();

       // const dateEnd = moment(enddate,'DD/MM/YYYY')
        //const dateStart = moment(startdate,'DD/MM/YYYY')

        newFormData.append("exclusionReason.type", type);
        newFormData.append("exclusionReason.mainReason", mainReasonAsComma);
        newFormData.append("exclusionReason.extendedReason", subReasonAsComma);
        newFormData.append("exclusionReason.startAmPmTime", startTime);
        newFormData.append("exclusionReason.endAmPmTime", endTime);
        newFormData.append("exclusionReason.comment", comment);
        newFormData.append(
          "exclusionReason.endDates",
          enddate.getDate().toString().padStart(2, "0") +
            "/" +
            (enddate.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            enddate.getFullYear()
        );
        newFormData.append(
          "exclusionReason.startDates",
          startdate.getDate().toString().padStart(2, "0") +
            "/" +
            (startdate.getMonth() + 1).toString().padStart(2, "0") +
            "/" +
            startdate.getFullYear()
        );
        newFormData.append("exclusionReason.userId", userId);
        newFormData.append("exclusionReason.pupilId", activePupilId);
        newFormData.append("schoolId", schoolId);

        axios
          .post(`${settings.API_HOST}/rest/saveExclusionReason`, newFormData)
          .then((res) => {
            //reset boxes value

            if (allsub_reason_box.length !== 0) {
              for (let i = 0; i < allsub_reason_box.length; i++) {
                allsub_reason_box[i].value = "";
              }
            }

            setformLoading(false);
            setselectedReason([]);
            setselectedStudentExclusionData(
              res.data.exclusionReasons === null
                ? []
                : res.data.exclusionReasons
            );

            document.getElementById("exclusion_type").value = "";
            document.getElementById("start_time").value = "";
            document.getElementById("end_time").value = "";
            document.getElementById("ex_comment").value = "";

            toast.success("Exclusion data successfully saved!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
            });
            setSaved(true);
          })
          .catch((e) => {
            console.log(e);

            toast.error("Sorry something went wrong!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            setformLoading(false);
          });
      } else {
        window.location.href = "/login";
      }
    }
  };

  //bulk export
  const exportDataAsFile = (e) => {
    setexportLoading(true);

    axios({
      url: `${settings.API_HOST}/rest/exportExcelExclusion?pupilId=${selectedStudent}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? `${pupilInfoContext.fullname}_exclusion_data.xlsx`
            : suggestedFileName;
        FileSaver.saveAs(response.data, effectiveFileName);
        toast.success("Exclusion report exported and downloaded successfull", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });

        setexportLoading(false);
      })
      .catch((e) => {
        console.log(e);

        toast.error("Sorry something went wrong ! Try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
        setexportLoading(false);
      });
  };

  //delete exclusion
  const deleteExclusion = (id) => {
    setdeleteExclusionId(id);
    setdeleteLoading(true);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const userId = await localStorage.getItem("userId");

        const newFormData = new FormData();

        newFormData.append("exclusionReason.id", id);
        newFormData.append("exclusionReason.pupilId", selectedStudent);
        newFormData.append("exclusionReason.userId", userId);

        axios
          .post(`${settings.API_HOST}/rest/deleteExclusionReason`, newFormData)
          .then((res) => {
            toast.success("Exclusion deleted successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });

            const newExclusionData = selectedStudentExclusionData.filter(
              (exclusionData) => exclusionData.id !== id
            );

            setdeleteExclusionId(null);
            setdeleteLoading(false);
            setselectedStudentExclusionData(newExclusionData);
          })
          .catch((e) => {
            toast.error("Sorry something went wrong!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });

            setdeleteLoading(false);
            setdeleteExclusionId(null);
          });
      } else {
        console.log("ok");
        setdeleteLoading(false);
        setdeleteExclusionId(null);
      }
    });
  };

  const currentDate = JSON.stringify(startdate)
  const defaultDate = JSON.stringify(newdate)

  return (
    <div className="flex-1 bg-container-custom rounded-custom-content padding-custom-content">
      <ToastContainer />

      <div className="flex justify-between items-center">
        <ComponentHeader />
        {/* <div className="left_action_button flex flex-row">
                    <div className="export_button">
                        <button onClick={(e) => exportDataAsFile()}>
                            {exportLoading ? (
                                <div className="spinner"></div>
                            ) : "Export"}
                        </button>
                    </div>
                </div> */}
      </div>

      <div className="bg-white tableview flex justify-center flex-col items-center mt-5 sm:overflow-x-scroll md:overflow-x-auto">
        <div className="exclusion-form-field text-field mb-2">
          <div className="form-field-label-exclusion grid-col-3">
            <label style={{ color: "#4c4d6c", fontSize: "13px" }} htmlFor="">
              Type<span className="red">*</span>
            </label>
          </div>
          <div className="form-field-body">
            <select
              className="rounded-custom-content"
              name="type"
              onChange={(e) => settype(e.target.value)}
              id="exclusion_type"
            >
              <option value="">Select</option>
              <option>Fixed Term</option>
              <option>Lunch Term</option>
              <option>Permanent Term</option>
            </select>
          </div>
        </div>
        <div className="exclusion-form-field text-field mb-2">
          <div className="form-field-label-exclusion grid-col-3">
            <label style={{ color: "#4c4d6c", fontSize: "13px" }} htmlFor="">
              Main Reason<span className="red">*</span>
            </label>
          </div>
          <div className="form-field-body">
            <Multiselect
              options={options}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="name"
              selectionLimit={3}
              closeOnSelect={false}
              selectedValues={selectedReason}
            />
          </div>
        </div>
        {selectedReason.length !== 0 &&
          selectedReason.map(
            (subReason, i) =>
              subReason.sub_reason.length !== 0 && (
                <div className="exclusion-form-field text-field mb-2">
                  <div className="form-field-label-exclusion grid-col-3">
                    <label
                      style={{ color: "#4c4d6c", fontSize: "13px" }}
                      htmlFor=""
                    >
                      {`Extended ${subReason.name} Reason `}
                      <span className="red">*</span>
                    </label>
                  </div>
                  <div className="form-field-body">
                    <select
                      className="rounded-custom-content sub_reason_box"
                      name={subReason.name}
                      defaultValue=""
                    >
                      <option value="">Select</option>
                      {subReason.sub_reason.length !== 0 &&
                        subReason.sub_reason.map((subReasonList) => (
                          <option>{subReasonList.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
              )
          )}

        <div className="exclusion-form-field text-field mb-2">
          <div className="form-field-label-exclusion grid-col-3">
            <label style={{ color: "#4c4d6c", fontSize: "13px" }} htmlFor="">
              Start Date & Time<span className="red">*</span>
            </label>
          </div>
          <div className="form-field-body">
            <div className="flex flex-row items-center w-full startDateTime">
              <div>
               
               <DatePicker
               
                  selected={startdate}
                  //defaultValue={newdate !== '' ? moment(defaultDate,'dd/mm/yyyy') : ''}
                
                  className="rounded-custom-content"
                  onChange={(date) => handleDateChangeStart(date)}
                  dateFormat="dd/MM/yyyy"
                  
                />
             
                
                
              </div>
              <div className="md:ml-8 sm:ml-2">
                <select
                  className="rounded-custom-content"
                  onChange={(e) => setstartTime(e.target.value)}
                  defaultValue={startTime}
                  id="start_time"
                >
                  <option value="">Select</option>
                  <option>AM</option>
                  <option>PM</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="exclusion-form-field text-field mb-2">
          <div className="form-field-label-exclusion grid-col-3">
            <label style={{ color: "#4c4d6c", fontSize: "13px" }} htmlFor="">
              End Date & Time<span className="red">*</span>
            </label>
          </div>
          <div className="form-field-body">
            <div className="flex flex-row items-center w-full startDateTime">
              <div>
                <DatePicker
                 // defaultValue={moment(enddate,"DD/MM/YYYY")}
                 selected ={enddate}
                  className="rounded-custom-content"
                  onChange={(date) => handleDateChangeEnd(date)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="md:ml-8 sm:ml-2">
                <select
                  className="rounded-custom-content"
                  onChange={(e) => setendTime(e.target.value)}
                  defaultValue={endTime}
                  id="end_time"
                >
                  <option value="">Select</option>
                  <option>AM</option>
                  <option>PM</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="exclusion-form-field text-field mb-2">
          <div className="form-field-label-exclusion grid-col-3">
            <label style={{ color: "#4c4d6c", fontSize: "13px" }} htmlFor="">
              Comments<span className="red">*</span>
            </label>
          </div>
          <div className="form-field-body">
            <textarea
              defaultValue={comment}
              onChange={(e) => setcomment(e.target.value)}
              className="p-4 outile-none focus:outline-none w-full comment_input commentSect"
              rows="3"
              placeholder="Comment"
              style={{ resize: "none" }}
              id="ex_comment"
            ></textarea>
          </div>
        </div>

        <div className="exclusion-form-field text-field mb-2">
          <div className="form-field-label-exclusion grid-col-3"></div>
          <div className="form-field-body w-full">
            <button
              className="form_submit_button rounded-custom-content flex flex-row justify-center items-center"
              onClick={(e) => !formLoading && formSubmitSave(e)}
              style={{ minWidth: "120px" }}
            >
              {formLoading ? <div className="spinner"></div> : "Submit"}
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          selectedStudentExclusionLoading
            ? "mt-6 tableview bg-white fix-height "
            : "mt-6 tableview bg-white "
        }
      >
        {selectedStudentExclusionData !== null ? (
          <Fragment>
            <div className="export_button_ex mb-3 flex flex-row justify-end exportBut">
              <button
                className="flex flex-row justify-center items-center "
                onClick={(e) => exportDataAsFile(e)}
                style={{ minWidth: "120px" }}
              >
                {exportLoading ? <div className="spinner"></div> : "Export"}
              </button>
            </div>

            <div className="comment-table mt-6 sm:overflow-x-scroll md:overflow-x-auto">
              <table className="table-auto w-100 exclusion-table">
                <thead>
                  <tr>
                    <th style={{ width: "85px" }}></th>
                    <th style={{ maxWidth: "250px" }}>Type</th>
                    <th style={{ maxWidth: "120px" }}>Main Reason</th>
                    <th className="">Extended Reason</th>
                    <th className="">Start Date</th>
                    <th className="" style={{ maxWidth: "150px" }}>
                      Start Time
                    </th>
                    <th className="">End Date</th>
                    <th className="" style={{ maxWidth: "150px" }}>
                      End Time
                    </th>
                    <th className="">Comments</th>
                  </tr>
                </thead>
                {/* {console.log(selectedStudentExclusionData)} */}
                <tbody className="mt-4">
                  {selectedStudentExclusionData.map((eclusion, i) => (
                    <tr key={i} className="">
                      <td style={{ paddingLeft: "10px" }}>
                        <div className="flex flex-row items-center ">
                          <span className="mr-4">
                            <Link to={`/exclusion/edit/${eclusion.id}`}>
                              <FiEdit3 />
                            </Link>
                          </span>
                          <span>
                            {deleteExclusionId === eclusion.id &&
                            deleteLoading ? (
                              <div className="spinner"></div>
                            ) : (
                              <FiTrash
                                onClick={() => deleteExclusion(eclusion.id)}
                                className="cursor-pointer"
                              />
                            )}
                          </span>
                        </div>
                      </td>
                      <td>{eclusion.type}</td>
                      <td>{eclusion.mainReason}</td>

                      <td>{eclusion.extendedReason}</td>
                      <td>{eclusion.startDates}</td>
                      <td>{eclusion.startAmPmTime}</td>
                      <td>{eclusion.endDates}</td>
                      <td>{eclusion.endAmPmTime}</td>
                      <td>{eclusion.comment}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : (
          <Skeleton count={4} height={50} />
        )}
      </div>
    </div>
  );
};
export default Exclusions;
