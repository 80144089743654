import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import Modal from '../../../Components/Modal'
import Pagination from '../../../Components/Pagination';
import { showWarningToast } from '../../../Components/utils/utilityFunctions';

const AssessmentSubjectListModal = ({
  isOpen,
  closeModal,
  subjectList,
  indexForKs4Subject,
  saveField,
}) => {

  const filterSubjectList = () => {
    const regex = new RegExp(search, "i");
    const filterList = subjectList?.filter(item => regex.test(item[1]));
    return filterList;
  }

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;

  const filteredSubjectList = filterSubjectList();

  let currentResults = filteredSubjectList && filteredSubjectList.slice(indexOfFirstResult, indexOfLastResult);

  const paginate = (num) => setCurrentPage(num);

  const selectSubjectName = (form, item) => {
    if (!isLoading) {
      if (form.values.assessmentDate) {
        const subjectName = item[1];
        const subjectGroup = item[3];
        const values = { ...form.values }
        values.ks4Subjects[indexForKs4Subject].subject = subjectName;
        values.ks4Subjects[indexForKs4Subject].groupName = subjectGroup;
        form.setFieldValue(`ks4Subjects[${indexForKs4Subject}].subject`, subjectName);
        form.setFieldValue(`ks4Subjects[${indexForKs4Subject}].groupName`, subjectGroup);
        closeModal();
        saveField({
          values,
          setIsLoading: setLoading,
        })
      } else {
        showWarningToast("Please select assessment meeting first")
      }
    }
  }

  const setLoading = (flag) => {
    if (flag === false) {
    }
    setIsLoading(flag);
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
    >

      <div className="subjectList-modal dark-font">
        <div className='flex justify-between pb-1 mb-4 border-b border-gray-200'>
          <h2 className='font-bold block header-icon w-100'>KS4 Subjects
            <span className="close-btn"  >
              <i className="fa-solid fa-xmark" onClick={closeModal}></i>
            </span>
          </h2>
          {/* <button>Add new Subject</button> */}
        </div>
        <div className='flex justify-between items-center mb-4 border-b border-gray-200 pb-3'>
          <div className=''>
            <span className="pr-3 text-sm">Show</span>
            <select
              className="rounded-custom-content border p-1 text-sm border-gray-200"
              value={resultsPerPage}
              onChange={(e) => setResultsPerPage(parseInt(e.target.value))}
            >
              <option value=""></option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="pl-3 text-sm">entries</span>
          </div>
          <div className="flex gap-5 items-center ">
            <span>Search</span>
            <div className="relative flex items-center">
              <input
                type="text"
                className="border rounded-full border-gray-300 p-2 text-sm"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <table className="modal-table">
          <thead>
            <tr>
              <th>Subject Name</th>
              <th>Group</th>
            </tr>
          </thead>
          <tbody>
            <Field>
              {
                ({ form }) => {
                  return (
                    currentResults && currentResults.map((item) => {
                      return (
                        <tr key={item[0]} onClick={() => selectSubjectName(form, item)}>
                          {
                            /* isLoading ?;
                              <td colSpan={2}>;
                                <Skeleton height={30} className="w-full" />;
                              </td> :; */
                              <>
                                <td>{item[1]}</td>
                                <td>{item[3]}</td>
                              </>

                          }
                        </tr>
                      )
                    })
                  )
                }
              }
            </Field>
          </tbody>
        </table>
        <Pagination
          resultsPerPage={resultsPerPage}
          totalRecords={
            filteredSubjectList ? filteredSubjectList.length : 0
          }
          paginate={paginate}
          currentPage={currentPage}
          showEntries={true}
        />
      </div>
    </Modal>
  )
}

export default AssessmentSubjectListModal
