import React from 'react'
import { setLoading, setNccStrengthAndNeed, setNccStrengthAndNeedPost16 } from '../../constants/reducerActions'

const strengthAndNeedReducer = (state, action) => {
  const data = action.payload;
  switch(action.type) {
    case setNccStrengthAndNeed:
      return {
        strengthsAndNeeds: data.strengthsAndNeeds,
        isLoading: false,
      }
      // return {
      //   isLoading: false,
      //   additionalLanguage: data.strengthsAndNeeds?.additionalLanguage,
      //   communicationPersonResponsible: data.strengthsAndNeeds?.communicationPersonResponsible,
      //   communicationStrengths: data.strengthsAndNeeds?.communicationStrengths,
      //   communicationStrengthsActionRequired: data.strengthsAndNeeds?.communicationStrengthsActionRequired,
      //   communicationStrengthsNeed: data.strengthsAndNeeds?.communicationStrengthsNeed,
      //   created_date: data.strengthsAndNeeds?.created_date,
      //   dateOfPep: data.strengthsAndNeeds?.dateOfPep,
      //   executiveFunctioningPersonResponsible: data.strengthsAndNeeds?.executiveFunctioningPersonResponsible,
      //   executiveFunctioningStrengths: data.strengthsAndNeeds?.executiveFunctioningStrengths,
      //   executiveFunctioningStrengthsActionRequired: data.strengthsAndNeeds?.executiveFunctioningStrengthsActionRequired,
      //   executiveFunctioningStrengthsNeed: data.strengthsAndNeeds?.executiveFunctioningStrengthsNeed,
      //   id: data.strengthsAndNeeds?.id,
      //   isAdditionalSupportRequired: data.strengthsAndNeeds?.isAdditionalSupportRequired,
      //   isCompleted: data.strengthsAndNeeds?.isCompleted,
      //   isDeleted: data.strengthsAndNeeds?.isDeleted,
      //   mentalHdalthPersonResponsible: data.strengthsAndNeeds?.mentalHdalthPersonResponsible,
      //   mentalHealthStrengths: data.strengthsAndNeeds?.mentalHealthStrengths,
      //   mentaldHalthStrengthsActionRequired: data.strengthsAndNeeds?.mentaldHalthStrengthsActionRequired,
      //   mentaldHalthStrengthsNeed: data.strengthsAndNeeds?.mentaldHalthStrengthsNeed,
      //   nameOfYoungPerson: data.strengthsAndNeeds?.nameOfYoungPerson,
      //   preparingForAdulthoodPersonResponsible: data.strengthsAndNeeds?.preparingForAdulthoodPersonResponsible,
      //   preparingForAdulthoodStrengths: data.strengthsAndNeeds?.preparingForAdulthoodStrengths,
      //   preparingForAdulthoodStrengthsActionRequired: data.strengthsAndNeeds?.preparingForAdulthoodStrengthsActionRequired,
      //   preparingForAdulthoodStrengthsNeed: data.strengthsAndNeeds?.preparingForAdulthoodStrengthsNeed,
      //   pupilId: data.strengthsAndNeeds?.pupilId,
      //   relationshipsStrengths: data.strengthsAndNeeds?.relationshipsStrengths,
      //   relationshipsStrengthsActionRequired: data.strengthsAndNeeds?.relationshipsStrengthsActionRequired,
      //   relationshipsStrengthsNeed: data.strengthsAndNeeds?.relationshipsStrengthsNeed,
      //   relationshipsStrengthsPersonResponsible: data.strengthsAndNeeds?.relationshipsStrengthsPersonResponsible,
      //   schoolId: data.strengthsAndNeeds?.schoolId,
      //   updated_date: data.strengthsAndNeeds?.updated_date,
      // }

    case setNccStrengthAndNeedPost16:
      return {
        strengthsAndNeedsPost16: data.strengthsAndNeedsPost16,
        isLoading: false,
      }
      // return {
      //   additionalLanguage: data.strengthsAndNeedsPost16?.additionalLanguage,
      //   communicationPersonResponsible: data.strengthsAndNeedsPost16?.communicationPersonResponsible,
      //   communicationStrengths: data.strengthsAndNeedsPost16?.communicationStrengths,
      //   communicationStrengthsActionRequired: data.strengthsAndNeedsPost16?.communicationStrengthsActionRequired,
      //   communicationStrengthsNeed: data.strengthsAndNeedsPost16?.communicationStrengthsNeed,
      //   created_date: data.strengthsAndNeedsPost16?.created_date,
      //   dateOfPep: data.strengthsAndNeedsPost16?.dateOfPep,
      //   executiveFunctioningPersonResponsible: data.strengthsAndNeedsPost16?.executiveFunctioningPersonResponsible,
      //   executiveFunctioningStrengths: data.strengthsAndNeedsPost16?.executiveFunctioningStrengths,
      //   executiveFunctioningStrengthsActionRequired: data.strengthsAndNeedsPost16?.executiveFunctioningStrengthsActionRequired,
      //   executiveFunctioningStrengthsNeed: data.strengthsAndNeedsPost16?.executiveFunctioningStrengthsNeed,
      //   id: data.strengthsAndNeedsPost16?.id,
      //   isAdditionalSupportRequired: data.strengthsAndNeedsPost16?.isAdditionalSupportRequired,
      //   isCompleted: data.strengthsAndNeedsPost16?.isCompleted,
      //   isDeleted: data.strengthsAndNeedsPost16?.isDeleted,
      //   mentalHdalthPersonResponsible: data.strengthsAndNeedsPost16?.mentalHdalthPersonResponsible,
      //   mentalHealthStrengths: data.strengthsAndNeedsPost16?.mentalHealthStrengths,
      //   mentaldHalthStrengthsActionRequired: data.strengthsAndNeedsPost16?.mentaldHalthStrengthsActionRequired,
      //   mentaldHalthStrengthsNeed: data.strengthsAndNeedsPost16?.mentaldHalthStrengthsNeed,
      //   nameOfYoungPerson: data.strengthsAndNeedsPost16?.nameOfYoungPerson,
      //   preparingForAdulthoodPersonResponsible: data.strengthsAndNeedsPost16?.preparingForAdulthoodPersonResponsible,
      //   preparingForAdulthoodStrengths: data.strengthsAndNeedsPost16?.preparingForAdulthoodStrengths,
      //   preparingForAdulthoodStrengthsActionRequired: data.strengthsAndNeedsPost16?.preparingForAdulthoodStrengthsActionRequired,
      //   preparingForAdulthoodStrengthsNeed: data.strengthsAndNeedsPost16?.preparingForAdulthoodStrengthsNeed,
      //   problemSolvingFramework: data.strengthsAndNeedsPost16?.problemSolvingFramework,
      //   problemSolvingStrengths: data.strengthsAndNeedsPost16?.problemSolvingStrengths,
      //   problemSolvingStrengthsActionRequired: data.strengthsAndNeedsPost16?.problemSolvingStrengthsActionRequired,
      //   problemSolvingStrengthsNeed: data.strengthsAndNeedsPost16?.problemSolvingStrengthsNeed,
      //   problemSolvingStrengthsPersonResponsible: data.strengthsAndNeedsPost16?.problemSolvingStrengthsPersonResponsible,
      //   pupilId: data.strengthsAndNeedsPost16?.pupilId,
      //   relationshipsStrengths: data.strengthsAndNeedsPost16?.relationshipsStrengths,
      //   relationshipsStrengthsActionRequired: data.strengthsAndNeedsPost16?.relationshipsStrengthsActionRequired,
      //   relationshipsStrengthsNeed: data.strengthsAndNeedsPost16?.relationshipsStrengthsNeed,
      //   relationshipsStrengthsPersonResponsible: data.strengthsAndNeedsPost16?.relationshipsStrengthsPersonResponsible,
      //   schoolId: data.strengthsAndNeedsPost16?.schoolId,
      //   teamWorkingFramework: data.strengthsAndNeedsPost16?.teamWorkingFramework,
      //   teamWorkingStrengths: data.strengthsAndNeedsPost16?.teamWorkingStrengths,
      //   teamWorkingStrengthsActionRequired: data.strengthsAndNeedsPost16?.teamWorkingStrengthsActionRequired,
      //   teamWorkingStrengthsNeed: data.strengthsAndNeedsPost16?.teamWorkingStrengthsNeed,
      //   teamWorkingStrengthsPersonResponsible: data.strengthsAndNeedsPost16?.teamWorkingStrengthsPersonResponsible,
      //   timeManagementFramework: data.strengthsAndNeedsPost16?.timeManagementFramework,
      //   timeManagementStrengths: data.strengthsAndNeedsPost16?.timeManagementStrengths,
      //   timeManagementStrengthsActionRequired: data.strengthsAndNeedsPost16?.timeManagementStrengthsActionRequired,
      //   timeManagementStrengthsNeed: data.strengthsAndNeedsPost16?.timeManagementStrengthsNeed,
      //   timeManagementStrengthsPersonResponsible: data.strengthsAndNeedsPost16?.timeManagementStrengthsPersonResponsible,
      //   travelFramework: data.strengthsAndNeedsPost16?.travelFramework,
      //   travelStrengths: data.strengthsAndNeedsPost16?.travelStrengths,
      //   travelStrengthsActionRequired: data.strengthsAndNeedsPost16?.travelStrengthsActionRequired,
      //   travelStrengthsNeed: data.strengthsAndNeedsPost16?.travelStrengthsNeed,
      //   travelStrengthsPersonResponsible: data.strengthsAndNeedsPost16?.travelStrengthsPersonResponsible,
      //   updated_date: data.strengthsAndNeedsPost16?.updated_date,
      //   isLoading: false,
      // }

    case setLoading:
      return {
        ...state,
        isLoading: data.isLoading,
      }
  }
}

export default strengthAndNeedReducer
