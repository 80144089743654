import { lazy, Suspense, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { AttendanceContextProvider } from "../Components/context/AttendanceContext";
import { PopupContextProvider } from "../Components/context/PopupContext";
import PupilContext, { PupilProvider } from "../Components/context/PupilContext";
import PrivateRouter from "../Components/PrivateRoute";
import { routeUrl } from "../Components/utils/RouteUrl";
import PupilVoice from "../Pages/NCC/PupilVoice/Index";
import NCC from "../Pages/NCC/Index";
import Pep from "../Pages/NCC/Index";
import { Redirect } from "react-router";
import Loader from "../Pages/Pep/PepTarget/components/Loader";
import PepCinCP from "../Pages/Pep/PepCinCP";
import PepESOLData from "../Pages/Pep/PepData/components/PepESOLData";
import PepDashboard from "../Pages/Pep/PepDashboard/Index";

import NCCData from "../Pages/NCC/NCCData/Index";
import NCCMeeting from "../Pages/NCC/NCCMeeting/Index";
import NCCStrength from "../Pages/NCC/StrengthAndNeeds/Index";
import PepIsDue from "../Pages/Pep/PepHeaderFilter/PepIsDue";
import PepIsOverdue from "../Pages/Pep/PepHeaderFilter/PepIsOverdue";
import PepRejected from "../Pages/Pep/PepHeaderFilter/PepRejected";
import PepInadequate from "../Pages/Pep/PepHeaderFilter/PepInadequate";
import PepApproved from "../Pages/Pep/PepHeaderFilter/PepApproved";
import PepApprovedWithAction from "../Pages/Pep/PepHeaderFilter/PepApprovedWithAction";
import PepNotApproved from "../Pages/Pep/PepHeaderFilter/PepNotApproved";
import PepStatus from "../Pages/Pep/PepHeaderFilter/PepStatus";
import AllPep from "../Pages/Pep/PepHeaderFilter/AllPep";
import MyPupils from "../Pages/NCC/MyPupils";
import { useRef } from "react";
import { IsCompletedContextProvider } from "./context/IsCompletedContext";
import { ToastContainer } from "react-toastify";

const NotFound = lazy(() => import("../Pages/NotFound"));
// import NotFound from "../NotFound";

const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy/Index"));
// import PrivacyPolicy from "../PrivacyPolicy/Index";

const TermsAndConditions = lazy(() => import("../Pages/TermsAndCondition/Index"));
// import TermsAndConditions from "../TermsAndCondition/Index";

const Compliance = lazy(() => import("../Pages/Compliance/index"));

const PepFooter = lazy(() => import("../Pages/Pep/PepFooter"));


const PepHeader = lazy(() => import("../Pages/Pep/PepHeader"));
// import PepHeader from "./PepHeader";

const PepMyPupil = lazy(() => import("../Pages/Pep/PepMyPupil"));
// import PepMyPupil from "./PepMyPupil";

const NCCTarget = lazy(() => import("../Pages/NCC/TargetsAndAction/Index"));
// const NCCTarget = lazy(() => import("../Pages/Pep/PepTarget/Index"));
// import PepTarget from "../Pages/Pep/PepTarget/Index";

const PepFinance = lazy(() => import("../Pages/Pep/PepFinance/Index"));
// import PepFinance from "./PepFinance/Index";

const PepDoc = lazy(() => import("../Pages/Pep/PepDoc/Index"));
// import PepDoc from "./PepDoc/Index";

const PepTraining = lazy(() => import("../Pages/Pep/PepTraining/Index"));
// import PepTraining from "./PepTraining/Index";

const ChangePassowrd = lazy(() => import("../Pages/ChangePassword/Index"));
// import ChangePassowrd from "../ChangePassword/Index";

const PepSend = lazy(() => import("../Pages/Pep/PepSend/Index"));
// import PepSend from "./PepSend/Index";

const PepMeeting = lazy(() => import("../Pages/Pep/PepMeeting/Index"));
// import PepMeeting from "./PepMeeting/Index";

const StartNewPep = lazy(() => import("../Pages/Pep/StartNewPep/Index"));
// import StartNewPep from "./StartNewPep/Index";

const ExclusionEdit = lazy(() => import("../Pages/Exclusions/Edit"));
// import ExclusionEdit from "../Exclusions/Edit";

const PepBasicInfo = lazy(() => import("../Pages/NCC/BasicInfo/Index"));
// import PepBasicInfo from "./BasicInfo/Index";

// const PepData = lazy(() => import("../Pages/Pep/PepData/Index"));
// import PepData from "./PepData/Index";

const Pep14To16 = lazy(() => import("../Pages/Pep/Pep14To16/Index"));
// import Pep14To16 from "./Pep14To16/Index";

const Dashboard = lazy(() => import("../Pages/Attendance/Index"));
// import Contact from "../Contact/Index";
const Contact = lazy(() => import("../Pages/Contact/Index"));
// import Exclusions from "../Exclusions/Index";
const Exclusions = lazy(() => import("../Pages/Exclusions/Index"));
// const PupilVoice = lazy(() => import("../Pages/Pep/PupilVoice/Index"));
const CinCp = lazy(() => import("../Pages/Pep/PepCinCP/index"));


const schoolId = localStorage.getItem("schoolId");

const RedirectPage = () => {
  return <Redirect to={routeUrl.pep_status} />;
};

const NCCAuthWrapper = (props) => {
  console.log("In auth wrapper");
  const activePupilYear = localStorage.getItem("activePupilYear");

  const sidebarRef = useRef();
  const ref = useRef({ sidebarRef });

  return (
    <div className="pep-body relative">
      <div className="pep-container pt-4 px-4">
        <ToastContainer />
        <PupilProvider>
          <AttendanceContextProvider>
            <IsCompletedContextProvider>
              <PepHeader ref={ref} />
              <div className="flex flex-row justify-start flex-1 lg:gap-4 main">
                <Suspense
                  fallback={<Loader />}
                >
                  <Switch>
                    <PrivateRouter exact path="/">
                      <NCC
                        Component={RedirectPage}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter exact path={routeUrl.pep_status}>
                      <NCC
                        Component={MyPupils}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.ncc_data}>
                      <NCC
                        hasSideBar={true}
                        Component={NCCData}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        ref={ref}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.ncc_pupil_voice}>
                      <NCC
                        hasSideBar={true}
                        Component={PupilVoice}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        ref={ref}
                      />
                    </PrivateRouter>



                    <PrivateRouter path={routeUrl.pep_dashboard}>
                      <Pep
                        hasSideBar={true}
                        Component={PepDashboard}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        schoolPortalList={props.schoolPortalList}
                        ref={ref}
                      />
                    </PrivateRouter>



                    <PrivateRouter
                      exact
                      path={`/exclusion/edit/:id`}
                      hasSideBar={false}
                      component={ExclusionEdit}
                      isAuthinticated={props.isAuthinticated}
                      roleId={props.roleId}
                    // ref={ref}
                    />

                    <PrivateRouter path="/test">
                      <Pep
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.ncc_targets}>
                      <NCC
                        hasSideBar={true}
                        Component={NCCTarget}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        ref={ref}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.finance}>
                      <NCC
                        hasSideBar={true}
                        Component={PepFinance}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        ref={ref}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.doc}>
                      <NCC
                        Component={PepDoc}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.training}>
                      <NCC
                        Component={PepTraining}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path="/esol">
                      <NCC
                        Component={PepESOLData}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.ncc_strength}>
                      <NCC
                        hasSideBar={true}
                        Component={NCCStrength}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        ref={ref}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.ncc_meeting}>
                      <NCC
                        hasSideBar={true}
                        Component={NCCMeeting}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        ref={ref}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.start_new_pep}>
                      <NCC
                        hasSideBar={true}
                        Component={StartNewPep}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        ref={ref}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.pep_cin_cp}>
                      <NCC
                        hasSideBar={true}
                        Component={CinCp}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        ref={ref}
                      />
                    </PrivateRouter>



                    <PrivateRouter path={routeUrl.changePassword}>
                      <NCC
                        Component={ChangePassowrd}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter
                      path={routeUrl.contact_us}
                      component={Contact}
                      isAuthinticated={props.isAuthinticated}
                    />

                    <PrivateRouter
                      path={routeUrl.termsAndConditions}
                      component={TermsAndConditions}
                      isAuthinticated={props.isAuthinticated}
                    />

                    <PrivateRouter
                      path={routeUrl.privacyAndPolicy}
                      component={PrivacyPolicy}
                      isAuthinticated={props.isAuthinticated}
                    />

                    {(() => {
                      if (schoolId && schoolId != 1934) {
                        return (
                          <PrivateRouter path={routeUrl.attendance}>
                            <Pep
                              hasSideBar={false}
                              Component={Dashboard}
                              isAuthinticated={props.isAuthinticated}
                              roleId={props.roleId}
                            />
                          </PrivateRouter>
                        )
                      }
                    })()}

                    <PrivateRouter
                      path={routeUrl.compliance}
                      component={Compliance}
                      isAuthinticated={props.isAuthinticated}
                    />

                    <PrivateRouter path={routeUrl.basic_info}>
                      <NCC
                        hasSideBar={true}
                        Component={PepBasicInfo}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                        ref={ref}
                      />
                    </PrivateRouter>

                    {/* Header Filters routing start */}
                    <PrivateRouter path={routeUrl.pep_is_due}>
                      <NCC
                        hasSideBar={false}
                        Component={PepIsDue}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.pep_is_overdue}>
                      <NCC
                        hasSideBar={false}
                        Component={PepIsOverdue}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.pep_rejected}>
                      <NCC
                        hasSideBar={false}
                        Component={PepRejected}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.pep_inadequate}>
                      <NCC
                        hasSideBar={false}
                        Component={PepInadequate}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.pep_approved}>
                      <NCC
                        hasSideBar={false}
                        Component={PepApproved}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.pep_approved_with_action}>
                      <NCC
                        hasSideBar={false}
                        Component={PepApprovedWithAction}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>

                    <PrivateRouter path={routeUrl.pep_not_approved}>
                      <NCC
                        hasSideBar={false}
                        Component={PepNotApproved}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>
                    <PrivateRouter path={routeUrl.pep_status_filter}>
                      <NCC
                        hasSideBar={false}
                        Component={PepStatus}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>
                    <PrivateRouter path={routeUrl.all_peps}>
                      <NCC
                        hasSideBar={false}
                        Component={AllPep}
                        isAuthinticated={props.isAuthinticated}
                        roleId={props.roleId}
                      />
                    </PrivateRouter>
                    {/* Header Filter routing end */}

                    <Route component={NotFound} />
                  </Switch>
                </Suspense>
              </div>
              <PepFooter />
            </IsCompletedContextProvider>
          </AttendanceContextProvider>
        </PupilProvider>
      </div>
    </div>
  );
};

export default NCCAuthWrapper;
