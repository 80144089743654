import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { useReducer } from 'react';
import { useEffect } from 'react'
import Swal from 'sweetalert2';
import IsCompletedContext from '../../../../Components/context/IsCompletedContext';
import PupilContext from '../../../../Components/context/PupilContext';
import CompleteButton from '../../../../Components/NCC/CompleteButton';
import PupilVoiceHoc from '../../../../Components/NCC/PupilVoice/PupilVoiceHoc';
import PupilVoicePost16Skeleton from '../../../../Components/NCC/PupilVoice/PupilVoicePost16Skeleton';
import TextAreaField from '../../../../Components/NCC/TextAreaField';
import { capatalizeFirstLetter, checkForForTrimmedEmptyValue, getYearGroup, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions';
import { NCCReportApi } from '../../../../constants/apiUrls';
import { setLoading, setNccPupilVoicePost16 } from '../../../../constants/reducerActions';
import PupilVoiceReducer from '../../../../reducers/NCC/PupilVoiceReducer';
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader';

const initialState = {
  post16PupilVoice: null,
  isLoading: false,
}

const NCCPupilVoicePost16 = ({
  saveForm,
  onChangeHandler,
  numberOnChangeHandler,
  dateChangeHandler,
  exportPdf,
  formValidationWrapper,
  isCompletedCallback
}) => {
  const reportName = "nccPost16Pupilvoice";
  const fullPepPdfReportName = "NccFullPep";
  const objectName = "post16PupilVoice";

  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");

  const { dateOfMeeting, pupilInfo } = useContext(PupilContext);
  const {setIsCompleted} = useContext(IsCompletedContext);

  const [state, dispatch] = useReducer(PupilVoiceReducer, initialState);

  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);
  
  useEffect(() => {
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      dispatch({ type: setLoading, payload: { isLoading: true } });
      const url = NCCReportApi.getPupilVoicePost16({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      axios.get(url)
        .then(res => {
          dispatch({ type: setNccPupilVoicePost16, payload: res.data })
          console.log(res.data, "from pupil voice post 16");
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.", { position: "bottom-right" })
    }
    
  }, [dateOfMeeting])

  const initialFormState = {
    post16PupilVoice: state.post16PupilVoice,

  }

  // const isCompletedCallback = () => {
  //   setIsCompleted("pupilVoiceIsCompleted", true);
  //   Swal.fire({
  //     iconColor: "#3daf49",
  //     iconHtml: '<i className="fa-regular fa-check"></i>',
  //     title: 'Data Primary Completed',
  //     showConfirmButton: false,
  //     timer: 1500
  //   })
  // }

  const successCallback = (values) => {
    values[objectName]["isCompleted"] = 1;
  }

  const checkForErrors = (values) => {
    let error = "";

    if (checkForForTrimmedEmptyValue(values.post16PupilVoice.childDreamsAndFutureGoals)) {
      error = "'Young person's hopes, dreams and future goals' is required";
    }

    return error;
  }

  const formValidaton = formValidationWrapper({
    saveForm,
    setIsCompleted,
    fieldName: "post16PupilVoice.isCompleted",
    objectName,
    successCallback,
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: () => showReportCompleteSuccess("Pupil Voice POST-16 Completed"),
  });

  return (
    <Formik
        initialValues={initialFormState}
        enableReinitialize
        onSubmit={formValidaton}
      >
        <Form>
          <PepSecondaryHeader
            heading={`NCC Pupil Voice  ${capatalizeFirstLetter(getYearGroup(pupilInfo.classYear))}`}
            buttons={[
              <CompleteButton
                fieldName={`${objectName}.isCompleted`}
                completeButtonLoader={completeButtonLoader}
              />,
              <div className="export-btn">
                <span className="blue-button px-5 py-2  text-sm">Export</span>
                <ul>
                  <li>
                    <button type="button" onClick={() => exportPdf(fullPepPdfReportName)}>Full Pep</button>
                  </li>
                  <li>
                    <button type="button" onClick={() => exportPdf(reportName)}>PDF</button>
                  </li>
                </ul>
              </div>
            ]}
          />
          {
            state.isLoading ?
              <PupilVoicePost16Skeleton /> :
              <div className='ncc-pupil-voice'>
                <h1 className="pupil-title mb-4">Hopes, dreams and future goals</h1>
                <div className="rounded-3xl bg-white p-4 overflow-hidden">
                  <div className="flex flex-col gap-3">
                    <div className="pb-2">
                      <p className="inline-block font-medium"><b>Young person's hopes, dreams and future goals *</b> e.g. what is important to you? What would you like to do/have in the future? What subjects do you like? What are your interests? What are your career ambitions? What would you like to study at university? Which university would you be interested in? <span className='required'>*</span>  </p>
                      <div className="textarea-block mt-1 w-full">
                        <TextAreaField 
                          fieldName={"post16PupilVoice.childDreamsAndFutureGoals"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl px-2 py-4 textarea textarea-small w-full",
                            rows: 2,
                          }}
                        />
                        {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                    <div className="pb-2">
                      <p className="inline-block font-medium"><b>If our young person is not in the meeting, who has had this conversation with them?</b> </p>
                      <div className="textarea-block mt-1 w-full">
                        <TextAreaField 
                          fieldName={"post16PupilVoice.youngPersonIsNotInTheMeeting"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl px-2 py-4 textarea textarea-small w-full",
                            rows: 2,
                          }}
                        />
                        {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                    <div className="pb-2">
                      <p className="inline-block font-medium"><b>What else does our young person want us to know about education ? record how/who will address anything raised here</b>E.g. what do you enjoy about education/training/employment? What are you good at? What makes you feel safe and comfortable in education/work setting? Is there anything you don't like about education/work ? what needs to be done to make it better? What help do you need at work/education setting to do things you don't already do? Is there anything else you would like to tell or ask us? </p>
                      <div className="textarea-block mt-1 w-full">
                        <TextAreaField 
                          fieldName={"post16PupilVoice.knowAboutEducation"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl px-2 py-4 textarea textarea-small w-full",
                            rows: 2,
                          }}
                        />
                        {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                    <div className="pb-2">
                      <p className="inline-block font-medium">Primary work interest </p>
                      <div className="textarea-block mt-1 w-full">
                        <TextAreaField 
                          fieldName={"post16PupilVoice.primaryWorkInterest"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl px-2 py-4 textarea textarea-small w-full",
                            rows: 2,
                          }}
                        />
                        {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                    <div className="pb-2">
                      <p className="inline-block font-medium">Secondary work interest </p>
                      <div className="textarea-block mt-1 w-full">
                        <TextAreaField 
                          fieldName={"post16PupilVoice.secondaryWorkInterest"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl px-2 py-4 textarea textarea-small w-full",
                            rows: 2,
                          }}
                        />
                        {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
        </Form>
      </Formik>
  )
}

export default PupilVoiceHoc(NCCPupilVoicePost16);
