import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import PupilContext from '../../../../../Components/context/PupilContext';
import { routeUrl } from '../../../../../Components/utils/RouteUrl';
import useActivateTabBasicInfo from '../../../../../hooks/useActivateTabBasicInfo';
import { settings } from '../../../../../Components/utils/Settings';
import Pagination from '../../../../../Components/Pagination';
import { NCCReportApi } from '../../../../../constants/apiUrls';
import { Field, FieldArray, Form, Formik } from 'formik'
import UploadModal from './UploadModal'


const PepBasicDocsTab = ({deleteUploadedFile, downloadUploadedFile}) => {

  useActivateTabBasicInfo(routeUrl.basic_info_doc);
  const { activePupilId, dateOfMeeting, activePupilYear } = useContext(PupilContext);
  const roleId = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const schoolId = localStorage.getItem("schoolId");
  const kidYear = localStorage.getItem("year");
  const [docInfo, setDocInfo, setIsOpen, isOpen] = useState({

  });
  const [documentsDetail, setDocumentDetail] = useState([]);

  const uploadedDocumentListHandler = (data, form) => {
    form.setFieldValue("schoolUploadedFiles", data);
    // dispatch({ type: setUploadedSchoolReports, payload: { schoolReports: data } })
  }

  useEffect(() => {
    activePupilId && axios(`${settings.API_HOST}/rest/pupilDocs?pupilId=${activePupilId}&schoolId=${schoolId}`)

      .then(res => {
        console.log(res.data, "documentsDetail");
        setDocInfo(res.data);
        setDocumentDetail(res.data?.documentsDetail);
      });

  }, [activePupilId]);

  const deleteDoc = (downloadLink, index) => {
    const filteredList = docInfo.documentsDetail.filter((doc, i) => i !== index);

    const url = NCCReportApi.deleteUploadedFileApi({blobKey : downloadLink});
    axios.get(url)
      .then((res) => {
        setDocumentDetail(filteredList);
      })
  }

  return (

    <div className="secondary-section w-full">
      <div className="basic-header flex w-full py-2 justify-between">
        <div className="basic-left">
          <h1>Basic Information
            <span><i className="fa-regular fa-circle-video"></i></span>
            <span><i className="fa-regular fa-circle-info"></i></span>
          </h1>
        </div>
        <div className="basic-right">
          <span><i className="fa-regular fa-user"></i></span>
          <input type="text" className="pep-input" value="ASSET Secondary" />
        </div>
      </div>
      <div className="rounded-3xl bg-white p-4 w-full">
        <div className="table-box w-full pt-2">
          <div className="table-outer rounded-3xl bg-white">
            <table className="table-fixed text-center w-full">
              <thead>
                <tr>
                  <th className="blue w-12"></th>
                  <th className="d-blue w-40">Report Name</th>
                  <th className="purple">Type of Doc</th>
                  <th className="orange">File Type</th>
                  <th className="d-orange">Term of PEP</th>
                  <th className="d-pink">Uploaded Date and Time</th>
                  <th className="pink">Uploaded By</th>
                </tr>
              </thead>
              <tbody>
                {documentsDetail && documentsDetail.map((result, index) => {
                  return (
                    <tr key={index}>
                      <td><i className="fa-regular fa-circle-trash cursor-pointer" onClick={() => deleteDoc(result.downloadLink, index)}></i></td>
                      <td>{result.reportName ? result.reportName : ""}</td>
                      <td>{result.typeOfDoc ? result.typeOfDoc : ""}</td>
                      <td>{result.fileType ? result.fileType : ""}</td>
                      <td>{result.termOfPep ? result.termOfPep : ""}</td>
                      <td>{result.dateOfUplaod ? result.dateOfUplaod : ""}</td>
                      <td>{result.userName ? result.userName : ""}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <br />
            <div className='flex  items-center'>
                    <div className='file file--upload'>
                      <button 
                        type="button" 
                        className="upload-button px-5 py-2 text-sm"
                        // onClick={() => setIsOpen(true)}  
                      >
                        Upload<i className="upload-icon fa-regular fa-cloud-arrow-up"></i>
                      </button>
                    </div>
                    
            </div>
          </div>
        </div>
      </div>
</div>
  );
}

export default PepBasicDocsTab;