import React from 'react'
import { useReducer } from 'react';
import { setAllCompletedStatus, setIsCompleted as setIsCompletedAction } from '../../constants/reducerActions';
import nccIsCompletedContextReducer from '../../reducers/NCC/nccIsCompletedContextReducer';

const initialState = {
  dataIsCompleted: false,
  pupilVoiceIsCompleted: false,
  strengthAndNeedIsCompleted: false,
  meetingIsCompleted: false,
  targetAndActionIsCompleted: false,
}

const IsCompletedContext = React.createContext();

export const IsCompletedContextProvider = ({children}) => {
  
  const [state, dispatch] = useReducer(nccIsCompletedContextReducer, initialState);

  const {
    dataIsCompleted,
    pupilVoiceIsCompleted,
    strengthAndNeedIsCompleted,
    meetingIsCompleted,
    targetAndActionIsCompleted,
  } = state;

  const setIsCompleted = (attribute, isCompleted) => {
    dispatch({ type: setIsCompletedAction, payload: {attribute, isCompleted}})
  }
  
  const setAllStatus = ({data, pupilVoice, strengthAndNeeds, meeting, target}) => {
    dispatch({ type: setAllCompletedStatus, payload: { data, pupilVoice, strengthAndNeeds, meeting, target }})
  }

  return (
    <IsCompletedContext.Provider
      value={{
        dataIsCompleted,
        pupilVoiceIsCompleted,
        strengthAndNeedIsCompleted,
        meetingIsCompleted,
        targetAndActionIsCompleted,
        setIsCompleted,
        setAllStatus,
      }}
    >
      {children}
    </IsCompletedContext.Provider>
  )
}

export default IsCompletedContext