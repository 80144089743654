import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { useReducer } from 'react';
import { useEffect } from 'react'
import PupilContext from '../../../../Components/context/PupilContext';
import StrengthAndNeedHoc from '../../../../Components/NCC/StrengthAndNeed/StrengthAndNeedHoc';
import TextAreaField from '../../../../Components/NCC/TextAreaField';
import { NCCReportApi } from '../../../../constants/apiUrls';
import { setLoading, setNccStrengthAndNeed } from '../../../../constants/reducerActions';
import strengthAndNeedReducer from '../../../../reducers/NCC/strengthAndNeedReducer';
import SelectField from "../../../../Components/NCC/SelectField";
import StrengthAndNeedSkeleton from '../../../../Components/NCC/StrengthAndNeed/StrengthAndNeedSkeleton';
import { checkForForTrimmedEmptyValue, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions';
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader';
import IsCompletedContext from '../../../../Components/context/IsCompletedContext';
import Swal from 'sweetalert2';
import CompleteButton from '../../../../Components/NCC/CompleteButton';


const initialState = {
  strengthsAndNeeds: null,
  isLoading: false,
}

const NCCStrengthAndNeed = ({
  saveForm,
  onChangeHandler,
  exportPdf,
  formValidationWrapper,
}) => {
  const objectName = "strengthsAndNeeds";
  const pdfReportName = "nccStrengthsAndNeeds";
  const fullPepPdfReportName = "NccFullPep";
  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");

  const [state, dispatch] = useReducer(strengthAndNeedReducer, initialState);

  const { dateOfMeeting, pupilInfo } = useContext(PupilContext);
  const {setIsCompleted} = useContext(IsCompletedContext);

  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);
  
  useEffect(() => {
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      dispatch({ type: setLoading, payload: { isLoading: true } });
      const url = NCCReportApi.getStrengthAndNeedReport({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      axios.get(url)
        .then(res => {
          dispatch({ type: setNccStrengthAndNeed, payload: res.data })
          console.log(res.data, "from strength need");
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.", { position: "bottom-right" })
    }
    
  }, [dateOfMeeting])

  const initialFormState = { 
    strengthsAndNeeds: state.strengthsAndNeeds,
  }
  
  const isCompletedCallback = () => {
    setIsCompleted("strengthAndNeedIsCompleted", true);
    showReportCompleteSuccess("Strength & Need Completed");
  }

  const updateIsCompleted = (values) => {
    values[objectName]["isCompleted"] = 1;
  }
  
  const checkForErrors = (values) => {
    let error = "";

    if (checkForForTrimmedEmptyValue(values.strengthsAndNeeds.relationshipsStrengths)) {
      error = "Please fill the required";
    } 

    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeeds.communicationStrengths)){
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeeds.preparingForAdulthoodStrengths)){
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeeds.mentalHealthStrengths)){
      error = "Please fill the required";
    }
    else if (checkForForTrimmedEmptyValue(values.strengthsAndNeeds.mentalHealthStrengths)){
      error = "Please fill the required";
    }

    return error;
  }

  const formValidaton = formValidationWrapper({
    saveForm,
    setIsCompleted,
    fieldName: `${objectName}.isCompleted`,
    objectName,
    successCallback: updateIsCompleted,
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: isCompletedCallback,
  });

  return (
    <div className="strength-data">
      <Formik
        initialValues={initialFormState}
        enableReinitialize
        onSubmit={formValidaton}
      >
        <Form>
          <PepSecondaryHeader
            heading={`NCC Strength & Needs`}
            buttons={[
              <CompleteButton
                fieldName={`${objectName}.isCompleted`}
                completeButtonLoader={completeButtonLoader}
              />,
              <div className="export-btn">
                <span className="blue-button px-5 py-2  text-sm">Export</span>
                <ul>
                  <li>
                    <button type="button" onClick={() => exportPdf(fullPepPdfReportName)}>Full Pep</button>
                  </li>
                  <li>
                    <button type="button" onClick={() => exportPdf(pdfReportName)}>PDF</button>
                  </li>
                </ul>
              </div>
            ]}
          />
         
          {
            state.isLoading ?
              <StrengthAndNeedSkeleton /> :
              <>
                <div className="rounded-3xl bg-white p-4 overflow-hidden">
                  <div className="flex flex-col gap-3">
                    <div className="pb-2">
                      <p className="inline-block font-medium pb-2">Relationships: What are the strengths in our child/young person relationships? Does our child/young person need any support with peer/adult relationships?</p>
                      <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                        <label className='flex gap-1 items-center'>Strengths: <span className="required">*</span></label>
                        <TextAreaField
                          fieldName={"strengthsAndNeeds.relationshipsStrengths"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl p-2 textarea textarea-small w-full"
                          }}
                          rows={2}
                        />
                        {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                  </div>
                  <div className="table-box pt-4 overflow-x-auto">
                    <table className='table-auto w-full'>
                      <thead>
                        <tr>
                          <th className="blue">Need</th>
                          <th className="d-blue">Action required</th>
                          <th className="purple">Person(s) Responsible</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.relationshipsStrengthsNeed"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.relationshipsStrengthsActionRequired"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.relationshipsStrengthsPersonResponsible"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                  <div className="flex flex-col gap-3">
                    <div className="pb-2">
                      <p className="inline-block font-medium pb-2">Communication Skills: How does our child/young person communicate to get their needs met?</p>
                      <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                        <label htmlFor="" className='flex gap-1 items-center'>Strengths: <span className="required">*</span></label>
                        <TextAreaField
                          fieldName={"strengthsAndNeeds.communicationStrengths"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl p-2 textarea textarea-small w-full"
                          }}
                          rows={2}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="table-box pt-4 overflow-x-auto">
                    <table className='table-auto w-full'>
                      <thead>
                        <tr>
                          <th className="blue">Need</th>
                          <th className="d-blue">Action required</th>
                          <th className="purple">Person(s) Responsible</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.communicationStrengthsNeed"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.communicationStrengthsActionRequired"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.communicationPersonResponsible"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                  <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                    <label htmlFor="" className='flex gap-1 items-center'>Does our child/young person have English as an additional language? </label>
                    <SelectField
                      fieldName={"strengthsAndNeeds.additionalLanguage"}
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      optionsList={[
                        <option></option>,
                        <option>Yes</option>,
                        <option>No</option>,
                      ]}
                      
                    />
                    {/* <select className="border-gray-300 border rounded-full p-2 text-sm w-24">
                      <option></option>
                      <option>Yes</option>
                      <option>No</option>
                    </select> */}
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="pb-2">
                      <p className="inline-block font-medium">If yes, is additional support required? Please state.  </p>
                      <div className="textarea-block mt-1 w-full">
                        <TextAreaField
                          fieldName={"strengthsAndNeeds.isAdditionalSupportRequired"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                          }}
                          rows={2}
                        />
                        {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                  <div className="flex flex-col gap-3">
                    <div className="pb-2">
                      <p className="inline-block font-medium pb-2">Mental Health and Emotional Wellbeing: including emotional regulation when our child/young person experiences a stressful situation</p>
                      <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                        <label htmlFor="" className='flex gap-1 items-center'>Strengths: <span className="required">*</span></label>
                        <TextAreaField
                          fieldName={"strengthsAndNeeds.mentalHealthStrengths"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl p-2 textarea textarea-small w-full"
                          }}
                          rows={2}
                        />
                        {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                  </div>
                  <div className="table-box pt-4 overflow-x-auto">
                    <table className='table-auto w-full'>
                      <thead>
                        <tr>
                          <th className="blue">Need</th>
                          <th className="d-blue">Action required</th>
                          <th className="purple">Person(s) Responsible</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.mentaldHalthStrengthsNeed"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.mentaldHalthStrengthsActionRequired"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.mentalHdalthPersonResponsible"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                  <div className="flex flex-col gap-3">
                    <div className="pb-2">
                      <p className="inline-block font-medium pb-2"><b>Executive Functioning Skills: Being able to plan, organise, monitor and carry out tasks throughout the day</b> (e.g. emotional regulation, memory, initiate task, shift from one task to another)</p>
                      <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                        <label htmlFor="" className='flex gap-1 items-center'>Strengths: <span className="required">*</span></label>
                        <TextAreaField
                          fieldName={"strengthsAndNeeds.executiveFunctioningStrengths"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl p-2 textarea textarea-small w-full"
                          }}
                          rows={2}
                        />
                        {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                  </div>
                  <div className="table-box pt-4 overflow-x-auto">
                    <table className='table-auto w-full'>
                      <thead>
                        <tr>
                          <th className="blue">Need</th>
                          <th className="d-blue">Action required</th>
                          <th className="purple">Person(s) Responsible</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.executiveFunctioningStrengthsNeed"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.executiveFunctioningStrengthsActionRequired"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl px-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.executiveFunctioningPersonResponsible"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                  <div className="flex flex-col gap-3">
                    <div className="pb-2">
                      <p className="inline-block font-medium pb-2"><b>Preparing for Adulthood: friends, part of a community, skills to employment, skills to greater independence and skills to good health</b> (e.g. friendships, being part of clubs, playing with money, getting dressed. See guidance for more examples of skills from age 3 upwards)</p>
                      <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                        <label htmlFor="" className='flex gap-1 items-center'>Strengths: <span className="required">*</span></label>
                        <TextAreaField
                          fieldName={"strengthsAndNeeds.preparingForAdulthoodStrengths"}
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          rest={{
                            className: "rounded-2xl p-2 textarea textarea-small w-full"
                          }}
                          rows={2}
                        />
                        {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                      </div>
                    </div>
                  </div>
                  <div className="table-box pt-4 overflow-x-auto">
                    <table className='table-auto w-full'>
                      <thead>
                        <tr>
                          <th className="blue">Need</th>
                          <th className="d-blue">Action required</th>
                          <th className="purple">Person(s) Responsible</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.preparingForAdulthoodStrengthsNeed"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.preparingForAdulthoodStrengthsActionRequired"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                          <td>
                            <TextAreaField
                              fieldName={"strengthsAndNeeds.preparingForAdulthoodPersonResponsible"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "rounded-2xl p-2 textarea textarea-small w-full"
                              }}
                              rows={2}
                            />
                            {/* <textarea className="rounded-2xl p-2 textarea textarea-small w-full" placeholder=""></textarea> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
          }
        </Form>
      </Formik>

    </div>
  )
}

export default StrengthAndNeedHoc(NCCStrengthAndNeed);
