import moment from 'moment'
import React from 'react'

const AltNeedTableY12ToY14 = ({
  pupilInfo
}) => {
  return (
    <div className="rounded-3xl bg-white mt-4 p-4 w-full">
      <div className="py-2 flex w-full items-center">
        <label>This student is receiving 25 hours per week in an Ofsted registered establishment?</label>
        <div className="pl-4 radio">
          <label className="inline-flex items-center">
            <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center ml-6">
            <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>

      <div className="table-box w-full pt-2">
        <div className="table-outer rounded-3xl bg-white">
          <table className="table-fixed text-center w-full">
            <thead>
              <tr>
                <th className="blue">Most Recent education/training/jobe</th>
                <th className="d-blue">Provider/Employer</th>
                <th className="purple w-40">Start Date</th>
                <th className="orange w-40">End Date</th>
              </tr>
            </thead>
            <tbody>
              {/* {pupilInfo.mostRecentEducationArrangements && pupilInfo.mostRecentEducationArrangements.map((result) => {
                return ( */}
                  <tr>
                    <td>
                      <input type="text" className="pep-input textarea-input" />
                    </td>
                    <td>
                      <input type="text" className="pep-input textarea-input" />
                    </td>
                    {/* {(() => {
                      if (result.recentStartDate != null) {
                        return ( */}
                          <td>
                            <input type="text" className="pep-input date-input" />
                          </td>
                        {/* )
                      } else {
                        return ( */}
                          {/* <td>
                            <input type="text" className="pep-input date-input" />
                          </td> */}
                        {/* )
                      }
                    })()} */}

                    {/* {(() => {
                      if (result.recentEndDate != null) {
                        return ( */}
                          <td>
                            <input type="text" className="pep-input date-input" />
                          </td>
                        {/* )
                      } else {
                        return ( */}
                          {/* <td>
                            <input type="text" className="pep-input date-input" />
                          </td> */}
                        {/* )
                      }
                    })()} */}

                  </tr>
                {/* )
              })} */}
            </tbody>
          </table>
        </div>
      </div>
      <div className="py-2 mt-4 flex w-full items-center">
        <div className="w-5/12 flex">
          <label>Is the pupil NEET?</label>
          <div className="pl-4 radio">
            <label className="inline-flex items-center">
              <input type="radio" className="form-radio" name="pupilNeet" />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input type="radio" className="form-radio" name="accountType" />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>
        <div className="w-7/12 flex items-center">
          <label className="w-24">Reason :</label>
          <input type="text" className="pep-input textarea-input" />
        </div>
      </div>
      <div className="py-2 flex w-full items-center">
        <div className="w-5/12 flex">
          <label>Without an effective school place during school year?</label>
        </div>
        <div className="w-7/12 flex items-center">
          <div className="pl-4 radio">
            <label className="inline-flex items-center">
              <input type="radio" className="form-radio" name="neetSchoolYear" />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input type="radio" className="form-radio" name="neetSchoolYear" />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>
      </div>

      <div className="py-4 widebox-container flex flex-wrap justify-between">
        <div className="w-full pb-2">
          <span className="inline-block">Are there any problems with the young person's education, employment or training?</span>
          <div className="textarea-block mt-1">
            <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Type here..." ></textarea>
          </div>
        </div>
        <div className="w-full pb-2">
          <span className="inline-block">If you have any problems, do you know who you can speak to?</span>
          <div className="textarea-block mt-1">
            <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Type here..." ></textarea>
          </div>
        </div>
        <div className="w-full pb-2">
          <span className="inline-block">Please outline any planned changes that are expected between now and the next PEP ie placement move, education move, or increase in family contact.
            Please outline how the young person will be supported throughout this period</span>
          <div className="textarea-block mt-1">
            <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Type here..." ></textarea>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AltNeedTableY12ToY14