import axios from 'axios';
import React, { Component, useEffect, useReducer } from 'react';
import Modal from './Modal';
import Skeleton from 'react-loading-skeleton';
import { FiX } from "react-icons/fi";
import PupilContext from './context/PupilContext';
import { useState } from 'react';
import { useContext } from 'react';
import historyModalReducer from '../reducers/historyModalReducer';
import { setHistoryList  } from '../constants/reducerActions';
import { settings } from './utils/Settings';

const initialState = {
  historyList: [],
  isLoading: true
}

const HistoryModal = (props) => {
  const {activePupilId} = useContext(PupilContext);

  // Create Reducer, and get history state and dispatch for perform state action on history state
  const [historyState, dispatch] = useReducer(historyModalReducer, initialState);

  const { historyList, isLoading } = historyState;

  useEffect(() => {
    axios(`${settings.API_HOST}/rest//rest/fetchPupilHistory?pupilId=${activePupilId}&history=${props.historyModalName}`)
      .then(res => {
        const pupilHistory = res.data.pupilContactsHistory[`${props.historyModalName}History`];
        dispatch({ type: setHistoryList, payload: { data: pupilHistory } });
      });
  }, [])

  // Capatalize First Letter for given string
  const toCapatalize = (value) => {
    const firstLetter = value.charAt(0).toUpperCase();
    const otherString = value.slice(1);
    return `${firstLetter}${otherString}`;
  }

  const historyModelName = toCapatalize(props.historyModalName);

  return (
    <Modal 
      isOpen={props.isHistoryModalOpen} 
      closeModal={props.closeHistoryModal}
    >
      <div className="history-modal">
        <div className="history-modal-heading flex justify-between gap-4">
          <h2 className="flex-1">{`${historyModelName} History`}</h2>
          <FiX size={30} className="modal-content-close-icon" onClick={props.closeHistoryModal} />
        </div>
        <table className="history-modal-table child-table">
          <thead>
            <tr>
              <th>{historyModelName}</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ?
              <tr>
                <td><Skeleton height={10} count={2} /></td>
                <td><Skeleton height={10} count={2} /></td>
              </tr> :              
              historyList && historyList.length > 0 &&
                historyList.map((item, index) => 
                  <tr key={index}>
                      <td>{item[1]}</td>
                      <td>{item[2]}</td>
                  </tr>
                )
            }
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

export default HistoryModal;
