import axios from 'axios'
import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { useReducer, useState } from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import PupilContext from '../../../../Components/context/PupilContext'
import IsCompletedContext from '../../../../Components/context/IsCompletedContext'
import DateField from '../../../../Components/NCC/DateField'
import InputField from '../../../../Components/NCC/InputField'
import NccMeetingHoc from '../../../../Components/NCC/Meeting/NccMeetingHoc'
import NccMeetingSkeleton from '../../../../Components/NCC/Meeting/NccMeetingSkeleton'
import WhoAttendTableRow from '../../../../Components/NCC/Meeting/WhoAttendTableRow'
import SelectField from '../../../../Components/NCC/SelectField'
import TextAreaField from '../../../../Components/NCC/TextAreaField'
import { checkForForTrimmedEmptyValue, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions'
import { NCCReportApi } from '../../../../constants/apiUrls'
import { setLoading, setNccMeetingReport } from '../../../../constants/reducerActions'
import nccMeetingReducer from '../../../../reducers/NCC/nccMeetingReducer'
import DataDateField from '../../../Pep/PepData/components/DataDateField'
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader'
import CompleteButton from '../../../../Components/NCC/CompleteButton'

const initialState = {
  nccPepMeeting: null,
  pupilUnique: null,
  subSchool: null,
  pupilVoice: null,
  presentPer: null,
  exclusionCount: null,
  isLoading: false,
}

const NCCMeeting = ({
  saveForm,
  onChangeHandler,
  numberOnChangeHandler,
  dateChangeHandler,
  attendanceButtonHandler,
  exclusionsButtonHandler,
  formValidationWrapper,
  exportPdf,
}) => {
  const pdfReportName = "nccMeeting";
  const fullPepPdfReportName = "NccFullPep";
  const objectName = "nccPepMeeting";
  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");

  const { dateOfMeeting, pupilInfo } = useContext(PupilContext);
  const {setIsCompleted} = useContext(IsCompletedContext);

  const [state, dispatch] = useReducer(nccMeetingReducer, initialState);

  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);

  useEffect(() => {
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      dispatch({ type: setLoading, payload: { isLoading: true } });
      const url = NCCReportApi.getNccMeeting({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      axios.get(url)
        .then(res => {
          dispatch({ type: setNccMeetingReport, payload: res.data })
          console.log(res.data, "response from ncc meeting");
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.", { position: "bottom-right" })
    }
    
  }, [dateOfMeeting])

  const initialFormValues = {
    presentPer: state.presentPer,
    nccPepMeeting: state.nccPepMeeting,
    pupilUnique: state.pupilUnique,
    subSchool: state.subSchool,
    pupilVoice: state.pupilVoice,
    exclusionCount: state.exclusionCount,
  }

  const isCompletedCallback = () => {
    setIsCompleted("meetingIsCompleted", true);
    showReportCompleteSuccess('Meeting Completed');
    
  }

  const checkForErrors = (values) => {
    let error = "";
    
    if (checkForForTrimmedEmptyValue(values.nccPepMeeting.ypAttended)) {
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.nccPepMeeting.dtAttended)) {
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.nccPepMeeting.swAttended)) {
      error = "Please fill the required";
    }

    else if (checkForForTrimmedEmptyValue(values.nccPepMeeting.fcAttended)) {
      error = "Please fill the required";
    }
    else if (checkForForTrimmedEmptyValue(values.nccPepMeeting.vsAttended)) {
      error = "Please fill the required";
    }

    return error;
  }

  const formValidaton = formValidationWrapper({
    saveForm,
    setIsCompleted,
    fieldName: `${objectName}.isCompleted`,
    objectName,
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: isCompletedCallback,
  });

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      onSubmit={formValidaton}
    >
      
      <Form>
        <PepSecondaryHeader
          heading={`NCC Meeting ${""}`}
          buttons={[
            <CompleteButton
              fieldName={`${objectName}.isCompleted`}
              completeButtonLoader={completeButtonLoader}
            />,
            <div className="export-btn">
              <span className="blue-button px-5 py-2  text-sm">Export</span>
              <ul>
                <li>
                  <button type="button" onClick={() => exportPdf(fullPepPdfReportName)}>Full Pep</button>
                </li>
                <li>
                  <button type="button" onClick={() => exportPdf(pdfReportName)}>PDF</button>
                </li>
              </ul>
            </div>
          ]}
        />
        {
          state.isLoading ?
            <NccMeetingSkeleton /> :
            <div className='ncc-meeting'>
              
              <h1 className="pupil-title mb-2 mt-4">Who Attended?</h1>
              
              <div className="flex rounded-3xl bg-white w-full">
                <div className="table-box w-full overflow-x-auto">
                  <table className="table-auto w-full text-center">
                    <thead>
                      <tr>
                        <th className="blue w-1/4 ...">Name</th>
                        <th className="green w-1/4 ...">Title</th>
                        <th className="pink w-1/4 ...">Email</th>
                        <th className="orange w-1/4 ...">Attended? <span className="required">*</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <WhoAttendTableRow 
                        name={pupilInfo.fullname}
                        title="Young Person"
                        email={state.pupilUnique?.youngPersonEmail}
                        attended="nccPepMeeting.ypAttended"
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                      <WhoAttendTableRow 
                        name={state?.subSchool?.designatedTeacherName}
                        title="Designated teacher"
                        email={state?.subSchool?.dtEmail}
                        attended={"nccPepMeeting.dtAttended"}
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                      <WhoAttendTableRow 
                        name={state?.pupilUnique?.socialWorker?.socialWorkerName}
                        title="Social Worker"
                        email={state?.pupilUnique?.socialWorker?.socialWorkerEmail}
                        attended={"nccPepMeeting.swAttended"}
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                      <WhoAttendTableRow 
                        name={state?.pupilUnique?.pupilcarer?.pupilsCarerName}
                        title="Pupil Carer"
                        email={state?.pupilUnique?.pupilcarer?.pupilsCarerEmail}
                        attended={"nccPepMeeting.fcAttended"}
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                      <WhoAttendTableRow 
                        name={state?.pupilUnique?.pepLacLink?.lacLinkName}
                        title="Achievement Officer"
                        email={state?.pupilUnique?.pepLacLink?.lacLinkEmail}
                        attended={"nccPepMeeting.vsAttended"}
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                      />
                    </tbody>
                  </table>
                </div>
              </div>

              <h1 className="pupil-title mb-2 mt-4">Hopes, dreams and future goals</h1>
              
              <div className="rounded-3xl bg-white p-4 overflow-hidden">

                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">Child/Young person's hopes, dreams and future goals:  </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.dreamsAndFutureGoals"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                  <div className="pb-2">
                    <p className="inline-block font-medium">If our young person is not in the meeting, who has had this conversation with them?  </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.ygIsNotInTheMeeting"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>What else does our child/young person want us to know about education - record how/who will address anything raised here?</b> E.g. what do you enjoy about education? What are you good at? What makes you feel safe and comfortable in school? Is there anything you don't like about school ? what needs to be done to make it better? What help do you need at school to do things you don't already do? Is there anything else you would like to tell us? </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.knowAboutEducation"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Our setting gives our child/young person a sense of belonging, connection and safety by...</b> (Please list below how you do this e.g. availability of relationships with trusted adults, focus on repair of relationships, environment - having a safe space, sensory awareness etc.) </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.connectionAndSafety"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Our setting and team around our child, supports the ongoing development of our child/young person's identity by?</b> (e.g. role models, gender, culture, race, heritage, faith) </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.ongoingDevelopmentOfOurChild"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <h2 className="text-xl font-semibold">Attendance</h2>
                    <div className="flex flex-col gap-3 md:gap-4 md:flex-row md:items-center">
                      <div className="flex gap-2 items-center">
                        <label for="">Attendance %: <span className="required">*</span> </label>
                        <div>
                          <InputField
                            fieldName="presentPer"
                            saveForm={saveForm}
                            onChangeHandler={numberOnChangeHandler}

                            rest={{
                              className: "p-2 radio rounded-full border-gray-300 border text-sm w-16",
                              readOnly: true,
                            }}
                          />
                          {/* <input type="text" className="p-2 radio rounded-full border-gray-300 border text-sm w-16" /> */}
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <button 
                          type="button" 
                          className="blue-button py-2 px-4 text-sm"
                          onClick={attendanceButtonHandler}
                        >Enter Attendance</button>
                      </div>
                    </div>
                  </div>

                  <div className="pb-2">
                    <p className="inline-block font-medium">If below 95%, what actions are being taken or planned?  </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.takenOrPlanned"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full",
                          placeholder: "Comment",
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder="Comment"></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="py-4 widebox-container">
                  <div className="pb-2">
                    <h2 className="inline-block"><b>Exclusions</b></h2>
                    <div className="flex flex-col gap-3 md:gap-4 md:flex-row md:items-center">
                      <div className="flex gap-2 items-center">
                        <label for="">No fo sessions: <span className="required">*</span> </label>
                        <div>
                          <InputField
                            fieldName="exclusionCount"
                            saveForm={saveForm}
                            onChangeHandler={numberOnChangeHandler}

                            rest={{
                              className: "p-2 radio rounded-full border-gray-300 border text-sm w-16",
                              readOnly: true,
                            }}
                          />
                          {/* <input type="text" className="p-2 radio rounded-full border-gray-300 border text-sm w-16" /> */}
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <button 
                          type="button" 
                          className="blue-button py-2 px-4 text-sm"
                          onClick={attendanceButtonHandler}
                        >Enter Exclusions</button>
                      </div>
                    </div>
                  </div>
                  <div className="pb-2">
                    <p className="inline-block font-medium">If our young person has had an exclusion in the last term, what actions are in place to prevent further exclusions?</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.preventFurtherExclusions"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full",
                          placeholder: "Comment",
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea-small textarea w-full" placeholder="Comment"></textarea> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor="">Do the Strengths and Needs accurately describe our child/young person? </label>
                  <SelectField
                    fieldName={"nccPepMeeting.strengthsAndNeedsAccurately"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full p-2 text-sm w-24",
                    }}
                  />
                  {/* <select className="border-gray-300 border rounded-full p-2 text-sm w-24">
                    <option></option>
                    <option>Yes</option>
                    <option>No</option>
                  </select> */}
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">If no, amend the section as appropriate to reflect our child/young person. </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.amendTheSection"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3xl bg-white mt-4 p-4 overflow-hidden">
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor="">Has the attendance, attainment and progress of our young person been shared and discussed?  </label>
                  <SelectField
                    fieldName={"nccPepMeeting.progressSharedAndDiscussed"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full p-2 text-sm w-24",
                    }}
                  />
                  
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">If not, please do so now.</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.pleaseDoSoNow"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">What's going well?</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.whatGoingWell"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Things to celebrate</b> Please list any achievements linked to education/extra-curricular activities e.g. attendance, grades, activity, progress, engagement, wider community.</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.thingsToCelebrate"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Is there anything we need to do to facilitate engagement in extra-curricular activities? State who is responsible</b> Is there anything we need to do to facilitate engagement in extra-curricular activities? State who is responsible.</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.stateWhoIsResponsible"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium">Extra-curricular activities?</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.extraCurricularActivities"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
                <h1 className="pupil-title mb-2 mt-4">Transition planning :</h1>
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor="">Is our young person, due to transition to the next phase of education, employment or training? </label>
                  <SelectField
                    fieldName={"nccPepMeeting.nextPhaseOfEducation"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full p-2 text-sm w-24",
                    }}
                  />
                  {/* <select className="border-gray-300 border rounded-full p-2 text-sm w-24">
                    <option></option>
                    <option>Yes</option>
                    <option>No</option>
                  </select> */}
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>If yes, please detail transition plans below to ensure thorough discussions are held to inform next steps</b> e.g. has a school/college place been applied for, name next setting, dates starting new phase, detail support needed for positive transition including discussions with key staff at next school</p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.heldToInformNextSteps"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                      {/* <textarea className="rounded-2xl px-2 py-4 textarea textarea-small w-full" placeholder=""></textarea> */}
                    </div>
                  </div>
                </div>
              </div>

              

              <div className="rounded-3xl bg-white mt-4 w-full p-4 gap-5">
                
                <h1 className="pupil-title mb-2 mt-4">Next review</h1>
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label htmlFor=""><b>Date of next review</b> (consider times and locality to include our young person)</label>
                  <DateField
                    dateChangeHandler={dateChangeHandler}
                    fieldName="nccPepMeeting.dateOfNextReview"
                    saveForm={saveForm}
                    rest={{
                      className: "w-full border border-gray-300 rounded-3xl px-2 py-1",
                    }}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <div className="pb-2">
                    <p className="inline-block font-medium"><b>Who to invite</b> (consider staff from next school or year group if due to transition) </p>
                    <div className="textarea-block mt-1 w-full">
                      <TextAreaField
                        fieldName={"nccPepMeeting.whoToInvite"}
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rows={2}
                        rest={{
                          className: "rounded-2xl px-2 py-4 textarea textarea-small w-full"
                        }}
                      />
                    </div>
                  </div>
                </div>


              </div>

            </div>
        }
      </Form>
    </Formik>
  )
}

export default NccMeetingHoc(NCCMeeting)
