import { Field, FieldArray, Form, Formik } from 'formik'
import React, { useEffect, useReducer, useState } from 'react'
import {useHistory} from "react-router-dom"
import InputField from '../../../../Components/NCC/InputField'
import RadioField from '../../../../Components/NCC/RadioField'
import TextAreaField from '../../../../Components/NCC/TextAreaField'
import SelectField from '../../../../Components/NCC/SelectField'
import { Input } from 'postcss'
import {routeUrl} from "../../../../Components/utils/RouteUrl"
import axios from 'axios'
import { NCCReportApi, pupilVoiceApi } from '../../../../constants/apiUrls'
import { useContext } from 'react'
import PupilContext from '../../../../Components/context/PupilContext'
import nccDataEyfsReducer from '../../../../reducers/NCCData/nccDataEyfsReducer'
import { setLoading, setNccEyfs, setSchoolReports, setUploadedSchoolReports } from '../../../../constants/reducerActions'
import { toast } from 'react-toastify'
import NCCDataEyfsSkeleton from '../../../../Components/NCC/NCCDataEyfsSkeleton'
import NccDataHoc from '../../../../Components/NCC/NccDataHoc'
import UploadModal from '../../../Pep/PupilVoice/components/UploadModal'
import NumberField from '../../../../Components/NCC/NumberField'
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader'
import { checkForForTrimmedEmptyValue, formValidationWrapper, isObjectEmpty, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions'
import Swal from 'sweetalert2'
import IsCompletedContext from '../../../../Components/context/IsCompletedContext'
import CompleteButton from '../../../../Components/NCC/CompleteButton'


const initialState = {
  presentPer: null,
  exclusionCount: null,
  dataEyfs: null,
  schoolUploadedFiles: null,
  isLoading: false,
}

const primaryNeedOptionList = [
  <option value=""></option>,
  <option value="Specific Learning Difficulty">Specific Learning Difficulty</option>,
  <option value="Moderate Learning Difficulty">Moderate Learning Difficulty</option>,
  <option value="Severe Learning Difficulty">Severe Learning Difficulty</option>,
  <option value="Profound &amp; Multiple Learning Difficulty">Profound &amp; Multiple Learning Difficulty</option>,
  <option value="Social, Emotional and Mental Health">Social, Emotional and Mental Health</option>,
  <option value="Speech, Language and Communications Needs">Speech, Language and Communications Needs</option>,
  <option value="Hearing Impairment">Hearing Impairment</option>,
  <option value="Visual Impairment">Visual Impairment</option>,
  <option value="Multi-Sensory Impairment">Multi-Sensory Impairment</option>,
  <option value="Physical Disability">Physical Disability</option>,
  <option value="Autistic Spectrum Disorder">Autistic Spectrum Disorder</option>,
  <option value="Other Difficulty/Disability">Other Difficulty/Disability</option>,
]

const developmentBandsOptionList = [
  <option value=""></option>,
  <option value="Birth to 3">Birth to 3</option>,
  <option value="3 and 4 year olds">3 and 4 year olds</option>,
  <option value="Children in reception">Children in reception</option>,
]

const progressOptionList = [
  <option value=""></option>,
  <option value="Below Expected Progress">Below Expected Progress</option>,
  <option value="Expected Progress">Expected Progress</option>,
  <option value="Exceeding Progress">Exceeding Progress</option>,
]

const emergingOptionList = [
  <option value=""></option>,
  <option value="Emerging">Emerging</option>,
  <option value="Expected">Expected</option>,
]

const NCCDataEyfs = ({
  attendanceButtonHandler,
  exclusionsButtonHandler,
  saveForm,
  onChangeHandler,
  numberOnChangeHandler,
  downloadUploadedFile,
  deleteUploadedFile,
  exportPdf,
}) => {
  const dataReportCompleteMsg = "Data Eyfs Completed";

  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");

  // Pupil Info Context
  const {dateOfMeeting, pupilInfo} = useContext(PupilContext);

  // Is Completed context for setting sidebar
  const { setIsCompleted } = useContext(IsCompletedContext);

  const [state, dispatch] = useReducer(nccDataEyfsReducer, initialState);

  const [isOpen, setIsOpen] = useState(false);

  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      setIsLoading(true);
      const url = NCCReportApi.getEyfsData({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      
      axios.get(url, { cancelToken: source.token })
        .then((res) => {
          dispatch({ type: setNccEyfs, payload: res.data })
          console.log(res.data, "response from ncc data eyfs");
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.")
    }
    return () => {
      source.cancel();
    }
  }, [dateOfMeeting])

  const setIsLoading = (flag) => {
    dispatch({type: setLoading, payload: {isLoading: flag}})
  }

  const uploadedDocumentListHandler = (data) => {
    dispatch({ type: setUploadedSchoolReports, payload: { schoolReports: data }})
    console.log(data);
  }

  const initialFormState = {
    presentPer: state.presentPer,
    exclusionCount: state.exclusionCount,
    dataEyfs: state.dataEyfs,
    schoolUploadedFiles: state.schoolUploadedFiles,
  }

  const isCompletedCallback = () => {
    setIsCompleted("dataIsCompleted", true);
    showReportCompleteSuccess(dataReportCompleteMsg);
  }

  const successCallback = (values) => {
    values.dataEyfs.isCompleted = 1;
  }

  const checkForErrors = (values) => {
    let error = "";
    if (checkForForTrimmedEmptyValue(values.dataEyfs.commonData.youngPersonHaveSEND)) {
      error = "'Does this young person have SEND?' is required";
    }

    if (checkForForTrimmedEmptyValue(values.presentPer)) {
      error = "Attendance is required";
    }

    if (checkForForTrimmedEmptyValue(values.dataEyfs.expectedLevelOfDev)) {
      error = "'Is our child/young person meeting expected level of development?' is required";
    }

    if (checkForForTrimmedEmptyValue(values.dataEyfs.progressOverTheLastTerm)) {
      error = "'Has our child/young person made progress over the last term?' is required";
    }

    if (checkForForTrimmedEmptyValue(values.dataEyfs.makingGoodProgress)) {
      error = "'From your assessments, is the child/young person making good progress?' is required";
    }
    return error;
  }

  const formValidaton = formValidationWrapper({ 
    saveForm,
    setIsCompleted,
    attributeName: "dataIsCompleted",
    successCallback, 
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: isCompletedCallback,
  });

  return (
    <Formik
      initialValues={initialFormState}
      enableReinitialize
      onSubmit={formValidaton}
    >
      <Form>
        <PepSecondaryHeader
          heading={`NCC PEP - Data Eyfs`}
          buttons={[
            <CompleteButton 
              fieldName={"dataEyfs.isCompleted"}
              completeButtonLoader={completeButtonLoader}
            />,
            
            <div className="export-btn">
              <span className="blue-button px-5 py-2  text-sm">Export</span>
              <ul>
                <li>
                  <button type='button' onClick={() => exportPdf("NccFullPep")}>Full Pep</button>
                </li>
                <li>
                  <button type='button' onClick={() => exportPdf("nccDataEyfs")}>PDF</button>
                </li>
              </ul>
            </div>
          ]}
        />
        {
          state.isLoading ?
            <NCCDataEyfsSkeleton /> :
            <>
              {/* First part of Page */}
              <div className="rounded-3xl bg-white lg:w-max md:pr-8 overflow-hidden">
                <div className="flex flex-col gap-2 md:flex-row md:gap-3 md:items-center">
                  <label htmlFor=""  className='p-6 blue md:w-96'>Does this young person have SEND? <span className='required'>*</span></label>
                  <div className='pl-4 pt-2 pb-4 md:pl-0 md:pb-0'>
                    
                    <RadioField 
                      fieldName="dataEyfs.commonData.youngPersonHaveSEND"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      radioOptionsList={[
                        {innerValue: "Yes", outerValue: "Yes"},
                        {innerValue: "No", outerValue: "No"},
                      ]}
                    />
                  </div>
                </div>

                <div className="divide-solid divide-class ml-2 w-full"></div>

                <div className="flex flex-col gap-2 md:flex-row md:gap-3">
                  <label htmlFor="" className='p-6 pb-12 blue md:w-96'>If yes, how are they being supported? <span className='required'>*</span></label>
                  <div className='flex flex-col gap-3 pl-4 pb-4 md:pl-0 md:pb-0'>
                    <div className="flex items-center gap-3 md:pt-6">
                      <label htmlFor="" className='w-28'>SEN Support</label>
                      <Field name="dataEyfs.commonData.youngPersonHaveSEND">
                        {
                          ({ form, field }) => {

                            return (
                              <RadioField
                                fieldName="dataEyfs.commonData.SENSupport"
                                onChangeHandler={onChangeHandler}
                                saveForm={saveForm}
                                radioOptionsList={[
                                  { innerValue: "Yes", outerValue: "Yes" },
                                  { innerValue: "No", outerValue: "No" },
                                ]}
                                disabled={field.value === "No" ? true : false}
                              />
                            )
                          }
                        }
                      </Field>
                      {/* <RadioField 
                        fieldName="dataEyfs.commonData.SENSupport"
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        radioOptionsList={[
                        {innerValue: "Yes", outerValue: "Yes"},
                        {innerValue: "No", outerValue: "No"},
                      ]}
                      /> */}
                    </div>
                    <div className="flex items-center gap-3">
                      <label htmlFor="" className='w-28'>EHCP</label>
                      <Field name="dataEyfs.commonData.youngPersonHaveSEND">
                        {
                          ({ form, field }) => {

                            return (
                              <RadioField
                                fieldName="dataEyfs.commonData.EHCP"
                                onChangeHandler={onChangeHandler}
                                saveForm={saveForm}
                                radioOptionsList={[
                                  { innerValue: "Yes", outerValue: "Yes" },
                                  { innerValue: "No", outerValue: "No" },
                                ]}
                                disabled={field.value === "No" ? true : false}
                              />
                            )
                          }
                        }
                      </Field>
                      {/* <RadioField 
                        fieldName="dataEyfs.commonData.EHCP"
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        radioOptionsList={[
                        {innerValue: "Yes", outerValue: "Yes"},
                        {innerValue: "No", outerValue: "No"},
                      ]}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>


              {/* Second part of page */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <div className="flex flex-col gap-3">
                  <h1 className='text-xl font-semibold'>Attendance</h1>
                  <div className='flex flex-col gap-3 md:gap-4 md:flex-row md:items-center'>
                    <div className="flex gap-2 items-center">
                      <label htmlFor="">Attendance %: <span className="required">*</span> </label>
                      <InputField
                        fieldName="presentPer"
                        saveForm={saveForm}
                        onChangeHandler={numberOnChangeHandler}
                        
                        rest={{
                          className: "px-2 py-1 radio rounded-full border-gray-300 border text-sm w-16",
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className='flex gap-3'>
                      <button
                        type='button'
                        className='blue-button py-2 px-4 text-sm'
                        onClick={attendanceButtonHandler}
                      >Enter Attendance</button>
                      
                      {/* <button type='button' className='blue-button py-2 px-4 text-sm'>Recalculate Attendance</button> */}
                    </div>
                  </div>
                  <div>
                    <p className='font-medium'>If below 95%, what actions are being taken or planned?</p>
                    <TextAreaField
                      fieldName="dataEyfs.commonData.actionsBeingTaken"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <h1 className='text-xl font-semibold'>Exclusions</h1>
                  <div className='flex flex-col gap-3 md:gap-4 md:flex-row md:items-center'>
                    <div className="flex items-center gap-3">
                      <label htmlFor="">No of sessions: </label>
                      <InputField
                        fieldName="exclusionCount"
                        saveForm={saveForm}
                        onChangeHandler={numberOnChangeHandler}
                        rest={{
                          className: "px-2 py-1 radio rounded-full border-gray-300 border text-sm w-16",
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className='flex gap-3'>
                      <button type='button'
                        className='blue-button py-2 px-4 text-sm'
                        onClick={exclusionsButtonHandler}
                      >Enter Exclusions</button>
                      {/* <button type='button' className='blue-button py-2 px-4 text-sm'>Recalculate Exclusions</button> */}
                    </div>
                  </div>
                  <div>
                    <p className='font-medium'>If our young person has had an exclusion in the last term, what actions are in place to prevent further exclusions?</p>
                    <TextAreaField
                      fieldName="dataEyfs.commonData.actionsAreInPlace"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                    />
                  </div>
                </div>
              </div>

              {/* Third part of page */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <h2 className='font-medium'>If YES please answer the following:</h2>
                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label htmlFor="">If Yes for ECHP, what is the primary identified need?</label>
                  <SelectField
                    fieldName={"dataEyfs.commonData.primaryIdentifiedNeed"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={primaryNeedOptionList}
                  />
                </div>
              </div>

              {/* Forth (Table) part of page */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 gap-5">
                <div className="table-box overflow-x-auto">
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th width="50%" colSpan={2} className='blue border-collapse border-2 border-white'></th>
                        <th className='d-blue border-collapse border-2 border-white'>
                          Development bands (months)
                        </th>
                        <th className='purple border-collapse border-2 border-white'>
                          Progress
                        </th>
                        <th className='orange border-collapse border-2 border-white'>
                          Emerging / Expected
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <td rowSpan={3} className='blue'>
                          Personal, Social & Emotional Development
                        </td>
                        <td>
                          1 Self Regulation
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.selfRegulationDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.selfRegulationProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.selfRegulationEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          2 Managing Self
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.managingSelfDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.managingSelfProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.managingSelfEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          3 Building Relationships
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.buildingRelationshipsDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.buildingRelationshipsProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.buildingRelationshipsEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='d-blue' rowSpan={2}>
                          Physical Development
                        </td>
                        <td>
                          4 Gross Motor Skills
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.grossMotorDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.grossMotorProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.grossMotorEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          5 Fine Motor Skills
                        </td>
                        
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.fineMotorDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.fineMotorProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.fineMotorEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      
                      <tr>
                        <td className='purple' rowSpan={2}>
                          Communication & Language
                        </td>
                        <td>
                          6 Listening, Attention & Understanding
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.listnAttnUnderstandingDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.listnAttnUnderstandingProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.listnAttnUnderstandingEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          7 Speaking
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.speakingDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.speakingProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.speakingEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      
                      <tr>
                        <td className='orange' rowSpan={3}>
                          Literacy
                        </td>
                        <td>
                          8 Comprehension
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.comprehensionDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.comprehensionProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.comprehensionEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          9 Word Reading
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.wordReadingDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.wordReadingProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.wordReadingEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          10 Writing
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.writingDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.writingProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.writingEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      
                      <tr>
                        <td className='d-orange' rowSpan={2}>
                          Mathematics
                        </td>
                        <td>
                          11 Number
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.numberDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.numberProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.numberEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          12 Numerical Patterns
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.numericalPatternsDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.numericalPatternsProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.numericalPatternsEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                  
                      <tr>
                        <td className='d-pink' rowSpan={3}>
                          Understanding the World
                        </td>
                        <td>
                          13 Past & Present
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.pastPresentDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.pastPresentProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.pastPresentEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          14 People, Culture & Communities
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.pupilCulutreCommuDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.pupilCulutreCommuProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.pupilCulutreCommuEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          15 The Natural World
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.naturalWorldDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.naturalWorldProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.naturalWorldEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                    
                      <tr>
                        <td className='pink' rowSpan={2}>
                          Expressive Arts and Design
                        </td>
                        <td>
                          16 Creating with Materials
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.creatingMaterialsDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.creatingMaterialsProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.creatingMaterialsEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          17 Being imaginative & Expressive
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.imaginativeExpressiveDeveBands"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={developmentBandsOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.imaginativeExpressiveProgress"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={progressOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <SelectField
                            fieldName={"dataEyfs.imaginativeExpressiveEmerExpected"}
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                            optionsList={emergingOptionList}
                            rest={{
                              className: "border-gray-300 border rounded-full py-1 px-3 text-xs"
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>

              {/* Fifth part of page */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                
                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    Is our child/young person meeting expected level of development? <span className="required">*</span>
                  </label>
                  <SelectField 
                    fieldName={"dataEyfs.expectedLevelOfDev"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                    }}
                  />
                </div>

                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    Has our child/young person made progress over the last term? <span className="required">*</span>
                  </label>
                  <SelectField 
                    fieldName={"dataEyfs.progressOverTheLastTerm"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                    }}
                  />
                </div>
                
                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    Reception Baseline Assessment (Reception only) Score:
                  </label>
                  <div className='flex items-center gap-3'>
                    <NumberField 
                      fieldName="dataEyfs.receptionScore"
                      saveForm={saveForm}
                      onChangeHandler={numberOnChangeHandler}
                      maxDigit={39} 
                      rest={{
                        className: "p-2 rounded-full border-gray-300 border text-sm w-28"
                      }}
                    />
                    <span>/ 39</span> 
                    
                  </div>
                </div>

                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    Is our child/young person on a bespoke, targeted curriculum which is not measured by age/phase or working towards qualifications?
                  </label>
                  <SelectField
                    fieldName={"dataEyfs.targetedCurriculum"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                    }}
                  />
                </div>

                <div className="table-box overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead>
                      <tr className=''>
                        <th className='blue border-collapse border-2 border-white'>Current pathway/curriculum/subject</th>
                        <th className='d-blue border-collapse border-2 border-white'>Currently working at</th>
                        <th className='purple border-collapse border-2 border-white'>Expectations/target for end of KS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField 
                            fieldName="dataEyfs.currentPathway"
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                          />
                        </td>
                        <td className='text-center'>
                          <TextAreaField 
                            fieldName="dataEyfs.currentWorking"
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                          />
                          
                        </td>
                        <td className='text-center'>
                          <TextAreaField 
                            fieldName="dataEyfs.expectationsTarget"
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                          />
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    From your assessments, is the child/young person making good progress? <span className="required">*</span> 
                  </label>
                  <SelectField
                    fieldName={"dataEyfs.makingGoodProgress"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                    }}
                  />
                </div>
                
                <div>
                  <p className='font-medium'>How do you know and how it is measured?</p>
                  <TextAreaField
                    fieldName="dataEyfs.howItIsMeasured"
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                  />
                </div>

                <div className='flex flex-col gap-5 md:flex-row md:gap-7 md:items-center'>
                  <div className="flex gap-3 items-center">
                    <label className='font-medium'>School Report Uploaded? </label>
                    <RadioField 
                      fieldName="dataEyfs.schoolReportUploaded"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      radioOptionsList={[
                        { innerValue: "Yes", outerValue: "Yes" },
                        { innerValue: "No", outerValue: "No" },
                      ]}
                    />
                  </div>

                  <div className='flex  items-center'>
                    <div className='file file--upload'>
                      <button 
                        type="button" 
                        className="upload-button px-5 py-2 text-sm"
                        onClick={() => setIsOpen(true)}  
                      >
                        Upload<i className="upload-icon fa-regular fa-cloud-arrow-up"></i>
                      </button>
                    </div>
                    <div className="pl-4 view-report">
                      <button type="button" className="btn pep-btn">View Reports <i className="fa-regular fa-angle-down"></i></button>
                      <ul className="scroll_add">
                        <FieldArray name="schoolUploadedFiles">
                          {
                            ({ form, remove }) => {
                              return (
                                form.values.schoolUploadedFiles && form.values.schoolUploadedFiles.map((result, index) => {
                                  return (
                                    <li key={index} className="flex justify-between items-center gap-3">
                                      <span
                                        onClick={() => downloadUploadedFile({
                                          blobKey: result.blobkey,
                                          fileName: result.fileName,
                                        })}
                                        className='flex justify-between items-center gap-3'>
                                        {result.fileShowName ? result.fileShowName : result.fileName}
                                      </span>
                                      <i
                                        className="fa-solid fa-circle-trash text-red-600"
                                        onClick={() => deleteUploadedFile({
                                          blobKey: result.blobkey,
                                          fileName: result.fileName,
                                          deleteFile: () => remove(index),
                                        })}
                                      >
                                      </i>
                                    </li>
                                  )
                                })
                              )
                            }
                          }
                        </FieldArray>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </>

        }
        <UploadModal
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          isSecondary="Y"
          listHandler={uploadedDocumentListHandler}
          uploadUrl={NCCReportApi.saveSchoolReport}
          responseObjectName={"schoolReport"}
        />

      </Form>
    </Formik>
  )
}

export default NccDataHoc(NCCDataEyfs);

