import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";

//Pre defined Routes URL -> To change url pattern easily in future
import { routeUrl } from "./Components/utils/RouteUrl";
import MainContext from "./Components/context/MainContext";
import {
  setAuthenticatedUser,
  setStateReady,
} from "./constants/reducerActions";
import NCCAuthWrapper from "./Components/NCCAuthWrapper";
import ExtractionReport from "./Pages/Caller/ExtractionReport";
import axios from "axios";
import SchoolCaller from "./Pages/Caller/SchoolCaller";
import Summary from "./Pages/Caller/SummaryList";

//Lazy import
const Login = React.lazy(() => import("./Pages/Login"));
const NotFound = React.lazy(() => import("./Pages/NotFound"));
const AuthWrapper = React.lazy(() => import("./Components/AuthWrapper"));
const PepAuthWrapper = React.lazy(() => import("./Pages/Pep/PepAuthWrapper"));

// const DccAuthWrapper = React.lazy(() => import('./Pages/DCC/DccAuthWrapper'));

const LoginRedirect = () => {
  return <Redirect to="/login" />;
};
const ForuRedirect = () => {
  return <Redirect to="/404" />;
};

const App = (props) => {
  const { state, dispatch: mainContextDispatcher } = useContext(MainContext);
  const dccSchoolId = "2270";
  const dccSubSchoolId = "50421";

  useEffect(() => {
    setTimeout(() => {
      document.querySelector("body").classList.remove("overflow-hidden");
    }, 2000);

    const authToken = localStorage.getItem("authorizationKey");
    const userName = localStorage.getItem("userName");
    const roleId = localStorage.getItem("userRole");
    const schoolId = localStorage.getItem("schoolId");

    if (authToken && roleId) {
      const myDecodedToken = decodeToken(authToken);
      const isMyTokenExpired = isExpired(authToken);

      if (isMyTokenExpired) {
        localStorage.removeItem("authorizationKey");
        localStorage.removeItem("userName");
        localStorage.removeItem("userRole");
        localStorage.removeItem("userId");
        localStorage.removeItem("activePupilId");
        localStorage.removeItem("activepageNo");
      } else {
        if (myDecodedToken.sub === userName) {
          // this.state.isAuthinticated = true;
          // this.state.roleId = roleId;
          mainContextDispatcher({
            type: setAuthenticatedUser,
            payload: { roleId: parseInt(roleId), schoolId: parseInt(schoolId) },
          });
        }
      }
    }
    mainContextDispatcher({ type: setStateReady });
    // this.setState({isStateReady: true})
  }, []);

  axios.defaults.headers.common[
    "Authorization"
  ] = `asset ${localStorage.getItem("authorizationKey")}`;

  return (
    <React.Suspense
      fallback={
        <div className="h-screen w-ascreen flex justify-center items-center">
          <div className="loader"></div>
        </div>
      }
    >
      {state.isStateReady ? (
        <Router>
          <Switch>
            <Route path={routeUrl.login_url}>
              <Login />
            </Route>

            <Route path="404" component={NotFound} />

            {/* <Route path={routeUrl.extraction}>
              <ExtractionReport />
            </Route> */}

           

            <Route path={routeUrl.call}>
              <SchoolCaller />
            </Route>

            {/* <Route path={routeUrl.summary}>
              <Summary />
            </Route> */}
            {(() => {
              if (state.roleId === null) {
                return (
                  <>
                    <Route exact path="/">
                      <Login />
                    </Route>
                    <Route component={LoginRedirect} />
                  </>
                );
              } else if (state.schoolId === dccSchoolId) {
                return (
                  <Route path="/">
                    {/* <DccAuthWrapper
                                                isAuthinticated={state.isAuthinticated}
                                                roleId={state.roleId}
                                            /> */}
                  </Route>
                );
              } else if (state?.schoolId === state?.ncc) {
                return (
                  <>
                    <Route path="/">
                      <NCCAuthWrapper
                        isAuthinticated={state?.isAuthinticated}
                        roleId={state?.roleId}
                      />
                    </Route>
                  </>
                );
              } 
              else if (
                state.pep.find(
                  (element) => element === parseInt(state.roleId)
                ) !== undefined
              ) {
                return (
                  <Route path="/">
                    <PepAuthWrapper
                      isAuthinticated={state.isAuthinticated}
                      roleId={state.roleId}
                      schoolPortalList={state.reactSchoolAndCaller}
                    />
                  </Route>
                );
              } else if (
                state.reactSchoolAndCaller.find(
                  (element) => element === parseInt(state.roleId)
                ) !== undefined
              ) {
                return (
                  <Route path="/">
                    <AuthWrapper
                      isAuthinticated={state.isAuthinticated}
                      roleId={state.roleId}
                    />
                  </Route>
                );
              }
            })()}
            {/* { state.roleId === null && (
                                    <>
                                        <Route exact path="/" >
                                            <Login />
                                        </Route>
                                        <Route component={LoginRedirect} />       
                                    </>
                                )
                            }
                            {state.ncc.find(element => element === parseInt(state.schoolId)) !== undefined && (
                                    <>
                                        <Route path="/">
                                            <NCCAuthWrapper isAuthinticated={state.isAuthinticated} roleId={state.roleId} />
                                        </Route>
                                    </>
                                )
                            }     
                        {state.pep.find(element => (element === parseInt(state.roleId)) !== undefined && (
                                    
                                    <Route path="/" >
                                        <PepAuthWrapper  
                                            isAuthinticated={state.isAuthinticated} 
                                            roleId={state.roleId}
                                            schoolPortalList={state.reactSchoolAndCaller}
                                        />
                                    </Route>   
                                            
                                    
                                )

                            }
                            { state.reactSchoolAndCaller.find(element => element === parseInt(state.roleId)) !== undefined && (
                                    <>
                                        <Route path="/">
                                            <AuthWrapper  
                                                isAuthinticated={state.isAuthinticated} 
                                                roleId={state.roleId}
                                            />
                                        </Route>
                                    </>
                                )
                            }     
                             */}
          </Switch>
        </Router>
      ) : (
        <div className="h-screen w-ascreen flex justify-center items-center">
          <div className="loader"></div>
        </div>
      )}
    </React.Suspense>
  );
};

export default App;
