import React, { useReducer } from 'react';
import { useState, createContext } from 'react';
import mainContextReducer from '../../reducers/mainContextReducer';

const MainContext = React.createContext();

const initalState = {
  isAuthinticated: false,
  isStateReady: false,
  roleId: null,
  schoolId: null,
  reactSchoolAndCaller: [27, 12, 30, 10],
  pep: [13, 14, 15, 19, 20, 21, 22, 24, 25, 4, 10, 16, 17],
 // ncc: [423693],
  dataManagerUserRoleIdList: [4, 10, 16, 17],
}

export const MainContextProvider = (props) => {

  const [state, dispatch] = useReducer(mainContextReducer, initalState);
  
  return (
    <MainContext.Provider value={{state, dispatch}}>
      {props.children}
    </MainContext.Provider>
  )
}

export default MainContext;