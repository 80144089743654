import React from 'react'
import { setLoading, setNccPupilVoice, setNccPupilVoicePost16 } from '../../constants/reducerActions'

const PupilVoiceReducer = (state, action) => {
  const data = action.payload;
  switch(action.type) {
    
    case setNccPupilVoice:
      return {
        pupilVoice: data.pupilVoice,
        isLoading: false,
      }

    case setNccPupilVoicePost16:
      return {
        post16PupilVoice: data.post16PupilVoice,
        isLoading: false,
      }

    case setLoading:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
  }
}

export default PupilVoiceReducer
