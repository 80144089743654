import React from 'react'
import RadioField from '../RadioField'

const WhoAttendTableRow = ({
  name,
  title,
  email,
  attended,
  saveForm,
  onChangeHandler,
}) => {
  return (
    <tr>
      <td>{name}</td>
      <td>{title}</td>
      <td>{email}</td>
      <td className='text-center'>
        <div className="flex justify-center">
          <RadioField 
            fieldName={attended}
            onChangeHandler={onChangeHandler}
            saveForm={saveForm}
            radioOptionsList={[
              { innerValue: "Yes", outerValue: "Yes" },
              { innerValue: "No", outerValue: "No" },
            ]}
          />
        </div>
      </td>
    </tr>
  )
}

export default WhoAttendTableRow
