import axios from "axios";
import { ALL } from "dns";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { useReducer } from "react";
import { FiDivideCircle } from "react-icons/fi";
import Skeleton from "react-loading-skeleton";

// import { Redirect, useHistory } from "react-router";
import { useHistory } from "react-router-dom";
import PupilContext from "../../Components/context/PupilContext";
import Pagination from "../../Components/Pagination";
import { routeUrl } from "../../Components/utils/RouteUrl";
import { settings } from "../../Components/utils/Settings";
import { showErrorToast } from "../../Components/utils/utilityFunctions";
import { NCCReportApi } from "../../constants/apiUrls";
import useActivateTabLink from "../../hooks/useActivateTabLink";
import myPupilReducer from "../../reducers/myPupilReducer";
import PepBasicInfo from "./BasicInfo/Index";
// import pupilReducer from '../../../../../reducers/pupilReducer';
// import { NCCReportApi, pupilVoiceApi } from '../../constants/apiUrls';


const initialState = {
  termofPep: "",
  pepStatus: "",
  pepStatusList: [],
  isLoading: false,
  currentPage: 1,
  recordPerPage: 10,
  totalRecords: null,
}



const MyPupils = (props) => {

  const [state, dispatch] = useReducer(myPupilReducer, initialState)

  const { pupilInfo } = useContext(PupilContext);


  // Context variables
  const { setActivePupilIdHandler } = useContext(PupilContext);

  // Getting School Id and User Id from local storage
  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  // const authorizationKey = localStorage.getItem("authorizationKey");


  // All state variable
  const [termofPep, setTermOfPep] = useState();
  const [pepStatus, setPepStatus] = useState(null);
  const [pepStatusList, setPepStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);


  // History variable for redirecting
  const history = useHistory();

  useActivateTabLink(routeUrl.pep_status);

  const recordPerPageHandler = (e) => {
    setCurrentPage(1);
    setRecordPerPage(e.target.value);
  };

  const termOfPepHandler = (e) => {
    setCurrentPage(1);
    setTermOfPep(e.target.value);
  };

  const pepStatusHandler = (e) => {
    setCurrentPage(1);
    setPepStatus(e.target.value);
  };

  const searchHandler = (e) => {
    setCurrentPage(1);
    setSearchKeyword(e.target.value);
  }

  const redirectToBasicInfo = (item) => {
    setActivePupilIdHandler(item.pupilId, item.classYear.split(" ")[0], item.dateOfMeeting);
    history.push(routeUrl.basic_info);
  }

  const paginationHandler = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const url = `${settings.API_HOST}/rest/fetchPepStatus?pepstatus.termofpep=${termofPep}&pepstatus.pepstatus=${pepStatus}&pepstatus.page=${currentPage}&pepstatus.recordPerPage=${recordPerPage}&pepstatus.userid=${userId}&keyword=${searchKeyword}&schoolId=${schoolId}`;
    setIsLoading(true);
    axios(url)
      .then((res) => {
        const pepStatus = res.data;
        setPepStatusList(pepStatus.pepStatusList);
        setTotalPages(pepStatus.pepstatus.totalPages);
        setTotalRecords(pepStatus.pepstatus.totalRecords);
        setTermOfPep(pepStatus.pepstatus.termofpep);
        setPepStatus(pepStatus.pepstatus.pepstatus);
        setIsLoading(false);

      })
      .catch(err => showErrorToast(err));
  }, [currentPage, pepStatus, termofPep, recordPerPage, searchKeyword]);


  const redirectToDataReport = (item) => {
    setActivePupilIdHandler(item.pupilId, item.classYear.split(" ")[0], item.dateOfMeeting);
    history.push(routeUrl.ncc_data)
  }
  const redirectToPupilVoiceReport = (item) => {
    setActivePupilIdHandler(item.pupilId, item.classYear.split(" ")[0], item.dateOfMeeting);
    history.push(routeUrl.ncc_pupil_voice)
  }
  const redirectToStrengthAndNeedsReport = (item) => {
    setActivePupilIdHandler(item.pupilId, item.classYear.split(" ")[0], item.dateOfMeeting);
    history.push(routeUrl.ncc_strength)
  }
  const redirectToMeetingReport = (item) => {
    setActivePupilIdHandler(item.pupilId, item.classYear.split(" ")[0], item.dateOfMeeting);
    history.push(routeUrl.ncc_meeting)
  }
  const redirectToTargetReport = (item) => {
    setActivePupilIdHandler(item.pupilId, item.classYear.split(" ")[0], item.dateOfMeeting);
    history.push(routeUrl.ncc_targets)
  }

  const componentToRender = {
    Component: null,
    componentTitle: null,
    reportName: "Pep-Status.xlsx"
  }

  const url = NCCReportApi.exportPdfReports({
    pupilId: pupilInfo.pupilId,
    schoolId: pupilInfo.schoolId,
    classYear: pupilInfo.classYear,
    termofpep: pupilInfo.termOfPep,
    pupilsName: pupilInfo.fullname,
    dateofmeeting: pupilInfo.dateofmeeting,
    pupilUPN: pupilInfo.pupilUPN,
    pupilSen: "",
  });

  const exportExcel = () => {
    const a = document.createElement("a");

    const url = NCCReportApi.exportPepStatusExcel({
      userId: userId,
      termofPep: termofPep,
      pepStatus: pepStatus,
      pupilUPN: pepStatus.pupilUPN,
    });
    axios.get(url, { responseType: "blob" })
      .then((res) => {
        let objectUrl = window.URL.createObjectURL(res.data);
        a.href = objectUrl;
        a.download = componentToRender.reportName;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      })
      .catch(err => {
        const error = err;
        console.log(err);
        console.log(err);
      })
  }

  return (
    <div className="tableview w-full bg-white my_pupil_pep">
      <div className="pep_pupil_header flex justify-between pb-5">
        <h1>My Pupils</h1>
        <button className="rounded-custom-content" onClick={() => exportExcel()} >Export</button>
      </div>

      <div className="pep_pupil_filter flex justify-between">
        <div>
          <span className="pr-3">Show</span>
          <select
            className="rounded-custom-content"
            value={recordPerPage}
            onChange={recordPerPageHandler}
          >
            <option value=""></option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <span className="pl-3">entries</span>
        </div>

        <div className="flex gap-5">
          <div>
            <span className="pr-3">Term of Pep</span>
            <select
              className="rounded-custom-content"
              value={termofPep}
              onChange={termOfPepHandler}
            >
              {/* <option value=""></option>
              <option value="Autumn 2020">Autumn 2020</option>
              <option value="Spring 2021">Spring 2021</option>
              <option value="Summer 2021">Summer 2021</option>
              <option value="Autumn 2021">Autumn 2021</option>
              <option value="Spring 2022">Spring 2022</option>
              <option value="Summer 2022">Summer 2022</option> */}
              <option value=""></option>
              <option value="Autumn 2021">Autumn 2021</option>
              <option value="Spring 2022">Spring 2022</option>
              <option value="Summer 2022">Summer 2022</option>
              <option value="Autumn 2022">Autumn 2022</option>
              <option value="Spring 2023">Spring 2023</option>
              <option value="Summer 2023">Summer 2023</option>
              <option value="2020-21">2020-21</option>
              <option value="2021-22">2021-22</option>
              <option value="2022-23">2022-23</option>
            </select>
          </div>
          <div>
            <span className="pr-3">Pep Status</span>
            <select
              className="rounded-custom-content"
              value={pepStatus}
              onChange={pepStatusHandler}
            >
              <option value=""></option>
              <option value="NotCompleted">Not Completed</option>
              <option value="NoPEP">No PEP</option>
              <option value="Rejected">Rejected</option>
              <option value="Completed">Completed</option>
              <option value="Inadequate">Inadequate</option>
              <option value="Approved">Approved</option>
              <option value="Approved with action">Approved with action</option>
              <option value="Not approved">Not approved</option>
              <option value="Overdue">Overdue</option>
              <option value="Due">Due</option>
              <option value="RequiresQA">Requires QA</option>
              <option value="All">All</option>
            </select>
          </div>
        </div>

        <div className="flex gap-5 items-center">
          <span>Search</span>
          <div className="relative flex items-center">
            <input
              type="text"
              className="rounded-custom-content pep-pupil-search-filter"
              placeholder="Search"
              value={searchKeyword}
              onChange={searchHandler}
            />
            <i className="absolute right-3 fa-solid fa-magnifying-glass "></i>
          </div>
        </div>
      </div>
      <div className="table-box w-full overflow-x-auto">
        <table className="table w-full contacts-table pep-pupil-table mt-6">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>UPN Number</th>
              <th>Year</th>
              <th>RAG rating</th>
              <th>Term of PEP</th>
              <th>Data</th>
              <th>Pupil Voice</th>
              <th>Strength & Needs</th>
              <th>Meeting</th>
              <th>Target & Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton height={25} />
                  </td>
                </tr>
              </>
            ) : (
              pepStatusList.map((pepStatus, index) => (
                <tr key={index} >
                  <td className="td_text" onClick={() => redirectToBasicInfo(pepStatus)}>
                    {pepStatus.pupilFullName}
                  </td>
                  <td className="td_text" onClick={() => redirectToBasicInfo(pepStatus)}>
                    {console.log(pepStatus)} 
                    {pepStatus.pupilUPN}
                  </td>
                  <td className="td_text" onClick={() => redirectToBasicInfo(pepStatus)}>{pepStatus.classYear.split(" ")[0]}</td>
                  <td className="td_text" onClick={() => redirectToPupilVoiceReport(pepStatus)}>
                    {pepStatus.ragrating}
                  </td>
                  <td className="td_text" onClick={() => redirectToBasicInfo(pepStatus)}>{pepStatus.termOfPep}</td>
                  <td className="td_text" onClick={() => redirectToDataReport(pepStatus)}>
                    {pepStatus.statusDataModule && (
                      <i className="fa-solid fa-check"></i>
                    )}
                  </td>
                  <td className="" onClick={() => redirectToPupilVoiceReport(pepStatus)}>
                    {pepStatus.statusPupilVoiceModule ?
                      <i className="fa-solid fa-check"></i> : ""
                    }
                  </td>
                  <td className="" onClick={() => redirectToStrengthAndNeedsReport(pepStatus)}>
                    {pepStatus.statusSendModule && (
                      <i className="fa-solid fa-check"></i>
                    )}
                  </td>
                  <td className="" onClick={() => redirectToMeetingReport(pepStatus)}>
                    {pepStatus.statusMeetingModule && (
                      <i className="fa-solid fa-check"></i>
                    )}
                  </td>
                  <td className="" onClick={() => redirectToTargetReport(pepStatus)}>
                    {pepStatus.statusTargetModule && (
                      <i className="fa-solid fa-check"></i>
                    )}
                  </td>
                </tr>
              ))
            )}

            <tr>
              <td colSpan="10" className="border-0">
                {/* <div className="flex justify-between items-center pt-3 px-3"> */}
                <Pagination
                  showEntries={true}
                  resultsPerPage={recordPerPage}
                  totalRecords={totalRecords}
                  paginate={paginationHandler}
                  currentPage={currentPage}
                  totalNumberOfPages={totalPages}
                  hasNextOrPreviousButton={true}
                />
                {/* </div> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyPupils;
