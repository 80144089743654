import React, { Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { useDebouncedCallback } from "use-debounce/lib";
import RadioButton from "./RadioButton";
import { Field, useFormikContext } from "formik";

//Import plus icon from react-native icon
import {
  FiPlus,
  FiEdit3,
  FiTrash,
  FiAlertTriangle,
  FiX,
  FiChevronsRight,
  FiChevronsLeft,
  FiArrowUp,
  FiArrowDown,
} from "react-icons/fi";

// Sweet Alert Componen
import Swal from "sweetalert2";

//Bulk entry modal import
import Modal from "react-awesome-modal";
//React date picker
import DatePicker from "react-datepicker";

import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import FileSaver from "file-saver";

//Mock data from json file
import atCode from "../../../../../mockdata/AtCode.json";
import { settings } from "../../../../../Components/utils/Settings";

import { routeUrl } from "../../../../../Components/utils/RouteUrl";
import useActivateTabBasicInfo from "../../../../../hooks/useActivateTabBasicInfo";
import PupilContext from "../../../../../Components/context/PupilContext";
import AttendanceContext from "../../../../../Components/context/AttendanceContext";
import { Doughnut } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import useDocumentTitle from "../../../../../hooks/useDocumentTitle";
import "chart.js/auto";
import { Formik } from "formik";

Chart.register(ChartDataLabels);

const PepAttendanceTab = (rowIndex, saveForm, onChangeHandler) => {
  let history = useHistory();
  useActivateTabBasicInfo(routeUrl.basic_info_attendance);
  const schoolId = localStorage.getItem("schoolId");

  const [reload, setReload] = useState(0);

  const { activePupilId, pupilInfo } = useContext(PupilContext);
  const { currentSesson, setcurrentSesson } = useContext(AttendanceContext);
  const [atCodeState] = useState(atCode);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [pageLoading] = useState(false);
  const [bulkEntryModelVisable, setbulkEntryModelVisable] = useState(false);
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [attendanceCode, setAttendanceCode] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [attendanceDTO, setAttendanceDTO] = useState(null);

  const [selectedStudentComments, setSelectedStudentComments] = useState(null);
  const [selectedStudentCommentsLoading, setSelectedStudentCommentsLoading] =
    useState(false);

  const [bulkAttendanceLoading, setBulkAttendanceLoading] = useState(false);

  const [getSelectedStudenLoading, setGetSelectedStudenLoading] =
    useState(false);
  const [selectedStudentAtData, setSelectedStudentAtData] = useState(null);

  const [formLoading, setFormLoading] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [deletingCommentId, setdeletingCommentId] = useState(null);
  const [editingCommentId, seteditingCommentId] = useState(null);
  const [formEditLoading, setformEditLoading] = useState(false);

  // const Term = localStorage.getItem('term')
  // setcurrentSesson(Term)

  const [chartData, setChartData] = useState({
    presentCountPer: "",
    authAbsCountPer: "",
    unauthAbsCountPer: "",
  });

  const [academicYear, setAcademicyear] = useState(null);

  // const filterHandler = (e) => {
  //   // setProcess(e.target.value);
  //   changeSession(e, "Autumn")
  // }

  useDocumentTitle("Attendance");

  useEffect(() => {
    setGetSelectedStudenLoading(true);
    setSelectedStudentCommentsLoading(true);
    setSelectedStudent(activePupilId);
    setSelectedStudentAtData(null);

    //Get data from fetchPupils Attendance api
    activePupilId &&
      axios
        .get(
          `${settings.API_HOST}/rest/fetchAttendanceByPupil?pupilId=${activePupilId}&term=${currentSesson}`
        )
        .then((response) => {
          setSelectedStudentAtData(response.data.attendances);
          setGetSelectedStudenLoading(false);
          setcurrentSesson(response.data.term);
          setAttendanceDTO(response.data.attendanceDTO);
          setChartData({
            presentCountPer: response.data.attendanceDTO?.presentCountPer,
            authAbsCountPer: response.data.attendanceDTO?.authAbsCountPer,
            unauthAbsCountPer: response.data.attendanceDTO?.unauthAbsCountPer,
          });
        })
        .then(() => {
          //Get data from fetchPupils Comment api
          axios
            .get(
              `${settings.API_HOST}/rest/fetchPupilComments?pupilId=${activePupilId}`
            )
            .then(async (response) => {
              await localStorage.setItem("activePupilId", activePupilId);

              setSelectedStudentComments(response.data.pupilsComments);
              setSelectedStudentCommentsLoading(false);
            })
            .then(() => {
              setSelectedStudent(activePupilId);
            });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePupilId]);

  useEffect(() => {
    if (reload > 0) {
      axios
        .get(
          `${settings.API_HOST}/rest/fetchAttendanceByPupil?pupilId=${activePupilId}&term=${currentSesson}`
        )
        .then((response) => {
          setSelectedStudentAtData(response.data.attendances);
          setGetSelectedStudenLoading(false);
          setcurrentSesson(response.data.term);
          setAttendanceDTO(response.data.attendanceDTO);
          setChartData({
            presentCountPer: response.data.attendanceDTO?.presentCountPer,
            authAbsCountPer: response.data.attendanceDTO?.authAbsCountPer,
            unauthAbsCountPer: response.data.attendanceDTO?.unauthAbsCountPer,
          });
        });
    }
  }, [reload]);

  const callApi = () => {
    axios
      .get(
        `${settings.API_HOST}/rest/fetchAttendanceByPupil?pupilId=${activePupilId}&term=${currentSesson}`
      )
      .then((response) => {
        setSelectedStudentAtData(response.data.attendances);
        setGetSelectedStudenLoading(false);
        setcurrentSesson(response.data.term);
        setAttendanceDTO(response.data.attendanceDTO);
        setChartData({
          presentCountPer: response.data.attendanceDTO?.presentCountPer,
          authAbsCountPer: response.data.attendanceDTO?.authAbsCountPer,
          unauthAbsCountPer: response.data.attendanceDTO?.unauthAbsCountPer,
        });
      });
  };

  const debouncedCallApi = useDebouncedCallback(callApi, 1500);

  //weekly attendance entry handle
  const weekKeyChanger = (e, row, mondayId) => {
    const getClassName = e.target.options[e.target.selectedIndex].className;

    const targetClass = `select_by_week_${row}`;

    const targetSelectBoxesFromRow =
      document.getElementsByClassName(targetClass);

    const attendanceData = {
      "attendanceDTO.friAm": "",
      "attendanceDTO.friPm": "",
      "attendanceDTO.monAm": "",
      "attendanceDTO.monPm": "",
      "attendanceDTO.thuAm": "",
      "attendanceDTO.thuPm": "",
      "attendanceDTO.tueAm": "",
      "attendanceDTO.tuePm": "",
      "attendanceDTO.wedAm": "",
      "attendanceDTO.wedPm": "",
      "attendanceDTO.masterMonId:dateId": "",
      "attendanceDTO.pupilId": "",
    };

    for (let i = 0; i < targetSelectBoxesFromRow.length; i++) {
      if (e.target.value === "/") {
        if (i === 0) {
          targetSelectBoxesFromRow[i].value = "/";

          attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] =
            "/";

          const oldClassListFromTd =
            targetSelectBoxesFromRow[i].parentNode.classList;

          if (Object.keys(oldClassListFromTd).length !== 0) {
            targetSelectBoxesFromRow[i].parentNode.classList.remove(
              oldClassListFromTd
            );
          }

          if (getClassName !== "") {
            targetSelectBoxesFromRow[i].parentNode.classList.add(getClassName);
          }
        } else {
          if (i % 2 === 0) {
            attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] =
              "/";

            targetSelectBoxesFromRow[i].value = "/";

            const oldClassListFromTd =
              targetSelectBoxesFromRow[i].parentNode.classList;

            if (Object.keys(oldClassListFromTd).length !== 0) {
              targetSelectBoxesFromRow[i].parentNode.classList.remove(
                oldClassListFromTd
              );
            }

            if (getClassName !== "") {
              targetSelectBoxesFromRow[i].parentNode.classList.add(
                getClassName
              );
            }
          } else {
            targetSelectBoxesFromRow[i].value = "";

            const oldClassListFromTd =
              targetSelectBoxesFromRow[i].parentNode.classList;

            if (Object.keys(oldClassListFromTd).length !== 0) {
              targetSelectBoxesFromRow[i].parentNode.classList.remove(
                oldClassListFromTd
              );
            }

            if (getClassName !== "") {
              targetSelectBoxesFromRow[i].parentNode.classList.add(
                getClassName
              );
            }

            attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] =
              "";
          }
        }
      } else if (e.target.value === "\\") {
        if (i === 0) {
          targetSelectBoxesFromRow[i].value = "";
          attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] = "";

          const oldClassListFromTd =
            targetSelectBoxesFromRow[i].parentNode.classList;

          if (Object.keys(oldClassListFromTd).length !== 0) {
            targetSelectBoxesFromRow[i].parentNode.classList.remove(
              oldClassListFromTd
            );
          }

          if (getClassName !== "") {
            targetSelectBoxesFromRow[i].parentNode.classList.add(getClassName);
          }
        } else {
          if (i % 2 === 0) {
            targetSelectBoxesFromRow[i].value = "";

            const oldClassListFromTd =
              targetSelectBoxesFromRow[i].parentNode.classList;

            if (Object.keys(oldClassListFromTd).length !== 0) {
              targetSelectBoxesFromRow[i].parentNode.classList.remove(
                oldClassListFromTd
              );
            }

            if (getClassName !== "") {
              targetSelectBoxesFromRow[i].parentNode.classList.add(
                getClassName
              );
            }

            attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] =
              "";
          } else {
            targetSelectBoxesFromRow[i].value = "\\";

            attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] =
              "\\";

            const oldClassListFromTd =
              targetSelectBoxesFromRow[i].parentNode.classList;

            if (Object.keys(oldClassListFromTd).length !== 0) {
              targetSelectBoxesFromRow[i].parentNode.classList.remove(
                oldClassListFromTd
              );
            }

            if (getClassName !== "") {
              targetSelectBoxesFromRow[i].parentNode.classList.add(
                getClassName
              );
            }
          }
        }
      } else if (e.target.value === "/\\") {
        if (i === 0) {
          targetSelectBoxesFromRow[i].value = "/";

          const oldClassListFromTd =
            targetSelectBoxesFromRow[i].parentNode.classList;

          if (Object.keys(oldClassListFromTd).length !== 0) {
            targetSelectBoxesFromRow[i].parentNode.classList.remove(
              oldClassListFromTd
            );
          }

          if (getClassName !== "") {
            targetSelectBoxesFromRow[i].parentNode.classList.add(getClassName);
          }

          attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] =
            "/";
        } else {
          if (i % 2 === 0) {
            targetSelectBoxesFromRow[i].value = "/";
            attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] =
              "/";

            const oldClassListFromTd =
              targetSelectBoxesFromRow[i].parentNode.classList;

            if (Object.keys(oldClassListFromTd).length !== 0) {
              targetSelectBoxesFromRow[i].parentNode.classList.remove(
                oldClassListFromTd
              );
            }

            if (getClassName !== "") {
              targetSelectBoxesFromRow[i].parentNode.classList.add(
                getClassName
              );
            }
          } else {
            targetSelectBoxesFromRow[i].value = "\\";
            attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] =
              "\\";

            const oldClassListFromTd =
              targetSelectBoxesFromRow[i].parentNode.classList;

            if (Object.keys(oldClassListFromTd).length !== 0) {
              targetSelectBoxesFromRow[i].parentNode.classList.remove(
                oldClassListFromTd
              );
            }

            if (getClassName !== "") {
              targetSelectBoxesFromRow[i].parentNode.classList.add(
                getClassName
              );
            }
          }
        }
      } else {
        targetSelectBoxesFromRow[i].selectedIndex = e.target.selectedIndex;

        attendanceData[targetSelectBoxesFromRow[i].getAttribute("name")] =
          targetSelectBoxesFromRow[i].value;

        const oldClassListFromTd =
          targetSelectBoxesFromRow[i].parentNode.classList;

        if (Object.keys(oldClassListFromTd).length !== 0) {
          targetSelectBoxesFromRow[i].parentNode.classList.remove(
            oldClassListFromTd
          );
        }

        if (getClassName !== "") {
          targetSelectBoxesFromRow[i].parentNode.classList.add(getClassName);
        }
      }
    }

    const attFormData = new FormData();

    attFormData.append(
      "attendanceDTO.friAm",
      attendanceData["attendanceDTO.friAm"]
    );
    attFormData.append(
      "attendanceDTO.friPm",
      attendanceData["attendanceDTO.friPm"]
    );
    attFormData.append(
      "attendanceDTO.monAm",
      attendanceData["attendanceDTO.monAm"]
    );
    attFormData.append(
      "attendanceDTO.monPm",
      attendanceData["attendanceDTO.monPm"]
    );
    attFormData.append(
      "attendanceDTO.thuAm",
      attendanceData["attendanceDTO.thuAm"]
    );
    attFormData.append(
      "attendanceDTO.thuPm",
      attendanceData["attendanceDTO.thuPm"]
    );
    attFormData.append(
      "attendanceDTO.tueAm",
      attendanceData["attendanceDTO.tueAm"]
    );
    attFormData.append(
      "attendanceDTO.tuePm",
      attendanceData["attendanceDTO.tuePm"]
    );
    attFormData.append(
      "attendanceDTO.wedAm",
      attendanceData["attendanceDTO.wedAm"]
    );
    attFormData.append(
      "attendanceDTO.wedPm",
      attendanceData["attendanceDTO.wedPm"]
    );
    attFormData.append("attendanceDTO.masterMonId", mondayId);
    attFormData.append("attendanceDTO.pupilId", selectedStudent);

    axios
      .post(`${settings.API_HOST}/rest/saveAttendance`, attFormData)
      .then((res) => {
        debouncedCallApi();
        // setReload(prev => prev + 1);
        // reload = reload + 1;
        console.log(res.data);
        toast.success("Attendance data successfully saved!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          if (e.target.value === "E") {
            // history.push("/exclusions");
          }
        }, 2000);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // const [chartData, setChartData] = useState({
  //   presentCountPer: attendanceDTO?.presentCountPer,
  //   authAbsCountPer: attendanceDTO?.authAbsCountPer,
  //   unauthAbsCountPer: attendanceDTO?.unauthAbsCountPer,
  // })

  const labels = ["Present", "Authorised Absence", "Unauthorised Absence"];
  // const labelData = [attendanceDTO?.presentCountPer, attendanceDTO?.authAbsCountPer, attendanceDTO?.unauthAbsCountPer]
  const labelData = [
    chartData?.presentCountPer,
    chartData?.authAbsCountPer,
    chartData?.unauthAbsCountPer,
  ];

  const chartOptions = {
    responsive: false,

    plugins: {
      legend: { display: true, position: "top" },
      datalabels: {
        display: true,
        color: "white",
        formatter: function (value, context) {
          if (value) {
            return `${value}%`;
          } else {
            return null;
          }
        },
      },
    },

    tooltips: {
      backgroundColor: "#5a6e7f",
    },
  };
  const customLabels = labels.map(
    (label, index) => `${label}: ${labelData[index]}%`
  );

  const data = {
    labels: customLabels,
    datasets: [
      {
        // label: 'My First Dataset',
        data: labelData,
        backgroundColor: ["#2EB481", "#3BA5C9", "#FF8153"],
        offset: 3,
        hoverOffset: 15,
        borderWidth: 3,
        animation: {
          animateScale: true,
        },
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      labels: {
        render: "label",
      },
    },
    pieceLabel: {
      render: "value",
    },
  };
  //change a single cell data
  const changeBgTd = (e, masterMondayId, classNameTD) => {
    const attendanceData = {
      "attendanceDTO.friAm": "",
      "attendanceDTO.friPm": "",
      "attendanceDTO.monAm": "",
      "attendanceDTO.monPm": "",
      "attendanceDTO.thuAm": "",
      "attendanceDTO.thuPm": "",
      "attendanceDTO.tueAm": "",
      "attendanceDTO.tuePm": "",
      "attendanceDTO.wedAm": "",
      "attendanceDTO.wedPm": "",
      "attendanceDTO.masterMonId:dateId": "",
      "attendanceDTO.pupilId": "",
    };

    const allRowCell = document.getElementsByClassName(classNameTD);
    const oldClassListFromTd = e.target.parentNode.classList;

    if (Object.keys(oldClassListFromTd).length !== 0) {
      e.target.parentNode.classList.remove(oldClassListFromTd);
    }
    const getClassName = e.target.options[e.target.selectedIndex].className;

    if (getClassName !== "") {
      e.target.parentNode.classList.add(getClassName);
    }

    for (let i = 0; i < allRowCell.length; i++) {
      attendanceData[allRowCell[i].getAttribute("name")] = allRowCell[i].value;
    }

    const attFormData = new FormData();

    attFormData.append(
      "attendanceDTO.friAm",
      attendanceData["attendanceDTO.friAm"]
    );
    attFormData.append(
      "attendanceDTO.friPm",
      attendanceData["attendanceDTO.friPm"]
    );
    attFormData.append(
      "attendanceDTO.monAm",
      attendanceData["attendanceDTO.monAm"]
    );
    attFormData.append(
      "attendanceDTO.monPm",
      attendanceData["attendanceDTO.monPm"]
    );
    attFormData.append(
      "attendanceDTO.thuAm",
      attendanceData["attendanceDTO.thuAm"]
    );
    attFormData.append(
      "attendanceDTO.thuPm",
      attendanceData["attendanceDTO.thuPm"]
    );
    attFormData.append(
      "attendanceDTO.tueAm",
      attendanceData["attendanceDTO.tueAm"]
    );
    attFormData.append(
      "attendanceDTO.tuePm",
      attendanceData["attendanceDTO.tuePm"]
    );
    attFormData.append(
      "attendanceDTO.wedAm",
      attendanceData["attendanceDTO.wedAm"]
    );
    attFormData.append(
      "attendanceDTO.wedPm",
      attendanceData["attendanceDTO.wedPm"]
    );
    attFormData.append("attendanceDTO.masterMonId", masterMondayId);
    attFormData.append("attendanceDTO.pupilId", selectedStudent);

    axios
      .post(`${settings.API_HOST}/rest/saveAttendance`, attFormData)
      .then((res) => {
        debouncedCallApi();

        toast.success("Attendance data successfully saved!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          if (
            attendanceData["attendanceDTO.friAm"] === "E" ||
            attendanceData["attendanceDTO.friPm"] === "E" ||
            attendanceData["attendanceDTO.monAm"] === "E" ||
            attendanceData["attendanceDTO.monPm"] === "E" ||
            attendanceData["attendanceDTO.thuAm"] === "E" ||
            attendanceData["attendanceDTO.thuPm"] === "E" ||
            attendanceData["attendanceDTO.tueAm"] === "E" ||
            attendanceData["attendanceDTO.tuePm"] === "E" ||
            attendanceData["attendanceDTO.wedAm"] === "E" ||
            attendanceData["attendanceDTO.wedPm"] === "E"
          ) {
            // history.push("/exclusions");
          }
        }, 2000);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  //get attendance code cell class name
  const getClassName = (code) => {
    const getClassNameFromCode = atCodeState.filter(
      (leter) => leter.code === code
    );

    if (getClassNameFromCode.length !== 0) {
      return getClassNameFromCode[0]["class"];
    } else {
      return "";
    }
  };

  //Submit Bulk Entry
  const submitBulkEntry = async () => {
    let error = [];

    if (startdate === "") {
      toast.warn("Start date field is required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        progress: undefined,
      });
      error.push("Error");
    }

    if (enddate === "") {
      toast.warn("End date field is required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        progress: undefined,
      });
      error.push("Error");
    }

    if (attendanceCode === null) {
      toast.warn("Attendance field field is required", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: false,
      });

      error.push("Error");
    }

    if (error.length === 0) {
      setBulkAttendanceLoading(true);

      const userId = await localStorage.getItem("userId");

      const attFormData = new FormData();

      attFormData.append("bulkAttendance.attendancecode", attendanceCode);
      attFormData.append(
        "bulkAttendance.enddate",
        moment(enddate).format("DD/MM/YYYY")
      );
      attFormData.append(
        "bulkAttendance.startdate",
        moment(startdate).format("DD/MM/YYYY")
      );
      attFormData.append("bulkAttendance.userId", userId);
      attFormData.append("bulkAttendance.pupilId", selectedStudent);
      attFormData.append("bulkAttendance.activeSession", currentSesson);

      axios
        .post(`${settings.API_HOST}/rest/savebulkAttendance`, attFormData)
        .then((res) => {
          setBulkAttendanceLoading(false);
          setbulkEntryModelVisable(false);
          setSelectedStudentAtData(res.data.attendances);
          callApi();
          toast.success("Bulk attendance entry successfull saved!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

          if (attendanceCode === "E") {
            history.push(routeUrl.basic_info_exclusions);
          }
        })
        .catch((e) => {
          setBulkAttendanceLoading(false);
          setbulkEntryModelVisable(false);
          toast.error("Sorry ! Something went wrong.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const bulkModelVis = () => {
    if (selectedStudent === null) {
      toast.warn("Please select a pupil first.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    setbulkEntryModelVisable(true);
  };

  //change session
  const changeSession = (e, sessionName) => {
    setGetSelectedStudenLoading(true);
    setSelectedStudentAtData(null);

    //Get data from fetchPupils Attendance api
    axios
      .get(
        `${settings.API_HOST}/rest/fetchAttendanceByPupil?pupilId=${selectedStudent}&term=${sessionName}`
      )
      .then((response) => {
        setSelectedStudentAtData(response.data.attendances);
        setGetSelectedStudenLoading(false);
        setcurrentSesson(response.data.term);
      });
  };

  const changeYear = (e, academicyear) => {
    setGetSelectedStudenLoading(true);
    setSelectedStudentAtData(null);

    //Get data from fetchPupils Attendance api
    axios
      .get(
        `${settings.API_HOST}/rest/fetchAttendanceByPupil?pupilId=${selectedStudent}&term=${academicyear}`
      )
      .then((response) => {
        setSelectedStudentAtData(response.data.attendances);
        setGetSelectedStudenLoading(false);
        setcurrentSesson(response.data.term);
      });
  };

  const academicYearHandler = (e) => {
    setAcademicyear(e.target.value);
    changeYear(e, "Spring");
  };

  //export attendance data
  const exportAttendanceData = () => {
    if (selectedStudent === null) {
      toast.warn("Please select a pupil first.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    setExportLoading(true);
    Swal.fire({
      title: "Which format you want to download?",
      icon: "info",
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: "XLS Format",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "black",
      confirmButtonText: "PDF Format",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */

      if (result.isConfirmed) {
        axios({
          url: `${settings.API_HOST}/rest/PDFReports?pupilId=${selectedStudent}&reportName=pupilAttendance`,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader];
            const effectiveFileName =
              suggestedFileName === undefined
                ? `${pupilInfo.fullname}_attendance_data.pdf`
                : suggestedFileName;
            FileSaver.saveAs(response.data, effectiveFileName);
            toast.success(
              "Attendance report exported and downloaded successfull as PDF Format",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
              }
            );
            Swal.close();
            setExportLoading(false);
          })
          .catch((e) => {
            toast.error("Sorry something went wrong ! Try again.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
            });
            Swal.close();
            setExportLoading(false);
          });
      } else if (result.dismiss === "cancel") {
        axios({
          url: `${settings.API_HOST}/rest/expotAttendanceExcel?pupilId=${selectedStudent}`,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader];
            const effectiveFileName =
              suggestedFileName === undefined
                ? `${pupilInfo.fullname}_attendance_data.xlsx`
                : suggestedFileName;
            FileSaver.saveAs(response.data, effectiveFileName);
            toast.success(
              "Attendance report exported and downloaded successfull as EXCEL Format",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
              }
            );
            Swal.close();
            setExportLoading(false);
          })
          .catch((e) => {
            toast.error("Sorry something went wrong ! Try again.", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
            });
          });
        Swal.close();
        setExportLoading(false);
      } else {
        setExportLoading(false);
      }
    });
  };

  //Submit comment from
  const submitCommentForm = (e) => {
    e.preventDefault();
    setFormLoading(true);

    const comment_box = document.getElementById("comment_box");

    if (comment_box.value.trim() === "") {
      toast.warn("Comment field is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setFormLoading(false);
    } else {
      const newFormData = new FormData();

      newFormData.append("pupilsComment.comment", comment_box.value);
      newFormData.append("pupilsComment.pupilId", selectedStudent);

      axios
        .post(`${settings.API_HOST}/rest/savepPupilComments`, newFormData)
        .then((res) => {
          toast.success("Comment saved successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

          setFormLoading(false);
          setSelectedStudentComments(res.data.pupilsComments);

          comment_box.value = "";
        })
        .catch((e) => {
          toast.error("Sorry something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setFormLoading(false);
          comment_box.value = "";
        });
    }
  };

  //Delete comment with confim handle
  const deleteComment = (id) => {
    setdeletingCommentId(id);
    setdeleteLoading(true);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const newFormData = new FormData();

        newFormData.append("pupilsComment.id", id);
        newFormData.append("pupilsComment.pupilId", selectedStudent);

        axios
          .post(`${settings.API_HOST}/rest/deletePupilComments`, newFormData)
          .then((res) => {
            toast.success("Comment deleted successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            const newCommentsData = selectedStudentComments.filter(
              (commentData) => commentData.id !== id
            );

            setdeleteLoading(true);
            setSelectedStudentComments(newCommentsData);
            setdeletingCommentId(null);
          })
          .catch((e) => {
            toast.error("Sorry something went wrong!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            setdeleteLoading(false);
            setdeletingCommentId(null);
          });
      } else {
        setdeleteLoading(false);
        setdeletingCommentId(null);
      }
    });
  };

  //Edit comment and pppulate comment field input
  const editComment = (id) => {
    seteditingCommentId(id);

    const filterComment = selectedStudentComments.filter(
      (comment) => comment.id === id
    );

    const comment_box = document.getElementById("comment_box");
    comment_box.value = filterComment[0].comment;
  };

  //Submit update comment form
  const submitCommentEditForm = () => {
    setformEditLoading(true);
    const comment_box = document.getElementById("comment_box");

    if (comment_box.value.trim() === "") {
      toast.warn("Comment field is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setformEditLoading(false);
    } else {
      const newFormData = new FormData();

      newFormData.append(" pupilsComment.id", editingCommentId);
      newFormData.append("pupilsComment.comment", comment_box.value);
      newFormData.append("pupilsComment.pupilId", selectedStudent);

      axios
        .post(`${settings.API_HOST}/rest/savepPupilComments`, newFormData)
        .then((res) => {
          toast.success("Comment updates successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setformEditLoading(false);
          setSelectedStudentComments(res.data.pupilsComments);
          setdeletingCommentId(null);
          comment_box.value = "";
        })
        .catch((e) => {
          toast.error("Sorry something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setformEditLoading(false);
          setdeletingCommentId(null);
          comment_box.value = "";
        });
    }
  };

  return (
    <div className="secondary-section w-full">
      {/* <ToastContainer /> */}
      <Modal
        visible={bulkEntryModelVisable}
        width="400"
        height="350"
        effect="fadeInUp"
        onClickAway={() => setbulkEntryModelVisable(false)}
      >
        <div className="flex justify-between items-center flex-row pb-6 pt-8  px-8">
          <h4>Bulk Attendance Entry Form</h4>

          <FiX
            onClick={() => setbulkEntryModelVisable(false)}
            className="cursor-pointer"
          />
        </div>

        <div className="flex flex-col  items-center px-4 pb-4 h-full w-full mt-4">
          <div className="pupil-form-field text-field mb-2">
            <div className="form-field-label-exclusion grid-col-3 text-end">
              <label htmlFor="">
                Start Date<span className="red">*</span>
              </label>
            </div>
            <div className="form-field-body">
              <div className="flex flex-row items-center w-full">
                <div>
                  <DatePicker
                    selected={startdate}
                    className="rounded-custom-content"
                    onChange={(date) => setStartdate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pupil-form-field text-field mb-2">
            <div className="form-field-label-exclusion grid-col-3 text-end">
              <label htmlFor="">
                End Date<span className="red">*</span>
              </label>
            </div>
            <div className="form-field-body">
              <div className="flex flex-row items-center w-full">
                <div>
                  <DatePicker
                    selected={enddate}
                    className="rounded-custom-content"
                    onChange={(date) => setEnddate(date)}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="pupil-form-field text-field mb-4">
            <div className="form-field-label-exclusion grid-col-3 text-end">
              <label htmlFor="">
                Attendance Code<span className="red">*</span>
              </label>
            </div>
            <div className="form-field-body">
              <div className="flex flex-row items-center w-full">
                <div className="w-32">
                  <select
                    value={attendanceCode}
                    className="rounded-custom-content"
                    onChange={(e) => setAttendanceCode(e.target.value)}
                  >
                    {atCodeState !== null &&
                      atCodeState.map((optionItem, b) => (
                        <option
                          key={Math.random()}
                          className={optionItem.class}
                        >
                          {optionItem.code}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="pupil-form-field text-field mb-2">
            <div className="form-field-label-exclusion grid-col-3 text-end"></div>
            <div className="form-field-body">
              <div className="flex flex-row items-center w-full">
                <div className="flex flex-row items-center">
                  {!bulkAttendanceLoading && (
                    <button
                      className="px-6 py-2 text-sm rounded-full"
                      style={{ backgroundColor: "#E6E6F2", color: "#4C4D6C" }}
                      onClick={() => setbulkEntryModelVisable(false)}
                    >
                      Close
                    </button>
                  )}

                  <button
                    className="px-6 py-2 text-sm text-white rounded-full ml-4 flex flex-row items-center justify-center"
                    style={{
                      backgroundColor: "rgb(97, 65, 167)",
                      minWidth: "120px",
                    }}
                    onClick={() => submitBulkEntry()}
                  >
                    {bulkAttendanceLoading ? (
                      <div className="spinner"></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="basic-header flex w-full py-2 justify-between">
        <div className="basic-left">
          <h1>
            Attendance
            <span>
              <i className="fa-regular fa-circle-video"></i>
            </span>
            <span>
              <i className="fa-regular fa-circle-info"></i>
            </span>
          </h1>
        </div>
        <div className="basic-right">
          <span>
            <i className="fa-regular fa-user"></i>
          </span>
          <input
            type="text"
            className="pep-input"
            value={pupilInfo && pupilInfo.fullname}
            onChange={(e) => console.log(e.target.value)}
          />
          <label className="ml-4 pr-2">Academic Year</label>
          <select
            className="pep-select mr-4"
            value={academicYear}
            onChange={academicYearHandler}
          >
            <option value=""></option>
            <option value="2018-19">2018 - 2019</option>
            <option value="2019-20">2019 - 2020</option>
            <option value="2020-21">2020 - 2021</option>
            <option value="2021-22">2021 - 2022</option>
            <option value="2022-23">2022 - 2023</option>
          </select>

          <span className="ml-2 bg-white">
            <i className="fa-regular fa-video"></i>
          </span>
          <button
            className="btn pep-btn export"
            onClick={(e) => exportAttendanceData()}
          >
            {exportLoading ? <div className="spinner"></div> : "Export"}
          </button>
        </div>
      </div>
      <div className="lg:flex attendance-inr gap-4">
        <div className="attendance-left lg:w-8/12">
          <div className="rounded-3xl bg-white p-4 w-full">
            <div className="left-filter flex justify-between">
              <button
                className="btn purple mr-2"
                onClick={(e) => bulkModelVis()}
              >
                Bulk Entry
              </button>
              <div className="terms-btn">
                <button
                  className={
                    currentSesson === "Autumn"
                      ? "active btn s-green active mr-2"
                      : "btn s-green mr-2"
                  }
                  onClick={(e) => changeSession(e, "Autumn")}
                  disabled={
                    selectedStudent === null || getSelectedStudenLoading
                      ? true
                      : false
                  }
                >
                  Autumn
                </button>
                <button
                  className={
                    currentSesson === "Spring"
                      ? "active btn s-green active mr-2"
                      : "btn s-green mr-2"
                  }
                  onClick={(e) => changeSession(e, "Spring")}
                  disabled={
                    selectedStudent === null || getSelectedStudenLoading
                      ? true
                      : false
                  }
                >
                  Spring
                </button>
                <button
                  className={
                    currentSesson === "Summer"
                      ? "active btn s-green active mr-2"
                      : "btn s-green mr-2"
                  }
                  onClick={(e) => changeSession(e, "Summer")}
                  disabled={
                    selectedStudent === null || getSelectedStudenLoading
                      ? true
                      : false
                  }
                >
                  Summer
                </button>
              </div>
            </div>
            <div className="table-box w-full pt-2">
              <div className="table-outer rounded-3xl bg-white">
                {!pageLoading ? (
                  <Fragment>
                    {selectedStudent !== null ? (
                      !getSelectedStudenLoading &&
                      selectedStudentAtData !== null ? (
                        <table className="table-fixed w-100 attendecnce_data_view_table">
                          <thead>
                            <tr>
                              <th className="w-48">Week</th>
                              <th colSpan="2">Mon</th>
                              <th colSpan="2">Tue</th>
                              <th colSpan="2">Wed</th>
                              <th colSpan="2">Thur</th>
                              <th colSpan="2">Fri</th>
                            </tr>
                            <tr>
                              <th className="w-48">Commencing</th>
                              <th colSpan="2">
                                <div>
                                  <span>AM</span>
                                  <span>PM</span>
                                </div>
                              </th>
                              <th colSpan="2">
                                <div>
                                  <span>AM</span>
                                  <span>PM</span>
                                </div>
                              </th>
                              <th colSpan="2">
                                <div>
                                  <span>AM</span>
                                  <span>PM</span>
                                </div>
                              </th>
                              <th colSpan="2">
                                <div>
                                  <span>AM</span>
                                  <span>PM</span>
                                </div>
                              </th>
                              <th colSpan="2">
                                <div>
                                  <span>AM</span>
                                  <span>PM</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedStudentAtData.map((row, i) => (
                              <tr key={Math.random()}>
                                <td className="w-48">
                                  <div>
                                    <span style={{ color: "#4c4d6c" }}>
                                      {row.date}
                                    </span>
                                    <select
                                      name=""
                                      className="w-16 mx-3 px-3 py-1 rounded-full outile-none focus:outline-none table_count_action"
                                      onChange={(e) =>
                                        weekKeyChanger(
                                          e,
                                          row.date,
                                          row.masterMonId
                                        )
                                      }
                                    >
                                      {atCodeState !== null &&
                                        atCodeState.map((optionItem, b) => (
                                          <option
                                            key={Math.random()}
                                            className={optionItem.class}
                                          >
                                            {optionItem.code}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </td>
                                <td className={getClassName(row.monAm)}>
                                  <select
                                    name="attendanceDTO.monAm"
                                    defaultValue={row.monAm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={b}
                                          className={optionItem.class}
                                          value={optionItem.code}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td className={getClassName(row.monPm)}>
                                  <select
                                    name="attendanceDTO.monPm"
                                    defaultValue={row.monPm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={Math.random()}
                                          className={optionItem.class}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td className={getClassName(row.tueAm)}>
                                  <select
                                    name="attendanceDTO.tueAm"
                                    defaultValue={row.tueAm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={Math.random()}
                                          className={optionItem.class}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td className={getClassName(row.tuePm)}>
                                  <select
                                    name="attendanceDTO.tuePm"
                                    defaultValue={row.tuePm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={Math.random()}
                                          className={optionItem.class}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td className={getClassName(row.wedAm)}>
                                  <select
                                    name="attendanceDTO.wedAm"
                                    defaultValue={row.wedAm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={Math.random()}
                                          className={optionItem.class}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td className={getClassName(row.wedPm)}>
                                  <select
                                    name="attendanceDTO.wedPm"
                                    defaultValue={row.wedPm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={Math.random()}
                                          className={optionItem.class}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td className={getClassName(row.thuAm)}>
                                  <select
                                    name="attendanceDTO.thuAm"
                                    defaultValue={row.thuAm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={Math.random()}
                                          className={optionItem.class}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td className={getClassName(row.thuPm)}>
                                  <select
                                    name="attendanceDTO.thuPm"
                                    defaultValue={row.thuPm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={Math.random()}
                                          className={optionItem.class}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td className={getClassName(row.friAm)}>
                                  <select
                                    name="attendanceDTO.friAm"
                                    defaultValue={row.friAm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={Math.random()}
                                          className={optionItem.class}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td className={getClassName(row.friPm)}>
                                  <select
                                    name="attendanceDTO.friPm"
                                    defaultValue={row.friPm}
                                    className={`custom_select_td_basic select_by_week_${row.date}`}
                                    onChange={(e) =>
                                      changeBgTd(
                                        e,
                                        row.masterMonId,
                                        `custom_select_td_basic select_by_week_${row.date}`
                                      )
                                    }
                                  >
                                    {atCodeState !== null &&
                                      atCodeState.map((optionItem, b) => (
                                        <option
                                          key={Math.random()}
                                          className={optionItem.class}
                                        >
                                          {optionItem.code}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="loader"></div>
                        </div>
                      )
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <FiAlertTriangle color="rgb(97, 65, 167)" size={50} />
                        <h4
                          style={{
                            color: "#4C4D6C",
                            marginTop: "10px",
                            fontSize: "15px",
                          }}
                        >
                          Please select a pupil first
                        </h4>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Skeleton count={12} height={50} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="attendance-right sm:pt-4 lg:w-4/12">
          <div className="rounded-3xl bg-white px-4 w-full">
            <div className="table-box w-full pt-2">
              <div className="table-outer rounded-3xl bg-white">
                <table className="table-attn text-center w-full">
                  <thead>
                    <tr>
                      <th colspan="3">
                        Attendance by Sessions between{" "}
                        <span>{academicYear}</span> and{" "}
                        <span>{academicYear}</span>
                      </th>
                    </tr>
                    <tr>
                      <th className="blue w-7/12"></th>
                      <th className="d-blue w-3/12">Session</th>
                      <th className="purple w-2/12">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Present</td>
                      <td>
                        {attendanceDTO?.presentCount
                          ? attendanceDTO.presentCount
                          : "0"}
                      </td>
                      <td>
                        {attendanceDTO?.presentCountPer
                          ? attendanceDTO.presentCountPer
                          : "0"}
                      </td>
                    </tr>
                    <tr>
                      <td>Authorised Absence</td>
                      <td>
                        {attendanceDTO?.authAbsCount
                          ? attendanceDTO.authAbsCount
                          : ""}
                      </td>
                      <td>
                        {attendanceDTO?.authAbsCountPer
                          ? attendanceDTO.authAbsCountPer
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Unauthorised Absence</td>
                      <td>
                        {attendanceDTO?.unauthAbsCount
                          ? attendanceDTO.unauthAbsCount
                          : ""}
                      </td>
                      <td>
                        {attendanceDTO?.unauthAbsCountPer
                          ? attendanceDTO.unauthAbsCountPer
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Possible sessions</td>
                      <td>
                        {attendanceDTO?.totalSessions
                          ? attendanceDTO.totalSessions
                          : ""}
                      </td>
                      <td>
                        {/* {attendanceDTO?.unauthAbsCountPer ? attendanceDTO.unauthAbsCountPer : ""} */}
                      </td>
                    </tr>
                    <tr>
                      <td>Missing</td>
                      <td>
                        {attendanceDTO?.totalMissingCount
                          ? attendanceDTO.totalMissingCount
                          : ""}
                      </td>
                      <td>
                        {/* {attendanceDTO?.unauthAbsCountPer ? attendanceDTO.unauthAbsCountPer : ""} */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {(() => {
            if (schoolId && schoolId == 29679) {
              return (
                <div className="rounded-3xl schoolId bg-white mt-4 p-4 w-full">
                  <div className="table-box w-full pt-2">
                    <div className="table-outer rounded-3xl bg-white">
                      <table className="table-fixed text-center w-full">
                        <tbody>
                          <tr>
                            <td className="blue">Punctuality</td>
                            <td>
                              <select className="pep-select">
                                <option></option>
                                <option>Outstanding</option>
                                <option>Good</option>
                                <option>Requires Improvement</option>
                                <option>Poor</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td className="purple">Attitude to learning</td>
                            <td>
                              <select className="pep-select">
                                <option></option>
                                <option>Outstanding</option>
                                <option>Good</option>
                                <option>Requires Improvement</option>
                                <option>Poor</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td className="orange">Behaviour</td>
                            <td>
                              <select className="pep-select">
                                <option></option>
                                <option>Outstanding</option>
                                <option>Good</option>
                                <option>Requires Improvement</option>
                                <option>Poor</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            }
          })()}
          <div className="rounded-3xl bg-white p-4 mt-4 w-full">
            <div className="chart-div p-2 flex justify-center relative">
              <Doughnut
                data={data}
                options={chartOptions}
                aspectRatio={2}
                width={350}
                height={350}
              />
            </div>
          </div>
          {/* <Field>
          <RadioButton
            fieldValueOne={`No`}
            fieldValueTwo={`Yes`}
            fieldName={`dataTarget.smartTargetRows[${rowIndex}].vsAgreed`}
            classOne={`radio-label act cursor-pointer`}
            classTwo={`radio-label yes cursor-pointer`}
            fieldTextOne={`No`}
            fieldTextTwo={`Yes`}
            onChangeHandler={onChangeHandler}
            saveForm={saveForm}
          />
          </Field> */}
        </div>
      </div>
      <br /> <br />
      <div className="terms-btn"></div>
      <div className="rounded-3xl bg-white mt-4 p-4 w-full">
        <div className="table-box w-full pt-2">
          <div className="table-outer">
            {/* <table className="table-fixed text-left w-full">
              <thead>
                <tr>
                  <th className="blue w-1/12"></th>
                  <th className="green w-1/12 ...">Date</th>
                  <th className="pink w-10/12 ...">Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className="mr-2 fa-regular fa-pen-to-square"></i>
                    <i className="fa-regular fa-circle-trash"></i>
                  </td>
                  <td>09/09/2021</td>
                  <td>test comment</td>
                </tr>
              </tbody>
            </table> */}
            <div className="relative w-full h-full">
              <div className="radio-label yes cursor-pointer">
                <div className="toggle-handle w-full flex justify-center items-center">
                  Yes
                </div>
              </div>
            </div>
            <div className="w-full py-4">
              <h3 className="rounded-3xl w-full px-4">Comment</h3>
            </div>
            {!pageLoading ? (
              selectedStudent !== null ? (
                !selectedStudentCommentsLoading &&
                selectedStudentComments !== null ? (
                  <Fragment>
                    <div className="cooment-form">
                      <div className="widebox-inr w-full px-2 pb-2">
                        <div className="textarea-block mt-1">
                          <textarea
                            className="rounded-2xl px-2 py-4 textarea w-full"
                            placeholder="Type here..."
                            id="comment_box"
                          ></textarea>
                        </div>
                      </div>

                      {editingCommentId !== null ? (
                        <button
                          className="btn pep-btn"
                          onClick={(e) => submitCommentEditForm(e)}
                          style={{ minWidth: "120px" }}
                        >
                          {formEditLoading ? (
                            <div className="spinner"></div>
                          ) : (
                            "Update"
                          )}
                        </button>
                      ) : (
                        <button
                          className="btn pep-btn"
                          onClick={(e) => submitCommentForm(e)}
                          style={{ minWidth: "120px" }}
                        >
                          {formLoading ? (
                            <div className="spinner"></div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}
                    </div>

                    <div className="mt-6">
                      <table className="table-auto w-100 comment-table">
                        <thead>
                          <tr>
                            <th style={{ maxWidth: "130px" }}></th>
                            <th style={{ maxWidth: "200px" }}>Written By</th>
                            <th style={{ maxWidth: "120px" }}>Date</th>
                            <th className="w-2/3 style={{maxWidth: '55px'}}">
                              Comment
                            </th>
                          </tr>
                        </thead>
                        <tbody className="mt-4">
                          {selectedStudentComments.map((comment, i) => (
                            <tr key={i} className="">
                              <td style={{ paddingLeft: "5px" }}>
                                <div className="flex flex-row items-center ">
                                  <span className="mr-4">
                                    <i
                                      className="mr-2 fa-regular fa-pen-to-square cursor-pointer"
                                      onClick={() => editComment(comment.id)}
                                    ></i>
                                  </span>
                                  <span>
                                    {deletingCommentId === comment.id &&
                                    deleteLoading ? (
                                      <div className="spinner"></div>
                                    ) : (
                                      <i
                                        className="fa-regular fa-circle-trash cursor-pointer"
                                        onClick={() =>
                                          deleteComment(comment.id)
                                        }
                                      ></i>
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td>{comment?.writtenBy}</td>
                              <td>
                                {moment(comment.createdDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>{comment.comment}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div className="loader"></div>
                  </div>
                )
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <FiAlertTriangle color="rgb(97, 65, 167)" size={50} />
                  <h4
                    style={{
                      color: "#4C4D6C",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Please select a pupil first
                  </h4>
                </div>
              )
            ) : (
              <Skeleton count={5} height={50} />
            )}
          </div>
        </div>
      </div>
      {/* <div className="rounded-3xl bg-white mt-4 p-4 w-full">
        <div className="table-box w-full pt-2">
          <div className="table-outer">
            <table className="table-fixed text-smalls text-left w-full">
              <tbody>
                <tr>
                  <td className="blue w-4/12">
                    Is this person at risk of exclusion?
                  </td>
                  <td>
                    <div className="radio">
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio"
                          name="accountType"
                          value="Yes"
                        />
                        <span className="ml-2">Yes</span>
                      </label>
                      <label className="inline-flex items-center ml-6">
                        <input
                          type="radio"
                          className="form-radio"
                          name="accountType"
                          value="No"
                        />
                        <span className="ml-2">No</span>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="d-blue w-4/12">
                    What arrangements are there for exclusions?
                  </td>
                  <td>
                    <textarea
                      rows="1"
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder=""
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td className="purple w-4/12">
                    Are there any concern we have with the Young Person's
                    social, emotional and mental health?
                  </td>
                  <td>
                    <textarea
                      rows="1"
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder=""
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td className="orange w-4/12">
                    Comments on this information
                  </td>
                  <td>
                    <textarea
                      rows="1"
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder=""
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td className="d-orange w-4/12">
                    If attendance is falling or below 95% what support is in
                    place to raise it
                  </td>
                  <td>
                    <textarea
                      rows="1"
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder=""
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td className="d-pink w-4/12">
                    How have these figures changed since the previous PEP?
                  </td>
                  <td>
                    <textarea
                      rows="1"
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder=""
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      <div className="rounded-3xl flex gap-2 bg-white mt-4 p-4 w-full">
        <div className="tables w-3/12">
          <div className="table-box w-full pt-2">
            <div className="table-outer">
              <table className="text-smalls text-left w-full">
                <tbody>
                  <tr>
                    <th className="blue" colspan="2">
                      Unauthorised Absence Codes
                    </th>
                  </tr>
                  <tr>
                    <td>G</td>
                    <td>Unauthorised holiday</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>N</td>
                    <td>Reason not provided</td>
                  </tr>
                  <tr>
                    <td>O</td>
                    <td>Unauthorised absence</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>U</td>
                    <td>Arrival after registration</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="tables w-3/12">
          <div className="table-box w-full pt-2">
            <div className="table-outer">
              <table className="text-smalls text-left w-full">
                <tbody>
                  <tr>
                    <th className="blue" colspan="2">
                      Administrative
                    </th>
                  </tr>
                  <tr>
                    <td>X</td>
                    <td>Not required to be in school</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>Y</td>
                    <td>Unable to attend due to exceptional circumstances</td>
                  </tr>
                  <tr>
                    <td>Z</td>
                    <td>Pupil not on admission register</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>#</td>
                    <td>Planned school closure</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="tables w-3/12">
          <div className="table-box w-full pt-2">
            <div className="table-outer">
              <table className="text-smalls text-left w-full">
                <tbody>
                  <tr>
                    <th className="blue" colspan="2">
                      Attendance codes
                    </th>
                  </tr>
                  <tr className="bgcolor">
                    <td>/</td>
                    <td>Present (AM)</td>
                  </tr>
                  <tr>
                    <td>\</td>
                    <td>Present (PM)</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>B</td>
                    <td>Off-site educational activity</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>D</td>
                    <td>Dual registered</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>J</td>
                    <td>Interview</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>L</td>
                    <td>Late arrival</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>P</td>
                    <td>Sporting activity</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>V</td>
                    <td>Educational visit or trip</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>W</td>
                    <td>Work experience</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="tables w-3/12">
          <div className="table-box w-full pt-2">
            <div className="table-outer">
              <table className="text-smalls text-left w-full">
                <tbody>
                  <tr>
                    <th className="blue" colspan="2">
                      Authorised Absence Codes
                    </th>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td>Authorised leave of absence</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>E</td>
                    <td>Excluded</td>
                  </tr>
                  <tr>
                    <td>H</td>
                    <td>Authorised holiday</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>I</td>
                    <td>Illness</td>
                  </tr>
                  <tr>
                    <td>M</td>
                    <td>Medical/dental appointment</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>R</td>
                    <td>Religious observance</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>S</td>
                    <td>Study leave</td>
                  </tr>
                  <tr className="bgcolor">
                    <td>T</td>
                    <td>Gypsy, Roma and Traveller absence</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PepAttendanceTab;
