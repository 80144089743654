import { setHistoryList } from "../constants/reducerActions"

const historyModalReducer = (state, action) => {
  switch (action.type) {
    case setHistoryList:
      return {
        historyList: action.payload.data,
        isLoading: false,
      }
  }
}

export default historyModalReducer;