import axios from 'axios';
import moment from 'moment';
import PupilContext from '../../../../../Components/context/PupilContext';
import { settings } from '../../../../../Components/utils/Settings';
import { routeUrl } from '../../../../../Components/utils/RouteUrl';
import useActivateTabBasicInfo from '../../../../../hooks/useActivateTabBasicInfo';
import React, { useState, useEffect, useContext } from 'react';
import { Form, Formik } from 'formik';
import AltNeedTableY12ToY14 from '../../../../../Components/NCC/PersonalInfo/AltNeedEd/AltNeedTableY12ToY14';
import AltNeedTableY1ToY11 from '../../../../../Components/NCC/PersonalInfo/AltNeedEd/AltNeedTableY1ToY11';
import AltNeedTableRToN4 from '../../../../../Components/NCC/PersonalInfo/AltNeedEd/AltNeedTableRToN4';


const PepAltEdNeetTab = () => {

  // Activating tab link for Current page on basic info nav
  useActivateTabBasicInfo(routeUrl.basic_info_pep_alt_ed_neet);
  const { activePupilId, activePupilYear, dateOfMeeting, pupilInfo: pupilInfoContext } = useContext(PupilContext);

  // const activePupilYear = pupilInfo.classYear;

  const roleId = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const schoolId = localStorage.getItem("schoolId");
  const kidYear = localStorage.getItem("year");
  // const { pupilInfo } = useContext(PupilContext);
  const [ pupilInfo, setPupilInfo] = useState({

  });

  useEffect(() => {
    activePupilId && axios(`${settings.API_HOST}/rest/fetchPupilBasicInfo?pupilId=${activePupilId}&schoolId=${schoolId}&roleId=${roleId}&userId=${userId}`)
    
      .then(res => {
           console.log(res.data.pupilInfo,"Alt nd Neet");
           setPupilInfo(res.data.pupilInfo);
      });    

  }, [activePupilId]);

  const initialFormState = {
    pupilInfo,
  }

  const onChangeHandler = () => {

  }

  const saveForm = () => {

  }

  return (
    <Formik
      initialValues={initialFormState}
      enableReinitialize
    >
      <Form>
        <div className="post16-section w-full">
          <div className="basic-header flex w-full py-2 justify-between">
            <div className="basic-left">
              <h1>Alternative Education & NEET
                <span><i className="fa-regular fa-circle-video"></i></span>
                <span><i className="fa-regular fa-circle-info"></i></span>
              </h1>
            </div>
            <div className="basic-right">
            <span>
            <i className="fa-regular fa-user"></i>
          </span>
        
          <input
            type="text"
            className="pep-input"
            value={pupilInfoContext && pupilInfoContext.fullname}
            onChange={(e) => console.log(e.target.value)}
          />
            </div>
            <button  className="blue-button px-7 py-3  text-sm" value="Save" onClick={() => this} >Save</button>
          </div>

          {
            (activePupilYear ==='Y12' ||activePupilYear ==='Y13' ||activePupilYear ==='Y14') &&
              <AltNeedTableY12ToY14 pupilInfo={pupilInfo} />
          }

          {(activePupilYear ==='R' ||activePupilYear ==='N' ||activePupilYear ==='N1' ||activePupilYear ==='N2' ||activePupilYear ==='N3' ||activePupilYear ==='N4') &&
            <AltNeedTableRToN4 pupilInfo={pupilInfo} />
          }

          {
            (activePupilYear === 'Y1' || activePupilYear === 'Y2' || activePupilYear === 'Y3' || activePupilYear === 'Y4' || activePupilYear === 'Y4' || activePupilYear === 'Y5' ||
            activePupilYear ==='Y6'||activePupilYear ==='Y7'||activePupilYear ==='Y8'||
            activePupilYear ==='Y9'||activePupilYear ==='Y10'||activePupilYear ==='Y11') &&

              <AltNeedTableY1ToY11  pupilInfo={pupilInfo} />
          }


        </div>
      </Form>
    </Formik>
  )
}

export default PepAltEdNeetTab;