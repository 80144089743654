import React from 'react'
import Skeleton from 'react-loading-skeleton'

const NccMeetingSkeleton = () => {
  return (
    <div>
      <div className="w-28">
        <Skeleton height={20}/>
      </div>

      <div className="flex flex-col rounded-3xl bg-white w-full mt-3">
        <div className="flex gap-3 w-full">
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
        </div>
        <div className="flex gap-3 w-full">
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
        </div>
        <div className="flex gap-3 w-full">
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
        </div>
        <div className="flex gap-3 w-full">
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
          <div className="p-3 w-full">
            <Skeleton height={30}/>
          </div>
        </div>
      </div>

      <div className="w-36 mt-3">
        <Skeleton height={20} />
      </div>

      <div className="flex flex-col gap-2 rounded-3xl bg-white p-4 overflow-hidden mt-3">
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 rounded-3xl bg-white p-4 overflow-hidden mt-3">
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 rounded-3xl bg-white p-4 overflow-hidden mt-3">
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
      </div>
      
      <div className="flex flex-col gap-2 rounded-3xl bg-white p-4 overflow-hidden mt-3">
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-38">
            <Skeleton height={20} />
          </div>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 rounded-3xl bg-white p-4 overflow-hidden mt-3">
        <div className="w-28">
          <Skeleton height={20} />
        </div>
        <div className="flex gap-2">
          <div className="w-96">
           <Skeleton height={20} />
          </div>
          <div className="w-32">
           <Skeleton height={20} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="w-36">
            <Skeleton height={20} />
          </div>
          <div className="w-full p-10">
            <Skeleton height={20} />
          </div>
        </div>
      </div>

    </div>
  )
}

export default NccMeetingSkeleton
