import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const checkForNullAndReturnEmpty = (value, emptyReceivedObject) => {
  if (value === null || !value) {
    return emptyReceivedObject
  } else {
    return value;
  }
}

export const convertDateFromUkToUs = (date) => {
  const formatedDate =  date.split("/");
  if(formatedDate.length === 3) {
    return `${formatedDate[1]}-${formatedDate[0]}-${formatedDate[2]}`;
  } else {
    return null;
  }
}

export const getPrototypeName = (value) => {
  if(!value) {
    return "";
  }
  const funcNameRegex = /function (.{1,})\(/;
  const results = (funcNameRegex).exec((value).constructor.toString());
  return (results && results.length > 1) ? results[1] : "";
}

export const showErrorToast = (msg, rest) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    ...rest,
  });
}

export const showSuccessToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
  });
}

export const showWarningToast = (msg, rest) => {
  toast.warning(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    ...rest,
  });
}

export const getYearGroup = (classYear) => {
  if (
    classYear === "N" ||
    classYear === "N1" ||
    classYear === "N2" ||
    classYear === "N3" ||
    classYear === "N4"
  ) {
    return "eyfs";
  } else if (
    classYear === "Y1" ||
    classYear === "Y2" ||
    classYear === "Y3" ||
    classYear === "Y4" ||
    classYear === "Y5" ||
    classYear === "Y6"
  ) {
    return "primary";
  } else if (
    classYear === "Y7" ||
    classYear === "Y8" ||
    classYear === "Y9" ||
    classYear === "Y10" ||
    classYear === "Y11"
  ) {
    return "secondary";
  } else if (
    classYear === "Y12" ||
    classYear === "Y13" ||
    classYear === "Y14" ||
    classYear === "Y15" ||
    classYear === "Y16"
  ) {
    return "post16";
  }
}

export const isObjectEmpty = (object) => {
  if (object) {
    return Object.keys(object).length === 0;
  }
  return true;
}

export const isValueEmpty = (value) => {
  return !value || value.trim() <= 0;
}

export const getArrayIndexFromString = (value) => {
  try {
    return parseInt(value.split("[")[1].split("]")[0]);
  }
  catch (err) {
    return "Value should be like eg: 'value[1]' ";
  }
}

export const handleEmptyValuesForSaving = (value) => {
  let valueToSave;
  if (value) {
    if (value === "null") {
      valueToSave = "";
    } else {
      valueToSave = value;
    }
  } else {
    if (value == "0") {
      valueToSave = value;
    } else {
      if (value === undefined || value === "null") {
        valueToSave = "";
      }
      valueToSave = "";
    }
  }
  return valueToSave;
}

export const formValidationWrapper = ({
  fieldName,
  setCompleteButtonLoader,
  saveForm = () => { },
  saveCallback = () => { },
  successCallback = () => { },
  checkForErrors = (errors) => {},
}) => {
  const formValidation = (values, formik) => {
    const updatedValues = { ...values };
    const error = checkForErrors(values);
    formik.setFieldValue(fieldName, 1);

    if (error && error.trim().length > 0) {
      showValidationError(error);
    } else {
      successCallback(updatedValues);

      saveForm({
        values: updatedValues,
        setIsLoading: setCompleteButtonLoader,
        saveCallback,
        
      })
    }
  }
  return formValidation;
}

export const checkForForTrimmedEmptyValue = (value) => {
  try {
    return !value || value.trim() <= 0;
  }
  catch(e) {
    return true;
  }
}

export const capatalizeFirstLetter = (word) => {
  const capatalizeWord = `${word.charAt(0).toUpperCase()}${word.slice(1)}` ;
  return capatalizeWord;

}

export const showValidationError = (erroMsg, callback=()=>{}) => {
  Swal.fire({
    iconColor: "#D54336",
    icon: 'warning',
    title: erroMsg,
    showConfirmButton: true,
    button: "Ok",
  })
}

export const showReportCompleteSuccess = (successMsg) => {
  Swal.fire({
    iconColor: "#3daf49",
    iconHtml: '<i className="fa-regular fa-check"></i>',
    title: successMsg,
    button: "Ok",
  })
}

export const isUserRoleAuthenticated = (userRoleList, userId) => {
  let flag = false;
  userRoleList.forEach(role => {
    if (role === parseInt(userId)) {
      flag = true;
    }
  }) 
  return flag;
}