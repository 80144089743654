import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import PupilContext from '../../../../Components/context/PupilContext';
import Modal from '../../../../Components/Modal';
import { settings } from '../../../../Components/utils/Settings';
import { showErrorToast } from '../../../../Components/utils/utilityFunctions';

const PepCinCpHistoryModule = (props) => {
  
  const [historyModalList, setHistoryModalList] = useState(null);
  const {activePupilId} = useContext(PupilContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios(`${settings.API_HOST}/rest/fetchCinCpFieldsHistory?pupilId=${activePupilId}&attribute=${props.historyModalAtrributeName}`)
    .then((res) => {
      setHistoryModalList(res.data.cicHistoryList);
      setIsLoading(false);
    })
    .catch((err) => {
        setIsLoading(false);
        showErrorToast(err);
      });
  }, [props.historyModalAtrributeName])

  return (
    <Modal 
      isOpen={props.isHistoryModalOpen} 
      closeModal={props.closeHistoryModal}
    >
      <div className="history-modal">
        <div className="history-modal-heading flex justify-between gap-4">
          <h2 className="flex-1"> History</h2>
          <FiX size={30} className="modal-content-close-icon" onClick={props.closeHistoryModal} />
        </div>
        <table className="history-modal-table child-table">
          <thead>
            <tr>
              <th>Field</th>
              <th>Year Group</th>
              <th>Created Date</th>
            </tr>
          </thead>
          <tbody>
          {
            isLoading ? 
              <tr>
                <td colSpan={3}><Skeleton /></td>
              </tr> :
              historyModalList && historyModalList.map((result) => {
                return (
                  <tr>
                    <td>{result[0]}</td>
                    <td>{result[1]}</td>
                    <td>{result[2]}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}

export default PepCinCpHistoryModule;
