import React from 'react'
import { setLoading, setNccPrimaryData, setUploadedSchoolReports } from '../../constants/reducerActions'

const nccDataPrimaryReducer = (state, action) => {
  const data = action.payload;

  switch(action.type) {

    case setNccPrimaryData:
      return {
        dataPrimary: data.dataPrimary,
        pupilUnique: data.pupilUnique,
        schoolUploadedFiles: data.schoolUploadedFiles,
        primaryDataOptionsList: data.primaryDataOptionsList,
        presentPer: data.presentPer,
        exclusionCount: data.exclusionCount,
      }
      // return {
      //   EHCP: data.dataPrimary?.commonData?.EHCP,
      //   SENSupport: data.dataPrimary?.commonData?.SENSupport,
      //   actionsAreInPlace: data.dataPrimary?.commonData?.actionsAreInPlace,
      //   actionsBeingTaken: data.dataPrimary?.commonData?.actionsBeingTaken,
      //   id: data.dataPrimary?.commonData?.id,
      //   primaryIdentifiedNeed: data.dataPrimary?.commonData?.primaryIdentifiedNeed,
      //   youngPersonHaveSEND: data.dataPrimary?.commonData?.youngPersonHaveSEND,
      //   attendance: data.presentPer,
      //   noOfSessions: data.exclusionCount,
        

      //   dateCreated: data.dataPrimary?.dateCreated,

      //   created_date: data.dataPrimary?.created_date,
        
      //   currentAttainmentMaths: data.dataPrimary?.currentAttainmentMaths,
      //   currentAttainmentPhonicsSPAG: data.dataPrimary?.currentAttainmentPhonicsSPAG,
      //   currentAttainmentReading: data.dataPrimary?.currentAttainmentReading,
      //   currentAttainmentWriting: data.dataPrimary?.currentAttainmentWriting,

      //   endOfYearMaths: data.dataPrimary?.endOfYearMaths,
      //   endOfYearPhonicsSPAG: data.dataPrimary?.endOfYearPhonicsSPAG,
      //   endOfYearReading: data.dataPrimary?.endOfYearReading,
      //   endOfYearWriting: data.dataPrimary?.endOfYearWriting,

      //   progressMaths: data.dataPrimary?.progressMaths,
      //   progressPhonicsSPAG: data.dataPrimary?.progressPhonicsSPAG,
      //   progressReading: data.dataPrimary?.progressReading,
      //   progressWriting: data.dataPrimary?.progressWriting,

      //   currentPathway: data.dataPrimary?.currentPathway,
      //   currentWorking: data.dataPrimary?.currentWorking,
      //   dateOfPep: data.dataPrimary?.dateOfPep,
      //   expectationsTarget: data.dataPrimary?.expectationsTarget,
      //   howItIsMeasured: data.dataPrimary?.howItIsMeasured,
      //   id: data.dataPrimary?.id,
      //   isCompleted: data.dataPrimary?.isCompleted,
      //   isDeleted: data.dataPrimary?.isDeleted,
      //   makingGoodProgress: data.dataPrimary?.makingGoodProgress,
      //   nameOfYoungPerson: data.dataPrimary?.nameOfYoungPerson,
      //   phonicsEndOfYear1: data.dataPrimary?.phonicsEndOfYear1,
      //   phonicsEndOfYear2: data.dataPrimary?.phonicsEndOfYear2,
      //   goodLevOfDevAchiev: data.pupilUnique?.goodLevOfDevAchiev,
      //   phonicsEndOfYear1: data.dataPrimary?.phonicsEndOfYear1,
      //   phonicsScreen: data.pupilUnique?.phonicsScreen,
      //   phonicsResit: data.pupilUnique?.phonicsResit,
      //   priPriorAttainment: data.pupilUnique?.priPriorAttainment,
        
      //   readingKeyStage1Score: data.pupilUnique?.readingKeyStage1Score,
      //   phonicKeyStage1Score: data.pupilUnique?.phonicKeyStage1Score,
      //   writingKeyStage1Score: data.pupilUnique?.writingKeyStage1Score,
      //   mathKeyStage1Score: data.pupilUnique?.mathKeyStage1Score,




      //   pupilId: data.dataPrimary?.pupilId,
      //   schoolId: data.dataPrimary?.schoolId,
      //   schoolReportUploaded: data.dataPrimary?.schoolReportUploaded,
      //   sdqDate: data.dataPrimary?.sdqDate,
      //   sdqScore: data.dataPrimary?.sdqScore,
      //   targetedCurriculum: data.dataPrimary?.targetedCurriculum,
      //   updated_date: data.dataPrimary?.updated_date,

      //   schoolUploadedFiles: data.schoolUploadedFiles,

      //   primaryDataOptionsList: data?.primaryDataOptionsList,
      //   isLoading: false,
      // }

    case setUploadedSchoolReports:
      return {
        ...state,
        schoolUploadedFiles: data.schoolReports,
      }

    case setLoading:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }

  }
}

export default nccDataPrimaryReducer

// pupilUnique.goodLevOfDevAchiev
// pupilUnique.priPriorAttainment
// pupilUnique.phonicsScreen
// pupilUnique.phonicsResit
// pupilUnique.readingKeyStage1Score
// pupilUnique.phonicKeyStage1Score
// pupilUnique.writingKeyStage1Score
// pupilUnique.mathKeyStage1Score

