import React from 'react'
import { setLoading, setPepCincpEyfs, setPepCincpPost16, setPepCincpPrimary, setPepCincpSecondary, setPepCincpId } from '../constants/reducerActions'

const cincpReducer = (state, action) => {
  switch(action.type) {

    case setPepCincpEyfs:
      return {
        gldAchieved: action.payload.data.gldAchieved ? action.payload.data.gldAchieved : "",
        phonicsScore: action.payload.data.phonicsScore ? action.payload.data.phonicsScore : "",
        comment: action.payload.data.comment ? action.payload.data.comment : "",
        attendanceEndOfAutumn: action.payload.data.attendanceEndOfAutumn ? action.payload.data.attendanceEndOfAutumn : "",
        attendanceEndOfSpring: action.payload.data.attendanceEndOfSpring ? action.payload.data.attendanceEndOfSpring : "",
        attendanceEndOfSummer: action.payload.data.attendanceEndOfSummer ? action.payload.data.attendanceEndOfSummer : "",
        communicationEndOfAutumn: action.payload.data.communicationEndOfAutumn ? action.payload.data.communicationEndOfAutumn : "",
        communicationEndOfSpring: action.payload.data.communicationEndOfSpring ? action.payload.data.communicationEndOfSpring : "",
        communicationEndOfSummer: action.payload.data.communicationEndOfSummer ? action.payload.data.communicationEndOfSummer : "",
        physicalDevelopmentEndOfAutumn: action.payload.data.physicalDevelopmentEndOfAutumn ? action.payload.data.physicalDevelopmentEndOfAutumn : "",
        physicalDevelopmentEndOfSpring: action.payload.data.physicalDevelopmentEndOfSpring ? action.payload.data.physicalDevelopmentEndOfSpring : "",
        physicalDevelopmentEndOfSummer: action.payload.data.physicalDevelopmentEndOfSummer ? action.payload.data.physicalDevelopmentEndOfSummer : "",
        pseDevelopmentEndOfAutumn: action.payload.data.pseDevelopmentEndOfAutumn ? action.payload.data.pseDevelopmentEndOfAutumn : "",
        pseDevelopmentEndOfSpring: action.payload.data.pseDevelopmentEndOfSpring ? action.payload.data.pseDevelopmentEndOfSpring : "",
        pseDevelopmentEndOfSummer: action.payload.data.pseDevelopmentEndOfSummer ? action.payload.data.pseDevelopmentEndOfSummer : "",
        commentsDevelopmentEndOfAutumn: action.payload.data.commentsDevelopmentEndOfAutumn ? action.payload.data.commentsDevelopmentEndOfAutumn : "",
        commentsDevelopmentEndOfSpring: action.payload.data.commentsDevelopmentEndOfSpring ? action.payload.data.commentsDevelopmentEndOfSpring : "",
        commentsDevelopmentEndOfSummer: action.payload.data.commentsDevelopmentEndOfSummer ? action.payload.data.commentsDevelopmentEndOfSummer : "",
        educationNeedThisChild: action.payload.data.educationNeedThisChild ? action.payload.data.educationNeedThisChild : "",
        barrierToMakingGoodProgress: action.payload.data.barrierToMakingGoodProgress ? action.payload.data.barrierToMakingGoodProgress : "",
        spendingPpgChild: action.payload.data.spendingPpgChild ? action.payload.data.spendingPpgChild : "",
        helpOvercomeTheBarriers: action.payload.data.helpOvercomeTheBarriers ? action.payload.data.helpOvercomeTheBarriers : "",
        childIsNotInSchool: action.payload.data.childIsNotInSchool ? action.payload.data.childIsNotInSchool : "",
        successLookLike: action.payload.data.successLookLike ? action.payload.data.successLookLike : "",  
      }

    case setPepCincpPost16:
      return {
        gldAchieved: action.payload.data.gldAchieved,
        phonicsScore: action.payload.data.phonicsScore,
        keyStage1Phonics: action.payload.data.keyStage1Phonics,
        keyStage1Writing: action.payload.data.keyStage1Writing,
        keyStage1Reading: action.payload.data.keyStage1Reading,
        keyStage1Maths: action.payload.data.keyStage1Maths,
        keyStage2Spag: action.payload.data.keyStage2Spag,
        keyStage2Writing: action.payload.data.keyStage2Writing,
        keyStage2Reading: action.payload.data.keyStage2Reading,
        keyStage2Maths: action.payload.data.keyStage2Maths,
        keyStage4English: action.payload.data.keyStage4English,
        keyStage4Maths: action.payload.data.keyStage4Maths,
        comment: action.payload.data.comment,
        attendanceEndOfAutumn: action.payload.data.attendanceEndOfAutumn,
        attendanceEndOfSpring: action.payload.data.attendanceEndOfSpring,
        attendanceEndOfSummer: action.payload.data.attendanceEndOfSummer,
        sub1: action.payload.data.sub1,
        sub1EndOfAutumn: action.payload.data.sub1EndOfAutumn,
        sub1EndOfSpring: action.payload.data.sub1EndOfSpring,
        sub1EndOfSummer: action.payload.data.sub1EndOfSummer,
        sub2: action.payload.data.sub2,
        sub2EndOfAutumn: action.payload.data.sub2EndOfAutumn,
        sub2EndOfSpring: action.payload.data.sub2EndOfSpring,
        sub2EndOfSummer: action.payload.data.sub2EndOfSummer,
        sub3: action.payload.data.sub3,
        sub3EndOfAutumn: action.payload.data.sub3EndOfAutumn,
        sub3EndOfSpring: action.payload.data.sub3EndOfSpring,
        sub3EndOfSummer: action.payload.data.sub3EndOfSummer,
        sub4: action.payload.data.sub4,
        sub4EndOfAutumn: action.payload.data.sub4EndOfAutumn,
        sub4EndOfSpring: action.payload.data.sub4EndOfSpring,
        sub4EndOfSummer: action.payload.data.sub4EndOfSummer,
        commentsDevelopmentEndOfAutumn: action.payload.data.commentsDevelopmentEndOfAutumn,
        commentsDevelopmentEndOfSpring: action.payload.data.commentsDevelopmentEndOfSpring,
        commentsDevelopmentEndOfSummer: action.payload.data.commentsDevelopmentEndOfSummer,
        educationNeedThisChild: action.payload.data.educationNeedThisChild,
        barrierToMakingGoodProgress: action.payload.data.barrierToMakingGoodProgress,
        spendingPpgChild: action.payload.data.spendingPpgChild,
        helpOvercomeTheBarriers: action.payload.data.helpOvercomeTheBarriers,
        childIsNotInSchool: action.payload.data.childIsNotInSchool,
        successLookLike: action.payload.data.successLookLike,
      }

    case setPepCincpPrimary:
      return {
        keyStage1Phonics: action.payload.pepEyfsCinCp.keyStage1Phonics,
        keyStage1Writing: action.payload.pepEyfsCinCp.keyStage1Writing,
        keyStage1Reading: action.payload.pepEyfsCinCp.keyStage1Reading,
        keyStage1Maths: action.payload.pepEyfsCinCp.keyStage1Maths,
        keyStage2Spag: action.payload.pepEyfsCinCp.keyStage2Spag,
        keyStage2Writing: action.payload.pepEyfsCinCp.keyStage2Writing,
        keyStage2Reading: action.payload.pepEyfsCinCp.keyStage2Reading,
        keyStage2Maths: action.payload.pepEyfsCinCp.keyStage2Maths,
        keyStage4English: action.payload.pepEyfsCinCp.keyStage4English,
        keyStage4Maths: action.payload.pepEyfsCinCp.keyStage4Maths,
        comment: action.payload.pepEyfsCinCp.comment,
        attendanceEndOfAutumn: action.payload.pepEyfsCinCp.attendanceEndOfAutumn,
        attendanceEndOfSpring: action.payload.pepEyfsCinCp.attendanceEndOfSpring,
        attendanceEndOfSummer: action.payload.pepEyfsCinCp.attendanceEndOfSummer,
        readingEndOfAutumn: action.payload.pepEyfsCinCp.readingEndOfAutumn,
        readingEndOfSpring: action.payload.pepEyfsCinCp.readingEndOfSpring,
        readingEndOfSummer: action.payload.pepEyfsCinCp.readingEndOfSummer,
        writingEndOfAutumn: action.payload.pepEyfsCinCp.writingEndOfAutumn,
        writingEndOfSpring: action.payload.pepEyfsCinCp.writingEndOfSpring,
        writingEndOfSummer: action.payload.pepEyfsCinCp.writingEndOfSummer,
        mathsEndOfAutumn: action.payload.pepEyfsCinCp.mathsEndOfAutumn,
        mathsEndOfSpring: action.payload.pepEyfsCinCp.mathsEndOfSpring,
        mathsEndOfSummer: action.payload.pepEyfsCinCp.mathsEndOfSummer,
        spagEndOfAutumn: action.payload.pepEyfsCinCp.spagEndOfAutumn,
        spagEndOfSpring: action.payload.pepEyfsCinCp.spagEndOfSpring,
        spagEndOfSummer: action.payload.pepEyfsCinCp.spagEndOfSummer,
        commentsDevelopmentEndOfAutumn: action.payload.pepEyfsCinCp.commentsDevelopmentEndOfAutumn,
        commentsDevelopmentEndOfSpring: action.payload.pepEyfsCinCp.commentsDevelopmentEndOfSpring,
        commentsDevelopmentEndOfSummer: action.payload.pepEyfsCinCp.commentsDevelopmentEndOfSummer,
        educationNeedThisChild: action.payload.pepEyfsCinCp.educationNeedThisChild,
        barrierToMakingGoodProgress: action.payload.pepEyfsCinCp.barrierToMakingGoodProgress,
        spendingPpgChild: action.payload.pepEyfsCinCp.spendingPpgChild,
        helpOvercomeTheBarriers: action.payload.pepEyfsCinCp.helpOvercomeTheBarriers,
        childIsNotInSchool: action.payload.pepEyfsCinCp.childIsNotInSchool,
        successLookLike: action.payload.pepEyfsCinCp.successLookLike,
      }
    
    case setPepCincpSecondary:
      return {
        keyStage1Phonics: action.payload.data.keyStage1Phonics,
        keyStage1Writing: action.payload.data.keyStage1Writing,
        keyStage1Reading: action.payload.data.keyStage1Reading,
        keyStage1Maths: action.payload.data.keyStage1Maths,
        keyStage2Spag: action.payload.data.keyStage2Spag,
        keyStage2Writing: action.payload.data.keyStage2Writing,
        keyStage2Reading: action.payload.data.keyStage2Reading,
        keyStage2Maths: action.payload.data.keyStage2Maths,
        keyStage4English: action.payload.data.keyStage4English,
        keyStage4Maths: action.payload.data.keyStage4Maths,
        comment: action.payload.data.comment,
        attendanceEndOfAutumn: action.payload.data.attendanceEndOfAutumn,
        attendanceEndOfSpring: action.payload.data.attendanceEndOfSpring,
        attendanceEndOfSummer: action.payload.data.attendanceEndOfSummer,
        englishEndOfAutumn: action.payload.data.englishEndOfAutumn,
        englishEndOfSpring: action.payload.data.englishEndOfSpring,
        englishEndOfSummer: action.payload.data.englishEndOfSummer,
        mathsEndOfAutumn: action.payload.data.mathsEndOfAutumn,
        mathsEndOfSpring: action.payload.data.mathsEndOfSpring,
        mathsEndOfSummer: action.payload.data.mathsEndOfSummer,
        sub1EndOfAutumn: action.payload.data.sub1EndOfAutumn,
        sub1EndOfSpring: action.payload.data.sub1EndOfSpring,
        sub1EndOfSummer: action.payload.data.sub1EndOfSummer,
        sub2EndOfAutumn: action.payload.data.sub2EndOfAutumn,
        sub2EndOfSpring: action.payload.data.sub2EndOfSpring,
        sub3EndOfSummer: action.payload.data.sub3EndOfSummer,
        sub3EndOfAutumn: action.payload.data.sub3EndOfAutumn,
        sub3EndOfSpring: action.payload.data.sub3EndOfSpring,
        sub3EndOfSummer: action.payload.data.sub3EndOfSummer,
        sub4EndOfAutumn: action.payload.data.sub4EndOfAutumn,
        sub4EndOfSpring: action.payload.data.sub4EndOfSpring,
        sub4EndOfSummer: action.payload.data.sub4EndOfSummer,
        sub5EndOfAutumn: action.payload.data.sub5EndOfAutumn,
        sub5EndOfSpring: action.payload.data.sub5EndOfSpring,
        sub5EndOfSummer: action.payload.data.sub5EndOfSummer,
        commentsDevelopmentEndOfAutumn: action.payload.data.commentsDevelopmentEndOfAutumn,
        commentsDevelopmentEndOfSpring: action.payload.data.commentsDevelopmentEndOfSpring,
        commentsDevelopmentEndOfSummer: action.payload.data.commentsDevelopmentEndOfSummer,
        educationNeedThisChild: action.payload.data.educationNeedThisChild,
        barrierToMakingGoodProgress: action.payload.data.barrierToMakingGoodProgress,
        spendingPpgChild: action.payload.data.spendingPpgChild,
        helpOvercomeTheBarriers: action.payload.data.helpOvercomeTheBarriers,
        childIsNotInSchool: action.payload.data.childIsNotInSchool,
        successLookLike: action.payload.data.successLookLike,
      }

    case setLoading:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }

    
  }
}

export default cincpReducer
