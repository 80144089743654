// import moment from 'moment'
import React from 'react'
import InputField from "../../InputField";
import DateField from './DateField';
// import DateField from "../../DateField";
import { useState } from "react";

const AltNeedTableY1ToY11 = ({
  pupilInfo
}) => {

  const onChangeHandler = () => {}
  const saveForm = () => {}

  

  return (
    <div className="rounded-3xl bg-white mt-4 p-4 w-full">
      <div className="py-2 flex w-full items-center">
        <label>This student is receiving 25 hours per week in an Ofsted registered establishment?</label>
        <div className="pl-4 radio">
          <label className="inline-flex items-center">
            <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
            <span className="ml-2">Yes</span>
          </label>
          <label className="inline-flex items-center ml-6">
            <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
            <span className="ml-2">No</span>
          </label>
        </div>
      </div>

      <div className="table-box w-full pt-2">
        <div className="table-outer rounded-3xl bg-white">
          <table className="table-fixed text-center w-full">
            <thead>
              <tr>
                <th className="blue">Reason</th>
                <th className="purple w-40">Start Date</th>
                <th className="orange w-40">End Date</th>
                <th className="d-pink w-40">Hours per week</th>
                <th className="d-blue">Activity</th>

              </tr>
            </thead>
            <tbody>
              {/* {pupilInfo.educationArrangements && pupilInfo.educationArrangements.map((result, index) => {
                return ( */}
                  {/* <tr key={index}> */}
                  <tr>
                    <td>
                      {/* <InputField 
                        // fieldName={`pupilInfo.educationArrangements[${index}].ofstedReason`}
                        fieldName
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "pep-input w-full"
                        }}
                      /> */}
                      <input type="text" className="pep-input textarea-input" />
                    </td>

                    {/* {(() => {
                      if (result.ofstedStartDate != null) {
                        return ( */}
                          <td>
                            {/* <DateField 
                              // fieldName={`pupilInfo.educationArrangements[${index}].ofstedStartDate`}
                              fieldName
                              dateChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "pep-input w-full"
                              }}
                            /> */}
                            <input type="text" className="pep-input textarea-input" />
                          </td>
                        {/* )
                      } else {
                        return ( */}
                          {/* <td>
                            <input type="text" className="pep-input date-input" />
                          </td> */}
                        {/* )
                      }
                    })()} */}


                    {/* {(() => {
                      if (result.ofstedEndDate != null) {
                        return ( */}
                          <td>
                            {/* <DateField
                              // fieldName={`pupilInfo.educationArrangements[${index}].ofstedEndDate`}
                              fieldName
                              dateChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "pep-input w-full"
                              }}
                            /> */}
                            <input type="text" className="pep-input date-input" />
                          </td>
                        {/* )
                      } else {
                        return ( */}
                          {/* <td>
                            <input type="text" className="pep-input date-input" />
                          </td> */}
                        {/* )
                      }
                    })()} */}

                    <td>
                      {/* <InputField
                        // fieldName={`pupilInfo.educationArrangements[${index}].ofstedHours`}
                        fieldName
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "pep-input w-full"
                        }}
                      /> */}
                      <input type="text" className="pep-input date-input" />
                    </td>
                    <td>
                      {/* <InputField
                        // fieldName={`pupilInfo.educationArrangements[${index}].ofstedActivity`}
                        fieldName
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "pep-input w-full"
                        }}
                      /> */}
                      <input type="text" className="pep-input textarea-input" />
                    </td>
                    
                  </tr>
                {/* )
              })} */}
                               <tr>
                    <td>
                      {/* <InputField 
                        // fieldName={`pupilInfo.educationArrangements[${index}].ofstedReason`}
                        fieldName
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "pep-input w-full"
                        }}
                      /> */}
                      <input type="text" className="pep-input textarea-input" />
                    </td>

                    {/* {(() => {
                      if (result.ofstedStartDate != null) {
                        return ( */}
                          <td>
                            {/* <DateField 
                              // fieldName={`pupilInfo.educationArrangements[${index}].ofstedStartDate`}
                              fieldName
                              dateChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "pep-input w-full"
                              }}
                            /> */}
                            <input type="text" className="pep-input textarea-input" />
                          </td>
                        {/* )
                      } else {
                        return ( */}
                          {/* <td>
                            <input type="text" className="pep-input date-input" />
                          </td> */}
                        {/* )
                      }
                    })()} */}


                    {/* {(() => {
                      if (result.ofstedEndDate != null) {
                        return ( */}
                          <td>
                            {/* <DateField
                              // fieldName={`pupilInfo.educationArrangements[${index}].ofstedEndDate`}
                              fieldName
                              dateChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "pep-input w-full"
                              }}
                            /> */}
                            <input type="text" className="pep-input date-input" />
                          </td>
                        {/* )
                      } else {
                        return ( */}
                          {/* <td>
                            <input type="text" className="pep-input date-input" />
                          </td> */}
                        {/* )
                      }
                    })()} */}

                    <td>
                      {/* <InputField
                        // fieldName={`pupilInfo.educationArrangements[${index}].ofstedHours`}
                        fieldName
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "pep-input w-full"
                        }}
                      /> */}
                      <input type="text" className="pep-input date-input" />
                    </td>
                    <td>
                      {/* <InputField
                        // fieldName={`pupilInfo.educationArrangements[${index}].ofstedActivity`}
                        fieldName
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "pep-input w-full"
                        }}
                      /> */}
                      <input type="text" className="pep-input textarea-input" />
                    </td>
                    
                  </tr>
                  <tr>
                    <td>
                      {/* <InputField 
                        // fieldName={`pupilInfo.educationArrangements[${index}].ofstedReason`}
                        fieldName
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "pep-input w-full"
                        }}
                      /> */}
                      <input type="text" className="pep-input textarea-input" />
                    </td>

                    {/* {(() => {
                      if (result.ofstedStartDate != null) {
                        return ( */}
                          <td>
                            {/* <DateField 
                              // fieldName={`pupilInfo.educationArrangements[${index}].ofstedStartDate`}
                              fieldName
                              dateChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "pep-input w-full"
                              }}
                            /> */}
                            <input type="text" className="pep-input textarea-input" />
                          </td>
                        {/* )
                      } else {
                        return ( */}
                          {/* <td>
                            <input type="text" className="pep-input date-input" />
                          </td> */}
                        {/* )
                      }
                    })()} */}


                    {/* {(() => {
                      if (result.ofstedEndDate != null) {
                        return ( */}
                          <td>
                            {/* <DateField
                              // fieldName={`pupilInfo.educationArrangements[${index}].ofstedEndDate`}
                              fieldName
                              dateChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rest={{
                                className: "pep-input w-full"
                              }}
                            /> */}
                            <input type="text" className="pep-input date-input" />
                          </td>
                        {/* )
                      } else {
                        return ( */}
                          {/* <td>
                            <input type="text" className="pep-input date-input" />
                          </td> */}
                        {/* )
                      }
                    })()} */}

                    <td>
                      {/* <InputField
                        // fieldName={`pupilInfo.educationArrangements[${index}].ofstedHours`}
                        fieldName
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "pep-input w-full"
                        }}
                      /> */}
                      <input type="text" className="pep-input date-input" />
                    </td>
                    <td>
                      {/* <InputField
                        // fieldName={`pupilInfo.educationArrangements[${index}].ofstedActivity`}
                        fieldName
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        rest={{
                          className: "pep-input w-full"
                        }}
                      /> */}
                      <input type="text" className="pep-input textarea-input" />
                    </td>
                    
                  </tr>
            </tbody>
            
          </table>
        </div>
      </div>

      <div className="py-2 flex w-full items-center">
        <label>Who agreed the alternative arrangements?</label>
        <input type="input" className="pep-input" />
      </div>


      <div className="py-2 mt-4 flex w-full items-center">
        <div className="w-5/12 flex">
          <label>Is the pupil without an effective school place?</label>
          <div className="pl-4 radio">
            <label className="inline-flex items-center">
              <input type="radio" className="form-radio" name="pupilNeet" />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input type="radio" className="form-radio" name="accountType" />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>
        <div className="w-7/12 flex items-center">
          <label className="w-24">Reason :</label>
          <input type="text" className="pep-input textarea-input" />
        </div>
      </div>

      <div className="py-2 flex w-full items-center">
        <div className="w-5/12 flex">
          <label>Without an effective school place during school year?</label>
        </div>
        <div className="w-7/12 flex items-center">
          <div className="pl-4 radio">
            <label className="inline-flex items-center">
              <input type="radio" className="form-radio" name="neetSchoolYear" />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input type="radio" className="form-radio" name="neetSchoolYear" />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>
      </div>

      <div className="w-full pb-2">
        <span className="inline-block">What is the action plan?</span>
        <div className="textarea-block mt-1">
          <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Type here..." ></textarea>
        </div>
      </div>

    </div>
  )
}

export default AltNeedTableY1ToY11