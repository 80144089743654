import { clearAllPopup, closeNavLinks, closeSearchCollapse, closeSetting, openNavLinks, openSearchCollapse, setSearchCollapse, toggleNavLinks, toggleSetting } from "../constants/reducerActions"

const popupContextReducer = (state, action) => {
  switch (action.type) {
    
    case openSearchCollapse:
      return {
        linksOpen: false,
        isSettingOpen: false,
        searchCollopse: true,
      }

    case closeSearchCollapse:
      return {
        ...state,
        searchCollopse: false,
      }

    case toggleSetting:
      return {
        ...state,
        // linksOpen: state.isSettingOpen ?
        isSettingOpen: !state.isSettingOpen,
      }

    case closeSetting:
      return {
        ...state,
        isSettingOpen: false,
      }

    case openNavLinks:
      return {
        ...state,
        linksOpen: true,
      }

    case closeNavLinks:
      return {
        ...state,
        linksOpen: false,
      }

    case toggleNavLinks:
      return {
        ...state,
        linksOpen: !state.linksOpen,
      }

    case clearAllPopup:
      return {
        searchCollopse: false,
        linksOpen: false,
        isSettingOpen: false,
      }

    case setSearchCollapse:
      return {
        ...state,
        searchCollopse: action.payload.flag,
      }

    default:
      return state;

  }
}

export default popupContextReducer;
