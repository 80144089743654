import { clearSearchField, decreaseCursor, increaseCursor, loading, notLoading, setCurrentPage, setIdToSearch, setSearchField, setSearchResult } from "../constants/reducerActions"

const initialState = {
  searchField: "",
  searchFieldCollapsed: true,
  searchResults: [],
  currentPage: 1,
  searchResultsPerPage: 5,
  isLoading: true,
  cursor: -1,
  idToSearch: "",
}

const searchBarReducer = (state, action) => {
  switch (action.type) {
    case setSearchField:
      return {
        ...state,
        searchField: action.payload.value,
        searchFieldCollapsed: action.payload.searchCollpase,
      }
    case clearSearchField:
      return {
        ...state,
        searchField: "",
        searchFieldCollapsed: true,
        currentPage: 1,
      }
    case increaseCursor:
      return {
        ...state,
        cursor: state.cursor + 1,
      }
    case decreaseCursor:
      return {
        ...state,
        cursor: state.cursor - 1,
      }
    case setIdToSearch:
      return {
        ...state,
        idToSearch: action.payload.id,
      }
    case setCurrentPage:
      return {
        ...state,
        currentPage: action.payload.pageNumber,
      }
    case loading:
      return {
        ...state,
        isLoading: true,
      }
    case notLoading:
      return {
        ...state,
        isLoading: false,
      }
    case setSearchResult:
      return {
        ...state,
        searchResults: action.payload.searchResults,
        isLoading: false,
        pageNumber: 1,
      }
  }
}

export default searchBarReducer;