import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';

import PupilContext from '../../../../Components/context/PupilContext';
import { routeUrl } from '../../../../Components/utils/RouteUrl';
import { settings } from '../../../../Components/utils/Settings';


const PepESOLData = () => {
    
    const { activePupilId, dateOfMeeting, activePupilYear } = useContext(PupilContext);

    const roleId = localStorage.getItem("userRole");
    const userId = localStorage.getItem("userId");
    const schoolId = localStorage.getItem("schoolId");
    const kidYear = localStorage.getItem("year");
  
    const [esol, setEsolData] = useState({});
    const [schoolReports, setEsolUploads] = useState([]);
  
    useEffect(() => {
        activePupilId && axios(`${settings.API_HOST}/rest/esol?pupilId=${activePupilId}&dateOfMeeting=${dateOfMeeting}`)

            .then(res => {
                console.log(res.data, "Esol data");
                setEsolData(res.data.pepEsolAttainment);
                //setEsolUploads(res.data.pepEsolSchoolDocs);
                
            });

    }, [activePupilId]);

    return (
        <div>
            <div className="secondary-section w-full">
                <div className="rounded-3xl bg-white p-4 w-full">
                    <div className="pink mb-2 rounded-3xl px-4 py-2 flex w-full items-center">
                        <h3 className="pr-4">ESOL Attainment</h3>
                    </div>
                    <div className="table-box w-full pt-2">
                        <div className="table-outer rounded-3xl bg-white">
                            <table className="table-fixed w-full">
                                <thead>
                                    <tr>
                                        <td className="blue w-40"></td>
                                        <td className="d-blue w-48">Previous PEP level</td>
                                        <td className="purple w-48">Current level</td>
                                        <td className="orange w-48">End of year level</td>
                                        <td className="d-orange w-60">On track to reach target this year?</td>
                                        <td className="d-pink">Comments</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>ESOL English</td>
                                        <td><select className="pep-select" value={esol.esolEngPepLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td><select className="pep-select" value={esol.esolEngCurrentLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td><select className="pep-select" value={esol.esolEngYearLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td>
                                            <div className="pl-4 radio">
                                                <label className="inline-flex items-center">
                                                    <input type="radio" className="form-radio" name="esolEngTrackToTarget" checked={esol.esolEngTrackToTarget !== null &&
                                esol.esolEngTrackToTarget === "Yes"
                                ? true
                                : false
                            } />
                                                    <span className="ml-2">Yes</span>
                                                </label>
                                                <label className="inline-flex items-center ml-6">
                                                    <input type="radio" className="form-radio" name="esolEngTrackToTarget" checked={esol.esolEngTrackToTarget !== null &&
                                esol.esolEngTrackToTarget === "No"
                                ? true
                                : false
                            }  />
                                                    <span className="ml-2">No</span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>{esol.esolEngComment}</td>
                                    </tr>
                                    <tr>
                                        <td>ESOL Maths</td>
                                        <td><select className="pep-select"  value={esol.esolMathsPepLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td><select className="pep-select"  value={esol.esolMathsCurrentLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td><select className="pep-select"  value={esol.esolMathsYearLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td>
                                            <div className="pl-4 radio">
                                                <label className="inline-flex items-center">
                                                    <input type="radio" className="form-radio" name="esolMathsTrackToTarget" checked={esol.esolMathsTrackToTarget !== null &&
                                esol.esolMathsTrackToTarget === "Yes"
                                ? true
                                : false
                            } />
                                                    <span className="ml-2">Yes</span>
                                                </label>
                                                <label className="inline-flex items-center ml-6">
                                                    <input type="radio" className="form-radio" name="esolMathsTrackToTarget" checked={esol.esolMathsTrackToTarget !== null &&
                                esol.esolMathsTrackToTarget === "No"
                                ? true
                                : false
                            } />
                                                    <span className="ml-2">No</span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>{esol.esolMathsComment}</td>
                                    </tr>
                                    <tr>
                                        <td>ESOL IT</td>
                                        <td><select className="pep-select" value={esol.esolItPepLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td><select className="pep-select" value={esol.esolItCurrentLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td><select className="pep-select" value={esol.esolItYearLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td>
                                            <div className="pl-4 radio">
                                                <label className="inline-flex items-center">
                                                    <input type="radio" className="form-radio" name="esolItTrackToTarget" checked={esol.esolItTrackToTarget !== null &&
                                esol.esolItTrackToTarget === "Yes"
                                ? true
                                : false
                            }  />
                                                    <span className="ml-2">Yes</span>
                                                </label>
                                                <label className="inline-flex items-center ml-6">
                                                    <input type="radio" className="form-radio" name="esolItTrackToTarget" checked={esol.esolItTrackToTarget !== null &&
                                esol.esolItTrackToTarget === "No"
                                ? true
                                : false
                            }  />
                                                    <span className="ml-2">No</span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>{esol.esolItComment}</td>
                                    </tr>
                                    <tr>
                                        <td>ESOL Vacational</td>
                                        <td><select className="pep-select" value={esol.esolVocationalPepLevel}> 
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td><select className="pep-select" value={esol.esolVocationalCurrentLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td><select className="pep-select" value={esol.esolVocationalYearLevel}>
                                            <option></option>
                                            <option value="Entry Level 1">Entry Level 1</option>
                                            <option value="Entry Level 2">Entry Level 2</option>
                                            <option value="Entry Level 3">Entry Level 3</option>
                                            <option value="Level 1 or above">Level 1 or above</option>
                                            <option value="Pre-entry">Pre-entry</option>
                                        </select></td>
                                        <td>
                                            <div className="pl-4 radio">
                                                <label className="inline-flex items-center">
                                                    <input type="radio" className="form-radio" name="esolVocationalTrackToTarget" checked={esol.esolVocationalTrackToTarget !== null &&
                                esol.esolVocationalTrackToTarget === "Yes"
                                ? true
                                : false
                            }  />
                                                    <span className="ml-2">Yes</span>
                                                </label>
                                                <label className="inline-flex items-center ml-6">
                                                    <input type="radio" className="form-radio" name="esolVocationalTrackToTarget" checked={esol.esolVocationalTrackToTarget !== null &&
                                esol.esolVocationalTrackToTarget === "No"
                                ? true
                                : false
                            }  />
                                                    <span className="ml-2">No</span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>{esol.esolVocationalComment}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="mt-4 blue mb-2 rounded-3xl px-4 py-2 flex w-full items-center">
                        <h3>Attendance</h3>
                    </div>
                    <div className="table-box w-full pt-2">
                        <div className="table-outer rounded-3xl bg-white">
                            <table className="table-fixed w-full">
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className="w-32 inline-block">Attendance</label>
                                            <input type="text" className="pep-input" value={esol.attendance}/> %
                                        </td>
                                        <td>
                                            <label className="w-40 inline-block">Unauthorise absence</label>
                                            <input type="text" className="pep-input" value={esol.unauthorisedAbsence}/> %
                                        </td>
                                        <td>
                                            <label className="w-32 inline-block">Exclusions</label>
                                            <input type="text" className="pep-input" value={esol.exclusions}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className="w-32 inline-block">Punctually</label>
                                            <select className="pep-select" value={esol.punctually}>
                                                <option></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </td>
                                        <td>
                                            <label className="w-40 inline-block">Attitude to learning</label>
                                            <select className="pep-select" value={esol.attitudeLearning}>
                                                <option></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </td>
                                        <td>
                                            <label className="w-32 inline-block">Behaviour</label>
                                            <select className="pep-select" value={esol.behaviour}>
                                                <option></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="mt-4 rounded-3xl bg-white p-4 w-full">
                    <div className="orange mb-2 rounded-3xl px-4 py-2 flex w-full items-center">
                        <h3>Education</h3>
                    </div>
                    <div className="table-box w-full py-2 border-btm">
                        <div className="py-2 flex w-full items-center">
                            <div className="w-2/12">
                                <span className="btn blue w-full inline-block">What pragramme of study is being followed? how many hours a week?</span>
                            </div>
                            <div className="w-10/12 textarea-block textarea-rows pl-4 mt-1">
                                <textarea rows="2" className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comments" value={esol.whatProgramOfStudy}></textarea>
                            </div>
                        </div>
                        <div className="py-2 flex w-full items-center">
                            <div className="w-2/12">
                                <span className="w-full btn d-blue inline-block">Future plans</span>
                            </div>
                            <div className="w-10/12 textarea-block textarea-rows pl-4 mt-1">
                                <textarea rows="2" className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comments" value={esol.futurePlan}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="green mt-4 mb-2 rounded-3xl px-4 py-2 flex w-full items-center">
                        <h3>Comment from teacher</h3>
                    </div>
                    <div className="table-box w-full py-2 border-btm">
                        <div className="py-2 flex w-full items-center">
                            <div className="w-1/12">
                                <span className="btn blue text-center w-full inline-block">English</span>
                            </div>
                            <div className="w-11/12 textarea-block textarea-rows pl-4 mt-1">
                                <textarea rows="2" className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comments" value={esol.englishComment}></textarea>
                            </div>
                        </div>
                        <div className="py-2 flex w-full items-center">
                            <div className="w-1/12">
                                <span className="btn d-blue text-center w-full inline-block">Maths</span>
                            </div>
                            <div className="w-11/12 textarea-block textarea-rows pl-4 mt-1">
                                <textarea rows="2" className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comments" value={esol.mathsComment}></textarea>
                            </div>
                        </div>
                        <div className="py-2 flex w-full items-center">
                            <div className="w-1/12">
                                <span className="btn purple text-center w-full inline-block">Tutor</span>
                            </div>
                            <div className="w-11/12 textarea-block textarea-rows pl-4 mt-1">
                                <textarea rows="2" className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comments" value={esol.tutorComment}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="table-box w-full py-2">
                        <div className="py-2 flex w-full items-center">
                            <h3>Is the Student making expected progress overall? <span className="required">*</span></h3>
                            <div className="pl-4 radio">
                                <label className="inline-flex items-center">
                                    <input type="radio" className="form-radio" name="progressOverall" checked={esol.progressOverall !== null &&
                                esol.progressOverall === "Yes"
                                ? true
                                : false
                            } />
                                    <span className="ml-2">Yes</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input type="radio" className="form-radio" name="progressOverall" checked={esol.progressOverall !== null &&
                                esol.progressOverall === "No"
                                ? true
                                : false
                            } />
                                    <span className="ml-2">No</span>
                                </label>
                            </div>
                            <h3>Is the Student at age-related expectations overall? <span className="required">*</span></h3>
                            <div className="pl-4 radio">
                                <label className="inline-flex items-center">
                                    <input type="radio" className="form-radio" name="expectationsOverall" checked={esol.expectationsOverall !== null &&
                                esol.expectationsOverall === "Yes"
                                ? true
                                : false
                            } />
                                    <span className="ml-2">Yes</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input type="radio" className="form-radio" name="expectationsOverall" checked={esol.expectationsOverall !== null &&
                                esol.expectationsOverall === "No"
                                ? true
                                : false
                            } />
                                    <span className="ml-2">No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="widebox-container">
                        <div className="">
                            <h3 className="inline-block"><b>If the Student is not making expected progress, what support is in place to close the gap?</b> <span className="required">*</span> </h3>
                            <div className="textarea-block mt-1 w-full">
                                <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comment"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="table-box w-full">
                        <div className="py-2 flex w-full items-center relative">
                            <h3>School report Uploaded?</h3>
                            <div className="pl-4 radio">
                                <label className="inline-flex items-center">
                                    <input type="radio" className="form-radio" name="schoolReportUploaded" checked={esol.schoolReportUploaded !== null &&
                                esol.schoolReportUploaded === "Yes"
                                ? true
                                : false
                            } />
                                    <span className="ml-2">Yes</span>
                                </label>
                                <label className="inline-flex items-center ml-6">
                                    <input type="radio" className="form-radio" name="schoolReportUploaded"checked={esol.schoolReportUploaded !== null &&
                                esol.schoolReportUploaded === "No"
                                ? true
                                : false
                            } />
                                    <span className="ml-2">No</span>
                                </label>
                            </div>
                            <div className='ml-4 file file--upload'>
                                <label for='input-file'>
                                    Upload <i className="fa-regular fa-upload"></i>
                                </label>
                                <input id='input-file' type='file' />
                            </div>
                            <div className="pl-4 view-report">
                                <button className="btn pep-btn">View Reports <i className="fa-regular fa-angle-down"></i></button>
                                <ul>
                                    {/* {schoolReports && schoolReports.map((result) => {
                                        return (
                                            <li>
                                                <span>{result.fileShowName}</span>
                                            </li>
                                        )
                                    })} */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PepESOLData
