import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { routeUrl } from '../../../../../Components/utils/RouteUrl';
import useActivateTabBasicInfo from '../../../../../hooks/useActivateTabBasicInfo';
import PupilContext from '../../../../../Components/context/PupilContext';
import { settings } from '../../../../../Components/utils/Settings';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { checkForForTrimmedEmptyValue, showValidationError } from '../../../../../Components/utils/utilityFunctions';

const PepCaseNotesTab = () => {

  useActivateTabBasicInfo(routeUrl.basic_info_case_notes);

  const { activePupilId } = useContext(PupilContext);

  const roleId = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const schoolId = localStorage.getItem("schoolId");

  const [pupilCaseNotes, setPupilCaseNotes] = useState([]);

  const [id, setId] = useState("");
  const [comment, setComment] = useState("");
  const [workerType, setWorkerType] = useState("");
  const [importance, setImportance] = useState("");
  const [createdDate, setCreatedDate] = useState("");

  const DeleteCaseNote = (id) => {
    axios.get(`${settings.API_HOST}/rest/deleteCaseNote?noteId=${id}&pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
      .then((res) => {
        setPupilCaseNotes(res.data.pupilCaseNotes ? res.data.pupilCaseNotes : null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EditCaseNotes = (id) => {
    axios.get(`${settings.API_HOST}/rest/editCaseNote?noteId=${id}&pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
      .then((res) => {
        console.log(res, " edit case notes");
        console.log(res.data.pupilsCaseNotes, " editable case note object");
        setId(res.data.pupilsCaseNotes ? res.data.pupilsCaseNotes.id : "");
        setComment(res.data.pupilsCaseNotes ? res.data.pupilsCaseNotes.comment : "");
        setWorkerType(res.data.pupilsCaseNotes ? res.data.pupilsCaseNotes.caseWorkType : "");
        setImportance(res.data.pupilsCaseNotes ? res.data.pupilsCaseNotes.importance : "");
        setCreatedDate(res.data.pupilsCaseNotes ? res.data.pupilsCaseNotes.stringCreatedDate : "");
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const validateForm = () => {
    const error = {
      errorMsg: "",
      errorOccur: 0,
    }
    if (checkForForTrimmedEmptyValue(comment)) {
      error.errorMsg = "Comment is Required";
      error.errorOccur++;
    } else if (checkForForTrimmedEmptyValue(workerType)) {
      error.errorMsg = "Case Work Type is Required";
      error.errorOccur++;
    } else if (checkForForTrimmedEmptyValue(importance)) {
      error.errorMsg = "Importance is Required";
      error.errorOccur++;
    } else if (checkForForTrimmedEmptyValue(createdDate)) {
      error.errorMsg = "Date is Required";
      error.errorOccur++;
    } 
    return error;
  }

  const SaveCaseNotes = () => {
    const error = validateForm();
    if(error.errorOccur <= 0) {
      const newFormData = new FormData();
      newFormData.append("pupilsCaseNotes.id", id);
      newFormData.append("pupilsCaseNotes.comment", comment);
      newFormData.append("pupilsCaseNotes.stringCreatedDateNew", createdDate);
      newFormData.append("pupilsCaseNotes.pupilId", activePupilId);
      newFormData.append("pupilsCaseNotes.caseWorkType", workerType);
      newFormData.append("pupilsCaseNotes.schoolId", schoolId);
      newFormData.append("pupilsCaseNotes.userId", userId);
      newFormData.append("pupilsCaseNotes.importance", importance);
      newFormData.append("pupilsCaseNotes.isDeleted", 0);
  
      axios.post(`${settings.API_HOST}/rest/savePupilCaseNotes`, newFormData)
        .then((res) => {
          console.log(res.data, " saving case note ");
          setPupilCaseNotes(res.data.pupilCaseNotes ? res.data.pupilCaseNotes : null);
          setId("");
          setComment("");
          setWorkerType("");
          setImportance("");
          setCreatedDate("");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      showValidationError(error.errorMsg);
    }
  }


  const slicedDate = createdDate && createdDate.split("/");
  const newDate = (slicedDate && slicedDate.length > 2) ? `${slicedDate[2]}/${slicedDate[1]}/${slicedDate[0]}` : null;
  const formatedDate = createdDate ? (createdDate.length > 2 ? new Date(newDate) : new Date(null)) : null;
  const handleDateChange = (date) => { setCreatedDate(moment(date).format("DD/MM/YYYY")); }

  useEffect(() => {
    activePupilId && axios(`${settings.API_HOST}/rest/pupilCaseNotes?pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
      .then(res => {
        console.log(res.data.pupilCaseNotes, "case notes list");
        setPupilCaseNotes(res.data.pupilCaseNotes ? res.data.pupilCaseNotes : null);
      });
  }, [activePupilId]);

  return (
    <div className="secondary-section w-full">
      <div className="basic-header flex w-full py-2 justify-between">
        <div className="basic-left">
          <h1>Case Note
            <span><i className="fa-regular fa-circle-video"></i></span>
            <span><i className="fa-regular fa-circle-info"></i></span>
          </h1>
        </div>
        <div className="basic-right">
          <span><i className="fa-regular fa-user"></i></span>
          <input type="text" className="pep-input" value="ASSET Secondary" />
        </div>
      </div>
      <div className="rounded-3xl bg-white p-4 w-full">
        <input type="button" className="blue-button px-7 py-3  text-sm" value="Save" onClick={() => SaveCaseNotes()} />
        <div className="case-notes-box flex w-full">
          <div className="case-left w-7/12">
            <div className="widebox-inr w-full px-2 pb-2">
              <div className="textarea-block mt-1">
                <textarea
                  className="rounded-2xl px-2 py-4 textarea w-full"
                  placeholder="Type here..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="case-right w-5/12">
            <div className="text-centers py-2">
              <label className="inline-block ml-4 pr-2 w-32">Case Work type</label>
              <select
                className="pep-select"
                value={workerType}
                onChange={(e) => setWorkerType(e.target.value)} >
                <option></option>
                <option>CAMHS</option>
                <option>Carer Contact</option>
                <option>Education Liaison</option>
                <option>Educational Psychologist</option>
                <option>Email</option>
                <option>Higher Education Outreach</option>
                <option>LAC Review</option>
                <option>Mentor Liason</option>
                <option>Other agency</option>
                <option>Safeguarding</option>
                <option>SEND Contact</option>
                <option>Social Work Contact</option>
                <option>Strategy Meeting</option>
                <option>Telephone</option>
                <option>UASC outreach</option>
                <option>YOS</option>
                <option>Young Person</option>
              </select>
            </div>
            <div className="text-centers py-2">
              <label className="inline-block ml-4 pr-2 w-32">Importance</label>
              <select
                className="pep-select w-32"
                value={importance}
                onChange={(e) => setImportance(e.target.value)}
              >
                <option></option>
                <option>High</option>
                <option>Middle</option>
                <option>Low</option>
              </select>
            </div>
            <div className="text-centers py-2">
              <label className="inline-block ml-4 pr-2 w-32">Date</label>
              <DatePicker
                selected={formatedDate}
                className="rounded-2xl px-2 py-2 border"
                onChange={date => handleDateChange(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>

        <div className="table-box w-full pt-2">
          <div className="table-outer rounded-3xl bg-white">
            <table className="table-fixed text-center w-full">
              <thead>
                <tr>
                  <th className="blue w-24"></th>
                  <th className="d-blue w-12"></th>
                  <th className="purple w-24">Date</th>
                  <th className="orange w-40">Notes Written by</th>
                  <th className="d-orange w-40">Case Work Type</th>
                  <th className="d-pink">Case Note</th>
                  <th className="pink w-40">Importance</th>
                </tr>
              </thead>
              <tbody>

                {pupilCaseNotes && pupilCaseNotes.map((result) => {
                  return (
                    <tr>
                      <td>
                        <i className="mr-2 fa-regular fa-pen-to-square" onClick={() => EditCaseNotes(result.id)}></i>
                        <i className="fa-regular fa-circle-trash" onClick={() => DeleteCaseNote(result.id)}></i>
                      </td>
                      <td>
                        {result.importance && result.importance === "High" && <i className="fa-solid fa-exclamation"></i>}
                        {result.importance && result.importance === "Low" && <i className="fal fa-arrow-down" ></i>}
                      </td>
                      <td>{result.stringCreatedDate}</td>
                      <td>{result.writtenBy}</td>
                      <td>{result.caseWorkType}</td>
                      <td>{result.comment}</td>
                      <td>{result.importance}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PepCaseNotesTab;