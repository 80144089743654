import React, {memo, PureComponent} from "react";

const TextFormField = (props) => {
  // console.log(props, "from text field");
  const sliceValueInput = (value) => {
    let slicedValue = value;
    if(value && value.length > 35) {
      slicedValue = `${value.slice(0, 35)} ...`
    }
    return slicedValue; 
  }
 
  return (
    <div className="pupil-form-field">
      <div className="form-field-label grid-col-3">
        <label htmlFor="">
          {props.fieldLabel}
          {props.required && <span className="red">*</span>}
        </label>
      </div>
      <div className="form-field-body">
        {props.fieldIcon && props.fieldIcon }
        <input 
          readOnly
          title={props.fieldValue}
          className="rounded-custom-content" 
          type="text" 
          placeholder={props.fieldLabel}
          value={sliceValueInput(props.fieldValue)}
          onChange={props.onChangeHandler} 
          name={props.fieldName}
        />

      </div>
    </div>
  )
} 

// This is equal create to check props with prev to next to avoid multiple render
const isEqual = (prevProps, nextProps) => {
  if(nextProps.fieldName === prevProps.fieldName) {
    return true;
  } else {
    return false;
  }
}

export default memo(TextFormField);

// class TextFormField extends PureComponent {

//   sliceValueInput = (value) => {
//     let slicedValue = value;
//     if(value && value.length > 35) {
//       slicedValue = `${value.slice(0, 35)} ...`
//     }
//     return slicedValue; 

//   }
 
//   render() {
//     console.log("In text form field", this.props);
//     return (
//       <div className="pupil-form-field">
//         <div className="form-field-label grid-col-3">
//           <label htmlFor="">
//             {this.props.fieldLabel}
//             {this.props.required && <span className="red">*</span>}
//           </label>
//         </div>
//         <div className="form-field-body">
//           {this.props.fieldIcon && this.props.fieldIcon }
//           <input 
//             title={this.props.fieldValue}
//             readOnly
//             className="rounded-custom-content" 
//             type="text" 
//             placeholder={this.props.fieldLabel}
//             value={this.sliceValueInput(this.props.fieldValue)}
//             onChange={this.props.onChangeHandler} 
//             name={this.props.fieldName}
//           />
//         </div>
//       </div>
//     )
//   }
// } 

