import React from 'react'
import { setMyPupilReport } from '../constants/reducerActions';

const myPupilReducer = (state, action) => {
  const data = action.payload;
  switch (action.payload) {
    case setMyPupilReport:
      return {
        termofPep: data.pepstatus.termofpep,
        pepStatus: data.pepstatus.pepstatus,
        pepStatusList: data.pepStatusList,
        isLoading: false,
        currentPage: data.currentPage,
        recordPerPage: "",
        totalRecords: data.pepstatus.totalRecords,

      }
  }
}

export default myPupilReducer