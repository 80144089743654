import React from 'react'
import DatePicker from "react-datepicker";

export default function InterventionRow(props) {
  return (
    <tr>
      <td>
        <DatePicker
          selected={props.formatedDate(props.stringInterventionStartDate)}
          className="pep-input w-full"
          dateFormat="dd/MM/yyyy"
          onChange={(date) => props.changeDateHandler(date, props.id, "stringInterventionStartDate")}
        />
      </td>
      <td>  <select className="pep-select w-full"
        value={props.interventionType}
        onChange={(e) => props.changeFieldHandler(e, props.id, "interventionType")}
         >
        {props.interventionTypeOptions.map((op, i) => (<option key={i}> {op} </option>))}
      </select>
      </td>
      <td>
        <input
          type="yext"
          className="pep-input w-full"
          value={props.interventionAgency}
          onChange={(e) => props.changeFieldHandler(e, props.id, "interventionAgency")}
          onBlur={(e) => props.onBlurHandler(e, props.id, "interventionAgency")}
        />
      </td>
      <td>
        <input
          type="yext"
          className="pep-input w-full"
          value={props.interventionWorkerName}
          onChange={(e) => props.changeFieldHandler(e, props.id, "interventionWorkerName")}
          onBlur={(e) => props.onBlurHandler(e, props.id, "interventionWorkerName")}
        />

      </td>
      <td>
        <input
          type="number"
          className="pep-number w-full"
          value={props.interventionHours}
          onChange={(e) => props.changeFieldHandler(e, props.id, "interventionHours")}
          onBlur={(e) => props.onBlurHandler(e, props.id, "interventionHours")}
        />

      </td>
      <td>  <DatePicker
        selected={props.formatedDate(props.stringInterventionEndDate)}
        className="pep-input w-full"
        dateFormat="dd/MM/yyyy"
        onChange={(date) => props.changeDateHandler(date, props.id, "stringInterventionEndDate")}
      />
      </td>
      <td><i className="fa-regular fa-circle-trash" onClick={() => props.DeleteInterventionRow(props.id)}></i></td>
    </tr>
  )
}

