import { Field, FieldArray, Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { useHistory } from "react-router-dom"
import InputField from '../../../../Components/NCC/InputField'
import RadioField from '../../../../Components/NCC/RadioField'
import TextAreaField from '../../../../Components/NCC/TextAreaField'
import SelectField from '../../../../Components/NCC/SelectField'
import { routeUrl } from "../../../../Components/utils/RouteUrl"
import DateField from '../../../../Components/NCC/DateField'
import NumberField from '../../../../Components/NCC/NumberField'
import NccDataHoc from '../../../../Components/NCC/NccDataHoc'
import { useEffect } from 'react'
import { NCCReportApi, pupilVoiceApi } from '../../../../constants/apiUrls'
import PupilContext from '../../../../Components/context/PupilContext'
import axios from 'axios'
import DataDateField from '../../../Pep/PepData/components/DataDateField'
import { useState } from 'react'
import { useReducer } from 'react'
import nccDataPrimaryReducer from '../../../../reducers/NCCData/nccDataPrimaryReducer'
import { setLoading, setNccPrimaryData, setUploadedSchoolReports } from '../../../../constants/reducerActions'
import NCCDataEyfsSkeleton from '../../../../Components/NCC/NCCDataEyfsSkeleton'
import UploadModal from '../../../Pep/PupilVoice/components/UploadModal'
import { checkForForTrimmedEmptyValue, formValidationWrapper, getPrototypeName, getYearGroup, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions'
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader'
import Swal from 'sweetalert2'
import IsCompletedContext from '../../../../Components/context/IsCompletedContext'
import CompleteButton from '../../../../Components/NCC/CompleteButton'



const initialState2 = {
  dataPrimary: null,
  pupilUnique: null,
  schoolUploadedFiles: null,
  primaryDataOptionsList: null,
  presentPer: null,
  exclusionCount: null,
  isLoading: false,
}

const progressOptionList = [
  <option value=""></option>,
  <option value="Below">Below</option>,
  <option value="Expected">Expected</option>,
  <option value="Exceeding">Exceeding</option>,
] 

const primaryNeedOptionList = [
  <option value=""></option>,
  <option value="Specific Learning Difficulty">Specific Learning Difficulty</option>,
  <option value="Moderate Learning Difficulty">Moderate Learning Difficulty</option>,
  <option value="Severe Learning Difficulty">Severe Learning Difficulty</option>,
  <option value="Profound &amp; Multiple Learning Difficulty">Profound &amp; Multiple Learning Difficulty</option>,
  <option value="Social, Emotional and Mental Health">Social, Emotional and Mental Health</option>,
  <option value="Speech, Language and Communications Needs">Speech, Language and Communications Needs</option>,
  <option value="Hearing Impairment">Hearing Impairment</option>,
  <option value="Visual Impairment">Visual Impairment</option>,
  <option value="Multi-Sensory Impairment">Multi-Sensory Impairment</option>,
  <option value="Physical Disability">Physical Disability</option>,
  <option value="Autistic Spectrum Disorder">Autistic Spectrum Disorder</option>,
  <option value="Other Difficulty/Disability">Other Difficulty/Disability</option>,
]

const NCCDataPrimary = ({
  attendanceButtonHandler,
  exclusionsButtonHandler,
  // saveForm,
  onChangeHandler,
  numberOnChangeHandler,
  dateChangeHandler,
  downloadUploadedFile,
  deleteUploadedFile,
  exportPdf,
}) => {
  const pdfReportName = "nccDataPrimary";
  const fullPepPdfReportName = "NccFullPep";

  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");

  const { dateOfMeeting, pupilInfo } = useContext(PupilContext);

  // Is Completed context for setting sidebar
  const { setIsCompleted } = useContext(IsCompletedContext);

  const [optionsList, setOptionList] = useState([]);

  const [state, dispatch] = useReducer(nccDataPrimaryReducer, initialState2);

  const [isOpen, setIsOpen] = useState(false);

  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);

  let selectOptions = [<option value=""></option>,]
  optionsList.forEach(item => selectOptions.push(<option value={item}>{item}</option>))

  useEffect(() => {
    const source = axios.CancelToken.source();
    console.log(typeof dateOfMeeting, "Type of =============");
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      dispatch({ type: setLoading, payload: { isLoading: true } });
      const url = NCCReportApi.getPrimaryData({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      axios.get(url, { cancelToken: source.token })
        .then((res) => {
          dispatch({ type: setNccPrimaryData, payload: res.data })
          setOptionList(res.data.primaryDataOptionsList);
          console.log(res.data, "response from ncc data primary");
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.", { position: "bottom-right"})
    }
    
    return () => {
      source.cancel();
    }
  }, [dateOfMeeting])

  const uploadedDocumentListHandler = (data, form) => {
    form.setFieldValue("schoolUploadedFiles", data);
    // dispatch({ type: setUploadedSchoolReports, payload: { schoolReports: data } })
  }

  const saveForm = ({ setIsLoading, values, saveCallback = () => { } }) => {
    const postUrl = NCCReportApi.savePrimaryData;
    const formData = new FormData();
    let objectName = "";
    if (getYearGroup(pupilInfo.classYear) === "eyfs") { objectName = "dataEyfs" }
    if (getYearGroup(pupilInfo.classYear) === "primary") { objectName = "dataPrimary" }
    if (getYearGroup(pupilInfo.classYear) === "secondary") { objectName = "dataSecondary" }
    if (getYearGroup(pupilInfo.classYear) === "post16") { objectName = "dataPost16" }

    Object.entries(values[objectName]).forEach(([key, value]) => {
      const valueType = getPrototypeName(value);
      if (valueType === "Object") {
        Object.entries(value).forEach(([key2, value2]) => {
          formData.append(`${objectName}.${key}.${key2}`, value2 ? value2 : "");
        })
      } else if (valueType !== "Object" && valueType !== "Array") {
        formData.append(`${objectName}.${key}`, value ? value : "")
      }
    })

    const pupilUnique = values.pupilUnique;

    formData.append(
      "pupilUnique.goodLevOfDevAchiev",
      pupilUnique?.goodLevOfDevAchiev ? pupilUnique.goodLevOfDevAchiev : "")
    formData.append(
      "pupilUnique.priPriorAttainment",
      pupilUnique?.priPriorAttainment ? pupilUnique.priPriorAttainment : "")
    formData.append(
      "pupilUnique.phonicsScreen",
      pupilUnique?.phonicsScreen ? pupilUnique.phonicsScreen : "")
    formData.append(
      "pupilUnique.phonicsResit",
      pupilUnique?.phonicsResit ? pupilUnique.phonicsResit : "")
    formData.append(
      "pupilUnique.readingKeyStage1Score",
      pupilUnique?.readingKeyStage1Score ? pupilUnique.readingKeyStage1Score : "")
    formData.append(
      "pupilUnique.phonicKeyStage1Score",
      pupilUnique?.phonicKeyStage1Score ? pupilUnique.phonicKeyStage1Score : "")
    formData.append(
      "pupilUnique.writingKeyStage1Score",
      pupilUnique?.writingKeyStage1Score ? pupilUnique.writingKeyStage1Score : "")
    formData.append(
      "pupilUnique.mathKeyStage1Score",
      pupilUnique?.mathKeyStage1Score ? pupilUnique.mathKeyStage1Score : "")
    


    axios.post(postUrl, formData)
      .then(res => {
        console.log(res.data, "saving response form primary data");
        setIsLoading(false);
        saveCallback();
      })
      .catch(err => {
        setIsLoading(false)
        showWarningToast("There is problem while saving")
      }) 
        
    setIsLoading(true);

  }

  const initialFormState = {
    dataPrimary: state.dataPrimary,
    pupilUnique: state.pupilUnique,
    schoolUploadedFiles: state.schoolUploadedFiles,
    primaryDataOptionsList: state.primaryDataOptionsList,
    presentPer: state.presentPer,
    exclusionCount: state.exclusionCount,
  }

  const isCompletedCallback = () => {
    setIsCompleted("dataIsCompleted", true);
    showReportCompleteSuccess("Data Primary Completed");
  }

  const successCallback = (values) => {
    values.dataPrimary.isCompleted = 1;
  }

  const checkForErrors = (values) => {
    let error = "";

    if (checkForForTrimmedEmptyValue(values.dataPrimary.commonData.youngPersonHaveSEND)) {
      error = "'Does this young person have SEND?' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.presentPer)) {
      error = "Attendance is required";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUnique.goodLevOfDevAchiev)) {
      error = "'Is our child/young person meeting expected level of development?' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.dataPrimary.makingGoodProgress)) {
      error = "'From your assessments, is the child/young person making good progress?' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUnique.priPriorAttainment)) {
      error = "Field is required priPriorAttainment";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUnique.readingKeyStage1Score)) {
      error = "Field is required readingKeyStage1Score";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUnique.mathKeyStage1Score)) {
      error = "Field is required mathKeyStage1Score";
    }
   
    else if (checkForForTrimmedEmptyValue(values.dataPrimary.endOfYearReading)) {
      error = "Field is required endOfYearReading";
    }

    else if (checkForForTrimmedEmptyValue(values.dataPrimary.endOfYearMaths)) {
      error = "Field is required endOfYearMaths";
    }
    
    else if (checkForForTrimmedEmptyValue(values.dataPrimary.progressReading)) {
      error = "Field is required progressReading";
    }

    else if (checkForForTrimmedEmptyValue(values.dataPrimary.progressMaths)) {
      error = "Field is required progressMaths";
    }

    else if (checkForForTrimmedEmptyValue(values.dataPrimary.progressPhonicsSPAG)) {
      error = "Field is required progressPhonicsSPAG";
    }

    else if (checkForForTrimmedEmptyValue(values.dataPrimary.progressWriting)) {
      error = "Field is required progressWriting";
    }

    else if (checkForForTrimmedEmptyValue(values.dataPrimary.dateCreated)) {
      error = "Field is required dateCreated";
    }

    return error;
  }

  const formValidaton = formValidationWrapper({
    saveForm,
    setIsCompleted,
    attributeName: "dataIsCompleted",
    successCallback,
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: isCompletedCallback,
  });

  return (
    <Formik
      initialValues={initialFormState}
      enableReinitialize
      onSubmit={formValidaton}
    >
      <Form>
        <PepSecondaryHeader
          heading={`NCC PEP - Data Primary`}
          buttons={[
            <CompleteButton
              fieldName={"dataPrimary.isCompleted"}
              completeButtonLoader={completeButtonLoader}
            />,
            <div className="export-btn">
              <span className="blue-button px-5 py-2  text-sm">Export</span>
              <ul>
                <li>
                  <button type="button" onClick={() => exportPdf(fullPepPdfReportName)}>Full Pep</button>
                </li>
                <li>
                  <button type="button" onClick={() => exportPdf(pdfReportName)}>PDF</button>
                </li>
              </ul>
            </div>
          ]}
        />
        {
          state.isLoading ?
            <NCCDataEyfsSkeleton /> :
            <>
              {/* This is fist part of page */}
              <div className="rounded-3xl bg-white lg:w-max md:pr-8 overflow-hidden">
                <div className="flex flex-col gap-2 md:flex-row md:gap-3 md:items-center">
                  <label htmlFor="" className='p-6 blue md:w-96'>Does this young person have SEND? <span className='required'>*</span></label>
                  <div className='pl-4 pt-2 pb-4 md:pl-0 md:pb-0'>
                    <RadioField
                      fieldName="dataPrimary.commonData.youngPersonHaveSEND"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      radioOptionsList={[
                        { innerValue: "Yes", outerValue: "Yes" },
                        { innerValue: "No", outerValue: "No" },
                      ]}
                    />
                  </div>
                </div>

                <div className="divide-solid divide-class ml-2 w-full"></div>

                <div className="flex flex-col gap-2 md:flex-row md:gap-3">
                  <label htmlFor="" className='p-6 pb-12 blue md:w-96'>If yes, how are they being supported? <span className='required'>*</span></label>
                  <div className='flex flex-col gap-3 pl-4 pb-4 md:pl-0 md:pb-0'>
                    <div className="flex items-center gap-3 md:pt-6">
                      <label htmlFor="" className='w-28'>SEN Support</label>
                      <Field name="dataPrimary.commonData.youngPersonHaveSEND">
                        {
                          ({ form, field }) => {

                            return (
                              <RadioField
                                fieldName="dataPrimary.commonData.SENSupport"
                                onChangeHandler={onChangeHandler}
                                saveForm={saveForm}
                                radioOptionsList={[
                                  { innerValue: "Yes", outerValue: "Yes" },
                                  { innerValue: "No", outerValue: "No" },
                                ]}
                                disabled={field.value === "No" ? true : false}
                              />
                            )
                          }
                        }
                      </Field>
                      {/* <RadioField
                        fieldName="dataPrimary.commonData.SENSupport"
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        radioOptionsList={[
                          { innerValue: "Yes", outerValue: "Yes" },
                          { innerValue: "No", outerValue: "No" },
                        ]}
                      /> */}
                    </div>
                    <div className="flex items-center gap-3">
                      <label htmlFor="" className='w-28'>EHCP</label>
                      <Field name="dataPrimary.commonData.youngPersonHaveSEND">
                        {
                          ({ form, field }) => {

                            return (
                              <RadioField
                                fieldName="dataPrimary.commonData.EHCP"
                                onChangeHandler={onChangeHandler}
                                saveForm={saveForm}
                                radioOptionsList={[
                                  { innerValue: "Yes", outerValue: "Yes" },
                                  { innerValue: "No", outerValue: "No" },
                                ]}
                                disabled={field.value === "No" ? true : false}
                              />
                            )
                          }
                        }
                      </Field>
                      {/* <RadioField
                        fieldName="dataPrimary.commonData.EHCP"
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        radioOptionsList={[
                          { innerValue: "Yes", outerValue: "Yes" },
                          { innerValue: "No", outerValue: "No" },
                        ]}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>


              {/* Second part of page */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <div className="flex flex-col gap-3">
                  <h1 className='text-xl font-semibold'>Attendance</h1>
                  <div className='flex flex-col gap-3 md:gap-4 md:flex-row md:items-center'>
                    <div className="flex gap-2 items-center">
                      <label htmlFor="">Attendance %: <span className="required">*</span> </label>
                      <InputField
                        fieldName="presentPer"
                        saveForm={saveForm}
                        onChangeHandler={numberOnChangeHandler}
                        
                        rest={{
                          className: "px-2 py-1 radio rounded-full border-gray-300 border text-sm w-16",
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className='flex gap-3'>
                      <button
                        type='button'
                        className='blue-button py-2 px-4 text-sm'
                        onClick={attendanceButtonHandler}
                      >Enter Attendance</button>

                      {/* <button type='button' className='blue-button py-2 px-4 text-sm'>Recalculate Attendance</button> */}
                    </div>
                  </div>
                  <div>
                    <p className='font-medium'>If below 95%, what actions are being taken or planned?</p>
                    <TextAreaField
                      fieldName="dataPrimary.commonData.actionsBeingTaken"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <h1 className='text-xl font-semibold'>Exclusions</h1>
                  <div className='flex flex-col gap-3 md:gap-4 md:flex-row md:items-center'>
                    <div className="flex items-center gap-3">
                      <label htmlFor="">No of sessions: </label>
                      <InputField
                        fieldName="exclusionCount"
                        saveForm={saveForm}
                        onChangeHandler={numberOnChangeHandler}
                        rest={{
                          className: "px-2 py-1 radio rounded-full border-gray-300 border text-sm w-16",
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className='flex gap-3'>
                      <button type='button'
                        className='blue-button py-2 px-4 text-sm'
                        onClick={exclusionsButtonHandler}
                      >Enter Exclusions</button>
                      {/* <button type='button' className='blue-button py-2 px-4 text-sm'>Recalculate Exclusions</button> */}
                    </div>
                  </div>
                  <div>
                    <p className='font-medium'>If our young person has had an exclusion in the last term, what actions are in place to prevent further exclusions?</p>
                    <TextAreaField
                      fieldName="dataPrimary.commonData.actionsAreInPlace"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                    />
                  </div>
                </div>
              </div>


              {/* Third part of page */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <h2 className='font-medium'>If YES please answer the following:</h2>
                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label htmlFor="">If Yes for ECHP, what is the primary identified need?</label>
                  <SelectField
                    fieldName={"dataPrimary.commonData.primaryIdentifiedNeed"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={primaryNeedOptionList}
                  />
                </div>
              </div>


              {/* Forth Part of page */}
              <div className="rounded-3xl bg-white p-6 w-full mt-4" >
                                 
                <div className="flex items-center gap-2">
                  <label className="">Date Completed <span className="required">*</span></label>
                  <DateField
                    saveForm={saveForm} 
                    dateChangeHandler={dateChangeHandler}
                    fieldName="dataPrimary.dateCreated"
                    rest={{
                      className: "w-full border border-gray-300 rounded-3xl px-2 py-1",
                    }}
                  />
                </div>
                                      
                <div className="flex w-full py-4">
                  <h3 className="blue rounded-3xl w-full px-4 py-2">Early Years Foundation Stage</h3>
                </div>
                
                <div className="py-2 flex w-full items-center">
                  <label>Good level of development currently achived? <span className="required">*</span></label>
                  <div className="pl-4 radio flex gap-3">
                    <RadioField 
                      fieldName="pupilUnique.goodLevOfDevAchiev"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      radioOptionsList={[
                        { innerValue: "Yes", outerValue: "Yes" },
                        { innerValue: "No", outerValue: "No" },
                      ]}
                    />

                  </div>
                </div>

                {/* Primary School section  */}
                <div className="flex w-full py-4">
                  <h3 className="purple rounded-3xl w-full px-4 py-2">Primary School</h3>
                </div>
                
                <div className="w-full items-center">
                  <div className="flex gap-3 pb-2">
                    <label htmlFor="">Is our child/young person predicted to achieve Phonics at the end of Year 1?</label>
                    <RadioField
                      fieldName="dataPrimary.phonicsEndOfYear1"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      radioOptionsList={[
                        { innerValue: "Yes", outerValue: "Yes" },
                        { innerValue: "No", outerValue: "No" },
                      ]}
                    />
                  </div>

                  <div className="divide-solid divide-class w-full my-1"></div>

                  <div className='flex flex-col gap-3 lg:flex-row lg:justify-between lg:gap-0 w-full pt-1'>
                    <div className="flex gap-3 items-center">
                        <label htmlFor="">Prior Attainment? <span className="required">*</span></label>
                        <RadioField 
                          fieldName="pupilUnique.priPriorAttainment"
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          radioOptionsList={[
                            { innerValue: "Yes", outerValue: "Yes" },
                            { innerValue: "No", outerValue: "No" },
                        ]}
                        />
                    </div>
                    <div className="pb-1 flex flex-col gap-4 md:flex-row md:items-center relative">

                      <div className="pl-0  flex gap-3 items-center">
                        <span>Year 1 Phonics screen</span>
                        <label className="inline-flex items-center">
                          
                          <NumberField
                            fieldName="pupilUnique.phonicsScreen"
                            saveForm={saveForm}
                            onChangeHandler={numberOnChangeHandler}
                            maxDigit={40}
                            rest={{
                              className: "py-1 px-2 rounded-full border-gray-300 border text-sm w-16"
                            }}
                          />
                          <span className="ml-2">/40</span>
                        </label>
                      </div>
                      <div className="pl-0  flex gap-3 items-center">
                        <span>Year 2 Phonics Resit</span>
                        <label className="inline-flex items-center">
                          <NumberField
                            fieldName="pupilUnique.phonicsResit"
                            saveForm={saveForm}
                            onChangeHandler={numberOnChangeHandler}
                            maxDigit={40}
                            rest={{
                              className: "py-1 px-2 rounded-full border-gray-300 border text-sm w-16"
                            }}
                          />
                          <span className="ml-2">/40</span>
                        </label>
                      </div>
                      

                    </div>
                  </div>
                </div>

                <div className='flex flex-col gap-5 pt-4'>
                  <div className="table-box overflow-x-auto">
                    <table className="table-auto text-center w-full">
                      <thead>
                        <tr>
                          <th rowSpan="2" className="w-3/5 blue"></th>
                          <th colSpan="3" className="d-pink w-8/12">English</th>
                          <th rowSpan="2" className="green w-2/12">Maths <span className="required">*</span></th>
                        </tr>
                        <tr>
                          <th className="rounded-none text-center w-40 d-pink">Reading <span className="required">*</span></th>
                          <th className=" w-40 d-pink">Phonics</th>
                          <th className="rounded-none w-40 d-pink">Writing</th>
                        </tr>
                      </thead>
                      <tbody>           
                        <tr>
                          <td>Key Stage 1 SATs Target for Y1-2</td>
                          <td>
                            <InputField 
                              fieldName="pupilUnique.readingKeyStage1Score"
                              saveForm={saveForm}
                              onChangeHandler={onChangeHandler}
                              rest={{
                                className: "p-2 text-center"
                              }}
                            />
                          </td>
                          <td>
                            <InputField 
                              fieldName="pupilUnique.phonicKeyStage1Score"
                              saveForm={saveForm}
                              onChangeHandler={onChangeHandler}
                              rest={{
                                className: "p-2 text-center"
                              }}
                            />
                          </td>
                          <td>
                            <InputField 
                              fieldName="pupilUnique.writingKeyStage1Score"
                              saveForm={saveForm}
                              onChangeHandler={onChangeHandler}
                              rest={{
                                className: "p-2 text-center"
                              }}
                            />
                          </td>
                          <td>
                            <InputField 
                              fieldName="pupilUnique.mathKeyStage1Score"
                              saveForm={saveForm}
                              onChangeHandler={onChangeHandler}
                              rest={{
                                className: "p-2 text-center"
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Current Attainment</td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.currentAttainmentReading"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={selectOptions}
                            />
                          </td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.currentAttainmentPhonicsSPAG"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={selectOptions}
                            />
                          </td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.currentAttainmentWriting"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={selectOptions}
                            />
                          </td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.currentAttainmentMaths"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={selectOptions}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Most recent scores</td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.endOfYearReading"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={selectOptions}
                            />
                          </td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.endOfYearPhonicsSPAG"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={selectOptions}
                            />
                          </td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.endOfYearWriting"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={selectOptions}
                            />
                          </td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.endOfYearMaths"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={selectOptions}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Progress? <span className="required">*</span></td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.progressReading"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={progressOptionList}
                            />

                          </td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.progressPhonicsSPAG"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={progressOptionList}
                            />

                          </td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.progressWriting"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={progressOptionList}
                            />

                          </td>
                          <td>
                            <SelectField 
                              fieldName={"dataPrimary.progressMaths"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={progressOptionList}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                    <label className='font-medium'>
                      Is our child/young person on a bespoke, targeted curriculum which is not measured by age/phase or working towards qualifications?
                    </label>
                    <SelectField
                      fieldName={"dataPrimary.targetedCurriculum"}
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      optionsList={[
                        <option value=""></option>,
                        <option value="Yes">Yes</option>,
                        <option value="No">No</option>,
                      ]}
                      rest={{
                        className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                      }}
                    />
                  </div>

                  <div className="table-box overflow-x-auto">
                    <table className="table-auto w-full">
                      <thead>
                        <tr className=''>
                          <th className='blue border-collapse border-2 border-white'>Current pathway/curriculum/subject</th>
                          <th className='d-blue border-collapse border-2 border-white'>Currently working at</th>
                          <th className='purple border-collapse border-2 border-white'>Expectations/target for end of KS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <TextAreaField
                              fieldName="dataPrimary.currentPathway"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                            />
                          </td>
                          <td className='text-center'>
                            <TextAreaField
                              fieldName="dataPrimary.currentWorking"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                            />                            
                          </td>
                          <td className='text-center'>
                            <TextAreaField
                              fieldName="dataPrimary.expectationsTarget"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                            />                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                    <label className='font-medium'>
                      From your assessments, is the child/young person making good progress? <span className="required">*</span>
                    </label>
                    <SelectField
                      fieldName={"dataPrimary.makingGoodProgress"}
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      optionsList={[
                        <option value=""></option>,
                        <option value="Yes">Yes</option>,
                        <option value="No">No</option>,
                      ]}
                      rest={{
                        className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                      }}
                    />
                  </div>

                  <div>
                    <p className='font-medium'>How do you know and how it is measured?</p>
                    <TextAreaField
                      fieldName="dataPrimary.howItIsMeasured"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                    />
                  </div>

                </div>

              </div>
              

              {/* fifth part of page */}
              <div className="rounded-3xl bg-white p-6 w-full mt-4" >
                <div className="flex flex-col gap-5">
                  <h1 className='font-medium text-md'>SDQ Summary</h1>
                  <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-8">
                    <div className="flex items-center gap-2">
                      <label htmlFor="" className=''>SDQ Score</label>
                      <InputField 
                        fieldName="dataPrimary.sdqScore"
                        saveForm={saveForm}
                        onChangeHandler={onChangeHandler}
                        rest={{
                          className:"py-1 px-2 rounded-full border-gray-300 border text-sm w-16",
                        }}
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <label className="">SDQ Date </label>
                      <DateField
                        dateChangeHandler={dateChangeHandler}
                        fieldName="dataPrimary.sdqDate"
                        saveForm={saveForm}
                      />
                    </div>
                  </div>
                  <p>Social Worker to provide the overall SDQ score. Discussion should take place about what impact this may have in school.</p>
                  <div className='flex flex-col gap-5 md:flex-row md:gap-7 md:items-center'>
                    <div className="flex gap-3 items-center">
                      <label className='font-medium'>School Report Uploaded? </label>
                      <RadioField 
                        fieldName="dataPrimary.schoolReportUploaded"
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        radioOptionsList={[
                          { innerValue: "Yes", outerValue: "Yes" },
                          { innerValue: "No", outerValue: "No" },
                        ]}
                      />
                    </div>

                    <div className='flex  items-center'>
                      <div className='file file--upload'>
                        <button 
                          type="button" 
                          className="upload-button px-5 py-2 text-sm"
                          onClick={() => setIsOpen(true)}
                        >
                          Upload<i className="upload-icon fa-regular fa-cloud-arrow-up"></i>
                        </button>
                      </div>
                      <div className="pl-4 view-report">
                        <button type="button" className="btn pep-btn">View Reports <i className="fa-regular fa-angle-down"></i></button>
                        <ul className="scroll_add">
                          <FieldArray name="schoolUploadedFiles">
                            {
                              ({form, remove}) => {
                                return (
                                  form.values.schoolUploadedFiles && form.values.schoolUploadedFiles.map((result, index) => {
                                    return (
                                      <li key={index} className="flex justify-between items-center gap-3">
                                        <span 
                                          onClick={() => downloadUploadedFile({
                                            blobKey: result.blobkey,
                                            fileName: result.fileName,
                                          })}
                                          className='flex justify-between items-center gap-3'>
                                           {result.fileShowName ? result.fileShowName : result.fileName}
                                        </span>
                                        <i
                                          className="fa-solid fa-circle-trash text-red-600"
                                          onClick={() => deleteUploadedFile({
                                            blobKey: result.blobkey,
                                            fileName: result.fileName,
                                            deleteFile: () => remove(index),
                                          })}
                                        >
                                        </i>
                                      </li>
                                    )
                                  }) 
                                )
                              }
                            }
                          </FieldArray>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
        }
        <Field>
          {
            ({ form }) => {
              return (
                <UploadModal
                  isOpen={isOpen}
                  closeModal={() => setIsOpen(false)}
                  isSecondary="Y"
                  listHandler={uploadedDocumentListHandler}
                  uploadUrl={NCCReportApi.saveSchoolReport}
                  responseObjectName={"schoolReport"}
                  form={form}
                />
              )
            }
          }
        </Field>
      </Form>
    </Formik>
  )
}

export default NccDataHoc(NCCDataPrimary);
