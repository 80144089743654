import axios from 'axios';
import React, { useEffect, useReducer, useContext } from 'react';
import Modal from './Modal';
import Skeleton from 'react-loading-skeleton';
import { FiCheck, FiX } from "react-icons/fi";
import PupilContext from './context/PupilContext';
import { setHistoryList as setHistoryListAction } from '../constants/reducerActions';
import historyModalReducer from '../reducers/historyModalReducer';
import { settings } from './utils/Settings';

const initialState = {
  historyList: [],
  isLoading: true
}

const HistoryModalContacts = (props) => {
  // Context for Active Pupil Id
  const { activePupilId } = useContext(PupilContext);
  // Create Reducer, and get history state and dispatch for perform state action on history state
  const [ historyState, dispatch ] = useReducer(historyModalReducer, initialState);
  
  const { historyList, isLoading } = historyState;

  // This useEffect calls api and set data to history list
  useEffect(() => {
    const url = `${settings.API_HOST}/rest/fetchPupilHistory?pupilId=${activePupilId}&history=${props.historyModalName.toLowerCase()}`;
    axios(url)
      .then((res) => {
        const pupilHistory = res.data.pupilContactsHistory[`${props.historyModalName}History`];
        dispatch({type: setHistoryListAction, payload: {data: pupilHistory}});
      });
  }, [])


  return (
    <Modal
      isOpen={props.isHistoryModalOpen}
      closeModal={props.closeHistoryModal}
    >
      <div className="history-modal">
        <div className="history-modal-heading flex gap-4 justify-between">
          <h2 className="flex-1">{`${props.historyHeading} History`}</h2>
          <FiX size={30} className="modal-content-close-icon" onClick={props.closeHistoryModal} />
        </div>
        <table className="history-modal-table child-table">
          <thead>
            <tr>
              <th style={{ width: "50%" }}>{props.historyHeading} Name</th>
              <th >Start Date</th>
              <th >End Date</th>
              <th >Current</th>
              
            </tr>
          </thead>
          <tbody>
            {isLoading ?
              <tr>
                <td><Skeleton height={20} count={1} /></td>
                <td><Skeleton height={20} count={1} /></td>
                <td><Skeleton height={20} count={1} /></td>
                <td><Skeleton height={20} count={1} /></td>
              </tr> :
              historyList && historyList.length > 0 &&
              historyList.map((item) =>
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.startDate}</td>
                  <td>{item.endDate}</td>
                  <td>{item.current && <FiCheck className="text-green-600 text-lg" />}</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </Modal>
  );
}


export default HistoryModalContacts;
