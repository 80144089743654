import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useReducer } from 'react';
import { useToastContainer } from 'react-toastify';
import PupilContext from '../../../../Components/context/PupilContext';
import { settings } from '../../../../Components/utils/Settings';
import { setPepCincpPost16 } from '../../../../constants/reducerActions';
import cincpReducer from '../../../../reducers/cincpReducer';
import PepSecondaryHeader from '../../PepSecondaryHeader';
import PepCinCpHistoryModule from './PepCinCpHistoryModel';
import CINCPHoc from '../../PepCinCP/components/CINCPHoc';
import { Form, Formik } from 'formik';
import CINCPInputField from './CINCPInputField';
import CINCPTextArea from './CINCPTextArea';


const initialState = {
  gldAchieved: "",
  phonicsScore: "",
  keyStage1Phonics: "",
  keyStage1Writing: "",
  keyStage1Reading: "",
  keyStage1Maths: "",
  keyStage2Spag: "",
  keyStage2Writing: "",
  keyStage2Reading: "",
  keyStage2Maths: "",
  keyStage4English: "",
  keyStage4Maths: "",
  comment: "",
  attendanceEndOfAutumn: "",
  attendanceEndOfSpring: "",
  attendanceEndOfSummer: "",
  sub1: "",
  sub1EndOfAutumn: "",
  sub1EndOfSpring: "",
  sub1EndOfSummer: "",
  sub2: "",
  sub2EndOfAutumn: "",
  sub2EndOfSpring: "",
  sub2EndOfSummer: "",
  sub3: "",
  sub3EndOfAutumn: "",
  sub3EndOfSpring: "",
  sub3EndOfSummer: "",
  sub4: "",
  sub4EndOfAutumn: "",
  sub4EndOfSpring: "",
  sub4EndOfSummer: "",
  commentsDevelopmentEndOfAutumn: "",
  commentsDevelopmentEndOfSpring: "",
  commentsDevelopmentEndOfSummer: "",
  educationNeedThisChild: "",
  barrierToMakingGoodProgress: "",
  spendingPpgChild: "",
  helpOvercomeTheBarriers: "",
  childIsNotInSchool: "",
  successLookLike: "",
}

const PepCinCPPost16 = ({ textFieldHandler, saveField }) => {

  const { activePupilId } = useContext(PupilContext);
  const roleId = localStorage.getItem("userRole");
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const schoolId = localStorage.getItem("schoolId");
  // const [reportData, setReportData] = useState({});
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyModalList, setHistoryModalList] = useState([]);
  const [historyFieldName, setHistoryFieldName] = useState("");

  const [reportData, dispatch] = useReducer(cincpReducer, initialState);

  const initialFormState = {
    gldAchieved: reportData.gldAchieved,
    phonicsScore: reportData.phonicsScore,
    keyStage1Phonics: reportData.keyStage1Phonics,
    keyStage1Writing: reportData.keyStage1Writing,
    keyStage1Reading: reportData.keyStage1Reading,
    keyStage1Maths: reportData.keyStage1Maths,
    keyStage2Spag: reportData.keyStage2Spag,
    keyStage2Writing: reportData.keyStage2Writing,
    keyStage2Reading: reportData.keyStage2Reading,
    keyStage2Maths: reportData.keyStage2Maths,
    keyStage4English: reportData.keyStage4English,
    keyStage4Maths: reportData.keyStage4Maths,
    comment: reportData.comment,
    attendanceEndOfAutumn: reportData.attendanceEndOfAutumn,
    attendanceEndOfSpring: reportData.attendanceEndOfSpring,
    attendanceEndOfSummer: reportData.attendanceEndOfSummer,
    sub1: reportData.sub1,
    sub1EndOfAutumn: reportData.sub1EndOfAutumn,
    sub1EndOfSpring: reportData.sub1EndOfSpring,
    sub1EndOfSummer: reportData.sub1EndOfSummer,
    sub2: reportData.sub2,
    sub2EndOfAutumn: reportData.sub2EndOfAutumn,
    sub2EndOfSpring: reportData.sub2EndOfSpring,
    sub2EndOfSummer: reportData.sub2EndOfSummer,
    sub3: reportData.sub3,
    sub3EndOfAutumn: reportData.sub3EndOfAutumn,
    sub3EndOfSpring: reportData.sub3EndOfSpring,
    sub3EndOfSummer: reportData.sub3EndOfSummer,
    sub4: reportData.sub4,
    sub4EndOfAutumn: reportData.sub4EndOfAutumn,
    sub4EndOfSpring: reportData.sub4EndOfSpring,
    sub4EndOfSummer: reportData.sub4EndOfSummer,
    commentsDevelopmentEndOfAutumn: reportData.commentsDevelopmentEndOfAutumn,
    commentsDevelopmentEndOfSpring: reportData.commentsDevelopmentEndOfSpring,
    commentsDevelopmentEndOfSummer: reportData.commentsDevelopmentEndOfSummer,
    educationNeedThisChild: reportData.educationNeedThisChild,
    barrierToMakingGoodProgress: reportData.barrierToMakingGoodProgress,
    spendingPpgChild: reportData.spendingPpgChild,
    helpOvercomeTheBarriers: reportData.helpOvercomeTheBarriers,
    childIsNotInSchool: reportData.childIsNotInSchool,
    successLookLike: reportData.successLookLike,
  }

  useEffect(() => {
    activePupilId && axios(`${settings.API_HOST}/rest/cin-cp?pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
      .then(res => {
        dispatch({ type: setPepCincpPost16, payload: { data: res.data.pepEyfsCinCp } })
      });
  }, [activePupilId]);

  const OpenHistoryPopup = (fieldName) => {
    setHistoryFieldName(fieldName);
    setIsHistoryModalOpen(true);
  }

  const closeHistoryModal = () => {
    setIsHistoryModalOpen(false);
  }



  return (
    <>
      <useToastContainer />
      <PepSecondaryHeader
        subHeading=""
        heading="CIN / CP / PLAC Post-16"
        buttons={[
          <button className="blue-button px-5 py-2  text-sm">Complete</button>,
        ]}
      />
      <Formik
        initialValues={initialFormState}
        enableReinitialize
      >
        <Form>
          <div className="secondary-section w-full">
            <div className="rounded-3xl bg-white p-4 w-full">
              <div className="flex w-full py-4">
                <h3 className="blue rounded-3xl w-full px-4 py-2">Prior Attainment</h3>
              </div>
              <div className="table-box w-full">
                <div className="table-outer rounded-3xl bg-white">
                  <table className="table-fixed text-left w-full">
                    <thead>
                      <tr>
                        <th className="blue w-32">EYFS</th>
                        <th className="d-blue w-32">GLD achieved?</th>
                        <th>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="gldAchieved"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.gldAchieved ? reportData.gldAchieved : ""}
                          /> */}
                        </th>
                        <th className="orange w-32">Phonics Score</th>
                        <th>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="phonicsScore"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.phonicsScore ? reportData.phonicsScore : ""}
                          /> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">Key stage 1</td>
                        <td>Phonics</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Phonics"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage1Phonics ? reportData.keyStage1Phonics : ""}
                          /> */}
                        </td>
                        <td>Writing</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Writing"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage1Writing ? reportData.keyStage1Writing : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>Reading</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Reading"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage1Reading ? reportData.keyStage1Reading : ""}
                          /> */}
                        </td>
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Maths"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage1Maths ? reportData.keyStage1Maths : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2">Key stage 2</td>
                        <td>SPaG</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Spag"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage2Spag ? reportData.keyStage2Spag : ""}
                          /> */}
                        </td>
                        <td>Writing</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Writing"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage2Writing ? reportData.keyStage2Writing : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>Reading</td>
                        <td>
                           <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Reading"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage2Reading ? reportData.keyStage2Reading : ""}
                          /> */}
                        </td>
                        <td>Maths</td>
                        <td>
                           <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Maths"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage2Maths ? reportData.keyStage2Maths : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>Key stage 4</td>
                        <td>English</td>
                        <td>
                           <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage4English"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage4English ? reportData.keyStage4English : ""}
                          /> */}
                        </td>
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage4Maths"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.keyStage4Maths ? reportData.keyStage4Maths : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>Comments</td>
                        <td colspan="4">
                          <CINCPTextArea
                            placeholder={""}
                            fieldName={"comment"}
                            fieldHandler={textFieldHandler}
                            saveField={saveField}
                            rest={""}
                          />
                          {/* <textarea
                            className="rounded-2xl px-2 py-4 textarea w-full"
                            placeholder="Type here..."
                            value={reportData.comment ? reportData.comment : ""}
                          ></textarea> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="rounded-3xl bg-white mt-4 p-4 w-full">
              <div className="flex w-full py-4">
                <h3 className="pink rounded-3xl w-full px-4 py-2">Latest Attendance and Attainment (Post-16)</h3>
              </div>
              <div className="table-box w-full">
                <div className="table-outer rounded-3xl bg-white">
                  <table className="table-fixed text-center w-full">
                    <thead>
                      <tr>
                        <th className="blue w-32"></th>
                        <th className="d-blue w-4/12">End of Autumn 2021</th>
                        <th className="purple w-4/12">End of Spring 2022</th>
                        <th className="orange w-4/12">End of Summer 2022</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Attendance</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="attendanceEndOfAutumn"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.attendanceEndOfAutumn ? reportData.attendanceEndOfAutumn : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="attendanceEndOfSpring"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.attendanceEndOfSpring ? reportData.attendanceEndOfSpring : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="attendanceEndOfSummer"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.attendanceEndOfSummer ? reportData.attendanceEndOfSummer : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub1"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub1 ? reportData.sub1 : ""}
                          /> */}

                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub1EndOfAutumn"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub1EndOfAutumn ? reportData.sub1EndOfAutumn : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub1EndOfSpring"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub1EndOfSpring ? reportData.sub1EndOfSpring : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub1EndOfSummer"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub1EndOfSummer ? reportData.sub1EndOfSummer : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub2"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub2 ? reportData.sub2 : ""}
                          /> */}

                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub2EndOfAutumn"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub2EndOfAutumn ? reportData.sub2EndOfAutumn : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub2EndOfSpring"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub2EndOfSpring ? reportData.sub2EndOfSpring : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub2EndOfSummer"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub2EndOfSummer ? reportData.sub2EndOfSummer : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub3"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub3 ? reportData.sub3 : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub3EndOfAutumn"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub3EndOfAutumn ? reportData.sub3EndOfAutumn : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub3EndOfSpring"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub3EndOfSpring ? reportData.sub3EndOfSpring : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub3EndOfSummer"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub3EndOfSummer ? reportData.sub3EndOfSummer : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub4"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub4 ? reportData.sub4 : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub4EndOfAutumn"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub4EndOfAutumn ? reportData.sub4EndOfAutumn : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub4EndOfSpring"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub4EndOfSpring ? reportData.sub4EndOfSpring : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub4EndOfSummer"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.sub4EndOfSummer ? reportData.sub4EndOfSummer : ""}
                          /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>Comments</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="commentsDevelopmentEndOfAutumn"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.commentsDevelopmentEndOfAutumn ? reportData.commentsDevelopmentEndOfAutumn : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="commentsDevelopmentEndOfSpring"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.commentsDevelopmentEndOfSpring ? reportData.commentsDevelopmentEndOfSpring : ""}
                          /> */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="commentsDevelopmentEndOfSummer"
                            rest={{
                              className: "pep-input w-full"
                            }}
                          />
                          {/* <input
                            type="text"
                            className="pep-input w-full"
                            value={reportData.commentsDevelopmentEndOfSummer ? reportData.commentsDevelopmentEndOfSummer : ""}
                          /> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="rounded-3xl bg-white mt-4 p-4 w-full">
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What are the main educational needs of this child? <i className="fas fa-folder-open" onClick={() => OpenHistoryPopup("education_need_this_child")}></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={"Comment"}
                      fieldName={"educationNeedThisChild"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder="Comment"
                      value={reportData.educationNeedThisChild ? reportData.educationNeedThisChild : ""}
                    ></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What are the barriers to making good progress? <i className="fas fa-folder-open" onClick={() => OpenHistoryPopup("barriers_to_making_good_progress")} ></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={"Comment"}
                      fieldName={"barrierToMakingGoodProgress"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder="Comment"
                      value={reportData.barrierToMakingGoodProgress ? reportData.barrierToMakingGoodProgress : ""}
                    ></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">How is the school spending PPG for the child? <i className="fas fa-folder-open" onClick={() => OpenHistoryPopup("spending_for_this_child")} ></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={"Comment"}
                      fieldName={"spendingPpgChild"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder="Comment"
                      value={reportData.spendingPpgChild ? reportData.spendingPpgChild : ""}
                    ></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What Additional support might help overcome the barriers? <i className="fas fa-folder-open" onClick={() => OpenHistoryPopup("help_overcome_the_barriers")} ></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={"Comment"}
                      fieldName={"helpOvercomeTheBarriers"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder="Comment"
                      value={reportData.helpOvercomeTheBarriers ? reportData.helpOvercomeTheBarriers : ""}
                    ></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">If this child is NOT in school? <i className="fas fa-folder-open" onClick={() => OpenHistoryPopup("child_is_not_in_school")} ></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={"Comment"}
                      fieldName={"childIsNotInSchool"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder="Comment"
                      value={reportData.childIsNotInSchool ? reportData.childIsNotInSchool : ""}
                    ></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What would success look like? <i className="fas fa-folder-open" onClick={() => OpenHistoryPopup("success_look_like")} ></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={"Comment"}
                      fieldName={"successLookLike"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea
                      className="rounded-2xl px-2 py-4 textarea w-full"
                      placeholder="Comment"
                      value={reportData.successLookLike ? reportData.successLookLike : ""}
                    ></textarea> */}
                  </div>
                </div>
              </div>
            </div>

            <PepCinCpHistoryModule
              isHistoryModalOpen={isHistoryModalOpen}
              closeHistoryModal={closeHistoryModal}
              historyModalAtrributeName={historyFieldName}
            />

          </div>
        </Form>
      </Formik>
    </>
  )
}
export default CINCPHoc(PepCinCPPost16);