import React from 'react';

class SelectFormField extends React.Component {
  
  render() {
    return (
      <div className="pupil-form-field select-field">
        <div className="form-field-label grid-col-3">
          <label >{this.props.fieldLabel}
            {this.props.required && <span className="red">*</span>}
          </label>
        </div>
        <div className="form-field-body">
          {this.props.fieldIcon && <this.props.fieldIcon className="form-field-icon"/>}
          <select 
            // readOnly
            name={this.props.fieldName}
            value={!this.props.fieldValue ? "" : this.props.fieldValue} 
            className="rounded-custom-content select-input"
            onChange={this.props.onChangeHandler}
          >
            <option value=""></option>
          
        {/* {
              this.props.optionList.map((option, index) => {
                return <option
                          title={option.title ? option.title : option.optionLabel} 
                          key={index} 
                          value={option.optionValue}
                        >
                          {option.optionLabel}
                        </option>
              })
            } */}
          </select>
        </div>
      </div>
    );
  }
}

export default SelectFormField;
