import React from 'react'
import { setAllCompletedStatus, setIsCompleted } from '../../constants/reducerActions'

const nccIsCompletedContextReducer = (state, action) => {
  const payload = action.payload;

  switch(action.type) {
    
    case setIsCompleted:
      return {
        ...state,
        [payload.attribute]: payload.isCompleted,
      }

    case setAllCompletedStatus:
      return {
        dataIsCompleted: payload.data,
        pupilVoiceIsCompleted: payload.pupilVoice,
        strengthAndNeedIsCompleted: payload.strengthAndNeeds,
        meetingIsCompleted: payload.meeting,
        targetAndActionIsCompleted: payload.target,
      }
  }
}

export default nccIsCompletedContextReducer