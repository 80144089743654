import React, { memo } from "react";
import { FiFolder } from "react-icons/fi";

const ContactRow = ({
  fieldIcon,
  openHistoryModal,
  history,
  fieldHeading,
  name,
  telephone,
  mobile,
  email,
}) => {
  return (
    <tr>
      <td>
        <div className="td_text flex justify-between">
          <span>{fieldHeading}</span>
          <span className="mr-4 icon self-center contactSpan">
            {fieldIcon && (
              <FiFolder
                className="text-blue-500 hover:text-blue-800 text-sm ml-2 lg:pl-0"
                onClick={() => openHistoryModal(history, fieldHeading)}
              />
            )}
          </span>
        </div>
      </td>
      <td>
        <div className="td_text">
          {name}
          {/* {contacts[`${fieldName}Name`]} */}
        </div>
      </td>
      <td>
        <div className="td_text">
          {telephone}
          {/* {contacts[`${fieldName}Tel`]} */}
        </div>
      </td>
      <td>
        <div className="td_text">
          {mobile}
          {/* {contacts[`${fieldName}Mobile`]} */}
        </div>
      </td>
      <td>
        <div className="td_text">
          {email}
          {/* {contacts[`${fieldName}Email`]} */}
        </div>
      </td>
    </tr>
  );
};
// const ContactRow = ({fieldName, fieldIcon, openHistoryModal, contacts, history, fieldHeading}) => {
//   console.log("In contact row render");
//   return (
//     <tr>
//       <td>
//         <div className="td_text flex justify-between">
//           <span>{fieldHeading}</span>
//           <span className="mr-4 icon self-center">
//             {
//               fieldIcon &&
//                 <FiFolder
//                   className="text-blue-500 hover:text-blue-800 text-sm ml-2 lg:pl-0"
//                   onClick={() => openHistoryModal(history, fieldHeading)}
//                 />
//             }
//           </span>
//         </div>
//       </td>
//       <td>
//         <div className="td_text">
//           {contacts[`${fieldName}Name`]}
//         </div>
//       </td>
//       <td>
//         <div className="td_text">
//           {contacts[`${fieldName}Tel`]}
//         </div>
//       </td>
//       <td>
//         <div className="td_text">
//           {contacts[`${fieldName}Mobile`]}
//         </div>
//       </td>
//       <td>
//         <div className="td_text">
//           {contacts[`${fieldName}Email`]}
//         </div>
//       </td>
//     </tr>
//   );
// }

export default memo(ContactRow);
// export default ContactRow;
