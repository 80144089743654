import React from 'react'
import { setLoading, setNccPost16Data, setUploadedSchoolReports } from '../../constants/reducerActions'

const nccDataPost16Reducer = (state, action) => {
  const data = action.payload.data;
  
  switch(action.type) {
    case setNccPost16Data:
      return {
        dataPost16: data.dataPost16,
        ks4Subject: data.ks4Subject,
        post16Ks5Grades: data.post16Ks5Grades,
        pupilUnique: data.pupilUnique,
        subjectNames: data.subjectNames,
        mathsGrade: data.mathsGrade,
        englishGrade: data.englishGrade,
        schoolUploadedFiles: data.schoolUploadedFiles,
        presentPer: data.presentPer,
        exclusionCount: data.exclusionCount,
        isLoading: false,
      }

    case setUploadedSchoolReports:
      return {
        ...state,
        schoolUploadedFiles: action.payload.schoolReports,
      }
      
    case setLoading:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
  }

}

export default nccDataPost16Reducer
