export const success = "success";
export const failure = "failure";
export const openHistoryModal = "openHistoryModal";
export const closeHistoryModal = "closeHistoryModal";

export const loading = "loading";
export const notLoading = "notLoading";

export const setSearchField = "setSearchField";
export const clearSearchField = "clearSearchField";
export const increaseCursor = "increaseCursor";
export const decreaseCursor = "decreaseCursor";

export const setIdToSearch = 'setIdToSearch';
export const setCurrentPage = 'setCurrentPage';
export const setSearchResult = "setSearchResult";

export const openSearchCollapse = "openSearchCollapse";
export const closeSearchCollapse = "closeSearchCollapse";
export const openNavLinks = "openNavLinks";
export const closeNavLinks = "closeNavLinks";
export const toggleNavLinks = "toggleNavLinks";
export const toggleSetting = "toggleSetting";
export const setSearchCollapse = "setSearchCollapse";
export const clearAllPopup = "clearAllPopup";
export const closeSetting = "closeSetting";

export const setHistoryList = "setHistoryList";

export const setAuthenticatedUser = "setAuthenticatedUser";
export const setStateReady = "setStateReady";
// export const 

// Reducer Action for Pep Eyfs Pupil Voice Report
export const setEyfsVoiceData = "setEyfsVoiceData";
export const addEyfsVoiceDocument = "addEyfsVoiceDocument";
export const deleteEyfsVoiceDocument = "deleteEyfsVoiceDocument";

// Reducer Action For primary voice
export const setPrimaryVoiceData = "setPrimaryVoiceData";
export const setPrimaryVoiceDataTextArea = "setPrimaryVoiceDataTextArea";
export const setAchievementListItemData = "setAchievementListItemData";
export const addAchievementListItem = "addAchievementListItem";
export const deleteAchievementListItem = "deleteAchievementListItem";
export const addPrimaryVoiceDocument = "addPrimaryVoiceDocument";
export const deletePrimaryVoiceDocument = "deletePrimaryVoiceDocument";

// Reducer Action For secondary voice
export const setSecondaryVoiceData = "setPrimaryVoiceData";
export const setSecondaryVoiceDataTextArea = "setPrimaryVoiceDataTextArea";
export const addSecondaryVoiceDocument = "addPrimaryVoiceDocument";
export const deleteSecondaryVoiceDocument = "deletePrimaryVoiceDocument";

// Reducer Action For post16 voice
export const setPost16VoiceData = "setPost16VoiceData";
export const setPost16VoiceDataTextArea = "setPost16VoiceDataTextArea";
export const addPost16VoiceDocument = "addPost16VoiceDocument";
export const deletePost16VoiceDocument = "deletePost16VoiceDocument";

// Reducer Action for Target
export const setTargetPageData = "setTargetPageData";
export const setLoading = "setLoading";

// Reducer Action for Pep Finance
export const getPepFinance = "getPepFinance";
export const setFinanceProcess = "setFinanceProcess";
export const setPageNo = "setPageNo";
export const setRecordsPerPage = "setRecordsPerPage";


// startNewPep
export const getStartNewPepInitialData = "getStartNewPepInitialData";
export const setLoadingTrue = "setLoadingTrue";
export const setLoadingFalse = "setLoadingFalse";

// Pep  Data
export const setPepPrimaryData = "getStartNewPepInitialData";
export const setSchoolReports = "setSchoolReports";
export const setPepSecondaryData = "setPepSecondaryData";

// Pep Basic Info Doc
export const setPepBasicInfoDocData = "setPepBasicInfoDocData";
export const setSearch = "setSearch";


// Assessment
export const setAssessmentEyfsReport = "setAssessmentEyfsReport";
export const setAssessmentY1Report = "setAssessmentY1Report";
export const setAssessmentY2Report = "setAssessmentY2Report";
export const setAssessmentY3ToY6Report = "setAssessmentY3ToY6Report";
export const setAssessmentY7ToY11Report = "setAssessmentY7ToY11Report";
export const setAssessmentY12ToY14Report = "setAssessmentY12ToY14Report";
export const setEmptyStateY1 = "setEmptyStateY1";
export const setEmptyStateY2 = "setEmptyStateY2";
export const setEmptyStateY3ToY6 = "setEmptyStateY3ToY6";
export const setEmptyStateY7ToY11 = "setEmptyStateY7ToY11";
export const setEmptyStateY12ToY14 = "setEmptyStateY12ToY14";
export const setEmptyStateEyfs = "setEmptyStateEyfs";
export const setIndexOfSubjectName = "setIndexOfSubjectName";
export const searchForPupil = "searchForPupil";
export const setSorting = "setSorting";
export const setPupilList = "setPupilList";
export const setResultsPerPage = "setResultsPerPage";
export const setCompletedAssessment = "setCompletedAssessment";
export const setCompletedAssessmentList = "setCompletedAssessmentList";

// CIN CP
export const setPepCincpEyfs = "setPepCincpEyfs";
export const setPepCincpPost16 = "setPepCincpPost16";
export const setPepCincpPrimary = "setPepCincpPrimary";
export const setPepCincpSecondary = "setPepCincpSecondary";

// ==== NCC

// NCC DATA
export const setNccEyfs = "setNccEyfs";
export const setNccPrimaryData = "setNccPrimaryData";
export const setNccSecondaryData = "setNccSecondaryData";
export const setNccPost16Data = "setNccPost16Data";
export const setUploadedSchoolReports = "setUploadedSchoolReports";

// NCC VOICE
export const setNccPupilVoice = "setNccPupilVoice";
export const setNccPupilVoicePost16 = "setNccPupilVoicePost16";

// NCC Strength And Needs
export const setNccStrengthAndNeed = "setNccStrengthAndNeed";
export const setNccStrengthAndNeedPost16 = "setNccStrengthAndNeedPost16";

// NCC Meeting
export const setNccMeetingReport = "setNccMeetingReport";
export const setNccMeetingPost16Report = "setNccMeetingPost16Report";

// NCC Target
export const setNccTargetReport = "setNccTargetReport";
export const setEmptyNccTargetReport = "setEmptyNccTargetReport";

// Is Completed Context Reducer Action
export const setIsCompleted = "setIsCompleted"; 
export const setAllCompletedStatus = "setAllCompletedStatus";

// My Pupil (Pep status)
export const setMyPupilReport = "setMyPupilReport"; 

// NCC Header Filter
export const setPepIsDue = "setPepIsDue"; 
export const setPepOverDue = "setPepOverDue"; 
export const setPepRejected = "setPepRejected"; 
export const setAllPep = "setAllPep"; 
export const setPepStatus = "setPepStatus"; 
export const setPepNotApproved = "setPepNotApproved"; 
export const setPepApprovedWithAction = "setPepApprovedWithAction"; 
export const setPepApproved = "setPepApproved"; 
export const setPepInadequate = "setPepInadequate"; 

// Prior Attainemnt 
export const setPriorAttainment = "setPriorAttainment"; 


// Setting tabs
export const deleteTab = "deleteTab";
export const setToggleState = "setToggleState";
export const setTabList = "setTabList";
export const setTabTitle = "setTabTitle";