import { Field } from 'formik';
import React from 'react'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useDebouncedCallback } from 'use-debounce/lib';

const CINCPInputField = ({
  onChangeHandler,
  saveField,
  fieldName,
  rest,
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const debouncedSave = useDebouncedCallback(saveField, 1000);

  return (
    <div className='relative'>
      <Field name={fieldName}>
        {
          ({form, field}) => {
            return (
              <input 
                type="text"
                value={field.value}
                onChange={(e) => onChangeHandler({
                  fieldName,
                  setIsLoading,
                  value: e.target.value,
                  saveField: debouncedSave,
                  setFieldValue: form.setFieldValue,
                })} 
                 className='w-full px-2'
                {...rest}
              />
            )
          }
        }
      </Field>
      {
        isLoading &&
          <div className='absolute top-0 left-0 w-full h-full grid items-center bg-white'>
            <Skeleton />
          </div>
      }
    </div>
  )
}

export default CINCPInputField
