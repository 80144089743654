import React, { memo, useContext } from "react";

import PupilContext from "../../Components/context/PupilContext";

const PepSecondaryHeader = ({ subHeading, heading, buttons }) => {
  const { pupilInfo, dateOfMeeting } = useContext(PupilContext);
  const termOfPep = localStorage.getItem("termOfPep");

  return (
    <div className="w-full pep-secondary-header-box pb-6">
      <div className="pep-secondary-header flex flex-col md:flex-row justify-between md:items-center pb-5 gap-2">
        <div className="flex gap-3">
          <h1 className="text-xl lg:text-2xl">
            {heading}
          </h1>
          <div className="flex gap-3 ">
            <i className="fa-light fa-circle-play text-2xl"></i>
            <i className="fa-light fa-circle-info text-2xl"></i>
          </div>
        </div>
        <div className="flex gap-2 self-end xl:self-start">
          {buttons}
        </div>
      </div>

      <div className="bg-white p-7 rounded-3xl pep-secondary-header flex flex-col  xl:flex-row gap-5 xl:gap-6 text-sm justify-start">
        <div className="flex flex-col gap-5	xl:w-2/5">
          <div className="flex gap-1 items-center">
            <label htmlFor="" className="font-medium w-40">
              Name:{" "}
            </label>
            <input
              // readOnly
              type="text"
              className="rounded-custom-content pep-form-field w-full"
              value={
                pupilInfo && pupilInfo.fullname
                  ? pupilInfo.fullname
                  : "Not Selected"
              }
            />
          </div>
          <div className="flex gap-1 items-center">
            <label htmlFor="" className="font-medium w-40">
              School Name:{" "}
            </label>
            <input
              // readOnly
              type="text"
              className="rounded-custom-content pep-form-field w-full"
              value={
                pupilInfo && pupilInfo.schoolname
                  ? pupilInfo.schoolname
                  : "Not Selected"
              }
            />
          </div>
        </div>

        <div className="flex flex-col gap-5 xl:w-1/5">
          <div className="flex gap-1 items-center justify-between">
            <label htmlFor="" className="font-medium w-40">
              Date of Meeting:{" "}
            </label>
            <input
              readOnly
              type="text"
              className="rounded-custom-content p-5 pep-form-field w-full"
              value={pupilInfo.dateofmeeting}
            />
          </div>
          <div className="flex gap-1 items-center justify-between">
            <label htmlFor="" className="font-medium w-40">
              Term of Pep:{" "}
            </label>
            <input
              readOnly
              type="text"
              className="rounded-custom-content p-5 pep-form-field w-full"
              value={termOfPep}
            // value={pupilInfo.termOfPep}
            />
          </div>
        </div>

        <div className="flex flex-col gap-5 xl:w-1/5">
          <div className="flex gap-1 items-center justify-between">
            <label htmlFor="" className="font-medium w-40">
              PEP Year:{" "}
            </label>
            <input
              readOnly
              type="text"
              className="rounded-custom-content p-5 pep-form-field w-full"
              value={
                pupilInfo && pupilInfo.classYear
                  ? pupilInfo.classYear
                  : "Not Selected"
              }
            />
          </div>
        </div>









      </div>
      <div className="flex">
        <div className="blue-button text-sm px-4 py-2 mr-4">
          <label className="font-medium w-40 text-white">
            Total PP+ Claimed :
          </label>
          <span></span>
        </div>
        <div className="blue-button text-sm px-4 py-2 mr-4">
          <label className="font-medium w-40 text-white">
            Ref :
          </label>
          <span></span>
        </div>
        <div className="blue-button text-sm px-4 py-2">
          <label className="font-medium w-auto text-white">
            Total PP+ Claimed :
          </label>
          <span></span>
        </div>
      </div>
    </div>
  );
};

const isEqual = (prevState, currentState) => {
  return prevState.heading === currentState.heading;
}

// export default memo(PepSecondaryHeader, isEqual);
export default memo(PepSecondaryHeader);
// export default PepSecondaryHeader;
