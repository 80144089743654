import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
//Importing Icons from react icon
import { FiFolder } from "react-icons/fi";
import SelectField from "./SelectField";
import SelectFormField from "./SelectFormField";
import { Field, FieldArray, Form, Formik } from "formik";
import RadioField from "./RadioField";

// Importing form fields

// Importing skeleton for loading time
import Skeleton from "react-loading-skeleton";

// Importing Activate Link Function for activating the components

import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router";
import PupilContext from "../../../../../Components/context/PupilContext";
import pupilReducer from "../../../../../reducers/pupilReducer";
import useDocumentTitle from "../../../../../hooks/useDocumentTitle";
import useActivateTabBasicInfo from "../../../../../hooks/useActivateTabBasicInfo";
import TextFormField from "../../../../../Components/TextFormField";
import { routeUrl } from "../../../../../Components/utils/RouteUrl";
import {
  closeHistoryModal as closeHistoryModalConstant,
  loading,
  notLoading,
  openHistoryModal as openHistoryModalConstant,
  setAchievementListItemData,
  success,
} from "../../../../../constants/reducerActions";
import HistoryModal from "../../../../../Components/HistoryModal";
import { settings } from "../../../../../Components/utils/Settings";

const initialState = {
  isLoading: true,
  forename: "",
  surname: "",
  upn: "",
  uln: "",
  lvsNotes: "",
  dob: "",
  stringDob: "",
  yeargroup: "",
  phase: "",
  gender: "",
  senCop: "",
  cwd: "",
  eal: "",
  firstLanguage: "",
  ethnicity: "",
  uasc: "",
  dateIntoCare: "",
  dateCareEnded: "",
  ragRating: "",
  youngPersonTel: "",
  youngPersonEmail: "",
  interviewBeenAddedToAsset: "",

  schoolName: "",
  schoolTel: "",
  schoolEmail: "",
  schoolAddress1: "",
  schoolAddress2: "",
  schoolAddress3: "",
  schoolPostcode: "",
  laInOrOut: "",
  schoolWebsite: "",
  ofstedGrade: "",
  schoolLa: "",
  urn: "",
  dfe: "",
  schoolmoves: "",
  dualRegisterSchool: "",
  leavers: "",

  vsPupilNo: "",
  legalStatus: "",
  numberOfPlacements: "",
  typeOfPlacement: "",
  placementLocalAuthority: "",
  placementDate: "",
  contactArrangements: "",
  placementNotification: "",
  dateBecameCareLeaver: "",
  numberOfSchool: "",
  countryOfOrigin: "",
  religion: "",
  laptop: "",
  dateLeftCare: "",
  tution: "",
  nationalInsuranceNo: "",
  course: "",
  intendedDestination: "",
  youngParent: "",
  yosService: "",
  cicType: "",

  socialWorker: "",
  socialWorkerTeam: "",
  designatedTeacher: "",
  pupilsCarerName: "",
  virtualSchoolOfficer: "",
  iro: "",

  isHistoryModalOpen: false,
  historyModalName: "",
  ppEligible: "",
};

const PepBasicPupilTab = (props) => {
  const roleId = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  // console.log(roleId, "roleId");
  // const schoolId = 442;
  const schoolId = localStorage.getItem("schoolId");
  const history = useHistory();
  // Getting Context variables
  const { activePupilId, activePupilYear } = useContext(PupilContext);
  // console.log(activePupilYear,"testing active year group");
  const [state, dispatch] = useReducer(pupilReducer, initialState);

  const [schoolNameLabel, setSchoolnameLabel] = useState("PEP School Name");
  const [schoolTelLabel, setSchoolTelLabel] = useState("School Tel");
  const [schoolEmailLabel, setSchoolEmailLabel] = useState("School Email");
  const [schoolAddress1Label, setSchoolAddress1Label] =
    useState("School Address1");
  const [schoolAddress2Label, setSchoolAddress2Label] =
    useState("School Address2");
  const [schoolAddress3Label, setSchoolAddress3Label] =
    useState("School Address3");
  const [isLoading, setIsLoading] = useState(false);

  const {
    // isLoading,
    // setIsLoading,

    forename,
    surname,
    upn,
    uln,
    lvsNotes,
    dob,
    stringDob,
    yeargroup,
    phase,
    gender,
    senCop,
    cwd,
    eal,
    firstLanguage,
    ethnicity,
    uasc,
    dateIntoCare,
    dateCareEnded,
    ragRating,
    youngPersonTel,
    youngPersonEmail,
    ppEligible,
    interviewBeenAddedToAsset,

    schoolName,
    schoolTel,
    schoolEmail,
    schoolAddress1,
    schoolAddress2,
    schoolAddress3,
    schoolPostcode,
    laInOrOut,
    schoolWebsite,
    ofstedGrade,
    schoolLa,
    urn,
    dfe,
    schoolmoves,
    dualRegisterSchool,
    leavers,
    leaversflag,
    careStatus,
    dateaddedintoASSET,

    vsPupilNo,
    legalStatus,
    numberOfPlacements,
    typeOfPlacement,
    placementLocalAuthority,
    placementDate,
    contactArrangements,
    placementNotification,
    dateBecameCareLeaver,
    numberOfSchool,
    countryOfOrigin,
    religion,
    laptop,
    dateLeftCare,
    tution,
    nationalInsuranceNo,
    course,
    intendedDestination,
    youngParent,
    yosService,
    cicType,

    socialWorker,
    socialWorkerTeam,
    designatedTeacher,
    pupilsCarerName,
    virtualSchoolOfficer,
    iro,

    isHistoryModalOpen,
    historyModalName,
    onChangeHandler,
    saveForm,
    deleteSmartTargetRow,
    form,
    remove,
    rowIndex,
  } = state;

  //change window/page title
  useDocumentTitle("Basic Info Pupil");

  useActivateTabBasicInfo(routeUrl.basic_info_pupil);

  useEffect(() => {
    dispatch({ type: loading });
    // getting Pupil Basic Info from Pupil Basic Info API
    // activePupilId && axios(`${settings.API_HOST}/rest/fetchPupilBasicInfo?pupilId=${activePupilId}`)
    activePupilId &&
      axios(
        `${settings.API_HOST}/rest/fetchPupilBasicInfo?pupilId=${activePupilId}&schoolId=${schoolId}&roleId=${roleId}&userId=${userId}`
      )
        .then((res) => {
          dispatch({ type: success, payload: res.data });

          if (
            activePupilYear == "Y12" ||
            activePupilYear == "Y13" ||
            activePupilYear == "Y14"
          ) {
            setSchoolnameLabel("PEP Provider Name");
            setSchoolTelLabel("Provider Tel");
            setSchoolEmailLabel("Provider Email");
            setSchoolAddress1Label("Provider Address 1");
            setSchoolAddress2Label("Provider Address 2");
            setSchoolAddress3Label("Provider Address 3");
          } else if (
            activePupilYear == "N1" ||
            activePupilYear == "N2" ||
            activePupilYear == "N3" ||
            activePupilYear == "N4" ||
            activePupilYear == "N5" ||
            activePupilYear == "R"
          ) {
            setSchoolnameLabel("PEP School/Setting Name");
            setSchoolTelLabel("School/Setting Tel");
            setSchoolEmailLabel("School/Setting Email");
            setSchoolAddress1Label("School/Setting Address 1");
            setSchoolAddress2Label("School/Setting Address 2");
            setSchoolAddress3Label("School/Setting Address 3");
          }
        })
        .catch((err) => {
          toast.warn(
            "Something went wrong please reload and try again and again",
            {
              position: "top-right",
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: false,
            }
          );
        });
  }, [activePupilId]);

  // Open History Modal
  const openHistoryModal = (e, history) => {
    dispatch({
      type: openHistoryModalConstant,
      payload: { historyName: history },
    });
  };

  // Close Modal History for Surname and forename of Pupil
  const closeHistoryModal = () => dispatch({ type: closeHistoryModalConstant });

  const calculateAgeFromDob = (dob) => {
    if (dob !== null) {
      const year = Number(dob.substr(0, 4));
      const month = Number(dob.substr(4, 2)) - 1;
      const day = Number(dob.substr(6, 2));
      const today = new Date();
      let age = today.getFullYear() - year;
      if (
        today.getMonth() < month ||
        (today.getMonth() === month && today.getDate() < day)
      ) {
        age--;
      }
      return age;
    }
  };

  return (
    <div className="flex-1" style={{ color: "#4C4D6C" }}>
      <ToastContainer />
      <div className="basic-header lg:flex w-full pt-4 justify-between">
        <div className="basic-left">
          <h1>
            Personal Information Pupil
            <span>
              <i className="fa-regular fa-circle-video"></i>
            </span>
            <span>
              <i className="fa-regular fa-circle-info"></i>
            </span>
          </h1>
        </div>
        <div className="basic-right">
          <span>
            <i className="fa-regular fa-user"></i>
          </span>
          <input
            type="text"
            className="pep-input"
            value={`${forename} ${surname}`}
          />
        </div>
      </div>
      {/* <form> */}
      <div
        className="grid lg:grid-cols-3 gap-4 mt-5 grid-cols-1"
        style={{ color: "#4C4D6C" }}
      >
        {/* Pupil Information */}
        {isLoading ? (
          <Skeleton count={12} height={50} />
        ) : (
          <div className="bg-white tableview">
            <div
              className="form-heading rounded-custom-content"
              style={{ backgroundColor: "#E6E6F2" }}
            >
              <h1>Pupil Information</h1>
            </div>
            <TextFormField
              fieldName="forename"
              fieldLabel="Forename"
              fieldValue={forename}
              fieldIcon={
                <FiFolder
                  onClick={(e) => openHistoryModal(e, "forename")}
                  className="form-field-icon"
                />
              }
              required={true}
            />
            <TextFormField
              fieldName="surname"
              fieldLabel="Surname"
              fieldValue={surname}
              fieldIcon={
                <FiFolder
                  onClick={(e) => openHistoryModal(e, "surname")}
                  className="form-field-icon"
                />
              }
              required={true}
            />
            <TextFormField
              fieldName="upn"
              fieldLabel="UPN"
              fieldValue={upn}
              required={true}
            />
            {activePupilYear === "Y12" ||
            activePupilYear === "Y13" ||
            activePupilYear === "Y14" ? (
              <TextFormField
                fieldName="uln"
                fieldLabel="ULN"
                fieldValue={uln}
              />
            ) : (
              ""
            )}
            {(() => {
              if (schoolId && schoolId == 1934) {
                return (
                  <TextFormField
                    fieldName="Notes(LVS use only)"
                    fieldLabel="Notes(LVS use only)"
                    fieldValue={lvsNotes}
                  />
                );
              }
            })()}
            <TextFormField
              fieldName="dob"
              fieldLabel="DOB"
              fieldValue={stringDob}
            />
            <TextFormField
              fieldName="age"
              fieldLabel="Age"
              fieldValue={calculateAgeFromDob(dob)}
            />
            <TextFormField
              fieldName="yeargroup"
              fieldLabel="Year Group"
              required={true}
              fieldValue={yeargroup.split(" ")[0]}
            />
            <TextFormField
              fieldName="phase"
              fieldLabel="Phase"
              fieldValue={phase}
            />
            <TextFormField
              fieldName="gender"
              fieldLabel="Gender"
              fieldValue={gender === "F" ? "Female" : "Male"}
              required={true}
            />
            <TextFormField
              fieldName="senCop"
              fieldLabel="SEND"
              fieldValue={senCop}
              required={true}
            />
            <TextFormField fieldName="cwd" fieldLabel="CWD" fieldValue={cwd} />
            {(() => {
              if (schoolId && schoolId !== 1934) {
                return (
                  <TextFormField
                    fieldName="eal"
                    fieldLabel="EAL"
                    fieldValue={eal}
                  />
                );
              }
            })()}
            {(() => {
              if (schoolId && schoolId !== 1934) {
                return (
                  <TextFormField
                    fieldName="firstLanguage"
                    fieldLabel="First Language"
                    fieldValue={firstLanguage}
                  />
                );
              }
            })()}
            <TextFormField
              fieldName="ethnicity"
              fieldLabel="Ethnicity"
              fieldValue={ethnicity}
            />
            <TextFormField
              fieldName="uasc"
              fieldLabel="UASC"
              fieldValue={uasc}
              checkedFieldValue="Yes"
              unCheckedFieldValue="No"
              fieldRadioSpan="No"
              // required={false}
            />
            <TextFormField
              fieldName="dateIntoCare"
              fieldLabel="Date Into Care"
              fieldValue={dateIntoCare}
              fieldIcon={
                <FiFolder
                  onClick={(e) => openHistoryModal(e, "dateIntoCare")}
                  className="form-field-icon"
                />
              }
            />
            ting
            <TextFormField
              fieldName="dateCareEnded"
              fieldLabel="Date Care Ended"
              fieldValue={dateCareEnded}
            />
            <TextFormField
              fieldName="ragRating"
              fieldLabel="RAG Rating"
              fieldValue={ragRating}
            />
            {/* <SelectFormField 
              fieldName="ragRating"
              fieldLabel="RAG Rating"
              value=""
              optionsList={[
                <option optionValue="Learning">Red</option>,
                <option optionValue="Attendance">Amber</option>,
                <option optionValue="SEMH">Green</option>,
              ]}
            /> */}
            <TextFormField
              fieldName="personEmail"
              fieldLabel="Young Person Email"
              fieldValue={youngPersonEmail}
            />
          </div>
        )}
        {/* School Column */}
        {isLoading ? (
          <Skeleton count={12} height={50} />
        ) : (
          <div className="bg-white tableview">
            <div
              className="form-heading rounded-custom-content"
              style={{ backgroundColor: "#E6E6F2" }}
            >
              <h1>School</h1>
            </div>
            <TextFormField
              fieldName="schoolName"
              fieldLabel={"School Name"}
              fieldValue={schoolName}
              required={true}
            />

            <TextFormField
              fieldName="schoolTel"
              fieldLabel={schoolTelLabel}
              fieldValue={schoolTel}
            />

            <TextFormField
              fieldName="schoolEmail"
              fieldLabel={schoolEmailLabel}
              fieldValue={schoolEmail}
            />

            <TextFormField
              fieldName="schoolAddress1"
              fieldLabel={schoolAddress1Label}
              fieldValue={schoolAddress1}
            />

            <TextFormField
              fieldName="schoolAddress2"
              fieldLabel={schoolAddress2Label}
              fieldValue={schoolAddress2}
            />

            <TextFormField
              fieldName="schoolAddress3"
              fieldLabel={schoolAddress3Label}
              fieldValue={schoolAddress3}
            />

            <TextFormField
              fieldName="schoolPostalcode"
              fieldLabel="School Postcode"
              fieldValue={schoolPostcode}
            />

            <TextFormField
              fieldName="inLaOrOut"
              fieldLabel="In LA or Out"
              // fieldLabel="inLaOrOut"
              fieldRadioSpan="Out"
              fieldValue={laInOrOut}
              checkedFieldValue="In"
              unCheckedFieldValue="Out"
            />

            <TextFormField
              fieldName="schoolWebsite"
              fieldLabel="School Website"
              fieldValue={schoolWebsite}
            />

            <TextFormField
              fieldName="ofstedGrade"
              fieldLabel="Ofsted Grade"
              fieldValue={ofstedGrade}
            />

            <TextFormField
              fieldName="schoolLa"
              fieldLabel="School LA"
              fieldValue={schoolLa}
            />

            <TextFormField fieldName="urn" fieldLabel="URN" fieldValue={urn} />

            <TextFormField fieldName="dfe" fieldLabel="DFE" fieldValue={dfe} />

            <TextFormField
              fieldName="schoolmoves"
              fieldLabel="Number of Schools in last 2 years"
              fieldValue={schoolmoves}
            />

            <TextFormField
              fieldName="dualRegisteredSchool"
              fieldLabel="Dual Registered School"
              fieldValue={dualRegisterSchool}
            />

            <TextFormField
              fieldName="leaversflag"
              fieldLabel="Leavers"
              fieldValue={leavers}
            />

            {/* <div className="py-2 flex w-full items-center">
              <label>Leavers</label>
            <div className="pl-4 radio">
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
                <span className="ml-2" defaultChecked>Yes</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
                <span className="ml-2">No</span>
              </label>
            </div>
            </div> */}
          </div>
        )}

        {/* Additional Pupil Information Column */}
        {isLoading ? (
          <Skeleton count={12} height={50} />
        ) : (
          <div className="bg-white tableview">
            <div
              className="form-heading rounded-custom-content"
              style={{ backgroundColor: "#E6E6F2" }}
            >
              <h1>Additional Pupil Information</h1>
            </div>

            <TextFormField
              fieldName="vsPupilNo"
              fieldLabel="VS No"
              required={true}
              fieldValue={vsPupilNo}
            />

            <TextFormField
              fieldName="legalStatus"
              fieldLabel="Legal Satus"
              fieldValue={legalStatus}
            />

            <TextFormField
              fieldName="numberOfPlacement"
              fieldLabel="Number of Placement"
              fieldValue={numberOfPlacements}
            />

            <TextFormField
              fieldName="typeOfPlacement"
              fieldLabel="Types of Placement"
              fieldValue={typeOfPlacement}
              fieldIcon={
                <FiFolder
                  onClick={(e) => openHistoryModal(e, "typeOfPlacement")}
                  className="form-field-icon"
                />
              }
            />

            {/* <TextFormField
              fieldName="placementLocalAuthority"
              fieldLabel="Placement Local Authority"
              fieldValue={placementLocalAuthority}
            /> */}

            <TextFormField
              fieldName="placementDate"
              fieldLabel="Placement Date"
              fieldValue={placementDate}
            />

            {(() => {
              if (schoolId && schoolId != 1934) {
                return (
                  <TextFormField
                    fieldName="contactArrangements"
                    fieldLabel="Contact Arrangements"
                    fieldValue={contactArrangements}
                  />
                );
              }
            })()}

            {/* {(() => {
              if (schoolId && schoolId == 1934) {
                return ( */}
            <TextFormField
              fieldName="placementNotification"
              fieldLabel="Placement Notification Letter Received Date"
              fieldValue={placementNotification}
            />
            {/* )
              } else {
                <TextFormField
                  fieldName="placementNotification"
                  fieldLabel="Placement Notification"
                  fieldValue={placementNotification}
                />
              }
            })()} */}

            <TextFormField
              fieldName="dateBecameCareLeaver"
              fieldLabel="Date became Care Leaver"
              fieldValue={dateBecameCareLeaver}
            />

            {(() => {
              if (schoolId && schoolId != 1934) {
                return (
                  <TextFormField
                    fieldName="countryOfOrigin"
                    fieldLabel="Country of Origin"
                    fieldValue={countryOfOrigin}
                  />
                );
              }
            })()}

            {(() => {
              if (schoolId && schoolId != 1934) {
                return (
                  <TextFormField
                    fieldName="religion"
                    fieldLabel="Religion"
                    fieldValue={religion}
                  />
                );
              }
            })()}

            {/* {(() => {
              if (schoolId && schoolId == 29679) {
                return (
                  <TextFormField
                    fieldName="laptop"
                    fieldLabel="Laptop"
                    fieldValue={laptop}
                  />
                )
              }
            })()} */}

            <TextFormField
              fieldName="course"
              fieldLabel="Course"
              fieldValue={course}
            />

            {(() => {
              if (schoolId && schoolId != 1934) {
                return (
                  <TextFormField
                    fieldName="tution"
                    fieldLabel="Tuition"
                    fieldValue={tution}
                  />
                );
              }
            })()}

            {activePupilYear == "Y12" ||
            activePupilYear == "Y13" ||
            activePupilYear == "Y14" ? (
              <TextFormField
                fieldName="nationalInsuranceNo"
                fieldLabel="National Insurance Number"
                fieldValue={nationalInsuranceNo}
              />
            ) : (
              ""
            )}

            {activePupilYear == "Y12" ||
            activePupilYear == "Y13" ||
            activePupilYear == "Y14" ? (
              <TextFormField
                fieldName="course"
                fieldLabel="Course"
                fieldValue={course}
              />
            ) : (
              ""
            )}

            <TextFormField
              fieldName="intendedDestination"
              fieldLabel="Intended Destination"
              fieldValue={intendedDestination}
            />

            {(() => {
              if (
                activePupilYear == "Y12" ||
                activePupilYear == "Y13" ||
                activePupilYear == "Y14" ||
                activePupilYear == "N" ||
                activePupilYear == "N1" ||
                activePupilYear == "N2" ||
                activePupilYear == "N3" ||
                activePupilYear == "N4" ||
                activePupilYear == "R"
              ) {
                return (
                  <TextFormField
                    fieldName="youngParent"
                    fieldLabel="Young Parent"
                    fieldValue={youngParent}
                  />
                );
              }
            })()}

            {/* {activePupilYear == "Y7" || activePupilYear == "Y8" || activePupilYear == "Y9"
              || activePupilYear == "Y10" || activePupilYear == "Y11" || activePupilYear == "Y12"
              || activePupilYear == "Y13" || activePupilYear == "Y14" ?
              <TextFormField
                fieldName="yosService"
                fieldLabel="YOS Service"
                fieldValue={yosService}
              /> : ""} */}

            {/* <TextFormField
              fieldName="cicType"
              fieldLabel="CIC Type"
              fieldValue={cicType}
            /> */}

            <TextFormField
              fieldName="careStatus"
              fieldLabel="Care Status"
              fieldValue={careStatus}
              fieldIcon={
                <FiFolder
                  onClick={(e) => openHistoryModal(e, "careStatus")}
                  className="form-field-icon"
                />
              }
            />
            {/* <SelectFormField 
              fieldName="careStatus"
              fieldLabel="Care Status"
              optionsList={[
                <option optionValue="">Select</option>,
                <option optionValue="CIC">CIC</option>,
                <option optionValue="CIN">CIN</option>,
                <option optionValue="CP">CP</option>,
                <option optionValue="PLAC">PLAC</option>,
                <option optionValue="PCIN">PCIN</option>,
                <option optionValue="PCP">PCP</option>,
              ]}
              
            /> */}

            <TextFormField
              fieldName="ppEligible"
              fieldLabel="PP + Eligible"
              fieldValue={ppEligible}
              required={true}
            />

            {/* <div className="py-2 flex w-full items-center">
              <label>PP + Eligible</label>
              <span>*</span>
            <div className="pl-4 radio">
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input type="radio" className="form-radio" name="ofstedRegisterdEstablished" />
                <span className="ml-2" >No</span>
              </label>
            </div>
            </div> */}

            <TextFormField
              fieldName="youngPersonTel"
              fieldLabel="Young Person Tel"
              fieldValue={youngPersonTel}
            />

            <TextFormField
              fieldName="dateaddedintoASSET"
              fieldLabel="Date added into ASSET"
              fieldValue={interviewBeenAddedToAsset}
              fieldIcon={
                <FiFolder
                  onClick={(e) =>
                    openHistoryModal(e, "interviewBeenAddedToAsset")
                  }
                  className="form-field-icon"
                />
              }
            />
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 mt-4">
        {/* Connection Information Column */}
        <div className="tableview bg-white">
          <div
            className="form-heading rounded-custom-content"
            style={{ backgroundColor: "#E6E6F2" }}
          >
            {isLoading ? (
              <Skeleton count={1} height={10} />
            ) : (
              <h1>Connections</h1>
            )}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
            {isLoading ? (
              <Skeleton count={2} height={50} />
            ) : (
              <div className="">
                <TextFormField
                  fieldName="socialWorkerName"
                  fieldLabel="Social Worker Name"
                  required={true}
                  fieldValue={socialWorker}
                />
                {/* <TextFormField
                  fieldName="socialWorkerTeam"
                  fieldLabel="Social Worker Team"
                  required={true}
                  fieldValue={socialWorkerTeam}
                /> */}
                <TextFormField
                  fieldName="pupilCarerName"
                  fieldLabel="Pupil Carer Name"
                  fieldValue={pupilsCarerName}
                  required={true}
                />
              </div>
            )}
            {isLoading ? (
              <Skeleton count={2} height={50} />
            ) : (
              <div className="">
                <TextFormField
                  fieldName="designatedTeacher"
                  fieldLabel="Designated Teacher"
                  fieldValue={designatedTeacher}
                />
                <TextFormField
                  fieldName="iro"
                  fieldLabel="IRO"
                  fieldValue={iro}
                  required={true}
                />
              </div>
            )}
            {isLoading ? (
              <Skeleton count={2} height={50} />
            ) : (
              <div className="">
                <TextFormField
                  fieldName="achievementOfficerr"
                  fieldLabel="Achievement Officer"
                  fieldValue={"acheievment"}
                  required={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </form> */}

      {isHistoryModalOpen && (
        <HistoryModal
          isHistoryModalOpen={isHistoryModalOpen}
          closeHistoryModal={closeHistoryModal}
          historyModalName={historyModalName}
        />
      )}
    </div>
  );
};

export default PepBasicPupilTab;
