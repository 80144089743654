import React from 'react'
import { setLoading, setNccMeetingPost16Report, setNccMeetingReport } from '../../constants/reducerActions'

const nccMeetingReducer = (state, action) => {

  const data = action.payload;
  
  switch(action.type) {
    
    case setNccMeetingReport:
      return {
        nccPepMeeting: data.nccPepMeeting,
        pupilUnique: data.pupilUnique,
        subSchool: data.subSchool,
        pupilVoice: data.pupilVoice,
        presentPer: data.presentPer,
        exclusionCount: data.exclusionCount,
        isLoading: false,
      }

    case setNccMeetingPost16Report:
      return {
        post16Meeting: data.post16Meeting,
        pupilUnique: data.pupilUnique,
        subSchool: data.subSchool,
        pupilVoice: data.pupilVoice,
        presentPer: data.presentPer,
        exclusionCount: data.exclusionCount,
        isLoading: false,
      }

    case setLoading:
      return {
        ...state,
        isLoading: data.isLoading,
      }
  }
}

export default nccMeetingReducer
