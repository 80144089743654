import React from 'react';
import BasicInfoRoute from './BasicInfoRoute';
import BasicInfoHeader from './Component/BasicInfoHeader';
import PepAttendanceTab from './Pages/PepAttendanceTab/Index';
import PepBasicDocsTab from './Pages/PepBasicDocsTab/Index';
import PepPriorAttainmentTab from './Pages/PepPriorAttainmentTab/Index';

const PepBasicInfo = () => {
  return (
  <div className="secondary-section w-full">
    <BasicInfoHeader />
    
    <BasicInfoRoute />
    {/* <PepBasicPupilTab /> */}
    {/* <PepBasicDocsTab /> */}
    {/* <PepPriorAttainmentTab /> */}
    {/* <PepAttendanceTab /> */}
  </div >);
}

export default PepBasicInfo;