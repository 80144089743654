import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { routeUrl } from '../../../../../Components/utils/RouteUrl';
import useActivateTabBasicInfo from '../../../../../hooks/useActivateTabBasicInfo';
import PupilContext from '../../../../../Components/context/PupilContext';
import { settings } from '../../../../../Components/utils/Settings';
import moment from 'moment';
import InterventionRow from './components/InterventionRow';
import DatePicker from "react-datepicker";
import Loader from '../../../../Pep/PepTarget/components/Loader';
import { showWarningToast } from '../../../../../Components/utils/utilityFunctions';
// import Loader from '../../../PepTarget/components/Loader';

const interventionTypeOptions = [
  "", "Academic Tutor", "Application Support",
  "CAMHS (Child and Adolescent Mental Health Services)", "EP",
  "ESOL Outreach Tutor", "Exclusions / Inclusions", "IAG Interview",
  "Mentor", "Monday Wheels", "Progressions Coach", "Transition",
  "VS CAMHS (Virtual School Child and Adolescent Mental Health Services)",
  "VS EP", "Youth Offending Service", "Other"
]
const PepInterventionTab = () => {

  useActivateTabBasicInfo(routeUrl.basic_info_pep_intervention);

  const { activePupilId } = useContext(PupilContext);

  const roleId = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const schoolId = localStorage.getItem("schoolId");
  const [isLoading, setIsLoading] = useState(false);
  const [pupilInterventions, setPupilInterventions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { activePupilYear, dateOfMeeting, pupilInfo: pupilInfoContext } = useContext(PupilContext);

  useEffect(() => {
    activePupilId && axios(`${settings.API_HOST}/rest/pupilInterventions?pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
      .then(res => {
        setPupilInterventions(res.data.interventionList ? res.data.interventionList : null);
      }).catch(err => {
        setIsLoading(false);
        showWarningToast(err);
      });
  }, [activePupilId]);

  useEffect(() => {
    if (startDate.length > 0 && endDate.length > 0) {
      setIsLoading(true);
      activePupilId && axios(`${settings.API_HOST}/rest/pupilInterventions?startDate=${startDate}&endDate=${endDate}&pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
        .then(res => {
          setIsLoading(false);
          setPupilInterventions(res.data.interventionList ? res.data.interventionList : null);
        }).catch(err => {
          setIsLoading(false);
          showWarningToast(err);
        });
    }
  }, [startDate, endDate]);

  const formatedDate = (date) => {
    const slicedDate = date && date.split("/");
    const newDate = (slicedDate && slicedDate.length > 2) ? `${slicedDate[2]}/${slicedDate[1]}/${slicedDate[0]}` : null;
    return date ? (date.length > 2 ? new Date(newDate) : new Date(null)) : null;
  }

  const DeleteInterventionRow = (id) => {
    setIsLoading(true);
    axios.get(`${settings.API_HOST}/rest/DeleteInterventions?noteId=${id}&pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
      .then((res) => {
        setIsLoading(false);
        setPupilInterventions(res.data.interventionList ? res.data.interventionList : null);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const AddInterventionRow = () => {
    setIsLoading(true);
    axios.get(`${settings.API_HOST}/rest/generateInterventionRow?pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
      .then((res) => {
        setIsLoading(false);
        setPupilInterventions(res.data.interventionList ? res.data.interventionList : null);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const changeFieldHandler = (e, rowid, fieldValue) => {
    const newList = pupilInterventions
      .map(item => {
        if (item.id === rowid) {
          console.log("working")
          item[fieldValue] = e.target.value;
           if(fieldValue === "interventionType"){
            onBlurHandler(e,rowid,fieldValue);
           }
          return item;
        }
        return item;
      });
    setPupilInterventions(newList)
  }

  // Handler to format and set Date in field
  const changeDateHandler = (date, rowid, fieldValue) => {
    const newList = pupilInterventions
      .map(item => {
        if (item.id === rowid) {
          item[fieldValue] = moment(date).format("DD/MM/YYYY");
          onBlurDateHandler(moment(date).format("DD/MM/YYYY"), rowid, fieldValue);
          return item;
        }
        return item;
      });
    setPupilInterventions(newList)
  }

  // Filter Handler for filter data
  const filterIntervention = (date, fieldValue) => {
    if (fieldValue === "start-date") {
      setStartDate(moment(date).format("DD/MM/YYYY"));
    }
    if (fieldValue === "end-date") {
      setEndDate(moment(date).format("DD/MM/YYYY"))
    }
  }

  // Handler for saving date
  const onBlurDateHandler = (value, rowId, attribute) => {
    saveIntervention(value, rowId, attribute);
  }

  // Handler for saving input fields
  const onBlurHandler = (e, rowId, attribute) => {
    saveIntervention(e.target.value, rowId, attribute);
  }

  const saveIntervention = (value, rowId, attribute) => {
    setIsLoading(true);
    axios.get(`${settings.API_HOST}/rest/saveInterventionRow?rowId=${rowId}&value=${value}&attribute=${attribute}&pupilId=${activePupilId}&schoolId=${schoolId}&userId=${userId}`)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="secondary-section w-full">
      {isLoading && <Loader />}
      <div className="basic-header flex w-full py-2 justify-between">
        <div className="basic-left">
          <h1>Interventions
            <span><i className="fa-regular fa-circle-video"></i></span>
            <span><i className="fa-regular fa-circle-info"></i></span>
          </h1>
        </div>
        <div className="basic-right">
          <table>
            <th>
              <td>
                <label className="pl-4 pr-2">Start Date</label>
              </td>
              <td>
                  <DatePicker
                    selected={formatedDate(startDate)}
                    className="pep-input"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => filterIntervention(date, "start-date")}
                  />
              </td>
              <td>
                <label className="pl-4 pr-2">End Date</label>
              </td>
              <td>
                  <DatePicker
                    selected={formatedDate(endDate)}
                    className="pep-input"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => filterIntervention(date, "end-date")}
                  />
              </td>
            </th>
          </table>
        </div>
         <div className="basic-right">
            <span>
            <i className="fa-regular fa-user"></i>
          </span>
        
          <input
            type="text"
            className="pep-input"
            value={pupilInfoContext && pupilInfoContext.fullname}
            onChange={(e) => console.log(e.target.value)}
          />
            </div>
      </div>
      <div className="rounded-3xl bg-white p-4 w-full">
        <div className="table-box w-full pt-2">
          <button className="blue-button px-5 py-2 text-sm mb-2" onClick={AddInterventionRow}>Add +</button>
          <div className="table-outer rounded-3xl bg-white">
            <table className="inner-table table-fixed text-center w-full">
              <thead>
                <tr>
                  <th className="blue w-32">Start Date</th>
                  <th className="d-blue">Intervention Type</th>
                  <th className="purple">Agency</th>
                  <th className="orange">Key Worker Name</th>
                  <th className="d-orange w-32">Hours (1 to 25)</th>
                  <th className="d-pink w-32">End Date</th>
                  <th className="pink w-12"></th>
                </tr>
              </thead>
              <tbody>

                {pupilInterventions && pupilInterventions.map((result) => {
                  return (
                    <InterventionRow
                      interventionTypeOptions={interventionTypeOptions}
                      id={result.id}
                      stringInterventionStartDate={result.stringInterventionStartDate}
                      interventionType={result.interventionType}
                      interventionAgency={result.interventionAgency}
                      interventionWorkerName={result.interventionWorkerName}
                      interventionHours={result.interventionHours}
                      stringInterventionEndDate={result.stringInterventionEndDate}
                      DeleteInterventionRow={DeleteInterventionRow}
                      formatedDate={formatedDate}
                      changeFieldHandler={changeFieldHandler}
                      changeDateHandler={changeDateHandler}
                      onBlurHandler={onBlurHandler}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PepInterventionTab;
