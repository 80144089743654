import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import { MainContextProvider } from './Components/context/MainContext';



ReactDOM.render(
    <MainContextProvider>
        <App />
    </MainContextProvider>,
    document.getElementById('app')
);