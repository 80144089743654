import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import PupilContext from '../../../../../Components/context/PupilContext';
import { routeUrl } from '../../../../../Components/utils/RouteUrl';
import useActivateTabBasicInfo from '../../../../../hooks/useActivateTabBasicInfo';
import { settings } from '../../../../../Components/utils/Settings';

const PepUascTab = () => {

  useActivateTabBasicInfo(routeUrl.basic_info_uasc);
  const { activePupilId, dateOfMeeting, activePupilYear } = useContext(PupilContext);


  const roleId = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const schoolId = localStorage.getItem("schoolId");
  const kidYear = localStorage.getItem("year");
  const [pupilInfo, setPupilInfo] = useState({

  });

  useEffect(() => {
    activePupilId && axios(`${settings.API_HOST}/rest/fetchPupilBasicInfo?pupilId=${activePupilId}&schoolId=${schoolId}&roleId=${roleId}&userId=${userId}`)

      .then(res => {
        console.log(res.data.pupilInfo, "UASC");
        setPupilInfo(res.data.pupilInfo);
      });

  }, [activePupilId]);

  return (
    <div className="secondary-section w-full">
      <div className="basic-header flex w-full py-2 justify-between">
        <div className="basic-left">
          <h1>UASC
            <span><i className="fa-regular fa-circle-video"></i></span>
            <span><i className="fa-regular fa-circle-info"></i></span>
          </h1>
        </div>
        <div className="basic-right">
          <span><i className="fa-regular fa-user"></i></span>
          <input type="text" className="pep-input" value="ASSET Secondary" />
        </div>
      </div>
      <div className="rounded-3xl bg-white p-4 w-full">
        <div className="flex flex-wrap w-full">
          <div className="w-4/12 pb-2">
            <label className="inline-block w-36 pr-2">Nationality</label>
            <input type="text" className="pep-input" value={pupilInfo ? pupilInfo.pepCountry : ""} />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-36 pr-2">First Language</label>
            <input type="text" className="pep-input" value={pupilInfo ? pupilInfo.firstLanguage : ""} />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-32 pr-2">Religion</label>
            <input type="text" className="pep-input" value={pupilInfo ? pupilInfo.religion : ""} />
          </div>
          <div className="w-4/12 pb-2">
            <label className="inline-block w-36 pr-2">Date Arrived in UK</label>
            {(() => {
              if (pupilInfo.dateArrived != null) {
                return (
                  <input type="text" className="pep-input" value={moment(pupilInfo.dateArrived != null ? pupilInfo.dateArrived : "").format("DD/MM/YYYY")} />
                )
              } else {
                return (
                  <input type="text" className="pep-input" />
                )
              }
            })()}



          </div>
          <div className="w-4/12 pb-2 leading-8">
            <label>Literate in 1st Language</label>
            <div className="radio inline-block pl-4">
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio" name="literateLang" checked={pupilInfo.literateLang !== null &&
                  pupilInfo.literateLang === "Yes"
                  ? true
                  : false
                } />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input type="radio" className="form-radio" name="literateLang" checked={pupilInfo.literateLang !== null &&
                  pupilInfo.literateLang === "No"
                  ? true
                  : false
                } />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="w-4/12 pb-2 leading-8">
            <label>Practising Religion</label>
            <div className="radio inline-block pl-4">
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio" name="practisingRel" checked={pupilInfo.practisingRel !== null &&
                  pupilInfo.practisingRel === "Yes"
                  ? true
                  : false
                } />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input type="radio" className="form-radio" name="practisingRel" checked={pupilInfo.practisingRel !== null &&
                  pupilInfo.practisingRel === "No"
                  ? true
                  : false
                } />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-3xl mt-4 mr-4 bg-white table-box w-full">
        <div className="p-4 widebox-container flex flex-wrap justify-between">
          <div className="widebox-inr w-full flex px-2 pb-4">
            <div className="w-64">
              <span className="rounded-2xl p-2 inline-block blue">History of education in home country </span>
            </div>
            <div className="textarea-block w-9/12 pl-8">
              <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Type here..." value={pupilInfo.skillsDeveloped ? pupilInfo.skillsDeveloped : ""}></textarea>

              {(() => {
                if (schoolId && schoolId == 29679) {
                  return (
                    <select className="pep-select" value={pupilInfo.historyOfEducationHomeCountry ? pupilInfo.historyOfEducationHomeCountry : ""}>
                      <option></option>
                      <option>Entry Level 1</option>
                      <option>Entry Level 2</option>
                      <option>Entry Level 3</option>
                      <option>Level one or above</option>
                    </select>
                  )
                }
              })()}

            </div>
          </div>
          <div className="widebox-inr w-full flex px-2 pb-4">
            <div className="w-64 ">
              <span className="rounded-2xl p-2 inline-block blue">English Language Skills </span>
            </div>
            <div className="textarea-block w-9/12 pl-8">
              <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Type here..." value={pupilInfo.uascEngLangSkill ? pupilInfo.uascEngLangSkill : ""}></textarea>
            </div>
          </div>

          {(() => {
            if (schoolId && schoolId != 29679) {
              return (
                <div className="widebox-inr w-full flex px-2 pb-4">
                  <div className="w-64">
                    <span className="rounded-2xl p-2 inline-block blue">Immigration Status </span>
                  </div>
                  <div className="textarea-block w-9/12 pl-8">
                    <select className="pep-select" value={pupilInfo.historyOfEducationHomeCountry ? pupilInfo.historyOfEducationHomeCountry : ""}>
                      <option></option>
                      <option>Asylum application in progress</option>
                      <option>UASC leave</option>
                      <option>Indefinite Leave to Remain</option>
                      <option>Refugee Status (5 years)</option>
                      <option>Humanitarian Protection (5 years)</option>
                      <option>Limited leave to remain</option>
                      <option>Section 67 leave (5 years)</option>
                    </select>
                  </div>
                </div>
                
              )
            }
          })()}

          <div className="widebox-inr w-full flex px-2 pb-4">
            <div className="w-64 ">
              <span className="rounded-2xl p-2 inline-block blue">Actions on Arrival </span>
            </div>
            <div className="textarea-block w-9/12 pl-8">
              <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Type here..." value={pupilInfo.uascEngLangSkill ? pupilInfo.uascEngLangSkill : ""}></textarea>
            </div>
          </div>

          <div className="widebox-inr w-full flex px-2 pb-4">
            <div className="w-64 ">
              <span className="rounded-2xl p-2 inline-block blue">ESOL </span>
            </div>
            <div className="radio inline-block pl-4">
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio" name="literateLang" checked={pupilInfo.literateLang !== null &&
                  pupilInfo.literateLang === "Yes"
                  ? true
                  : false
                } />
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input type="radio" className="form-radio" name="literateLang" checked={pupilInfo.literateLang !== null &&
                  pupilInfo.literateLang === "No"
                  ? true
                  : false
                } />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
}

export default PepUascTab;