import { Field } from 'formik';
import React, { useEffect, useState, useRef, memo } from 'react'
import Skeleton from 'react-loading-skeleton';

const AutoHeightInput = ({ fieldValue, fieldHandler, restForLoader, restForInput }) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const spanContainerRef = useRef();
  const spanInputRef = useRef(fieldValue);

  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    setContainerWidth(spanContainerRef.current.offsetWidth);
  }, [])

  return (
    <div className="w-full h-full" ref={spanContainerRef}>
      <span
        role="textbox"
        contentEditable={true}
        style={{
          display: "block",
          width: containerWidth,
          textAlign: "start",
        }}
        suppressContentEditableWarning={true}
        onInput={e => fieldHandler({ value: e.target.textContent, setIsLoading })}
        dangerouslySetInnerHTML={{ __html: spanInputRef.current }}
        {...restForInput}
      />
      {
        isLoading &&
          <div 
            className="gray-table-color absolute top-0 left-0 w-full h-full px-1 grid items-center" 
            {...restForLoader}
          >
            <Skeleton />
          </div>
      }
    </div>
  )
}

const isEqual = (prevState, nextState) => {
  if(prevState.fieldValue === nextState.fieldValue) {
    return true;
  } else {
    return false;
  }
}

// export default memo(AutoHeightInput, isEqual);
export default AutoHeightInput;
