import { Field } from 'formik';
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useDebouncedCallback } from 'use-debounce/lib';

const TextAreaField = ({
  placeholder, 
  rest, 
  rows,
  onChangeHandler,
  saveForm,
  fieldName,
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const debounceSaveForm = useDebouncedCallback(saveForm, 1000);

  return (
    <div className='mt-1 relative w-full'>
      <Field name={fieldName}>
        {
          ({form, field}) => {
            return (
              <textarea
                rows={rows ? rows : 4}
                placeholder={placeholder}
                className='border border-gray-300 rounded-xl w-full text-sm p-2'
                value={field.value ? field.value : ""}
                onChange={(e) => onChangeHandler({
                  fieldName,
                  setIsLoading,
                  fieldValue: e.target.value,
                  saveForm: debounceSaveForm,
                  setFieldValue: form.setFieldValue,
                  values: form.values,
                })}
                {...rest}
              >
              </textarea>
            )
          }
        }
      </Field>
      
      {
        isLoading &&
        <div className='absolute top-0 left-0 w-full h-full bg-white grid items-center'>
          <Skeleton />
        </div>
      }
    </div>
  )
}

export default TextAreaField
