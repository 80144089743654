import React from 'react';
import Skeleton from 'react-loading-skeleton';


const PepHeaderFilterLoadingTemplate = () => {
  const skeletonHeight = 20;
  return (
    <>
      <tr>
        <td colSpan={9}><Skeleton height={skeletonHeight} /></td>
      </tr>
      <tr>
        <td colSpan={9}><Skeleton height={skeletonHeight} /></td>
      </tr>
      <tr>
        <td colSpan={9}><Skeleton height={skeletonHeight} /></td>
      </tr>
      <tr>
        <td colSpan={9}><Skeleton height={skeletonHeight} /></td>
      </tr>
      <tr>
        <td colSpan={9}><Skeleton height={skeletonHeight} /></td>
      </tr>
      <tr>
        <td colSpan={9}><Skeleton height={skeletonHeight} /></td>
      </tr>
      <tr>
        <td colSpan={9}><Skeleton height={skeletonHeight} /></td>
      </tr>
    </>
  )
}

export default PepHeaderFilterLoadingTemplate